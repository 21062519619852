import { Divider } from '@mui/material';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import React from 'react';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useNavigate } from 'react-router-dom';

import { NotificationResourceTypeEnum } from '../../../domain/notification/NotificationResourceTypeEnum';
import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import { IRelationRepository } from '../../../domain/relation/relation.repository';
import { RelationStatusEnum } from '../../../domain/relation/RelationStatusEnum';
import {
  TranslationOption,
  TranslationType,
} from '../../../domain/translation/Translation.repository';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { routesConfig } from '../../Configs/Routes.config';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';

interface NotificationInvitationStructureContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
  isResponse?: boolean;
}

export const getOnClickResponseAddFriend = (
  relationRepository: IRelationRepository,
  relationId: number | string,
  t: (key: TranslationType, option?: TranslationOption) => string,
  response: boolean,
  reFetchMessage: () => void,
  setLoading: (loading: boolean) => void,
) => {
  return () => {
    setLoading(true);
    relationRepository
      .responseInvitation(parseInt(relationId.toString()), response)
      .then(() => {
        reFetchMessage();
        setLoading(false);
        sendEventToastMessage(
          t('notification.content.invitationFriend.response.sended'),
          'success',
        );
      });
  };
};

export const getOnClickSeeProfilFromAddFriend = (
  navigate: NavigateFunction,
  idProfil: number,
) => {
  return () => {
    navigate(routesConfig.userProfil.to(idProfil));
  };
};

export const NotificationInvitationFriendContent = ({
  notification,
  reFetchNotification,
  isResponse,
}: NotificationInvitationStructureContentProps) => {
  const t = useTranslate();
  const { relationRepository } = useContextDependency();
  const [relation] = useRetrieveFromDomain(
    () =>
      relationRepository.getById(
        parseInt(notification.resources[NotificationResourceTypeEnum.RELATION_ID]),
      ),
    undefined,
    !!notification.resources[NotificationResourceTypeEnum.RELATION_ID],
  );
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  if (!relation) return null;
  return (
    <div>
      <Divider className={'-topMarged'} />
      {relation?.status === RelationStatusEnum.ACCEPTED && (
        <span>
          {t(
            !isResponse
              ? 'notification.content.invitationFriend.alreadyAccepted'
              : 'notification.content.invitationFriend.response.accepted',
          )}
        </span>
      )}
      {!isResponse && relation && relation.status === RelationStatusEnum.PENDING && (
        <ActionButtonsForContent
          loading={loading}
          onClickExitTo={getOnClickSeeProfilFromAddFriend(
            navigate,
            notification.userFrom.idProfil,
          )}
          onClickCancel={getOnClickResponseAddFriend(
            relationRepository,
            notification.resources[NotificationResourceTypeEnum.RELATION_ID],
            t,
            false,
            reFetchNotification,
            setLoading,
          )}
          onClickValid={getOnClickResponseAddFriend(
            relationRepository,
            notification.resources[NotificationResourceTypeEnum.RELATION_ID],
            t,
            true,
            reFetchNotification,
            setLoading,
          )}
        />
      )}
    </div>
  );
};
