import { AspectRatio, HowToReg } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { ModalPendingEventParticipationsInvitation } from 'primary/activities/Modals/ModalPendingEventParticipationsInvitation';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React, { useState } from 'react';

export const ButtonInvitationsEventParticipations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { eventParticipantRepository } = useContextDependency();
  const [invitationList, , loading, retry] = useRetrieveFromDomain(
    () => eventParticipantRepository.getAllPendingEventParticipationForUser(),
    undefined,
  );

  const afterSubmit = () => {
    retry();
    if (invitationList?.length && invitationList.length - 1 === 0) {
      setIsOpen(false);
    }
  };

  return (
    <div className={'-socialItems'}>
      <div className={'-iconWithNumber'}>
        <HowToReg className={'-socialLogo'} />
        {!invitationList && <CircularProgress size={15} color={'info'} />}
        {invitationList && <span>{invitationList?.length ?? 0}</span>}
      </div>
      <div className={'-titleWithBtn'}>
        <Title title={'Participation à valider'} level={3} compensatePadding />
        <IconButton
          disabled={invitationList && invitationList.length <= 0}
          onClick={() => setIsOpen(true)}
        >
          <AspectRatio fontSize={'small'} />
        </IconButton>
      </div>
      {!invitationList && <CircularProgress size={15} color={'info'} />}
      {invitationList && (
        <ModalPendingEventParticipationsInvitation
          isOpen={isOpen}
          invitationList={invitationList}
          afterSubmit={afterSubmit}
          handleClose={() => setIsOpen(false)}
          loading={loading}
        />
      )}
    </div>
  );
};
