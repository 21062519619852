import '../../../../Utils/Forms/UnpyForm.scss';

import { Cancel, SwapHoriz } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField } from '@mui/material';
import { IEventRepository } from 'domain/event/Event.repository';
import { IMatchRepository } from 'domain/event/match/Match.repository';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import {
  ChangeHandlerTypeParam,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type GenerateMatchesFormData = {
  eventId?: number;
  matchPerSession?: number;
  totalMatchBR?: number;
  totalSession?: number;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    eventId: yup.number().required(t('general.forms.errors.required')),
    matchPerSession: yup.number().nullable(),
    totalMatchBR: yup.number().nullable(),
    totalSession: yup.number().nullable(),
  });

export const useOnSubmitGenerate =
  (
    matchRepository: IMatchRepository,
    idEvent: number,
    onSubmitted: () => void,
    handleClose: () => void,
    setLoading: (loading: boolean) => void,
  ) =>
  (data: GenerateMatchesFormData) => {
    setLoading(true);
    matchRepository
      .generateMatchs(data)
      .then(() => onSubmitted())
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

export const useOnSubmitStartEvent =
  (
    eventRepository: IEventRepository,
    idEvent: number,
    onSubmitted: () => void,
    handleClose: () => void,
    setLoading: (loading: boolean) => void,
  ) =>
  (data: GenerateMatchesFormData) => {
    setLoading(true);
    eventRepository
      .startEvent(idEvent, data)
      .then(() => onSubmitted())
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

interface GenerateMatchesFormProps {
  event: UnpyEvent | UnpyEventView;
  handleClose: () => void;
  onSubmitted: () => void;
  isForStart?: boolean;
  totalMatch?: number;
}

export const GenerateMatchesForm: FC<GenerateMatchesFormProps> = ({
  handleClose,
  onSubmitted,
  event,
  isForStart,
  totalMatch,
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
    control,
  } = useForm<GenerateMatchesFormData>({
    resolver,
    defaultValues: { eventId: event.id as number },
  });
  const [loading, setLoading] = useState(false);
  const { matchsRepository, eventRepository } = useContextDependency();
  const t = useTranslate();
  const onSubmit = isForStart
    ? useOnSubmitStartEvent(
        eventRepository,
        event.id as number,
        onSubmitted,
        handleClose,
        setLoading,
      )
    : useOnSubmitGenerate(
        matchsRepository,
        event.id as number,
        onSubmitted,
        handleClose,
        setLoading,
      );

  return (
    <Box className={'unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'unpy-form__row '}>
          {event.typeEvent.code === TypeEventCodeEnum.LIGUE && (
            <>
              <span className={'-totalMatchToGenerate'}>
                {t('classement.parameters.totalMatchToGenerate')}
                {totalMatch}
              </span>
              <div className={'-inputSwitchValues'}>
                <InputWrapper
                  label={t(
                    'classement.parameters.generateClassemetMatchPerSessionFieldLabel',
                  )}
                  errors={errors}
                  Input={TextField}
                  required
                  isSubmitting={isSubmitting}
                  inputProps={{
                    type: 'number',
                    max: totalMatch,
                    placeholder: t(
                      'classement.parameters.generateClassemetMatchPerSessionFieldLabel',
                    ),
                    ...register('matchPerSession'),
                    onChange: (e: ChangeHandlerTypeParam) => {
                      setValue('matchPerSession', e?.target?.value as number);
                      setValue(
                        'totalSession',
                        e?.target?.value
                          ? Math.ceil(
                              (totalMatch as number) / (e?.target?.value as number),
                            )
                          : 0,
                      );
                      return Promise.resolve();
                    },
                  }}
                />
                <SwapHoriz />
                <InputWrapper
                  label={t('classement.parameters.generateClassemettotalSessionLabel')}
                  errors={errors}
                  Input={TextField}
                  required
                  isSubmitting={isSubmitting}
                  inputProps={{
                    type: 'number',
                    max: totalMatch,
                    placeholder: t(
                      'classement.parameters.generateClassemetMatchPerSessionFieldLabel',
                    ),
                    ...register('totalSession'),
                    onChange: (e: ChangeHandlerTypeParam) => {
                      setValue('totalSession', e?.target?.value as number);
                      setValue(
                        'matchPerSession',
                        e?.target?.value
                          ? Math.ceil(
                              // @ts-ignore
                              ((totalMatch as number) / e?.target?.value ?? 1) as number,
                            )
                          : 0,
                      );
                      return Promise.resolve();
                    },
                  }}
                />
              </div>
            </>
          )}
          {event.typeEvent.code === TypeEventCodeEnum.LIG_BR && (
            <InputWrapper
              label={t('event.modal.generateClassement.LIG_BR.labelTotalMatch')}
              errors={errors}
              Input={TextField}
              required
              isSubmitting={isSubmitting}
              inputProps={{
                type: 'number',
                max: totalMatch,
                placeholder: t('event.modal.generateClassement.LIG_BR.labelTotalMatch'),
                ...register('totalMatchBR'),
              }}
            />
          )}
        </div>
        <div className={'unpy-form__buttons'}>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={() => handleClose()}
            className={'cancel'}
          >
            <Cancel />
            {t('classement.parameters.generateClassementModalCancelBtn')}
          </Button>
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            loading={loading}
          >
            {isForStart
              ? t('classement.parameters.startEventModalSubmitBtn')
              : t('classement.parameters.generateClassementModalSubmitBtn')}
          </LoadingButton>
        </div>
      </form>
    </Box>
  );
};
