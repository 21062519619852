import { useMustConnected } from 'primary/hooks/useMustConnected';
import React from 'react';
import { ReactNode } from 'react';

export const RouteSecured = ({ children }: { children: ReactNode }) => {
  const { user, isAccountLoaded } = useMustConnected();
  if (!isAccountLoaded) return null;
  if (!user) return null;
  return <>{children}</>;
};
