import { AxiosInstance } from 'axios';
import { Pageable } from 'domain/pageable/Pageable';
import { Payout, PayoutFiters } from 'domain/payout/Payout';
import { IPayoutRepository } from 'domain/payout/Payout.repository';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toPayout } from 'secondary/payout/RestPayout';
import { toPageable } from 'secondary/RestPageable';

export class PayoutRepository
  extends AxiosProviderRepository
  implements IPayoutRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  search(
    filters: PayoutFiters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Payout>> {
    return this.axios
      .get('/unpy/api/payout/search', {
        params: {
          ...filters,
          page,
          nbPerPage,
        },
      })
      .then((response) => toPageable(response, toPayout));
  }
}
