import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { useGetPlayerOfEvent } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { setTeamModalOpen } from 'domain/modal/store/ModalSlice';
import { EventParticipantCardListItem } from 'primary/Components/Event/EventParticipantCardListItem';
import { LayoutList } from 'primary/Components/Layout/Lists/LayoutList';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { NoContentBloc } from 'primary/Components/NoContentBloc/NoContentBloc';
import { SkeletonNoContent } from 'primary/Components/SkeletonNoContent/SkeletonNoContent';
import { Title } from 'primary/Components/Title/Title';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

export const ParticipantsView = () => {
  const { event } = useOutletContext<EventViewPageContext>();
  const { eventRepository, eventParticipantRepository, authRepository } =
    useContextDependency();
  const t = useTranslate();
  const staticFilters = {
    idEvent: event?.id,
    onlyAccepted: true,
  };
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    changeNbPerPage,
  } = useFetchWithFilterPage<EventParticipant, EventParticipantFilters>({
    keyStoreFilters: 'event-participants-param-list',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => eventParticipantRepository.search(filters, page, 10),
  });
  const [isModalTeamOpen, setIsModalTeamOpen] = React.useState(false);
  const { eventParticipant: participant, retry } = useGetPlayerOfEvent(
    event?.id,
    authRepository?.currentUser?.userProfil?.id,
  );

  if (!event) return null;

  return (
    <div className={'eventViewPageBloc eventViewPageRules'}>
      {event?.id && authRepository?.currentUser?.userProfil?.id && (
        <MyEventParticipation
          eventId={event?.id}
          profilId={authRepository?.currentUser?.userProfil?.id}
        />
      )}
      <Title
        title={t('event.eventViewPage.players.title')}
        level={1}
        threeQuarter
        bordered
      />
      <LayoutList
        transparent
        withoutBorder
        tablePagination
        className={'-layoutListNotification'}
        resetFilters={() => setFilters({})}
        keyDomainFilter={'structureMember'}
        paginationProps={{
          goToPage: goToPage,
          currentPage: fetchedResource?.currentPage,
          numberOfItem: fetchedResource?.totalElementsCount,
          setRowPerPage: changeNbPerPage,
          numberOfItemPerPage: fetchedResource?.pageSize,
          pagesCount: fetchedResource?.pagesCount || 0,
        }}
        pagination
        listComponent={
          <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
            <SkeletonNoContent displayContent={!!event}>
              {fetchedResource?.content?.length !== 0 &&
                fetchedResource?.content?.map((participant) => (
                  <EventParticipantCardListItem
                    key={participant.id}
                    asView={true}
                    participant={participant}
                  />
                ))}
              {!fetchedResource?.content?.length && (
                <NoContentBloc text={t('general.noContentList.eventParticipants')} />
              )}
            </SkeletonNoContent>
          </ListElements>
        }
        // @ts-ignore
        filtersTag={activeFilters?.filters}
        noTags
        onDeleteFilter={(filter) => {
          setFilters({ ...activeFilters?.filters, [filter.key]: undefined });
        }}
      />
    </div>
  );
};
type MyEventParticipationProps = {
  eventId: number;
  profilId: number;
  openTeam?: () => void;
};
const MyEventParticipation = ({ eventId, profilId }: MyEventParticipationProps) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { eventParticipant: participant, retry } = useGetPlayerOfEvent(eventId, profilId);
  const openModalTeam = () => {
    console.log('TEAM', participant);

    dispatch(
      setTeamModalOpen({
        props: {
          event: participant?.event as UnpyEventView,
          teamRegistered: participant,
          afterChanges: retry as () => void,
        },
      }),
    );
  };
  if (!participant) return null;
  return (
    <div className={'myParticipation'}>
      <Title
        title={t('event.parameters.myParticipation.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <div className={'-participant'}>
        {participant && (
          <EventParticipantCardListItem
            participant={participant}
            displayState
            openTeam={openModalTeam}
          />
        )}
      </div>
    </div>
  );
};
