import { BalanceChargeStatus } from 'domain/balance/BalanceChargeStatus';
import { ClassementParticipant } from 'domain/classement/ClassementParticipant';
import { MatchParticipant } from 'domain/event/match/MatchParticipant';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { StructureView } from 'domain/structure/StructureView';
import { Team } from 'domain/team/Team';

export type EventParticipantFilters = {
  idProfil?: number;
  idEvent?: number;
  dateLastMatch?: string;
  orderByNextMatch?: boolean;
  hasScoreNull?: boolean;
  onlyAccepted?: boolean;
  balanceChargeStatus?: BalanceChargeStatus;
};
export class EventParticipant {
  constructor(
    public readonly id: number,
    public readonly team: Team,
    public readonly event: UnpyEventView,
    public readonly status: EventParticipantStatusEnum,
    public readonly structure?: StructureView,
    public readonly matchsParticipants?: MatchParticipant[],
    public readonly classementParticipant?: ClassementParticipant,
    public readonly balanceChargeStatus?: BalanceChargeStatus,
  ) {}
}
