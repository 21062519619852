import './ModalCheckout.scss';

import { Box } from '@mui/material';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import { CheckoutFormWrapper } from 'primary/Stripe/CheckoutFormWrapper';
import React from 'react';

interface ModalCheckoutProps {
  handleClose: () => void;
  title: string;
  description?: string;
  clientSecretKey: string;
  isOpen: boolean;
}

const ModalCheckout = ({
  handleClose,
  title,
  description,
  isOpen,
  clientSecretKey,
}: ModalCheckoutProps) => {
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={isOpen}
      content={
        <Box className={'modal-checkout'}>
          <div className={'modal-checkout__container'}>
            <div className={'-header'}>
              <h2>
                {title}
                <small>{description}</small>
              </h2>
            </div>
            <div className={'-content'}></div>
            <CheckoutFormWrapper clientSecret={clientSecretKey} />
          </div>
        </Box>
      }
    />
  );
};

export default ModalCheckout;
