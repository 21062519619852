import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { toEnum } from 'helper/enum.helper';

import { StructureMember } from '../../domain/structureMember/StructureMember';
import { RestRoleStructure, toRoleStructure } from '../roleStructure/RestRoleStructure';
import { RestStructure, toStructure } from '../structure/RestStructure';
import { RestUserView, toUserView } from '../user/RestUser';

export interface RestStructureMember {
  user: RestUserView;
  structure: RestStructure;
  role: RestRoleStructure;
  state: string;
}

export function toStructureMember(restStructureMember: RestStructureMember) {
  return new StructureMember(
    toUserView(restStructureMember.user),
    toRoleStructure(restStructureMember.role),
    toEnum(StructureMemberState, restStructureMember.state),
    restStructureMember.structure
      ? toStructure(restStructureMember.structure)
      : undefined,
  );
}
