import '../ParametersPage.scss';

import { Divider, Hidden } from '@mui/material';
import classNames from 'classnames';
import { getSubStatusOfEvent } from 'domain/event/UnpyEvent.func';
import { findIndex } from 'lodash';
import { EventTimelineInfos } from 'primary/classements/Ligue/Modal/Infos/EventTimelineInfos';
import { EventImage } from 'primary/Components/Event/EventImage';
import { TimelineEventWrapperContextParam } from 'primary/Components/Event/TimelineEvent';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { SkeletonNoContent } from 'primary/Components/SkeletonNoContent/SkeletonNoContent';
import { routesConfig } from 'primary/Configs/Routes.config';
import {
  UnpyEventAction,
  useUnpyEventReducer,
} from 'primary/context/event/Event.reducer';
import { HelperPopper } from 'primary/helpers/HelperPopper';
import { useMustConnected } from 'primary/hooks/useMustConnected';
import React, { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  EventSubStatusEnum,
  STEPPER_CONF,
} from '../../../../domain/event/EventStatusEnum';
import { formatDate } from '../../../../helper/date.helper';
import { MenusParameters } from '../../../Components/MenuParameters/MenusParameters';
import { menusParametersEvent } from '../../../Components/MenuParameters/MenusParametersEvent.config';
import { EventProvider } from '../../../context/event/EventProvider';
import { useContextDependency } from '../../../hooks/useContextDependency';
import { useFetch } from '../../../hooks/useFetch';
import { useTranslate } from '../../../hooks/useTranslate';

interface EventParametersPageProps {
  dispatchEvent: Dispatch<UnpyEventAction>;
}

const _useEventParametersPage = ({ dispatchEvent }: EventParametersPageProps) => {
  const { idEvent } = useParams<{ idEvent: string }>();
  const { eventRepository } = useContextDependency();
  const { user, isAccountLoaded } = useMustConnected();
  const [event, , , retry] = useFetch(
    () => eventRepository.getEventById(idEvent as unknown as number),
    undefined,
  );
  const isFetched = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (event && !isFetched.current) {
      dispatchEvent({ type: 'SET_EVENT', event: event });
      isFetched.current = true;
    }
  }, [event]);

  useEffect(() => {
    isFetched.current = false;
    retry();
  }, [idEvent]);

  useEffect(() => {
    if (isAccountLoaded && user && event) {
      if (event.creator.id != user.userProfil.id) {
        navigate(routesConfig.home.to());
      }
    }
  }, [isAccountLoaded, user, event]);

  return {
    structureFetched: event,
    isAccountLoaded,
  };
};

export function getDateString(
  eventStep: EventSubStatusEnum,
  startDateTime: Date,
  endSubscribeDate: Date,
) {
  if (!startDateTime || !endSubscribeDate) return undefined;
  else if (eventStep === EventSubStatusEnum.SUBSCRIBE_OPEN)
    return formatDate(endSubscribeDate, 'readable(with hour)');
  else if (eventStep === EventSubStatusEnum.SUBSCRIBE_CLOSE)
    return formatDate(startDateTime, 'readable(with hour)');
  else return undefined;
}

export const EventParametersPage: FC = () => {
  const [{ event }, dispatchEvent] = useUnpyEventReducer();
  const { authRepository } = useContextDependency();
  const { isAccountLoaded } = _useEventParametersPage({ dispatchEvent });
  const location = useLocation();
  const [, setActiveStep] = useState(
    event ? findIndex(STEPPER_CONF.steps, (value) => value.name === event.status) : 0,
  );
  const [, setActiveSubStep] = useState(
    event
      ? STEPPER_CONF.steps.map((step) =>
          step.subSteps?.findIndex((value) => value.name === getSubStatusOfEvent(event)),
        )?.[0]
      : 0,
  );
  const { idEvent } = useParams<{ idEvent: string }>();

  useEffect(() => {
    setActiveStep(
      event ? findIndex(STEPPER_CONF.steps, (value) => value.name === event.status) : 0,
    );
    setActiveSubStep(
      event
        ? STEPPER_CONF.steps.map((step) =>
            step.subSteps?.findIndex(
              (value) => value.name === getSubStatusOfEvent(event),
            ),
          )?.[0]
        : 0,
    );
  }, [event]);
  const navigate = useNavigate();
  const t = useTranslate();
  if (!isAccountLoaded) {
    return (
      <LoadingScreen
        message={t('event.parameters.getEventMsgLoading')}
        loading={!isAccountLoaded || !event}
      />
    );
  }
  return (
    <div key={idEvent} className={classNames('page-parameters', '-event')}>
      <EventProvider event={event} dispatch={dispatchEvent}>
        <>
          <MenusParameters
            confs={menusParametersEvent(authRepository?.currentUser)}
            withTimeline={!location.pathname.includes('timeline')}
            header={
              <SkeletonNoContent displayContent={!!event}>
                {event && (
                  <div
                    className={'-paramHeader'}
                    onClick={() =>
                      navigate(routesConfig.eventViewDetails.eventViewInfos.to(event.id))
                    }
                  >
                    <EventImage
                      image={event.publicImageUrl}
                      className={classNames('eventImage')}
                    />
                    <span>{t('general.menuEventParamTitle')}</span>
                    <span className={'-accent'}>{event.name}</span>
                  </div>
                )}
              </SkeletonNoContent>
            }
          />
          <div className={'parametersWithTimeline'}>
            {!location.pathname.includes('timeline') && (
              <Hidden smDown>
                <div className={'parameters__timeline'}>
                  <h4 className={'timelineTitle'}>
                    {t('event.parameters.timeline.title')}
                    <HelperPopper
                      color={'black'}
                      modal={{
                        title: (
                          <h3>
                            {t('event.parameters.timeline.infos.title')} <br />
                            <small>{t('event.parameters.timeline.infos.subTitle')}</small>
                          </h3>
                        ),
                      }}
                    >
                      <EventTimelineInfos event={event} />
                    </HelperPopper>
                  </h4>
                  <TimelineEventWrapperContextParam orientation={'horizontal'} />
                  <Divider className={'divider-param-timeline'} />
                </div>
              </Hidden>
            )}

            <div className={'parameters__content --scrollBarContent'}>
              <SkeletonNoContent displayContent={!!event}>
                {event && <Outlet />}
              </SkeletonNoContent>
            </div>
          </div>
        </>
      </EventProvider>
    </div>
  );
};
