import { PayoutStatusEnum } from 'domain/payout/PayoutStatus.enum';
import { PayoutTypeEnum } from 'domain/payout/PayoutType.enum';
import { UserView } from 'domain/user/UserView';

export type PayoutFiters = {
  type?: PayoutTypeEnum;
  userId?: number;
};

export class Payout {
  constructor(
    public readonly id: number,
    public readonly status: PayoutStatusEnum,
    public readonly stripeId: string,
    public readonly type: PayoutTypeEnum,
    public readonly amount: number,
    public readonly user: UserView,
    public readonly arrivalDate?: Date,
  ) {}
}
