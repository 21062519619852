import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeTeam } from 'domain/event/TypeTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import {
  EventRegisterFormData,
  EventRegisterPlayerFormData,
} from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { FormStepperConfStep } from 'primary/Components/FormStepper/FormStepper';

export type TeamCreatingSliceState = {
  data: EventRegisterFormData;
  activeStep: string;
  steps: FormStepperConfStep[];
  game: GAME_ACTIVE_LIST;
  typeTeam: TypeTeam;
  event: UnpyEventView & UnpyEvent;
};

type InitialTeamCreatingSliceState = {
  teamCreating?: TeamCreatingSliceState;
};

export const teamCreatingSlice = createSlice({
  name: 'user',
  initialState: {
    teamCreating: undefined,
  } as InitialTeamCreatingSliceState,
  reducers: {
    setTeamCreating: (
      state,
      action: PayloadAction<TeamCreatingSliceState | undefined>,
    ) => {
      state.teamCreating = action.payload;
    },
    setPlayers: (state, action: PayloadAction<EventRegisterPlayerFormData>) => {
      if (!state.teamCreating?.data) return;
      if (!action.payload) return;
      state.teamCreating.data.players = {
        ...state.teamCreating.data.players,
        ...action.payload,
      };
    },
    setPlayerProperty: (
      state,
      action: PayloadAction<{ name: string; property: string; value?: string }>,
    ) => {
      if (!state.teamCreating?.data) return;
      if (!action.payload) return;
      // @ts-ignore
      state.teamCreating.data.players = {
        ...state.teamCreating.data.players,
        [action.payload.name]: {
          ...state.teamCreating.data.players[action.payload.name],
          [action.payload.property]: action.payload.value,
        },
      };
    },
    setStructure: (state, action: PayloadAction<string | undefined>) => {
      if (!state.teamCreating?.data) return;
      if (!action.payload) return;
      state.teamCreating.data.structure = action.payload;
    },
    addPlayer: (
      state,
      action: PayloadAction<
        { idPlayer?: string; role?: string; nameField: string } | undefined
      >,
    ) => {
      if (!state.teamCreating?.data) return;
      if (!action.payload) return;
      // @ts-ignore
      // @ts-ignore
      state.teamCreating.data.players = {
        ...state.teamCreating.data.players,
        [action.payload.nameField]: {
          ...state.teamCreating.data.players[action.payload.nameField],
          idPlayer: action.payload.idPlayer,
          role: action.payload.role,
        },
      };
    },
    removePlayer: (state, action: PayloadAction<{ nameField: string }>) => {
      // @ts-ignore
      if (!state.teamCreating?.data) return;
      if (!action.payload) return;
      // @ts-ignore
      // @ts-ignore
      state.teamCreating.data.players = {
        ...state.teamCreating.data.players,
        [action.payload.nameField]: {
          ...state.teamCreating.data.players[action.payload.nameField],
          idPlayer: undefined,
          role: undefined,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setStructure,
  setPlayerProperty,
  setPlayers,
  setTeamCreating,
  addPlayer,
  removePlayer,
} = teamCreatingSlice.actions;

export default teamCreatingSlice.reducer;
