import {
  changeNbPerPageByKey,
  changePageByKey,
  FiltersSliceItem,
  FiltersStoreItems,
  KeyDomainFilters,
  resetDomainFilters,
  selectDomainFilters,
  setFiltersByKey,
} from 'domain/filters/FiltersSlice';
import { Pageable } from 'domain/pageable/Pageable';
import { useResetFiltersOnUnmount } from 'primary/hooks/useResetFiltersOnUnmount';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

type UseFetchWithFilterPageParams<R, F extends FiltersStoreItems> = {
  callApi: (filters: F, page: number, nbPerPage: number) => Promise<Pageable<R>>;
  filters: F;
  staticFilters?: Partial<F>;
  keepPageResult?: boolean;
  initialPage?: 0;
  initialNbPerPage?: 30;
  keyStoreFilters?: KeyDomainFilters;
  infiniteScroll?: boolean;
  conditionFetch?: (filters: F) => boolean;
};

type UseFetchWithFilterPageToStoreParams<R, F> = {
  callApi: (filters: F, page: number, nbPerPage: number) => Promise<Pageable<R>>;
  filters: F;
  initialPage?: 0;
  initialNbPerPage?: 30;
  select: () => R | undefined;
  dispatch: (resource: Pageable<R>) => void;
};

type ParametersFetch<F extends FiltersStoreItems> = {
  filters: F;
  page: number;
  nbPerPage: number;
};

export interface UseFetchWithFilterPageReturn<R, F extends FiltersStoreItems> {
  fetch: (fetchForRetry?: boolean) => void;
  fetchedResource: Pageable<R> | undefined;
  prevPage: () => void;
  nextPage: () => void;
  goToPage: (pageNumber: number) => void;
  changeNbPerPage: (nbPerPage: number) => void;
  loading: boolean;
  setFilters: (filters: F) => void;
  activeFilters: FiltersSliceItem<F>;
  retry: () => void;
  addToCurrentPage: (element: R) => void;
  allContents: R[] | undefined;
  reset: () => void;
  totalElementsCount?: number;
}

export function useFetchWithFilterPage<R, F extends FiltersStoreItems>({
  callApi,
  filters,
  initialPage,
  initialNbPerPage,
  keepPageResult,
  staticFilters = {},
  infiniteScroll,
  keyStoreFilters = 'divers',
  conditionFetch = (f: F) => true,
}: UseFetchWithFilterPageParams<R, F>): UseFetchWithFilterPageReturn<R, F> {
  const filtersStore = useSelector((state: RootState) =>
    selectDomainFilters(state, keyStoreFilters),
  );
  // const [parametersFetch, setParametersFetch] = useState<ParametersFetch<F>>({
  //   filters: filtersStore as F,
  //   page: initialPage || 0,
  //   nbPerPage: initialNbPerPage as number,
  // });
  const [allContents, setAllContents] = useState<R[]>();
  const [fetchedResource, setFetchedResource] = useState<Pageable<R> | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const activeFilters = filtersStore?.filters as F;
  const canFetch = activeFilters !== undefined && conditionFetch(activeFilters);

  useEffect(() => {
    dispatch(
      setFiltersByKey({
        key: keyStoreFilters,
        filters: { ...filters, ...staticFilters },
      }),
    );
  }, []);

  useEffect(() => {
    if (canFetch) {
      fetch();
    }
  }, [canFetch, filtersStore?.page, filtersStore?.nbPerPage, activeFilters]);

  const fetch = useCallback(
    (fetchForRetry?: boolean) => {
      setLoading(true);
      callApi(
        { ...(activeFilters || {}), ...staticFilters } as F,
        filtersStore?.page || 0,
        filtersStore?.nbPerPage || 10,
      )
        .then((result) => {
          if (keepPageResult) {
            if (fetchForRetry) {
              setAllContents(result?.content ?? []);
            } else if (
              filtersStore.page != undefined &&
              result.currentPage !== filtersStore.page
            ) {
              setAllContents((prevState) => [
                ...(result.content ?? []), // page 2 30 > 0
                ...(prevState || []), // page 0 72 > 50
              ]);
            } else {
              setAllContents(result?.content ?? []);
            }
          }
          setFetchedResource(result);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filtersStore],
  );

  const setFilters = useCallback(
    (filters: F) => {
      dispatch(
        setFiltersByKey({
          key: keyStoreFilters,
          filters: { ...filters, ...staticFilters },
        }),
      );
    },
    [keyStoreFilters],
  );

  const isNextPageAvailable = filtersStore?.page !== fetchedResource?.pagesCount;

  const nextPage = useCallback(() => {
    if (!isNextPageAvailable) return;
    dispatch(
      changePageByKey({ key: keyStoreFilters, page: (filtersStore?.page || 0) + 1 }),
    );
  }, [isNextPageAvailable, keyStoreFilters]);

  const goToPage = useCallback(
    (pageNumber: number) => {
      dispatch(changePageByKey({ key: keyStoreFilters, page: pageNumber }));
    },
    [keyStoreFilters],
  );

  const prevPage = useCallback(() => {
    dispatch(
      changePageByKey({ key: keyStoreFilters, page: (filtersStore?.page || 0) - 1 }),
    );
  }, [keyStoreFilters]);

  const resetDomainOfFilters = useCallback(() => {
    dispatch(resetDomainFilters(keyStoreFilters));
  }, []);

  const changeNbPerPage = useCallback(
    (nbPerPage: number) => {
      dispatch(changeNbPerPageByKey({ key: keyStoreFilters, nbPerPage: nbPerPage }));
    },
    [keyStoreFilters],
  );

  const retry = () => {
    fetch(true);
  };

  const addToCurrentPage = useCallback((element: R) => {
    setFetchedResource(
      (prevState) =>
        ({
          ...prevState,
          content: [element, ...(prevState?.content ?? [])],
        } as Pageable<R>),
    );
    if (keepPageResult) {
      setAllContents((prevState) => [
        ...[element], // page 2 30 > 0
        ...(prevState || []), // page 0 72 > 50
      ]);
    }
  }, []);

  useResetFiltersOnUnmount(resetDomainOfFilters);

  return {
    fetch,
    fetchedResource,
    prevPage,
    nextPage,
    goToPage,
    changeNbPerPage,
    loading,
    setFilters,
    activeFilters: filtersStore as FiltersSliceItem<F>,
    retry,
    addToCurrentPage,
    allContents: allContents,
    reset: resetDomainOfFilters,
    totalElementsCount: fetchedResource?.totalElementsCount,
  };
}

//
// export function useFetchWithFilterPageToStore<R, F>({
//   callApi,
//   filters,
//   initialPage,
//   initialNbPerPage,
//   select,
//   dispatch,
// }: UseFetchWithFilterPageToStoreParams<R, F>) {
//   const [parametersFetch, setParametersFetch] = useState<ParametersFetch<F>>({
//     filters: filters,
//     page: initialPage as number,
//     nbPerPage: initialNbPerPage as number,
//   });
//   const values = select();
//   const [loading, setLoading] = useState<boolean>(false);
//
//   const fetch = useCallback(() => {
//     setLoading(true);
//     callApi(parametersFetch.filters, parametersFetch.page, parametersFetch.nbPerPage)
//       .then((result) => {
//         dispatch(result);
//       })
//       .finally(() => {f
//         setLoading(false);
//       });
//   }, [parametersFetch]);
//
//   const setFilters = useCallback((filters: F) => {
//     setParametersFetch((prevValue) => ({
//       ...prevValue,
//       filters: filters,
//     }));
//   }, []);
//
//   const nextPage = useCallback(() => {
//     setParametersFetch((prevValue) => ({
//       ...prevValue,
//       page: prevValue.page + 1,
//     }));
//   }, []);
//
//   const goToPage = useCallback((pageNumber: number) => {
//     setParametersFetch((prevValue) => ({
//       ...prevValue,
//       page: pageNumber,
//     }));
//   }, []);
//
//   const prevPage = useCallback(() => {
//     setParametersFetch((prevValue) => ({
//       ...prevValue,
//       page: prevValue.page - 1,
//     }));
//   }, []);
//
//   const changeNbPerPage = useCallback((nbPerPage: number) => {
//     setParametersFetch((prevValue) => ({
//       ...prevValue,
//       nbPerPage: nbPerPage,
//     }));
//   }, []);
//
//   const retry = () => {
//     fetch();
//   };
//
//   useEffect(() => {
//     fetch();
//   }, [parametersFetch]);
//
//   return {
//     fetch,
//     values,
//     prevPage,
//     nextPage,
//     goToPage,
//     changeNbPerPage,
//     loading,
//     setFilters,
//     activeFilters: parametersFetch,
//     retry,
//   };
// }
