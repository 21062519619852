import './EventFilters.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterTypeEventProps = {
  onSelectTypeEvent: (value?: string) => void;
  selectedTypeEvent?: string;
};

export const FilterTypeEvent = ({
  onSelectTypeEvent,
  selectedTypeEvent,
}: FilterTypeEventProps) => {
  const { eventRepository } = useContextDependency();
  const [eventType] = useFetch(() => eventRepository.getAllTypeEvent(), undefined);
  if (!eventType) return null;
  const t = useTranslate();
  return (
    <InputSelectTag
      placeholder={t('event.filters.typeEvent')}
      options={insertEmptyOpt(
        eventType.map((eventType) => ({
          value: eventType.code,
          label: t(`enum.typeEventCode.${eventType.code}`),
        })),
      )}
      onChange={(event: ChangeHandlerTypeMeta) => {
        //ts-ignore
        onSelectTypeEvent(event.target.value as string);
      }}
      value={selectedTypeEvent}
      menuPortalTarget={document.body}
    />
  );
};
