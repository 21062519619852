import './ActionButton.scss';

import { PersonAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { stopPropagationBeforeAction } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

import { ModalJoinStructure } from './Modals/ModalJoinStructure';

interface ActionButtonJoinStructureProps {
  profil: StructureProfil;
  withIcon?: boolean;
}

export const ActionButtonJoinStructure = ({
  profil,
  withIcon,
}: ActionButtonJoinStructureProps) => {
  const [isOpen, toggleOpen, , closeModal] = useBooleanToggle();
  const t = useTranslate();
  const isRestricted = isPrivacyRestricted(profil, PRIVACY_CODE.JOIN_STRUCTURE);
  const { structureMemberRepository, authRepository } = useContextDependency();
  const [member, , fetching] = useRetrieveFromDomain(
    () =>
      structureMemberRepository.getMember(
        profil?.structure?.id as number,
        authRepository.currentUser?.id as number,
      ),
    undefined,
    !!profil?.structure?.id && !!authRepository.currentUser?.id,
  );
  return (
    <>
      {isOpen && (
        <ModalJoinStructure
          handleClose={closeModal}
          isOpen={isOpen}
          name={profil.name || profil.structure?.name || ''}
          structureProfilId={profil.id}
        />
      )}
      <LoadingButton
        loading={fetching}
        color={'inherit'}
        variant={'text'}
        className={'-actionButton'}
        onClick={stopPropagationBeforeAction(toggleOpen)}
        disabled={!authRepository.currentUser?.id || !!member || isRestricted}
      >
        {withIcon && <PersonAdd />}
        {!member && t('profil.userProfilAction.joinStructure')}
        {member && t('profil.userProfilAction.alreadyJoined')}
      </LoadingButton>
    </>
  );
};
