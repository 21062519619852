import './ProfilTabs.scss';

import { AnnonceListStrucrtureWithActions } from 'primary/annonce/Modal/AnnonceListStructureWithActions';
import { StructureProfilContext } from 'primary/Components/Profils/structure/StructureProfilCard';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const StructureAnnoncesTabs = () => {
  const t = useTranslate();
  const { profil } = useOutletContext<StructureProfilContext>();

  return (
    <div className={'profilTabs'}>
      <h3 className={'-title -centered'}>{t('profil.annonces.title')}</h3>
      {profil.structure?.id && (
        <AnnonceListStrucrtureWithActions idStrucrture={profil.structure?.id} />
      )}
    </div>
  );
};
