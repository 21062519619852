import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { APIMessage, MessageReceived } from 'domain/APIMessage';
import {
  TranslationRepository,
  TranslationType,
} from 'domain/translation/Translation.repository';
import { ModalDisconnectedOpenType } from 'primary/Components/User/ModalDisconnected';

import { Auth } from '../domain/auth/Auth.repository';
import { sendEventToastMessage } from '../primary/Components/Toast/Toast.helper';

const rejectWithApiError =
  (translationRepository: TranslationRepository, authRepository?: Auth) =>
  (error: MessageReceived): Promise<APIMessage> => {
    let status: number | undefined;
    console.log('ERR', error);
    const defaultMessage = translationRepository.translate('error.unknown').get();
    const apiError = new APIMessage(
      translationRepository
        .translate(error.response.data as TranslationType, undefined, defaultMessage)
        .get(),
    );
    console.log('API ERR', apiError);
    switch (status) {
      case 500:
        sendEventToastMessage(apiError.message, 'error');
        break;
      case 401:
        window.document.dispatchEvent(new CustomEvent(ModalDisconnectedOpenType));
        break;
      case 403:
        sendEventToastMessage(
          apiError.message ||
            translationRepository.translate('apiErrors.notAllowed').get(),
          'error',
        );
        break;
      case 404:
        sendEventToastMessage(
          apiError.message || translationRepository.translate('apiErrors.notFound').get(),
          'error',
        );
        break;
      default:
        sendEventToastMessage(
          apiError.message || translationRepository.translate('apiErrors.notFound').get(),
          'error',
        );
    }

    return Promise.reject(apiError);
  };

export const createConnectedAxios = (
  config: AxiosRequestConfig | undefined,
  authRepository: Auth,
  translationRepository: TranslationRepository,
): AxiosInstance => {
  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.request.use(async (config) => {
    let token: string | undefined = '';
    try {
      token = await authRepository.getToken();
    } catch (e) {
      console.log(e);
    }
    if (config?.headers && !!token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  axiosInstance.interceptors.response.use(
    (config) => config,
    rejectWithApiError(translationRepository, authRepository),
  );
  return axiosInstance;
};

export const createNotConnectedAxios = (
  config: AxiosRequestConfig | undefined,
  translationRepository: TranslationRepository,
): AxiosInstance => {
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.response.use(
    (config) => config,
    rejectWithApiError(translationRepository),
  );
  return axiosInstance;
};
