import { AxiosInstance } from 'axios';
import { EventBalance } from 'domain/balance/EventBalance';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { RestEventBalance, toEventBalance } from 'secondary/balance/RestEventBalance';

export class EventBalanceRepository
  extends AxiosProviderRepository
  implements EventBalanceRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getBalanceEvent(): Promise<EventBalance> {
    return this.axios
      .get('/unpy/api/balance/event')
      .then((resp) => toEventBalance(resp.data as RestEventBalance));
  }

  cashoutAvailableEventAmount(): Promise<void> {
    return this.axios.put('/unpy/api/balance/event/cashout');
  }

  getBalanceCashprize() {
    return this.axios
      .get('/unpy/api/balance/cashprize')
      .then((resp) => toEventBalance(resp.data as RestEventBalance));
  }
}
