import './EventParticipantCard.scss';

import { ReadMore } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button, Chip } from '@mui/material';
import classNames from 'classnames';
import { UnpyMatch } from 'domain/event/match/UnpyMatch';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { formatDate } from 'helper/date.helper';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { NeonText } from 'primary/Components/NeonText/NeonText';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslate } from '../../hooks/useTranslate';
import { EventImage } from './EventImage';

interface EventParticipantCardProps {
  eventParticipant: EventParticipant;
  className?: string;
}

interface EventParticipantCardSkeletonProps {
  className?: string;
}

export const EventParticipantCardSkelleton = ({
  className,
}: EventParticipantCardSkeletonProps) => {
  const t = useTranslate();
  return (
    <div className={classNames('eventParticipantCard')}>
      <div className={'eventParticipantCard__sideMatchs'}>
        <div className={'-eventImage'}>
          <Skeleton animation="wave" height={'100%'} width="100%" />
        </div>
        <div className={'-matchs'}>
          {Array(5)
            .fill(1)
            .map((val, index) => (
              <Skeleton key={index} animation="wave" height={'100%'} width="100%" />
            ))}
        </div>
      </div>
      <div className={'eventParticipantCard__sideInfos'}>
        <div className={'-eventInfos'}>
          <div className={'-keyValue'}>
            <Skeleton animation="wave" height={'100%'} width="100%" />
          </div>
          <Skeleton animation="wave" height={'100%'} width="100%" />
        </div>
      </div>
    </div>
  );
};

export const ActivitiesEventParticipantCard = ({
  eventParticipant,
}: EventParticipantCardProps) => {
  const navigate = useNavigate();
  const { authRepository } = useContextDependency();
  const t = useTranslate();
  const matchs: UnpyMatch[] | undefined = eventParticipant.matchsParticipants
    ?.map((matchParticipant) => {
      if (matchParticipant.result != null || matchParticipant.score != null) {
        return;
      }
      return matchParticipant.match;
    })
    .filter((v) => v != null || v != undefined) as UnpyMatch[];

  const currentRound = matchs
    ?.sort((a, b) => a.group.phaseOrder - b.group.phaseOrder)
    ?.find((m) => m.participants.map((p) => p.result == null && p.score == null));
  const otherParticipant = currentRound?.participants?.find((p) =>
    p.eventParticipant.team.players.find(
      (player) => player?.player?.id != authRepository?.currentUser?.userProfil?.id,
    ),
  );

  const nbMatchsRemaining = matchs?.length ?? 0;

  return (
    <div className={classNames('eventParticipantCard', '--item')}>
      <div className={'eventParticipantCard__sideMatchs'}>
        <div className={'-eventImage'}>
          <EventImage image={eventParticipant.event.publicImageUrl} />
        </div>
        <div
          className={classNames('-matchs', {
            '-BR': eventParticipant.event.typeEvent.code === TypeEventCodeEnum.LIG_BR,
            '-tournament':
              eventParticipant.event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT,
          })}
        >
          {eventParticipant.event.typeEvent.code === TypeEventCodeEnum.LIGUE &&
            matchs
              ?.filter((v) => v != null || v != undefined)
              ?.slice(0, 5)
              ?.map((match) => (
                <Chip
                  key={match.id}
                  className={'-match'}
                  label={
                    <div className={'-textChipMatch'}>
                      <span>
                        {
                          match.participants.find(
                            (p) => p.eventParticipant.id != eventParticipant.id,
                          )?.eventParticipant?.team?.name
                        }
                      </span>
                      <span>
                        {match.startDate
                          ? formatDate(match.startDate, 'readable(with hour)')
                          : '--:--:----'}
                      </span>
                    </div>
                  }
                />
              ))}
          {eventParticipant.event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT &&
            currentRound != undefined && (
              <div className={'-textChipMatchVs'}>
                <div className={'-participant'}>
                  <span>
                    {t('event.participantCard.currentRount')}{' '}
                    {currentRound.group.phaseOrder}
                  </span>
                  <span>
                    {t('event.participantCard.nextMatchTeamName')}
                    <NeonText variant={'orange'} text={'VS'} />
                    {otherParticipant
                      ? otherParticipant.eventParticipant.team.name
                      : t('event.participantCard.nextMatchNoTeam')}
                  </span>
                  <div className={'-date'}>
                    {currentRound.startDate
                      ? formatDate(currentRound.startDate, 'readable(with hour)')
                      : '--/--/--'}
                  </div>
                </div>
              </div>
            )}
          {eventParticipant.event.typeEvent.code === TypeEventCodeEnum.LIG_BR && (
            <div className={'-textMatchBr'}>
              <span>{t('event.participantCard.nextMatchsBr')}</span>
              <span className={'-accent'}>{nbMatchsRemaining}</span>
            </div>
          )}
        </div>
      </div>
      <div className={'eventParticipantCard__sideInfos'}>
        <div className={'-eventInfos'}>
          <div className={'-keyValue'}>
            <KeyValue
              keyValue={'Événement:'}
              value={eventParticipant.event.name}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.modal.generateClassement.game.label')}
              value={t(`games.libelle.${eventParticipant.event.game.code}`)}
              accent
            />
            <KeyValue keyValue={'Equipe:'} value={eventParticipant.team.name} accent />
          </div>
          <div className={'-actionsWithTag'}>
            <Button
              color={'primary'}
              style={{ justifySelf: 'flex-end' }}
              variant={'text'}
              onClick={() =>
                navigate(
                  routesConfig.eventViewDetails.eventViewMatchs.to(
                    eventParticipant.event.id,
                  ),
                )
              }
            >
              <ReadMore />
            </Button>
            <Chip
              className={'-tag'}
              label={t(`enum.typeEventCode.${eventParticipant.event?.typeEvent?.code}`)}
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
