import { Divider } from '@mui/material';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import React, { useState } from 'react';

import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import { IStructureMemberRepository } from '../../../domain/structureMember/StructureMember.repository';
import {
  TranslationOption,
  TranslationType,
} from '../../../domain/translation/Translation.repository';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';

interface MessageInvitationStructureContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
}

export const getOnClickResponse = (
  structureMemberRepository: IStructureMemberRepository,
  notification: UnpyNotification,
  t: (key: TranslationType, option?: TranslationOption) => string,
  response: boolean,
  reFetchNotification: () => void,
  idStructure: number,
  setLoading: (loading: boolean) => void,
) => {
  return () => {
    setLoading(true);
    structureMemberRepository
      .responseMemberInvitation(response, notification.userFrom.id, idStructure)
      .then(() => {
        reFetchNotification();
        setLoading(false);
        sendEventToastMessage(
          !response
            ? t('notification.content.invitationStructure.response.refused')
            : t('notification.content.invitationStructure.response.accepted'),
          'success',
        );
      });
  };
};

export const NotificationJoinStructureContent = ({
  notification,
  reFetchNotification,
}: MessageInvitationStructureContentProps) => {
  const t = useTranslate();
  const { structureMemberRepository, authRepository } = useContextDependency();
  const [loading, setLoading] = useState(false);
  const [member, , fetching] = useRetrieveFromDomain(
    () =>
      structureMemberRepository.getMember(
        parseInt(notification.resources.STRUCTURE_ID),
        notification.userFrom.id,
      ),
    undefined,
  );

  if (!notification.resources.STRUCTURE_ID) return null;

  return (
    <div>
      <Divider className={'-topMarged'} />
      {!fetching && !member && (
        <span>{t('notification.content.joinStructure.alreadyRefused')}</span>
      )}
      {!fetching && member?.state === StructureMemberState.ACCEPTED && (
        <span>{t('notification.content.joinStructure.alreadyAccepted')}</span>
      )}
      {!fetching && member?.state !== StructureMemberState.ACCEPTED && (
        <ActionButtonsForContent
          loading={loading}
          onClickCancel={getOnClickResponse(
            structureMemberRepository,
            notification,
            t,
            false,
            reFetchNotification,
            parseInt(notification.resources.STRUCTURE_ID),
            setLoading,
          )}
          onClickValid={getOnClickResponse(
            structureMemberRepository,
            notification,
            t,
            true,
            reFetchNotification,
            parseInt(notification.resources.STRUCTURE_ID),
            setLoading,
          )}
        />
      )}
    </div>
  );
};
