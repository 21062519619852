import './PendingStructuresInvitation.scss';

import { Cancel } from '@mui/icons-material';
import { Box, Divider, IconButton } from '@mui/material';
import { RolesStructureEnum } from 'domain/security/RolesStructureEnum';
import { StructureMember } from 'domain/structureMember/StructureMember';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import {
  getOnClickResponseStructureInvitation,
  getOnClickSeeStructure,
} from 'primary/MessageList/contents/NotificationInvitationStructureContent';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ModalPendingStructureInvitationProps = {
  isOpen?: boolean;
  handleClose: () => void;
  invitationList: StructureMember[];
  afterSubmit: () => void;
  loading?: boolean;
};

export const ModalPendingStructureInvitation = ({
  isOpen,
  handleClose,
  afterSubmit,
  invitationList,
  loading,
}: ModalPendingStructureInvitationProps) => {
  const t = useTranslate();
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={!!isOpen}
      content={
        <Box className={'modal-pending-structure-invitation'}>
          <div className={'-header'}>
            <h2>{t('modals.pendingStructureInvitation.title')}</h2>
            <IconButton onClick={() => handleClose()}>
              <Cancel />
            </IconButton>
          </div>
          <ListPendingStructureInvitation
            invitationList={invitationList}
            afterSubmit={afterSubmit}
            loadingFromButton={loading}
          />
        </Box>
      }
    />
  );
};

type ListPendingStructureInvitationProps = {
  invitationList: StructureMember[];
  afterSubmit: () => void;
  loadingFromButton?: boolean;
};

const ListPendingStructureInvitation = ({
  invitationList,
  afterSubmit,
  loadingFromButton,
}: ListPendingStructureInvitationProps) => {
  const [loading, setLoading] = useState(false);
  const { structureMemberRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const navigate = useNavigate();
  return (
    <div className={'-listPendingStructureInvitation'}>
      {invitationList?.map((invitation) => {
        const chief = invitation.structure?.members?.find(
          (m) => m.role.code === RolesStructureEnum.PRES,
        )?.user.userProfil;
        return (
          <div key={invitation.structure?.id} className={'-pendingStructureInvitation'}>
            {chief && <TagPlayer embed player={chief} />}
            <Divider />
            <div className={'-infos'}>
              <div className={'-structureInfos'}>
                <KeyValue
                  className={'-margedRight'}
                  accent
                  keyValue={'Structure :'}
                  value={invitation.structure?.name}
                />
                <KeyValue
                  className={'-margedRight'}
                  accent
                  keyValue={'Membres :'}
                  value={invitation.structure?.members?.length?.toString() ?? '0'}
                />
              </div>
            </div>
            <Divider />
            <div className={'-footerActions'}>
              <ActionButtonsForContent
                loading={loadingFromButton || loading}
                onClickExitTo={getOnClickSeeStructure(
                  navigate,
                  invitation.structure?.id?.toString() as string,
                )}
                onClickCancel={getOnClickResponseStructureInvitation(
                  structureMemberRepository,
                  authRepository?.currentUser?.id as number,
                  invitation.structure?.id as number,
                  t,
                  false,
                  afterSubmit,
                  setLoading,
                )}
                onClickValid={getOnClickResponseStructureInvitation(
                  structureMemberRepository,
                  authRepository?.currentUser?.id as number,
                  invitation.structure?.id as number,
                  t,
                  true,
                  afterSubmit,
                  setLoading,
                )}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
