import '../AnnonceListWithActions.scss';

import { Annonce, AnnonceFilters } from 'domain/annonce/Annonce';
import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { useCanCreateAnnoncementStructure } from 'domain/profil/StructureProfil.func';
import { ActionButtonCreateEditAnnonce } from 'primary/annonce/ActionButton/ActionButtonCreateEditAnnonce';
import { AnnoncesList } from 'primary/annonce/AnnoncesList';
import { InfiniteScroll } from 'primary/Components/InfiniteScroll/InfiniteScroll';
import { NoContentBloc } from 'primary/Components/NoContentBloc/NoContentBloc';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
interface AnnonceListStrucrtureWithActionsProps {
  idStrucrture: number;
}

export const AnnonceListStrucrtureWithActions = ({
  idStrucrture,
}: AnnonceListStrucrtureWithActionsProps) => {
  const { annonceRepository } = useContextDependency();
  const t = useTranslate();
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Annonce, Partial<AnnonceFilters>>({
    filters: {},
    callApi: (filters, page) =>
      annonceRepository.searchAnnonceStructure(idStrucrture, filters, page, 30),
    keepPageResult: true,
  });

  const canCreate = useCanCreateAnnoncementStructure(idStrucrture);

  return (
    <div className={'annonceListWithActions'}>
      <div className={'annonceListWithActions__content'}>
        {idStrucrture && canCreate && (
          <div className={'-actions'}>
            <ActionButtonCreateEditAnnonce
              type={AnnonceTypeEnum.STRUCTURE}
              withIcon
              structureId={idStrucrture?.toString()}
              onSubmitted={(annonce: Annonce) => {
                retry();
              }}
            />
          </div>
        )}
        <div className={'-list'}>
          {!loading && !allContents?.length && (
            <NoContentBloc text={t('general.noContentList.annonceStructure')} />
          )}
          <InfiniteScroll onScrollBottom={() => nextPage()} isLoading={loading}>
            {(scrollToTop, scrollToBottom) => (
              <AnnoncesList annonces={allContents} scrollToTop={scrollToTop} />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
