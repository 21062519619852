import './MatchViewItem.scss';

import { UnpyMatch } from 'domain/event/match/UnpyMatch';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { NeonText } from 'primary/Components/NeonText/NeonText';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import React from 'react';

type MatchViewItemProps = {
  event: UnpyEventView | UnpyEvent;
  match: UnpyMatch;
};

export const MatchViewItem = ({ event, match }: MatchViewItemProps) => {
  if (event.typeEvent.code === TypeEventCodeEnum.LIGUE) {
    return <MatchViewLigueItem event={event} match={match} />;
  } else if (event.typeEvent.code === TypeEventCodeEnum.LIG_BR) {
    return <MatchViewLigueBrItem event={event} match={match} />;
  }
  return <></>;
};

export const MatchViewLigueItem = ({ event, match }: MatchViewItemProps) => {
  return (
    <div className={'matchViewLigueItem'}>
      <div className={'-teamColumn'}>
        <span className={'-teamName'}>
          {match.participants[0]?.eventParticipant.team.name}
        </span>
        <div className={'-players'}>
          {match.participants[0]?.eventParticipant.team.players.map((player) => (
            <TagPlayer key={player?.player?.idPlayer} player={player.player} />
          ))}
        </div>
      </div>
      <div className={'-infosColumn'}>
        <NeonText variant={'orange'} text={'VS'} />
        <span>
          {match?.startDate
            ? formatDate(match.startDate, 'readable(with hour)')
            : '--/--/--'}
        </span>
        <span>
          {match?.participants[0]?.score} - {match?.participants[1]?.score}
        </span>
      </div>
      <div className={'-teamColumn'}>
        <span className={'-teamName'}>
          {match.participants[1]?.eventParticipant.team.name}
        </span>
        <div className={'-players'}>
          {match.participants[1]?.eventParticipant.team.players.map((player) => (
            <TagPlayer key={player?.player?.idPlayer} player={player.player} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const MatchViewLigueBrItem = ({ event, match }: MatchViewItemProps) => {
  return <div></div>;
};
