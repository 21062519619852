import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'App';
import { CheckoutForm } from 'primary/Stripe/CheckoutForm';
import React from 'react';

type CheckoutFormWrapperProps = {
  clientSecret: string;
};

export const CheckoutFormWrapper = ({ clientSecret }: CheckoutFormWrapperProps) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};
