import { UnpySubscription } from 'domain/payment/UnpySubscription';
import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';

import { UserProfil } from '../profil/UserProfil';
import { RolesEnum } from '../security/RolesEnum';
import { StructureMember } from '../structureMember/StructureMember';

export class User {
  constructor(
    public readonly id: number,
    public readonly roles: RolesEnum[],
    public readonly username: string,
    public readonly email: string,
    public readonly userProfil: UserProfil,
    public readonly structuresAsMember?: StructureMember[],
    public readonly subscription?: UnpySubscription,
    public readonly stripeSellerId?: string,
    public readonly sellerAccountComformity?: SellerAccountConformityEnum,
  ) {}
}

export const hasAnyStructure = (user: User) => {
  if (!user.structuresAsMember) return false;
  return user.structuresAsMember.length > 0;
};

export const getStructuresAsPresident = (user: User) => {
  if (!user.structuresAsMember) return [];
  return user.structuresAsMember.filter((value) => value.role.code.toString() === 'PRES');
};
