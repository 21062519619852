import './NotificationListItem.scss';

import { Button, Card } from '@mui/material';
import classNames from 'classnames';
import { NotificationResourceTypeEnum } from 'domain/notification/NotificationResourceTypeEnum';
import { TranslationType } from 'domain/translation/Translation.repository';
import { formatDate } from 'helper/date.helper';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { routesConfig } from 'primary/Configs/Routes.config';
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NotificationTypeEnum } from '../../domain/notification/NotificationTypeEnum';
import { decrementNumberUnviewed } from '../../domain/notification/store/NotificationSlice';
import { UnpyNotification } from '../../domain/notification/UnpyNotification';
import { KeyValue } from '../Components/KeyValue/KeyValue';
import { useContextDependency } from '../hooks/useContextDependency';
import { useTranslate } from '../hooks/useTranslate';
import { NotificationInvitationFriendContent } from './contents/NotificationInvitationFriendContent';
import { NotificationInvitationParticipationEventContent } from './contents/NotificationInvitationParticipationEventContent';
import { NotificationInvitationStructureContent } from './contents/NotificationInvitationStructureContent';
import { NotificationInvitationTeamEventContent } from './contents/NotificationInvitationTeamEventContent';
import { NotificationJoinStructureContent } from './contents/NotificationJoinStructureContent';
import { NotificationParticipationEventContent } from './contents/NotificationParticipationEventContent';

interface notificationListItemProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
  className?: string;
}

// @ts-ignore
const ACTION_COMP_BY_NOTIFICATION_TYPE: {
  [key in NotificationTypeEnum]: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
    isResponse?: boolean,
  ) => ReactNode;
} = {
  [NotificationTypeEnum.INVITATION_STRUCTURE]: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationStructureContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  JOIN_STRUCTURE: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationJoinStructureContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  JOIN_STRUCTURE_FROM_INVITATION_LINK: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationJoinStructureContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  PARTICIPATION_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationParticipationEventContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_TEAM_PARTICIPATION_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationTeamEventContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_PARTICIPATION_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationParticipationEventContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_FRIEND: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationFriendContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_FRIEND_RESPONSE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationFriendContent
      isResponse
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_STRUCTURE_RESPONSE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => (
    <NotificationInvitationStructureContent
      isResponse
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  REVERT_EVENT_PLACE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  MAJ_DECLINE_EVENT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  NEW_MESSAGE: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <NotificationInvitationFriendContent
      notification={notification}
      reFetchNotification={reFetchNotification}
    />
  ),
  INVITATION_RESPONSE_DECLINE: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  INVITATION_RESPONSE_ACCEPT: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  EVENT_CANCELED: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <></>
  ),
  EVENT_START: (notification: UnpyNotification, reFetchNotification: () => void) => <></>,
  EVENT_DELAYED: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <></>
  ),
  EVENT_FINISHED: (notification: UnpyNotification, reFetchNotification: () => void) => (
    <></>
  ),
  EVENT_CANCEL_DELAYED: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  PARTICIPATION_EVENT_ACCEPTED: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  PARTICIPATION_PAYMENT_REQUIRED: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  PARTICIPATION_PAYMENT_RECEIVED: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
  [NotificationTypeEnum.ABONNEMENT_UPDATE]: (
    notification: UnpyNotification,
    reFetchNotification: () => void,
  ) => <></>,
};

function getNavigationLinkFromRessourceKey(
  keyValue: NotificationResourceTypeEnum,
  value: string,
  resources: Record<NotificationResourceTypeEnum, string>,
) {
  switch (keyValue) {
    case NotificationResourceTypeEnum.EVENT_PARTICIPANT_ID:
      if (!resources[NotificationResourceTypeEnum.EVENT_ID]) return '/';
      return routesConfig.eventViewDetails.eventViewPlayersTeamOpen.to(
        resources[NotificationResourceTypeEnum.EVENT_ID],
        value,
      );
    case NotificationResourceTypeEnum.STRUCTURE_ID:
      return routesConfig.structureProfilDetails.to(value);
    case NotificationResourceTypeEnum.EVENT_ID:
      return routesConfig.eventViewDetails.eventViewInfos.to(value);
    case NotificationResourceTypeEnum.CONVERSATION_ID:
      return routesConfig.messages.to();
    case NotificationResourceTypeEnum.RELATION_ID:
      return routesConfig.relationPage.to();
    default:
      return '/';
  }
}

export const NotificationListItem = ({
  notification,
  className,
  reFetchNotification,
}: notificationListItemProps) => {
  const t = useTranslate();
  const { notificationRepository } = useContextDependency();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alreadyViewed, setAlreadyViewed] = useState<boolean>(notification.viewed);
  const setViewedNotification = () => {
    if (alreadyViewed || !notification.id) return;
    setAlreadyViewed(true);
    notificationRepository.toViewed(notification.id).then(() => {
      dispatch(decrementNumberUnviewed());
    });
    return;
  };

  const msg = notification.content
    ? t(notification.content as TranslationType, {}, '')
    : undefined;
  useEffect(() => {
    if (!notification.viewed) {
      setViewedNotification();
    }
  }, []);

  const keys = notification?.resources ? Object.keys(notification.resources) : [];
  const ressourcesParsed: {
    keyValue: string;
    // @ts-ignore
    value: string;
    isLink: boolean;
  }[] = keys.reduce((acc, key) => {
    // @ts-ignore
    acc.push({
      keyValue: key,
      // @ts-ignore
      value: notification.resources[key],
      isLink: key.includes('ID'),
    });
    return acc;
  }, []);
  return (
    <Card className={classNames('notificationItem', className)}>
      <div className={'notificationItem__header'}>
        <KeyValue
          className={'-margedRight'}
          accent
          keyValue={''}
          value={<TagPlayer embed player={notification.userFrom} />}
        />
        <KeyValue
          accent
          keyValue={''}
          value={formatDate(notification.creationDate, 'readable(with hour)')}
        />
        {!alreadyViewed && <div className={'view-indicator'} />}
      </div>
      <KeyValue
        accent
        className={'-topMarged'}
        keyValue={''}
        value={t(
          `notificationList.item.objectByType.${notification.type}` as TranslationType,
          {
            user: notification.userFrom.idPlayer,
          },
        )}
      />
      <div className={'-keyValueContainerNotification'}>
        {ressourcesParsed
          .filter((r) => !r.isLink)
          .map((res) => (
            <KeyValue
              key={res.keyValue}
              accent
              keyValue={t(
                `notificationList.item.ressourceLabelByKey.${
                  res.keyValue as NotificationResourceTypeEnum
                }`,
              )}
              value={res.value}
            />
          ))}
      </div>
      <div className={'-linkContainerNotification'}>
        {ressourcesParsed
          .filter((r) => r.isLink)
          .map((res) => (
            <Button
              key={res.keyValue}
              variant={'outlined'}
              className={'-linkNotification'}
              onClick={() => {
                const link = getNavigationLinkFromRessourceKey(
                  res.keyValue as NotificationResourceTypeEnum,
                  res.value,
                  notification.resources,
                );
                navigate(link);
              }}
            >
              {t(
                `notificationList.item.ressourceLabelByKey.${
                  res.keyValue as NotificationResourceTypeEnum
                }`,
              )}
            </Button>
          ))}
      </div>
      <div className={'notificationItem__content -topMarged'}>
        {ACTION_COMP_BY_NOTIFICATION_TYPE[notification.type]?.(
          notification,
          reFetchNotification,
        )}
      </div>
    </Card>
  );
};
