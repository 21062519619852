import { KeyboardDoubleArrowRight, WarningSharp } from '@mui/icons-material';
import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';
import { Link } from 'react-router-dom';

type SellerAccountEnabledWarnProps = {
  isForRegister?: boolean;
};

export const SellerAccountEnabledWarn = ({
  isForRegister,
}: SellerAccountEnabledWarnProps) => {
  const { authRepository } = useContextDependency();
  return (
    <>
      {authRepository.currentUser?.sellerAccountComformity !==
        SellerAccountConformityEnum.COMFORM && (
        <div className={'-infosNotConform'}>
          <div>
            <WarningSharp />
            <span>
              {isForRegister &&
                'Vous devez compléter le formulaire de conformité pour participer à un Événements impliquant un cashprize.'}

              {!isForRegister &&
                "Vous devez compléter le formulaire de conformité pour attribuer un coût d'inscription."}
            </span>
          </div>
          <Link
            className={'-linkAsButton -widthAuto'}
            to={routesConfig.parametersDetails.comformity.to()}
          >
            <span>Onglet conformité</span> <KeyboardDoubleArrowRight />
          </Link>
        </div>
      )}
    </>
  );
};
