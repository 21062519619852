export enum PlatformCodeEnum {
  XBO = 'XBO',
  PC = 'PC',
  PLA = 'PLA',
}

export class SellerAccount {
  constructor(
    public readonly id: number,
    public readonly country: string,
    public readonly detailsSubmitted: boolean,
    public readonly requirements: Requirement,
  ) {}
}

export class Requirement {
  constructor(
    public readonly currentDeadline: number,
    public readonly currentlyDue: string[],
    public readonly eventuallyDue: string[],
    public readonly errors: string[],
    public readonly pendingVerification: string[],
  ) {}
}
