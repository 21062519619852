import './ActionModalAddParticipant.scss';

import { Add, ContentCopy } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, TextField } from '@mui/material';
import classNames from 'classnames';
import { Environment } from 'domain/environment/Environment';
import { EnvironmentRepository } from 'domain/environment/Environment.repository';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SystemEnvironmentRepository } from 'secondary/environment/SystemEnvironment.repository';

interface ActionModalAddParticipantProps {
  className?: string;
  event: UnpyEvent | UnpyEventView;
}

export const ActionModalAddParticipant = ({
  className,
  event,
}: ActionModalAddParticipantProps) => {
  const { eventParticipantRepository, authRepository, eventRepository } =
    useContextDependency();
  const [isModalOpen, , openModal, closeModal] = useBooleanToggle();
  const t = useTranslate();
  const navigate = useNavigate();
  const [link, , fetching] = useRetrieveFromDomain(
    () => eventParticipantRepository.getOrCreateLinkInvitationParticipant(event.id),
    undefined,
    !!event,
  );
  const environmentRepository: EnvironmentRepository = new SystemEnvironmentRepository();
  const environment: Environment = environmentRepository.get();

  const handleGoMercato = () => {
    closeModal();
    navigate(routesConfig.mercatoSubPath.mercatoPlayer.to());
  };

  const handleCopyLink = () => {
    if (!link) return;
    const linkUrl = `${environment.forntUrl}${routesConfig.homeWithInvitationLink.to(
      link.uuid,
    )}`;
    navigator.clipboard.writeText(linkUrl).then(() => {
      sendEventToastMessage('Copié avec succès', 'success');
    });
  };
  return (
    <div className={classNames('actionModalAddParticipant', className)}>
      <Button
        onClick={openModal}
        className={'actionModalAddParticipant__btn'}
        variant="contained"
        color={'primary'}
      >
        <Add />
        Ajouter un participant
      </Button>
      <ModalContainer
        handleClose={closeModal}
        isOpen={isModalOpen}
        content={
          <Box className={'modal-add-participant'}>
            <div className={'choiceModaliteAddParticipant'}>
              <div className={'choiceModaliteAddParticipant__header'}>
                <h2>Ajouter un participant à votre événement</h2>
              </div>
              <div className={'choiceModaliteAddParticipant__choices'}>
                <div className={'-addMercatoPlayer'}>
                  <p>{t('structure.members.datatable.addMercatoPlayerNotice')}</p>
                  <Button
                    onClick={handleGoMercato}
                    className={'-choiceModaliteAddParticipant__btn'}
                    variant="contained"
                    color={'primary'}
                  >
                    <Add />
                    {t('structure.members.datatable.addMercatoPlayer')}
                  </Button>
                </div>
                <Divider orientation={'horizontal'} flexItem className={'-divider'} />
                <div className={'-inviteNewPlayer'}>
                  <p>
                    Vous pouvez utiliser un lien d'invitation pour inviter un joueur a
                    s'inscrire a votre événement. Le joueur devra créé un compte sur Unpy
                    si il n'en possède pas.
                  </p>
                  <div className={'-fieldDisabledWithCopy'}>
                    <InputWrapper
                      errors={{}}
                      isSubmitting={false}
                      Input={TextField}
                      inputProps={{
                        // @ts-ignore
                        disabled: true,
                        variant: 'filled',
                        value: `${
                          environment.forntUrl
                        }${routesConfig.homeWithInvitationLink.to(link?.uuid)}`,
                      }}
                    />
                    <IconButton onClick={handleCopyLink} disabled={fetching}>
                      <ContentCopy />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        }
      />
    </div>
  );
};
