import { parametersRoutesEvent } from './parametersRoutesEvent';
import { parametersRoutesStructure } from './parametersRoutesStructure';
import { parametersRoutesUser } from './parametersRoutesUser';

// @ts-ignore
export type RoutesList<K extends string> = Record<
  K,
  // @ts-ignore
  RouteConfig<any> | RoutesListElement<K>
>;
// @ts-ignore
export type RoutesListElement<K extends string> = RouteConfig<any> | RoutesList<K>;

interface RouteConfig<T extends unknown[]> {
  // eslint-disable-next-line no-unused-vars
  to: ((...args: T) => string) | string;
  path: string;
  breadcrumb?: string;
}

// type for key of routesConfig with al subkeys on all files
export type RoutesConfigKey =
  | 'none'
  | 'replaceDefault'
  | 'home'
  | 'confirmPayment'
  | 'up'
  | 'mercato'
  | 'mercatoSubPath'
  | 'mercatoPlayer'
  | 'mercatoStructure'
  | 'marketplace'
  | 'login'
  | 'notifications'
  | 'messages'
  | 'activities'
  // Sub keys of activities
  | 'eventList'
  | 'eventListWithGameFilter'
  | 'teamList'
  | 'myStructures'
  // Sub key of myStructures
  | 'created'
  | 'member'
  | 'myEvents'
  // Sub key of myStructures
  | 'handled'
  | 'registered'
  | 'eventView'
  | 'eventViewDetails'
  // Sub key of eventViewDetails
  | 'eventViewInfos'
  | 'eventViewAnnonces'
  | 'eventViewTimeline'
  | 'eventViewRules'
  | 'eventViewPlayers'
  | 'eventViewPlayersTeamOpen'
  | 'eventViewScoreboard'
  | 'eventViewMatchs'
  | 'events'
  | 'eventsSubPath'
  | 'eventsPageContent'
  | 'relationPage'
  // Sub keys of relationPage
  | 'friendsList'
  | 'blockedList'
  | 'mercatoUserProfil'
  | 'userProfil'
  | 'userProfilDetails'
  | 'mercatoUserProfilDetails'
  // Sub keys of mercatoUserProfilDetails
  | 'userProfilPerformances'
  | 'userProfilStructures'
  | 'otherStructureProfil'
  | 'otherStructureProfilDetails'
  // Sub keys of otherStructureProfilDetails
  | 'structureProfilDetails'
  | 'structureProfilPerformances'
  | 'structureProfilMembers'
  | 'parametersUser'
  | 'parametersStructure'
  | 'parametersEvent'
  | 'parametersDetails'
  | 'parametersDetailsStructure'
  | 'parametersDetailsEvent';

// @ts-ignore
export const routesConfig: RoutesList<RoutesConfigKey> = {
  none: {
    to: () => '/',
    path: '/',
  },
  replaceDefault: {
    to: `/home`,
    path: '/home',
  },
  home: {
    to: () => '/home',
    path: '/home',
    breadcrumb: 'breadcrumb.routes.home',
  },
  homeWithInvitationLink: {
    to: (invitationLink?: string) => '/home?invitationLink=' + invitationLink,
    path: '/home',
    breadcrumb: 'breadcrumb.routes.home',
  },
  confirmPayment: {
    to: () => '/payment/confirm',
    path: '/payment/confirm',
    breadcrumb: 'breadcrumb.routes.payment.confirm',
  },
  up: {
    to: () => '/up',
    path: '/up',
    breadcrumb: 'breadcrumb.routes.up',
  },
  mercato: {
    to: () => '/mercato/player',
    path: '/mercato',
  },
  mercatoSubPath: {
    to: 'player',
    path: 'player',
    breadcrumb: 'breadcrumb.routes.mercato.general',
    replaceDefault: {
      to: () => '/mercato/player',
      path: '/mercato/player',
    },
    mercatoPlayer: {
      path: '/mercato/player',
      to: () => '/mercato/player',
      breadcrumb: 'breadcrumb.routes.mercato.player',
    },
    mercatoStructure: {
      path: '/mercato/structure',
      to: () => '/mercato/structure',
      breadcrumb: 'breadcrumb.routes.mercato.structure',
    },
  },
  marketplace: {
    to: () => '/marketplace',
    path: '/marketplace',
    breadcrumb: 'breadcrumb.routes.marketplace',
  },

  login: {
    to: () => '/login',
    path: '/login',
  },
  notifications: {
    to: () => '/notifications',
    path: '/notifications',
    breadcrumb: 'breadcrumb.routes.notifications.general',
  },
  messages: {
    to: () => '/messages',
    path: '/messages',
    breadcrumb: 'breadcrumb.routes.messages.general',
  },
  activities: {
    to: () => '/activities',
    path: '/activities',
    breadcrumb: 'breadcrumb.routes.activities.general',
    replaceDefault: {
      to: `activities`,
      path: 'activities',
    },
    eventList: {
      to: `/activities/event/list`,
      path: '/activities/event/list',
      breadcrumb: 'breadcrumb.activities.eventList',
    },
    teamList: {
      to: `/activities/team/list`,
      path: '/activities/team/list',
      breadcrumb: 'breadcrumb.activities.teamList',
    },
  },
  myStructures: {
    to: () => '/myStructures',
    path: '/myStructures',
    breadcrumb: 'breadcrumb.routes.myStructures.general',
    replaceDefault: {
      to: `myStructures`,
      path: 'myStructures',
    },
    created: {
      to: `/myStructures/created`,
      path: '/myStructures/created',
    },
    member: {
      to: `/myStructures/member`,
      path: '/myStructures/member',
    },
  },
  myEvents: {
    to: () => '/myEvents',
    path: '/myEvents',
    breadcrumb: 'breadcrumb.routes.myStructures.general',
    replaceDefault: {
      to: `myEvents`,
      path: 'myEvents',
    },
    handled: {
      to: `/myEvents/handled`,
      path: '/myEvents/handled',
    },
    registered: {
      to: `/myEvents/registered`,
      path: '/myEvents/registered',
    },
  },
  eventView: {
    to: (eventId: number) => `/events/${eventId}`,
    path: '/events/:id',
    breadcrumb: 'breadcrumb.routes.events.view',
  },
  eventViewDetails: {
    to: `infos`,
    path: 'infos',
    replaceDefault: {
      to: `infos`,
      path: 'infos',
    },
    eventViewInfos: {
      to: (id: number) => `/events/${id}/infos`,
      path: '/events/:id/infos',
    },
    eventViewAnnonces: {
      to: (id: number) => `/events/${id}/annonces`,
      path: '/events/:id/annonces',
    },
    eventViewTimeline: {
      to: (id: number) => `/events/${id}/timeline`,
      path: '/events/:id/timeline',
    },
    eventViewPlayers: {
      to: (id: number) => `/events/${id}/players`,
      path: '/events/:id/players',
    },
    eventViewPlayersTeamOpen: {
      to: (id: number, participantId: number) =>
        `/events/${id}/players?participantId=${participantId}&openTeam=true`,
      path: '/events/:id/players?participantId=:participantId&openTeam=true',
    },
    eventViewPlayersRegisterOpen: {
      to: (id: number) => `/events/${id}/players?register=true`,
      path: '/events/:id/players?register=true',
    },
    eventViewScoreboard: {
      to: (id: number) => `/events/${id}/scoreboard`,
      path: '/events/:id/scoreboard',
    },
    eventViewMatchs: {
      to: (id: number) => `/events/${id}/matchs`,
      path: '/events/:id/matchs',
    },
  },
  events: {
    to: () => '/events',
    path: '/events',
    breadcrumb: 'breadcrumb.routes.events.general',
  },
  eventsSubPath: {
    to: 'ongoing',
    path: 'ongoing',
    breadcrumb: 'breadcrumb.routes.mercato.general',
    replaceDefault: {
      to: () => '/events/ongoing',
      path: '/events/ongoing',
    },
    ongoing: {
      to: () => '/events/ongoing',
      path: '/events/ongoing',
      breadcrumb: 'breadcrumb.routes.mercato.player',
    },
    finished: {
      to: () => '/events/finished',
      path: '/events/finished',
      breadcrumb: 'breadcrumb.routes.mercato.structure',
    },
  },
  eventListWithGameFilter: {
    to: (game: string) => `/events?game=${game}`,
    path: '/events',
  },
  eventsPageContent: {
    to: (gameCode: string) => `/events/game/${gameCode}`,
    path: '/events/game/:gameCode',
  },
  relationPage: {
    to: () => '/relations',
    path: '/relations',
    breadcrumb: 'breadcrumb.routes.relations.general',
    replaceDefault: {
      to: `relations`,
      path: 'relations',
    },
    friendsList: {
      to: `/relations/friends`,
      path: '/relations/friends',
      breadcrumb: 'breadcrumb.relations.friendsList',
    },
    blockedList: {
      to: `/relations/blocked`,
      path: '/relations/blocked',
      breadcrumb: 'breadcrumb.relations.blockedList',
    },
  },
  userProfil: {
    to: (id: string | number) => `/profil/${id}/`,
    path: '/profil/:id/',
  },
  mercatoUserProfil: {
    to: (id: string | number) => `/mercato/player/${id}/`,
    path: '/mercato/player/:id/',
    breadcrumb: 'breadcrumb.routes.profils.player.view',
  },
  mercatoUserProfilDetails: {
    to: `details`,
    path: 'details',
    replaceDefault: {
      to: `details`,
      path: 'details',
    },
    userProfilDetails: {
      to: (id: number) => `/mercato/player/${id}/details`,
      path: '/mercato/player/:id/details',
    },
    userProfilPerformances: {
      to: (id: number) => `/mercato/player/${id}/performances`,
      path: '/mercato/player/:id/performances',
    },
    userProfilStructures: {
      to: (id: number) => `/mercato/player/${id}/structures`,
      path: '/mercato/player/:id/structures',
    },
  },
  otherStructureProfil: {
    to: (idProfil: string) => `/mercato/structure/profil/${idProfil}`,
    path: '/mercato/structure/profil/:id',
  },
  otherStructureProfilDetails: {
    to: `details`,
    path: 'details',
    replaceDefault: {
      to: `details`,
      path: 'details',
    },
    structureProfilDetails: {
      to: (id: number) => `/mercato/structure/profil/${id}/details`,
      path: '/mercato/structure/profil/:id/details',
    },
    structureProfilAnnonces: {
      to: (id: number) => `/mercato/structure/profil/${id}/annonces`,
      path: '/mercato/structure/profil/:id/annonces',
    },
    structureProfilPerformances: {
      to: (id: number) => `/mercato/structure/profil/${id}/performances`,
      path: '/mercato/structure/profil/:id/performances',
    },
    structureProfilMembers: {
      to: (id: number) => `/mercato/structure/profil/${id}/members`,
      path: '/mercato/structure/profil/:id/members',
    },
  },
  parametersUser: {
    to: () => '/parameters/user',
    path: '/parameters/user',
  },
  parametersStructure: {
    to: (idStructure: number) => `/parameters/structure/${idStructure}/`,
    path: '/parameters/structure/:idStructure/',
  },
  parametersEvent: {
    to: (idEvent: number) => `/parameters/event/${idEvent}/`,
    path: '/parameters/event/:idEvent/',
  },
  parametersDetails: {
    to: 'profil/utilisateur',
    path: 'profil/utilisateur',
    ...parametersRoutesUser,
  },
  parametersDetailsStructure: {
    to: 'profil/appearence',
    path: 'profil/appearence',
    ...parametersRoutesStructure,
  },
  parametersDetailsEvent: {
    to: `general/apparence`,
    path: 'general/apparence',
    ...parametersRoutesEvent,
  },
};
