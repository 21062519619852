import { Requirement, SellerAccount } from 'domain/sellerAccount/SellerAccount';

export interface RestSellerAccount {
  id: number;
  country: string;
  detailsSubmitted: boolean;
  requirements: RestRequirement;
}

export interface RestRequirement {
  id: number;
  currentDeadline: number;
  currentlyDue: string[];
  eventuallyDue: string[];
  errors: string[];
  pendingVerification: string[];
}

export function toSellerAccount(
  restSellerAccount?: RestSellerAccount,
): SellerAccount | null {
  if (!restSellerAccount) return null;
  return new SellerAccount(
    restSellerAccount.id,
    restSellerAccount.country,
    restSellerAccount.detailsSubmitted,
    toRequirement(restSellerAccount.requirements),
  );
}

export function toRequirement(restRequirement: RestRequirement): Requirement {
  return new Requirement(
    restRequirement.currentDeadline,
    restRequirement.currentlyDue,
    restRequirement.eventuallyDue,
    restRequirement.errors,
    restRequirement.pendingVerification,
  );
}
