import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { EventStatusEnum, EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { PlayerStatusEnum } from 'domain/event/PlayerStatusEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { InvitationLinkTypeEnum } from 'domain/invitationLink/InvitationLinkTypeEnum';
import { NotificationResourceTypeEnum } from 'domain/notification/NotificationResourceTypeEnum';
import { NotificationTypeEnum } from 'domain/notification/NotificationTypeEnum';
import { InvoiceStatusEnum } from 'domain/payment/InvoiceStatusEnum';
import { PayoutStatusEnum } from 'domain/payout/PayoutStatus.enum';
import { PermissionStructureEnum } from 'domain/permissionStructure/PermissionStructureEnum';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { RelationStatusEnum } from 'domain/relation/RelationStatusEnum';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { TeamStatusEnum } from 'domain/team/TeamStatusEnum';
import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';
import { translationDateFR } from 'locales/date.fr';
import { frB } from 'locales/fr/fr_b';
import { frEventTraductions } from 'locales/fr/fr_event';

export const translationFR = {
  connectedAccount: {
    requirement: {
      code: {
        external_account: 'Compte externe',
        individual: {
          address: {
            city: 'Ville',
            line1: 'Addresse',
            postal_code: 'code postal',
          },
          dob: {
            day: 'Jour de naissance',
            month: 'Mois de naissance',
            year: 'Année de naissance',
          },
          email: 'Adresse email',
          first_name: 'Prénom',
          last_name: 'Nom',
          phone: 'Téléphone',
          verification: {
            document: "Vérification des documents d'identité",
          },
        },
      },
    },
  },
  currency: {
    eur: '€',
  },
  ...frB,
  toast: {
    event: {
      unregister: "Supression de l'équipe effectuée avec succès",
      cancel: {
        success: 'Event annulé avec succès',
      },
    },
  },
  app: {
    betaTitle: 'Bêta privée Unpy',
    betaConnect: 'Se connecter',
  },
  cropping: {
    modal: {
      title: "Recadrer l'image",
      infos: 'Taille recommandée : {{size}}',
      actualSize: 'Taille actuelle : {{size}}',
      submitBtn: 'Valider les dimensions',
      cancelBtn: 'Annuler',
    },
  },
  invitationLink: {
    consumedModal: {
      structure: {
        title: 'Ce lien vous permets de rejoindre la structure {{structureName}}',
        alreadyMember: 'Vous êtes déjà membre de cette structure',
        success: 'Félicitations ! Vous êtes membre de la structure !',
        error: "Une erreur est survenue lors de l'ajout à la structure.",
        finishing: "Finalisation de l'ajout à la structure",
        goToStructure: 'Voir la structure',
        goToParams: 'Personnaliser mon apparence',
        close: 'Fermer',
      },
      event: {
        title: "Ce lien vous permets de rejoindre l'événement {{eventName}}",
        goToEvent: "Voir l'événement",
        close: 'Fermer',
      },
      eventTeam: {
        title:
          "Ce lien vous permets de rejoindre l`équipe {{teamName}} pour participer à l'événement {{eventName}}",
        goToEvent: "Voir l'équipe",
        close: 'Fermer',
      },
    },
    consumeModal: {
      [InvitationLinkTypeEnum.STRUCTURE]: {
        titleModal: "Lien d'invitation Unpy",
        subTitleModal:
          'Ce lien vous permet de rejoindre une structure, connectez vous pour continuer',
      },
      [InvitationLinkTypeEnum.EVENT]: {
        titleModal: "Lien d'invitation Unpy",
        subTitleModal:
          'Ce lien vous permet de rejoindre un event, connectez vous pour continuer',
      },
      [InvitationLinkTypeEnum.FRIEND]: {
        titleModal: "Lien d'invitation Unpy",
        subTitleModal:
          'Ce lien vous permet de rejoindre un ami, connectez vous pour continuer',
      },
      [InvitationLinkTypeEnum.TEAM_EVENT]: {
        titleModal: "Lien d'invitation Unpy",
        subTitleModal:
          'Ce lien vous permet de rejoindre une équipe pour participer à un événement',
      },
      stepsByType: {
        [InvitationLinkTypeEnum.STRUCTURE]: {
          account: 'Connexion / Inscription',
          consume: 'Adhésion à la structure',
          finish: 'Terminé',
        },
        [InvitationLinkTypeEnum.EVENT]: {
          account: 'Connexion / Inscription',
          consume: 'Adhésion à la structure',
          finish: 'Terminé',
        },
        [InvitationLinkTypeEnum.FRIEND]: {
          account: 'Connexion / Inscription',
          consume: 'Adhésion à la structure',
          finish: 'Terminé',
        },
      },
      steps: {
        account: {
          [InvitationLinkTypeEnum.STRUCTURE]: {
            title: 'Ce lien vous permet de rejoindre la structure {{name}} ! ',
            description: 'Vous devez être connecté pour continuer',
          },
          [InvitationLinkTypeEnum.EVENT]: {
            title:
              "Ce lien vous permet de créer une équipe pour participer à l'événement {{name}} ! ",
            description: 'Vous devez être connecté pour continuer',
          },
          [InvitationLinkTypeEnum.FRIEND]: {
            title: "Ce lien vous permet d'ajouter {{name}} ! ",
            description: 'Vous devez être connecté pour continuer',
          },
          loginBtn: 'Se connecter',
          registerBtn: "S'inscrire",
        },
      },
      title: 'Bienvenue sur Unpy !',
      linkNotFound: "Ce lien n'est plus valable !",
      structure: {
        title: 'Rejoindre la structure {{structureName}}',
        nbTournamentParticipate: 'Nombre de tournois effectués',
        nbVictories: 'Nombre de victoires',
        nbMembers: 'Nombre de membres',
        nbTournamentOranized: 'Nombre de tournois organisés',
        cancelBtn: 'Annuler',
        goToSubscribe: 'Rejoindre la structure',
      },
    },
  },
  annonce: {
    noContentList: 'Aucune annonce pour le moment',
    addAnnonce: 'Ajouter une annonce',
    edit: {
      succeed: 'Annonce modifiée avec succès',
    },
    create: {
      succeed: 'Annonce créée avec succès',
    },
    form: {
      isPublic: {
        label: 'Annonce publique',
      },
      sendNotification: {
        [AnnonceTypeEnum.EVENT]: 'Envoyer une notification aux participants',
        [AnnonceTypeEnum.STRUCTURE]: 'Envoyer une notification aux membres',
      },
      text: {
        label: 'Contenu',
      },
    },
  },
  pagination: {
    table: {
      fromToCount: '{{from}} - {{to}} pour un total de {{count}}',
      rowsPerPage: 'Éléments par page :',
    },
  },
  // Form Stepper
  formStepper: {
    nextLabel: 'Suivant',
    previousLabel: 'Précédent',
    creatingSuccess: 'Votre équipe a été inscrite avec succès !',
    creatingSuccessWaitValidate:
      "Votre équipe a été créée avec succès. Un administrateur de l'event doit valider votre inscription pour qu'elle soit effective.",
    creatingSuccessWaitPlayer:
      "Votre équipe a été créée avec succès, les autres joueurs doivent accepter l'invitation pour que l'inscription soit effective.",
    creatingSuccessWaitPlayerAndValidate:
      "Votre équipe a été créée avec succès, les autres joueurs doivent accepter l'invitation pour que la demande de participation soit envoyée, un administrateur de l'event devra ensuite accepter votre participation.",
    finishAndClose: 'Fermer',
    goToTeam: 'Voir mon équipe',
  },
  // Event
  team: {
    tooManyChief: "Il ne peut y avoir qu'un seul chef d'équipe",
    list: {
      noContentMsg: 'Aucune équipe',
    },
    details: {
      noStructure: 'Aucune structure',
    },
    status: {
      COMPLETE: 'Prête',
      INCOMPLETE: '{{count}} joueur manquant',
      INCOMPLETE_plural: '{{count}} joueurs manquants',
    },
    modal: {
      edit: {
        labelDelete: "Supprimer l'équipe",
        members: 'Joueurs',
        playerActions: {
          deleteLabel: 'Supprimer',
          leaveLabel: 'Quitter',
        },
        title: 'Équipe {{teamName}} pour le tournois {{eventName}}',
        playerStatus: {
          ACCEPTED: 'Accepté',
          PENDING: 'En attente',
          DECLINED: 'Refusé',
        },
        statusWaitingSubscribe: "En attente d'ìnscription",
      },
    },
  },
  classement: {
    scoreLabel: 'Score',
    abreviationResult: {
      WIN: 'V',
      LOOSE: 'D',
    },
    datatable: {
      place: 'Place',
      teamName: 'Équipe',
      score: 'Score',
      actions: 'Action',
    },
    general: {
      bracket: {
        teamUnknow: 'Non définis',
        currentRound: 'Round en cours',
        seeAllBracket: 'Voir tout le bracket',
        filtersRound: {
          labelMin: 'Afficher les rounds ',
          labelConnector: ' à ',
        },
      },
      podium: {
        title: "Podium de l'événement {{eventName}}",
      },
      team: {
        seeMore: "Détails de l'équipe",
      },
    },
    tournament: {
      modalShowBracketTitle: 'Bracket du tournois {{eventName}}',
      nextMathsTitle: 'Matchs à venir',
      showBracket: 'Voir le bracket',
    },
    parameters: {
      beginNotice:
        "Le démarrage de l'événement entraînera la génération des matchs, vous pourrez re-déclencher une génération a tout moment depuis les paramètres de l'événement.",
      titleEditMatchs: 'Editeur de matchs',
      [TypeEventCodeEnum.LIGUE]: {
        subTitle: 'Type : Ligue',
      },
      [TypeEventCodeEnum.LIG_BR]: {
        subTitle: 'Type : Ligue BR (Battle royal)',
      },
      [TypeEventCodeEnum.TOURNAMENT]: {
        subTitle: 'Type : Tournois à élimination directe',
      },
      // [TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM]: {
      //   subTitle: 'Type : Tournois à double élimination',
      // },
      switch: {
        sessionLabel: 'Passer en vue session',
        bracketLabel: 'Passer en vue bracket',
      },
      regenerate: 're-générer.',
      regenerateNotice:
        "Attention ! la re-génération d'un classement supprimera définitivement l'ancien et les scores renseignés",
      editClassementBtn: 'Appliquer les modifications',
      noContent: "Vous n'avez pas encore généré votre classement",
      loading: 'Récupération du classement',
      generateClassement: 'Générer le classement',
      titleParameterClassement: 'Classement & Matchs',
      titleTotalPreview: 'Aperçu classement général',
      listSession: 'Liste des sessions de matchs',
      titleEditScoreBoard: 'Liste des matchs de la session',
      generateClassementLigueModalTitle: 'Générer un classement de ligue',
      generateClassementModalSubmitBtn: 'Générer',
      startEventModalSubmitBtn: 'Générer',
      generateClassementModalCancelBtn: 'Annuler',
      generateClassementModalTitleFieldLabel: 'Titre du classement',
      generateClassemetMatchPerSessionFieldLabel: 'Nombre de matchs par groupe',
      totalMatchToGenerate: 'Nombre total de matchs à générer : ',
      generateClassemettotalSessionLabel: 'Nombre total de groupe',
      generateClassementNbMatchSessionOptLabel: '{{nbMatch}} match | {{count}} session',
      generateClassementNbMatchSessionOptLabel_plural:
        '{{nbMatch}} match | {{count}} sessions',
      deleteSessionBtn: 'Supprimer la session',
      deleteSessionConfirmTitle:
        'Êtes-vous sûr de vouloir supprimer cette session de matchs ?',
      deleteSessionConfirmDescription:
        "L'ensemble des matchs et de leurs scores sera supprimé du classement de l'event",
      datatableConfirmEditting: {
        teamNameLabel: 'Équipe',
        placesLabel: 'Place',
        pointsLabel: 'Points',
        noContentLabel: 'Aucune modification',
      },
      modal: {
        titleListModif: 'Liste des modifications',
        cancelBtn: 'Retour',
        confirmBtn: 'Confirmer',
        noModif: 'Aucune modification apportée au classement',
      },
    },
    view: {
      noContent: 'Aucun classement généré pour le moment',
    },
    result: {
      optDefault: {
        WIN: 'Victoire (+3)',
        NULL: 'nul (0)',
        LOOSE: 'défaite (-1)',
      },
    },
  },
  countDown: {
    inPast: 'Dépassée depuis :',
    day: '{{count}} jour',
    day_plural: '{{count}} jours',
    hour: '{{count}} heure',
    hour_plural: '{{count}} heures',
    minute: '{{count}} minute',
    minute_plural: '{{count}} minutes',
    second: '{{count}} seconde',
    second_plural: '{{count}} secondes',
  },
  countDownShort: {
    day: '{{count}} J',
    hour: '{{count}} H',
    minute: '{{count}} m',
    second: '{{count}} s',
  },
  //Event
  event: {
    ...frEventTraductions,
  },
  // Mercato

  // Profil
  privacy: {
    [PRIVACY_CODE.ADD_FRIEND]: {
      labelForm: "M'ajouter en ami",
    },
    [PRIVACY_CODE.JOIN_STRUCTURE]: {
      labelForm: 'Demander a rejoindre ma structure',
    },
    [PRIVACY_CODE.SEND_MESSAGE]: {
      labelForm: "M'envoyer un message",
    },
    [PRIVACY_CODE.INVITE_STRUCTURE]: {
      labelForm: "M'inviter dans une structure",
    },
  },
  relations: {
    myFriendsTitle: 'Mes amis',
    tagBlock: 'Bloqué',
    confirmModalDeleteBlock: {
      title: 'Débloquer {{name}}',
      success: '{{name}} a été débloqué',
      notice: 'Êtes-vous sûr de vouloir débloquer {{name}} ?',
      confirmBtnText: 'Oui',
      cancelBtnText: 'Annuler',
    },
    confirmModalDeleteFriend: {
      title: 'Retirer {{name}} de mes amis',
      success: '{{name}} a été retiré de vos amis',
      notice: 'Êtes-vous sûr de vouloir retirer {{name}} ?',
      confirmBtnText: 'Oui',
      cancelBtnText: 'Annuler',
    },
    block: {
      notice: "Vous pourrez le débloquer à tout moment dans l'onglet Relations",
      success: '{{name}} a été bloqué',
      title: 'Êtes-vous sûr de vouloir bloquer {{name}} ?',
      description:
        "Vous n'apparaîtrez plus dans les résultats de recherches de ce joueur et ne pourrez plus vous contacter ou vous inviter via Unpy. Vous pourrez le débloquer a tout moment dans l'onglet Relations",
      confirmBtnText: 'Bloquer',
      cancelBtnText: 'Annuler',
    },
    unBlock: {
      description:
        'Vous appraîterez de nouveau dans les résultats de recherches de ce joueur et il pourra vous contacter ou vous inviter via Unpy.',
      notice: '',
      success: '{{name}} a été débloqué',
      title: 'Êtes-vous sûr de vouloir débloquer {{name}} ?',
      confirmBtnText: 'Débloquer',
      cancelBtnText: 'Annuler',
    },
    page: {
      list: {
        title: 'Relations',
        addFriend: {
          btnLabel: 'Ajouter un ami',
          titleModal: 'Ajouter un ami',
          addMercatoPlayerNotice: 'Ajouter un ami via le Mercato',
          inviteFriendNotice: "Ajouter un ami avec un lien d'invitation",
        },
      },
    },
    tab: {
      blocked: 'Bloqués',
      friend: 'Amis',
    },
  },
  otherProfil: {
    pingDisabled: 'Cet utilisateur a désactivé le ping',
    titlePage: 'Profil de {{name}}',
  },
  user: {
    myProfil: {
      infosMore: 'Détails',
      addToTeam: "Ajouter à l'équipe",
      fullToAddTeam: 'Équipe complète',
      removeFromTeam: "Retirer de l'équipe",
      pageTitle: 'Mon profil',
      noGames: 'Aucun jeu renseigné',
    },
    parameters: {
      account: {
        title: 'Paramètres de compte',
        editEmail: "Modifier l'adresse email",
        editUsername: "Modifier l'ID unpy",
        editInfos: 'Modifier les informations du compte',
        resetPassword: 'Réinitialiser le mot de passe',
        resetPasswordBtn: 'Réinitialiser',
      },
      admins: {
        event: {
          fixture: {
            createBtn: "Ajouter l'event à générer",
            createAllEvent: 'Générer les {{count}} events',
            creating:
              "Création en cours, cette opération peut prendre plusieurs minutes. Merci de patienter, vous serez redirigé vers l'event une fois créé",
          },
        },
      },
      getUserMsgLoading: 'Récupération des paramètres utilsateur',
      title: 'Paramètres utilisateur',
      personalData: {
        title: 'Données personnelles',
      },
      paymentsAbonnements: {
        title: 'Paiement & abonnement',
        payments: {
          title: 'Transactions',
        },
        abonnements: {
          title: 'Abonnement',
        },
      },
      wallet: {
        title: 'Portefeuille',
      },
      prizes: {
        title: 'Prix & récompenses',
      },
      comformity: {
        title: 'Conformité',
      },
      balance: {
        title: 'Solde relatif aux inscriptions aux Événements',
      },
      cashprizeBalance: {
        title: 'Solde relatif aux cashprize obtenus',
      },
      sellerAccount: {
        title: 'Compte vendeur',
      },
      connexion: {
        title: 'Connexion / Inscription',
      },
      eventsAdmin: {
        title: "Administration d'event",
        subTitleFormFixture:
          "Formulaire de génération d'event. Ce formulaire déclenche la création de x joueurs en fonction du nombre d'équipes souhaité, avec deux joueurs dans chaque équipe et une structure par équipe",
      },
      credentials: {
        title: 'Identifiant',
      },
      appearence: {
        title: 'Apparence',
        titleForm: "Modifier l'apparence",
        titlePreview: 'Prévisualisation',
      },
      network: {
        title: 'Réseaux',
      },
      statGame: {
        title: 'Jeux',
      },
      interests: {
        title: 'Interêts',
      },
      userProfilDetails: {
        title: 'Détails utilisateur',
      },
    },
  },
  structure: {
    modalDelete: {
      confirmTitleModal: 'Êtes-vous sûr de vouloir exclure ce membre ?',
      confirmText:
        "Ce joueur n'aura plus accès à la structure ni à ses tournois et ne pourra plus participer à des tournois au nom de la structure",
      confirmBtnText: 'Oui, exclure',
      cancelBtnText: 'Annuler',
    },
    parameters: {
      btnToParam: 'Paramètres',
      getStructureMsgLoading: 'Récupération des paramètres de structure',
      title: 'Paramètres structure',
      appearence: {
        title: 'Apparence',
        titleForm: "Modifier l'apparence",
        titlePreview: 'Prévisualisation',
      },
      network: {
        title: 'Réseaux',
      },
      statGame: {
        title: 'Jeux',
      },
      membersStructure: {
        remindTitle: 'Rappel des rôles et permissions',
        listTitle: 'Liste des membres',
        title: 'Membres & rôles',
      },
      structureProfilDetails: {
        title: 'Détails de la structure',
      },
    },
    inviteResponseWaiting: 'En attente de réponse',
    modalRole: {
      title: 'Changer le rôle de {{name}}',
    },
    members: {
      helpTitleBtn: 'Infos sur les rôles et permissions',
      helperTextChiefCantEditRole:
        'Le vice-président ou prochain membre par ordre hierarchique de rôle sera nommé président de la structure.',
      helperTextCantEditRoleOneMemberPresident:
        'Impossible de changer de rôle. Vous êtes président et le seul membre de votre structure.',
      datatable: {
        addMember: 'Ajouter',
        inviteMemberNotice: "Inviter un joueur en partageant un lien d'invitation",
        addMercatoPlayer: 'Accéder au mercato',
        addMercatoPlayerNotice: 'Inviter un joueur via le mercato',
        titleModalAddMember: 'Ajouter un nouveau membre',
        copyLinkInvite: "Copier le lien d'invitation",
        copyLinkInviteLoading: "Récupération du lien d'invitation",
        accept: 'Accepté',
        decline: 'Refusé',
        statusPending: {
          [StructureMemberState.WAITING_INVITE_ACCEPTED]:
            'Invitation en attente de réponse',
          [StructureMemberState.WAITING_JOIN_ACCEPTED]:
            "Demande d'adhésion en attente de réponse",
          [StructureMemberState.WAITINT_LINK_ACCEPTED]:
            "Demande d'adhésion par lien d'invitation en attente de réponse",
        },
        inviteWaitingResponse: 'Invitation envoyée',
        cancelInvitation: 'Annuler',
        statusAccepted: 'Membre',
        idLabel: 'ID Player',
        idPlayerLabel: 'ID Player',
        roleLabel: 'Rôle',
        statusMemberLabel: 'Statut',
        actionLabel: 'Actions',
        seeProfil: 'Voir le profil',
        editRole: 'Modifier le rôle',
        deleteMember: 'Exclure',
        noContent: 'Aucun membre',
      },
      noContent: 'Aucun membre public',
    },
    roles: {
      PRES: 'Président',
      VICE_PRES: 'Vice président',
      STAFF: 'Staff',
      MANAGER: 'Manager',
      PLAYER: 'Joueur',
      RECRUE: 'Recrue',
    },
    form: {
      name: 'Nom de la structure',
      namePlaceholder: 'Renseignez le nom de votre structure',
      formSuccess: 'Structure créée avec succès',
      submitBtn: 'Créer',
    },
    myStructure: 'Ma structure',
    createStructure: 'Créer une structure',
  },
  autocomplete: {
    me: '(Vous)',
    noOptions: 'Aucun résultat',
    loading: 'Recherche . . . ',
    userProfil: {
      placeholder:
        'Tapez au moins 3 caractères pour rechercher un joueur par son ID unpy',
    },
    structureProfil: {
      placeholder:
        'Tapez au moins 3 caractères pour rechercher une structure par son nom',
    },
  },
  profil: {
    userProfilAction: {
      removeFriend: 'Retirer {{name}} de vos amis',
      removeFriendTextConfirm: 'Êtes-vous sûr ?',
      removeFriendBtnAccept: 'Oui',
      removeFriendBtnCancel: 'Annuler',
      inviteStructure: 'Inviter dans une structure',
      inviteEvent: 'Inviter à un événement',
      joinStructure: 'Rejoindre la structure',
      alreadyJoined: 'Déjà membre',
      structureMemberRepository: 'Rejoindre la structure',
      infosMore: 'Infos',
      parameters: 'Paramètres',
      addFriend: 'Ajouter en ami',
      alreadyBlockedNotice: 'Vous avez bloqué ce joueur',
      sendMessage: 'Envoyer un message',
      sendMessageSuccess: 'Envoyer un message',
      block: 'Bloquer',
      unBlock: 'Débloquer',
    },
    structures: {
      title: 'Structures',
      noStructures: 'Aucune structure',
    },
    noCountry: 'Pays non renseigné',
    previewCard: {
      displayGames: 'jeux joués',
      displayStats: 'statistiques',
    },
    header: {
      talkLangs: 'Langue',
      talkLangs__plural: 'Langues',
    },
    details: {
      pingBtn: 'Ping',
      notConnected: 'Vous devez être connecté',
      parameters: 'Paramètres',
      description: {
        noContent: 'Aucune description renseignée',
      },
    },
    members: {
      listMembersTitle: 'Liste des membres ',
    },
    annonces: {
      title: 'Annonces',
      noContent: 'Aucune annonce publiée',
    },
    performances: {
      statsUnpyTitle: 'Statistiques Unpy',
      nbTournaments: 'Tournois effectués :',
      nbWin: 'Tournois gagnés :',
      nbPodium: 'Podium :',
      nbEventAsCreator: 'Tournois créés :',
    },
  },
  previewEditProfil: {
    mercatoView: 'Vue mercato',
    miniatureView: 'Vue miniature',
  },

  // Pages
  myStructuresPage: {
    pageTitle: 'Mes structures',
    noContentMsg: "Vous ne faites partis d'aucune structure",
    noContent: {
      createStructure: 'Créer une structure',
      btnSearchStructure: 'Trouver une structure',
    },
  },
  footer: {
    sections: {
      mercato: 'Mercato',
      mercatoStructure: 'Structure',
      mercatoPlayer: 'Player',
      events: 'Event',
      marketplace: 'Marketplace',
      userMenu: {
        title: 'Utilisateur',
        loggedIn: {
          parametres: '',
        },
        loggedOut: {
          toConnect: 'Se connecter',
        },
      },
    },
  },
  home: {
    mercatoWithEvent: {
      title: 'BIENVENUE SUR LA PREMIERE PLATEFORME 100% E-SPORT',
    },
    mercato: {
      title: 'MERCATO',
      subtitle: 'TROUVEZ DES JOUEURS OU DES STRUCTURES E-SPORT',
      personnalize: {
        titleCard: 'Profils',
        titleCustomize: 'Personnalisable',
      },
    },
    events: {
      title: 'EVENT',
      subtitle: 'PARTICIPEZ OU CREEZ DES COMPETITION E-SPORT SUR VOS JEUX PREFERES',
      description: {
        services: {
          cachprizeSecure: 'Cashprize sécurisé',
          registerPrice: 'Participation payante ou gratuite',
          payout: 'Encaissez vos gains !',
          typeEvent: 'Plusieurs type de tournois',
          scoreAndMatch: 'Gestion des matchs et podium',
          createTournaments: 'Créer vos tournois sur vos jeux préférés',
          bracketGeneration: 'Génération de bracket simple ou double',

          personnalizeProfil: 'Créez et personnalisez votre profil',
          statsDisplay: 'Statistiques unpy lié au profil',
          createStructure: 'Création de structure e-sport',
          memberGestion: 'Gestion des rôles et membres',
          subscriptionTournamentSquad: 'Inscription au tournois seul ou en équipe',
          searchPlayerAndStructure: 'Recherche de joueur ou de structure',
        },
      },
    },
    marketplace: {
      title: 'MARKETPLACE',
      subtitle: 'Trouvez votre coach ou déposez votre annonce pour être recruté',
      lasttitle: '100% E-SPORT, GAMING, ET BIEN PLUS !',
    },
    discoverTournamentOnGame: 'Trouvez des tournois sur vos jeux favoris',
    follow: {
      title: "Suivez l'actualité",
      instaTitle: 'Instagram',
      twitterTitle: 'X / Twitter',
      linkedInTitle: 'LinkedIn',
      otherNetwork: 'Retrouvez-nous aussi sur',
      facebook: 'Facebook',
      discord: 'Discord',
      twitch: 'Twitch',
      youtube: 'Youtube',
    },
    counters: {
      profils: {
        title: 'MERCATO',
        sub: 'Profils player et structures',
      },
      events: {
        title: 'EVENT',
        sub: 'Événements organisés',
      },
      marketplace: {
        title: 'MARKETPLACE',
        sub: 'Annonces publiées',
      },
    },
    infos: {
      buttonTitle: 'Améliorez votre éxpérience avec',
      titlePersonnalize: 'Personnalisez votre profil joueur et structure',
      uniqueItems: 'Accèdez a des cosmétiques uniques pour votre profil',
      description1: "Personnalise ton profil e-sport avec l'abonnement up.",
      description2:
        'Démarque toi avec des neons, des arrières plans incroyables, et plus encore !',
      mercato: {
        title: 'Mercato',
        description:
          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
      },
      event: {
        title: 'Events',
        description:
          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
      },
      marketplace: {
        title: 'Marketplace',
        description:
          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
      },
    },
    up: {
      titleStats: 'Unpy en chiffres',
      titleSocial: "Ne ratez rien de l'actualité Unpy",
      labelChangeExp: 'Changez votre expérience unpy',
      goToPageLabel: 'Retrouvez tous les avantages up, ainsi que nos offres sur la page',
      seeAllOffers: 'Découvrir les avantages',
      subscribe: "S'abonner",
      noticeMoreInfo: 'Retrouvez tous les avantages up, ainsi que nos offres sur la page',
    },
    titleWelcome: 'Bienvenue sur unpy',
    titleFirstPlatform: 'Première plateforme e-sport',
    actuality: {
      title: 'NEWS UNPY',
      eventAnnouncement: {
        title: 'Event a venir',
        getEventOverviewMsgLoading: 'Récupération des event',
      },
      mercatoAnnouncement: {
        title: 'Tendance mercato',
      },
      marketPlaceAnnouncements: {
        title: 'Annonce marketplace populaires',
      },
    },
    gamingActuality: {
      title: 'Actualités gaming',
    },
  },
  myProfil: {
    videoPromo: 'Vidéo promotionnelle',
    noVideoPromo: 'Aucune vidéo promotionnelle renseignée',
    noMember: 'Aucun membre',
    age: 'Âge',
    membersNumber: 'Nombre de membres :',
    ageValue: '{{count}} ans',
    aboutMembers: 'Description / membre',
    titlePalmares: 'Palmarès Unpy',
    palmaresLoading: 'Chargement du palmarès',
    titleDescription: 'Description',
    titleInformations: 'Informations',
    gamesPlayed: 'Jeux joués',
    noGamesPlayed: 'Aucun jeu sélectioné',
    titlePlatforms: 'Plateformes',
    titleLangs: 'Langues',
    gamingExperience: 'Expérience e-sport',
    interest: 'Interêts',
    interestNoContent: 'Aucun interêt renseigné',
    noGames: 'Aucun jeu renseigné',
    noStructure: 'Aucune structure',
    noTalkLank: 'Aucune langue',
    noPlatforms: 'Aucune platforme renseignée',
    noNetworkValue: '{{network}} non renseigné',
    titlePage: 'Profil',
    ping: {
      enabled: 'Ping activé',
      disabled: 'Ping désactivé',
    },
    parametersBtnLabel: 'Paramètres',
    structureActuality: 'Actualité structure',
    infoBtnLabel: 'Détails',
    titleInfos: 'Informations',
    retourInfos: 'Retour',
    noneStats: 'Aucune statistique renseignée pour ce jeu.',
    noneGameStatSelected:
      'Veuillez sélectionner un jeu pour afficher les statistiques associées.',
  },
  mercato: {
    absoluteTitle: 'MERCATO',
    absoluteSubTitle: 'MERCATO',
    card: {
      skeleton: {
        addOwn: 'Ajouter votre structure',
      },
    },
    titlePlayer: 'Joueurs',
    titleStructure: 'Structures',
    titleSearchPlayer: 'Rechercher des joueurs',
    titleComposeTeam: 'Composez votre équipe',
    titleSearchStructure: 'Rechercher des structures',
    titleCaroussel: 'Sélectionnez les jeux',
    titleLeftPanel: 'Filtres',
    titlePanelFilterBtn: 'FILTRES',
    filters: {
      titlePlayer: 'Fitlres joueur',
      titleStructure: 'Fitlres structure',
      placeholders: {
        country: 'Pays',
        age: 'Age',
        langs: 'Langue',
        platforms: 'Plateforme',
        experiences: 'Expérience',
      },
      player: {
        country: {
          title: 'Pays',
          placeholder: 'Sélectionnez un pays',
          none: '',
        },
        age: 'Age',
        langs: 'Langues',
        platforms: 'Plateformes',
        experiences: 'Expérience',
        valueLabel: {
          age: {
            AGE_LESS_18: '- 18 ans',
            AGE_MORE_18: '+ 18 ans',
            AGE_MORE_25: '+ 25 ans',
          },
        },
      },
    },
  },
  // Parameters

  // Menus
  breadcrumb: {
    routes: {
      home: 'Accueil',
      payment: {
        confirm: 'Confirmation de paiement',
      },
      up: 'Unpy Up',
      mercato: {
        general: 'Mercato',
        player: 'Joueurs',
        structure: 'structure',
      },
      marketplace: 'Marketplace',
      notifications: {
        general: 'Notifications',
      },
      messages: {
        general: 'Messagerie',
      },
      myStructures: {
        general: 'Mes structures',
      },
      activities: {
        general: 'Activités',
      },
      event: {
        general: 'Event',
        view: 'Détails',
      },
      relations: {
        general: 'Relations',
      },
      profils: {
        player: {
          view: 'Carte',
        },
      },
      none: '',
    },
  },

  // List & Layout
  filtersValues: {
    APX: 'Apex Legends',
    WRZ: 'Warzone',
    MW3: 'CoD MW3',
    OVW: 'Overwatch',
    XDF: 'X Defiant',
    CS2: 'Counter Strike 2',
    LOL: 'League of legends',
    PBG: 'PUBG',
    R6S: 'Rainbow Six Siege',
    FFA: 'Fifa',
    VLR: 'Valorant',
    TFT: 'TFT',
    CSG: 'Counter Strike GO',
    RCK: 'Rocket League',
    FRT: 'Fortnite',
    STRFGT: 'STRFGT',
    SCB6: 'SCB6',
    MARD8: 'MARD8',
    TEK8: 'TEK8',
    SMULT: 'SMULT',
    DOTA2: 'DOTA2',
    HST: 'HST',
    K24: 'K24',
    none: 'Aucun filtre actif',
    [EventConfirmationStateEnum.WAITING]: 'En attente',
    [EventConfirmationStateEnum.CONFIRMED]: 'Confirmé',
    [EventConfirmationStateEnum.DELAYED]: 'Retardé',
    [EventConfirmationStateEnum.DECLINE]: 'Annulé',
    [SystemPointsEnum.DEFAULT]: 'Système par défaut',
    [SystemPointsEnum.PERSO]: 'Système personnalisé',
    [EventStatusEnum.PUBLISH]: 'Publié',
    [EventStatusEnum.DRAFT]: 'Brouillon',
    [EventStatusEnum.CANCELED]: 'Annulé',
    [EventStatusEnum.FINISH]: 'Terminé',
    [EventStatusEnum.ON_GOING]: 'Démarré',
    [EventSubStatusEnum.SUBSCRIBE_OPEN]: 'Inscriptions ouvertes',
    [EventSubStatusEnum.SUBSCRIBE_CLOSE]: 'Inscriptions closes',
    [EventStatusEnum.FINISH]: 'Event terminé',
    SIMPELEM: 'Simple élimination',
    DOUBLELEM: 'Double élimination',
    POULSIMPLELEM: 'Poule & Simple élimination',
    POULDOUBLELEM: 'Poule & Double élimination',
    POULBO: 'Poule & Best of (BO 1,3,5)',
    LIG: 'Ligue',
    LIG_BR: 'Ligue (Battle royal)',
    LIGBO: 'Ligue & Best of (BO 1,3,5)',
    SUISSE: 'Suisse',
    ROBIN: 'Robin',
    ROBIN2: 'Robin à deux tours',
    [TypeTeamCodeEnum.SOL]: '1 Joueur',
    [TypeTeamCodeEnum.DUO]: '2 Joueurs',
    [TypeTeamCodeEnum.TRI]: '3 Joueurs',
    [TypeTeamCodeEnum.QUA]: '4 Joueurs',
    [TypeTeamCodeEnum.FIV]: '5 Joueurs',
    [TypeTeamCodeEnum.SQU]: 'X Joueurs (Squad)',
    BO1: 'Best Of 1 (BO1)',
    BO3: 'Best Of 3 (BO3)',
    BO5: 'Best Of 5 (BO5)',
    LIGUE: 'Ligue',
    TOURNAMENT: 'Tournois à élimination directe',
    TOURNAMENT_DOUBLE_ELEM: 'Tournois à double élimination',
    JOIN_STRUCTURE: 'Demander à rejoindre cette structure',
    INVITE_STRUCTURE: 'Inviter dans une structure',
    SEND_MESSAGE: 'Envoyer un message',
    ADD_FRIEND: 'Demander en ami',
    EN: 'Anglais',
    FR: 'Français',
    ESP: 'Espagnol',
    PRO: 'Pro / Compétition',
    CASU: 'Casual',
    SEMIPRO: 'Semi-pro',
    XBO: 'XBOX',
    PC: 'PC',
    PLA: 'Playstation',
    minAge: '+ {{age}} ans',
    maxAge: '- {{age}} ans',
  },
  layoutPageCommonList: {
    filtersAddBtn: 'Ajouter des filtres',
    filtersResetBtn: 'Réinitialiser',
    goBack: 'Retour',
    filters: 'Filtre(s) actif :',
  },
  rightBar: {
    seeMore: 'Afficher plus',
    noConnected: {
      info: 'Vous devez être connecté',
    },
    activities: {
      title: 'Activités',
      noContent: 'Aucune activité',
      loading: 'Chargement des activités',
    },
  },
  modal: {
    confirmModal: {
      defaultAcceptLabel: 'Oui',
      defaultDeclineLabel: 'Non',
      defaultTitle: 'Êtes-vous sûr ?',
    },
  },

  // API
  apiErrors: {
    unknown: 'Une erreur est survenue, merci de contacter le support.',
    unauthorize: "Vous n'êtes pas authorisé à effectuer cette action.",
    notAllowed: "Vous n'avez pas les droits requis pour effectuer cette action.",
    notFound: 'Resource introuvable.',
    codes: {
      'event.create.limitEditStartDate': 'Vous ne pouvez plus modifier la date de début',
    },
  },

  // Store
  product: {
    PASS: {
      libelle: 'Pass de saison',
      description: '',
    },
    buy: 'Acheter',
    ABO_UP_1: {
      libelle: 'Abonnement unpy UP 1 mois',
      description: '',
    },
    ABO_UP_6: {
      libelle: 'Abonnement unpy UP 6 mois',
      description: '',
    },
    ABO_UP_12: {
      libelle: 'Abonnement unpy UP 12 mois',
      description: '',
    },
    ABO_UP_PREMIUM_1: {
      libelle: 'Abonnement unpy UP premium 1 mois',
      description: '',
    },
    ABO_UP_PREMIUM_6: {
      libelle: 'Abonnement unpy UP premium 6 mois',
      description: '',
    },
    ABO_UP_PREMIUM_12: {
      libelle: 'Abonnement unpy UP premium 12 mois',
      description: '',
    },
    ABO_UP_STRUCTURE: {
      libelle: 'Abonnement avantages structures',
      description: '',
    },
    ABO_UP_EVENT: {
      libelle: 'Abonnement avantages event',
      description: '',
    },
    PROD_BOOST: {
      libelle: 'Boost de visibilité 24h',
      description: '',
    },
  },
  subscription: {
    reactivateAction: 'Souscrire à un nouvel abonnement',
    cancelAction: {
      cancelAtPeriodEndNotice: 'Annulation à la fin de la période en cours',
      undoCancel: 'Réactiver le renouvellement automatique.',
      labelBtn: "Annuler l'abonnement",
      confirmTitleModal: 'Êtes-vous sûr de vouloir annuler votre abonnement ?',
      confirmText:
        'Votre abonnement sera annulé à la fin de la période en cours ( {{date}} ).',
      confirmBtnText: 'Oui, annuler',
      cancelBtnText: 'Non, conserver',
    },
    undoCancelAction: {
      confirmTitleModal:
        'Êtes-vous sûr de vouloir réactiver le renouvellement automatique ?',
      confirmText:
        'Votre abonnement sera renouvelé à la fin de la période en cours ( {{date}} ). Et une nouvelle facture sera disponible.',
      confirmBtnText: 'Oui, réactiver',
      cancelBtnText: "Non, conserver l'anulation",
    },
    startDate: 'Début: {{date}}',
    endDate: 'Fin',
    subscribe: 'Souscrire',
    noSubscriptionText: 'Aucun abonnement',
    parametersGoSubscribe: 'Souscrire à un abonnement',
  },
  // Up
  up: {
    titlePartChange: 'Changez ',
    titlePartAppearence: "d'apparence",
    titlePartWith: 'avec Unpy ',
    titlePartUp: 'Up',
    abonnement: {
      tabLabel: 'Abonnements',
    },
    products: {
      productLabel: 'Produits',
    },
  },

  // Socials
  message: {
    input: {
      placeholder: 'Tapez votre message',
    },
    list: {
      noMessages: 'Aucun messages',
    },
    page: {
      returnBtn: 'Retour',
      title: 'Messagerie',
      chooseConversationToSeeMessage: 'Choisissez une conversation',
    },
    conversation: {
      loading: 'Récupération de la conversation',
      noContent: "Vous n'avez aucune conversation en cours.",
      title: 'Discutions',
      addLabel: 'Démarrer une conversation',
      item: {
        noMessage: 'Aucun message',
        deleteSuccess: 'Conversation supprimée avec succès',
      },
    },
    item: {
      owner: 'Vous',
    },
  },
  notificationList: {
    noContent: 'Aucune notification',
    item: {
      invitationTeamEvent: {
        event: 'Événement concerné',
      },
      ressourceLabelByKey: {
        [NotificationResourceTypeEnum.EVENT_ID]: "Voir l'Événement",
        [NotificationResourceTypeEnum.EVENT_NAME]: 'Événement :',
        [NotificationResourceTypeEnum.STRUCTURE_ID]: 'Voir la structure',
        [NotificationResourceTypeEnum.STRUCTURE_NAME]: 'structure: ',
        [NotificationResourceTypeEnum.RELATION_ID]: 'Mes relations',
        [NotificationResourceTypeEnum.CONVERSATION_ID]: 'Mes messages',
        [NotificationResourceTypeEnum.EVENT_PARTICIPANT_ID]: "Voir l'équipe",
      },
      objectByType: {
        [NotificationTypeEnum.INVITATION_PARTICIPATION_EVENT]:
          'Un organisateur vous invite à participer à un événement',
        [NotificationTypeEnum.PARTICIPATION_EVENT_ACCEPTED]:
          'Vous avez reçu une réponse a une demande de participation',
        [NotificationTypeEnum.PARTICIPATION_PAYMENT_REQUIRED]: 'Paiement requis',
        [NotificationTypeEnum.PARTICIPATION_PAYMENT_RECEIVED]:
          'Vous avez reçu un paiement',
        [NotificationTypeEnum.INVITATION_RESPONSE_ACCEPT]:
          '{{user}} A accepté de rejoindre votre équipe {{team}}',
        [NotificationTypeEnum.JOIN_STRUCTURE_FROM_INVITATION_LINK]:
          "{{user}} A utilisé un lien d'invitation pour rejoindre votre structure",
        [NotificationTypeEnum.INVITATION_STRUCTURE]:
          '{{user}} Vous invite à rejoindre sa structure',
        [NotificationTypeEnum.ABONNEMENT_UPDATE]:
          'Une mise à jour a été effectuée sur votre abonnement',
        [NotificationTypeEnum.JOIN_STRUCTURE]:
          '{{user}} Souhaite rejoindre votre structure',
        [NotificationTypeEnum.INVITATION_TEAM_EVENT]:
          'Vous avez été invité à rejoindre une équipe !',
        [NotificationTypeEnum.INVITATION_TEAM_PARTICIPATION_EVENT]:
          'Vous avez été invité à rejoindre une équipe pour participer a un Événement !',
        [NotificationTypeEnum.PARTICIPATION_EVENT]:
          'Demande de participation à un Événement',
        [NotificationTypeEnum.INVITATION_FRIEND]: "Demande d'ajout à la liste d'amis",
        [NotificationTypeEnum.EVENT_SUBSCRIPTION_CLOSE]:
          "Les inscriptions à l'événement sont terminées",
        [NotificationTypeEnum.EVENT_START]: "L'event a démarré !",
        [NotificationTypeEnum.EVENT_DELAYED]: "L'event a été retardé.",
        [NotificationTypeEnum.EVENT_FINISHED]: "L'event est terminé !",
        [NotificationTypeEnum.EVENT_CANCELED]: "L'event a été annulé.",
        [NotificationTypeEnum.EVENT_CANCEL_DELAYED]:
          "L'event a été annulé car l'administrateur n'a pas démarré l'événement à temps.",
        [NotificationTypeEnum.INVITATION_FRIEND_RESPONSE]:
          "Un utilisateur a répondu à votre demande d'ajout à la liste d'amis",
        [NotificationTypeEnum.INVITATION_STRUCTURE_RESPONSE]:
          "Un utilisateur a répondu à votre demande d'ajout à une structure",
        [NotificationTypeEnum.REVERT_EVENT_PLACE]: 'Un résultat de match a été modifié',
        [NotificationTypeEnum.MAJ_DECLINE_EVENT]:
          "L'event a été annulé avant la confirmation de la date de début",
        [NotificationTypeEnum.NEW_MESSAGE]: 'Vous avez un nouveau message',
      },
      header: {
        userFrom: 'De :',
        receivedDate: 'Reçu le :',
      },
    },
  },
  notification: {
    tabs: {
      all: 'Tout',
      event: 'Événement',
      mercato: 'Mercato',
      social: 'Social',
      divers: 'Divers',
    },
    pageTitle: 'Mes notifications',
    object: 'Objet :',
    contentMsg: 'Message :',
    noContent: 'Aucune notification',
    content: {
      actionBtn: {
        refuseBtn: 'Refuser',
        acceptBtn: 'Accepter',
        seeMoreBtn: 'Voir plus',
      },
      inviteEvent: {
        createTeam: 'Inscrire une équipe',
        teamAlreadyCreated: 'Vous avez déjà créé une équipe pour cet événement',
        perrempted: "Cette invitation n'est plus valable",
      },
      event: {
        name: 'Événement concerné :',
        goToTeam: 'Voir mon équipe',
        openPayment: 'Payer',
        paymentRequiredTitle:
          "Un paiement est requis pour compléter la demande de participation à l'Événement",
        goToEvent: "Voir l'évènement",
      },
      invitationStructure: {
        alreadyRefused: 'Vous avez déjà refusé cette invitation',
        alreadyAccepted: 'Vous avez déjà accepté cette invitation',
        refuseBtn: 'Refuser',
        acceptBtn: 'Accepter',
        seeStructureBtn: 'Voir la structure',
        title: '{{ pseudo }} vous a invité à rejoindre sa structure {{ structure }}',
        response: {
          refused: 'Invitation refusée',
          accepted: 'Invitation acceptée',
          sended: 'Réponse envoyé',
        },
      },
      invitationFriend: {
        alreadyRefused: 'Vous avez déjà refusé cette invitation',
        alreadyAccepted: 'Vous avez déjà accepté cette invitation',
        refuseBtn: 'Refuser',
        acceptBtn: 'Accepter',
        seeProfilBtn: 'Voir le profil',
        title: '{{ pseudo }} aimerait vous ajouter à ses amis',
        response: {
          refused: 'Acceptée',
          accepted: 'Refusée',
          sended: 'Réponse envoyée',
        },
      },
      joinStructure: {
        alreadyRefused: 'Vous avez déjà refusé cette invitation',
        alreadyAccepted: 'Vous avez déjà accepté cette invitation',
        refuseBtn: 'Refuser',
        acceptBtn: 'Accepter',
        title: '{{ pseudo }} souhaite rejoindre votre structure',
        response: {
          refused: 'Demande refusée',
          accepted: 'Demande acceptée',
        },
      },
      joinEvent: {
        notFound: 'Demande introuvable. Elle a peut-être été annulée.',
        alreadyRefused: 'Refusé',
        alreadyAccepted: 'Acceptée',
        refuseBtn: 'Refuser',
        acceptBtn: 'Accepter',
        title: "L'équipe{{ teamName }} souhaite participer à votre event",
        response: {
          refused: 'Demande refusée',
          accepted: 'Demande acceptée',
        },
      },
    },
  },

  // Divers
  general: {
    action: {
      retour: 'Retour',
    },
    noContentList: {
      title: 'Aucun résultat',
      annonceStructure: 'Aucune annonce publiée',
      eventParticipants: 'Aucun participant inscrit',
    },
    copy: {
      success: 'Copié avec succès',
    },
    menuStructParamTitle: ' Paramètres structure',
    menuPlayerParamTitle: ' Paramètres joueur',
    menuEventParamTitle: ' Paramètres event',
    selectFitlersTitlePlayers: 'Filtrer les joueurs',
    selectFitlersTitleStructure: 'Filtrer les structures',
    selectFitlersTitleEvent: 'Filtrer les événements',
    apiResult: {
      success: 'Opération effectuée avec succès',
    },
    requiredLoginModal: {
      title: 'Vous devez être connecté',
      connectLabel: 'Se connecter',
      stayDisconnectLabel: 'Rester déconnecté',
    },
    disconnected: {
      noticeSessionFinish: 'Votre session a expiré, connexion requise pour continuer',
      stayDisconnectLabel: 'Rester déconnecté',
      reconnectLabel: 'Se reconnecter',
      toastMessage: 'Votre session a expiré',
    },
    btnLabel: {
      validate: 'Valider',
    },
    passwordreset: {
      success: 'Un lien de réinitialisation de mot de passe vous a été envoyé par mail',
      btnLabel: 'Réinitialiser mon mot de passe',
      confirmTitleModal: 'Réinitialiser mon mot de passe',
      confirmText:
        'En cliquant sur confirmer, vous allez recevoir un mail contenant un lien de réinitialisation de mot de passe',
      confirmBtnText: 'Réinitialiser',
      cancelBtnText: 'Annuler',
    },
    forms: {
      errors: {
        tooLong: 'Veuillez saisir moins de {{maxChar}} caractères',
        required: 'Champs requis',
        mustNumber: 'La valeur doit être un nombre',
        mustBePositive: 'La valeur doit être positive',
      },
    },
    lang: {
      btnTitle: 'Langue :',
      langsValue: {
        en: 'Anglais',
        fr: 'Français',
      },
    },
    keyValueNoValue: 'Non renseigné',
    loading: {
      message: 'Chargement en cours',
    },
    edit: {
      success: 'Modification effectuée avec succès',
      btnLabel: 'Modifier',
    },
  },
  comingSoon: 'Bientôt disponible',
  activities: {
    tabs: {
      eventHandling: 'Mes événements',
      teamHandling: 'Mes équipes',
    },
    createEvent: 'Créer mon événement',
    searchEvent: 'Participer à un événement',
    pageTitle: 'Activités',
  },
  myStructures: {
    tabs: {
      created: 'Structures gérées',
      member: 'Structures Rejointes',
    },
    createStructure: 'Créer une structure',
    searchStructure: 'Rejoindre une structure',
    pageTitle: 'Mes structures',
  },
  products: {
    comingSoon: 'Bientôt disponible',
    normal: {
      label: 'UP',
      advantages:
        '2 BOOST MENSUEL (Non cumulable) \n COSMETIQUES UNIQUES \n PARTICIPATION TOURNOIS UNPY \n MESSAGERIE ETENDUE \n ASSISTANCE ETENDUE \n ALERTING',
    },
    premium: {
      label: 'UP + Premium',
      advantages:
        'TOUS LES AVANTAGES UP \n + \n 5 BOOST MENSUEL (Non cumulable) \n AUCUNE PUB \n MESSAGERIE ILLIMITE \n SUPPORT DÉDIÉ \n CERTIFICATION DE PROFIL',
    },
    subscribeLabel: 'ABONNEMENT',
    chargement: 'Chargement des offres ...',
    duration: {
      '1': 'MENSUEL',
      '6': 'SEMESTRIEL',
      '12': 'ANNUEL',
    },
    getSubscribeBtnLabel: 'Abonnez vous maintenant',
    alreadySubscribeLabel: 'Vous avez déjà souscrit à cet abonnement',
    upgradeSubscribe: 'Changez de formule',
    upPremiumAvantageLabel: 'Tous les avantages up',
    seeMoreBtn: 'Voir plus',
    durationLabel: {
      '1': '1 Mois',
      '6': '6 Mois',
      '12': '12 Mois',
    },
    boost: {
      label: 'BOOST',
      subLabel: 'SEULEMENT',
      btnTxt: 'DÉMARQUE TOI',
    },
    pass: {
      label: 'PASS DE SAISON',
      btnTxt: 'DÉMARQUE TOI',
    },
  },
  payout: {
    datatable: {
      id: 'ID',
      amount: 'Montant',
      status: 'Status',
      noContent: 'Aucun résultat',
      title: 'Historique des retraits',
    },
  },
  enum: {
    comformity: {
      [SellerAccountConformityEnum.NO_COMFORMITY]: 'Non activé',
      [SellerAccountConformityEnum.PENDING_COMFORMITY]: 'Confirmation en attente',
      [SellerAccountConformityEnum.ERROR_COMFORMITY]: 'Erreur',
      [SellerAccountConformityEnum.COMFORM]: 'Conforme',
    },
    payoutStatus: {
      [PayoutStatusEnum.FAILED]: 'Erreur',
      [PayoutStatusEnum.PENDING]: 'En attente',
      [PayoutStatusEnum.IN_TRANSIT]: 'En cours de transfert',
      [PayoutStatusEnum.PAID]: 'Payé',
      [PayoutStatusEnum.CANCELED]: 'Annulé',
    },
    typeAnnonce: {
      [AnnonceTypeEnum.EVENT]: 'Event',
      [AnnonceTypeEnum.STRUCTURE]: 'Demande',
    },
    notificationCategory: {
      mercato: 'Mercato',
      social: 'Social',
      divers: 'Divers',
      event: 'Event',
    },
    rolePermissionStructure: {
      [PermissionStructureEnum.INVITE_MEMBERS]: 'Inviter des membres',
      [PermissionStructureEnum.ACCEPT_MEMBERS]: 'Accepter des membres',
      [PermissionStructureEnum.REMOVE_MEMBERS]: 'Supprimer des membres',
      [PermissionStructureEnum.RETROGRADE_MEMBER]: 'Rétrograder des membres',
      [PermissionStructureEnum.SEND_INFOS]: 'Envoyer des informations',
      [PermissionStructureEnum.SUBSCRIBE_EVENT]:
        'Participation aux tournois au nom de la structure',
      [PermissionStructureEnum.UPDATE_BIO]: 'Modifier la description',
      [PermissionStructureEnum.UPDATE_PP]: 'Modifier le logo',
      [PermissionStructureEnum.UPDATE_NAME]: 'Modifier le nom',
      [PermissionStructureEnum.UPGRADE_MEMBER]: 'Promouvoir des membres',
    },
    relationStatus: {
      [RelationStatusEnum.ACCEPTED]: 'Accepté',
      [RelationStatusEnum.PENDING]: "En attente d'acceptation",
    },
    invoiceStatus: {
      [InvoiceStatusEnum.DRAFT]: 'Brouillon',
      [InvoiceStatusEnum.OPEN]: 'Paiement en attente',
      [InvoiceStatusEnum.PAID]: 'Payé',
      [InvoiceStatusEnum.VOID]: 'Inconnu',
      [InvoiceStatusEnum.UNCOLLECTIBLE]: 'Paiement en attente de validation',
    },
    teamStatus: {
      [TeamStatusEnum.VALID]: 'Valide',
      [TeamStatusEnum.REGISTERED]: 'Inscription validée',
      [TeamStatusEnum.REJECTED]: 'Inscription rejetée',
      [TeamStatusEnum.WAIT_VALIDATE]: "En attente d'acceptation",
      [TeamStatusEnum.WAIT_VALIDATE_PLAYERS]: 'Joueurs en attente',
    },
    eventParticipantStatus: {
      [EventParticipantStatusEnum.WAIT_VALIDATE]:
        'En attente de validation par un administrateur',
      [EventParticipantStatusEnum.WAIT_VALID]: 'Equipe non valide',
      [EventParticipantStatusEnum.REJECTED]: 'Demande rejetée',
      [EventParticipantStatusEnum.PAYMENT_REQUIRED]: 'Paiement requis',
      [EventParticipantStatusEnum.REGISTERED]: 'Inscris',
    },
    playerStatus: {
      [PlayerStatusEnum.ACCEPTED]: 'Accepté',
      [PlayerStatusEnum.PENDING]: 'En attente',
      [PlayerStatusEnum.DECLINED]: 'Refusé',
    },
    playerRole: {
      [PlayerRoleEnum.CHIEF]: 'Chef',
      [PlayerRoleEnum.TITULAR]: 'Titulaire',
      [PlayerRoleEnum.REPLAC]: 'Remplaçant',
    },
    eventConfirmationState: {
      [EventConfirmationStateEnum.WAITING]: 'En attente',
      [EventConfirmationStateEnum.CONFIRMED]: 'Confirmé',
      [EventConfirmationStateEnum.DELAYED]: 'Retardé',
      [EventConfirmationStateEnum.DECLINE]: 'Annulé',
    },
    systemPoints: {
      [SystemPointsEnum.DEFAULT]: 'Système par défaut',
      [SystemPointsEnum.PERSO]: 'Système personnalisé',
    },
    eventStatus: {
      [EventStatusEnum.PUBLISH]: 'Publié',
      [EventStatusEnum.DRAFT]: 'Brouillon',
      [EventStatusEnum.CANCELED]: 'Annulé',
      [EventStatusEnum.FINISH]: 'Terminé',
      [EventStatusEnum.ON_GOING]: 'Démarré',
    },
    eventSubStatus: {
      [EventSubStatusEnum.SUBSCRIBE_OPEN]: {
        title: 'Inscriptions ouvertes',
        details: 'fin prévu le {{datetime}}',
      },
      [EventSubStatusEnum.SUBSCRIBE_CLOSE]: {
        title: 'Inscriptions closes',
        details: "début de l'événement prévu le {{datetime}}",
      },
      [EventSubStatusEnum.MAX_TEAM]: {
        title: 'Inscriptions closes (Équipes max.)',
        details: "début de l'événement prévu le {{datetime}}",
      },
    },
    typeBracketCode: {
      SIMPELEM: 'Simple élimination',
      DOUBLELEM: 'Double élimination',
      POULSIMPLELEM: 'Poule & Simple elimination',
      POULDOUBLELEM: 'Poule & Double elimination',
      POULBO: 'Poule & Best of (BO 1,3,5)',
      LIG: 'Ligue',
      LIGBO: 'Ligue & Best of (BO 1,3,5)',
    },
    typeRondeCode: {
      SUISSE: 'Suisse',
      ROBIN: 'Robin',
      ROBIN2: 'Robin à deux tours',
    },
    typeTeamCode: {
      [TypeTeamCodeEnum.SOL]: '1 Joueur',
      [TypeTeamCodeEnum.DUO]: '2 Joueurs',
      [TypeTeamCodeEnum.TRI]: '3 Joueurs',
      [TypeTeamCodeEnum.QUA]: '4 Joueurs',
      [TypeTeamCodeEnum.FIV]: '5 Joueurs',
      [TypeTeamCodeEnum.SQU]: 'X Joueurs (Squad)',
    },
    typeBoCode: {
      BO1: 'Best Of 1 (BO1)',
      BO3: 'Best Of 3 (BO3)',
      BO5: 'Best Of 5 (BO5)',
    },
    typeEventCode: {
      LIGUE: 'Ligue',
      LIG_BR: 'Ligue BR (Battle royal)',
      TOURNAMENT: 'Tournois',
      TOURNAMENT_DOUBLE_ELEM: 'Tournois double élimination',
    },
    typePing: {
      JOIN_STRUCTURE: 'Demander à rejoindre cette structure',
      INVITE_STRUCTURE: 'Inviter dans une structure',
      SEND_MESSAGE: 'Envoyer un message',
      ADD_FRIEND: 'Demander en amis',
    },
    lang: {
      EN: 'Anglais',
      FR: 'Français',
      ESP: 'Espagnol',
    },
    gamingExperience: {
      PRO: 'Pro / Compétition',
      CASU: 'Casual',
      SEMIPRO: 'Semi-pro',
    },
    platforms: {
      XBO: 'XBOX',
      PC: 'PC',
      PLA: 'Playstation',
    },
  },
  ping: {
    modalPing: {
      title: 'Envoyer un ping',
    },
    form: {
      typePingLabel: 'Type de ping',
      privaciesRestrict: "Désactivé par l'utilisateur",
      typePingPlaceholder: 'Type de ping',
      messagePingLabel: 'Ajouter un message',
      messagePlaceHolder: 'Tapez votre message',
      structureChoiceLabel: 'Choisissez une structure',
      eventChoiceLabel: 'Choisissez un événement',
      structureChoicePlaceholder: 'Séléctionnez une structure',
      noStructures: 'Aucune de vos structures ne peut accueillir ce joueur',
      noEvents: 'Aucun de vos événement ne peut accueillir ce joueur',
      eventsLoadingPlaceholder: 'Récupération de vos événements',
      structureLoadingPlaceholder: 'Récupération de vos structures',
      alreadyMember: 'Déjà membre',
      noPermission: "Vous n'avez pas les droits requis",
      submitBtn: 'Envoyer',
      structure: {
        successJoin: "Demande d'adhésion envoyée avec succès",
      },
      player: {
        successInvite: 'Invitation envoyée avec succès',
      },
    },
  },
  games: {
    libelle: {
      CS2: 'Counter strike 2',
      MW3: 'CoD Modern Warfare 3',
      APX: 'Apex legends',
      OVW: 'Overwatch 2',
      XDF: 'XDefiant',
      WRZ: 'Warzone',
      LOL: 'League of legends',
      PBG: 'PUBG',
      R6S: 'Rainbow Six : Siege',
      FFA: 'Fifa 24',
      VLR: 'Valorant',
      TFT: 'Teamfight Tactics',
      CSG: 'CS:GO',
      RCK: 'Rocket league',
      FRT: 'Fortnite',
      STRFGT: 'Street Fighter',
      SCB6: 'SoulCalibur VI',
      MARD8: 'MarioKart Deluxe 8',
      TEK8: 'Tekken 8',
      SMULT: 'Super Smash Bross Ultimate',
      DOTA2: 'Dota 2',
      HST: 'HearthStone',
      K24: 'NBA 24',
    },
    statistiques: {
      WINNUM: 'Nombre de victoires',
      WINPER: 'Pourcentage de victoires',
      KILL: 'Nombre de frag',
      KD: 'Ratio frag/mort',
      KILLMMATCH: 'Moyenne de frag par partie',
      TOP5: 'Nombre de top 5',
      DMGMOY: 'Dégâts moyens',
      NBRKO: 'Nombre de KO',
      POINTARENA: "Points d'arène",
      DIV: 'Division',
      DIVMAX: 'Division maximum atteinte',
      KDA: 'Ratio frag/mort/assistance',
      TOP10PER: 'Pourcentage de top 10',
      DEATH: 'Nombre de mort',
      ASSIST: "Nombre d'assistances",
      RANKPBG: 'Rang ',
      RANKPBGMAX: 'Rang maximal atteint',
      RANKAPX: 'Rang ',
      RANKAPXMAX: 'Rang  maximal atteint',
      RANKCSGO: 'Rang ',
      RANKCSGOMAX: 'Rang  maximal atteint',
      RANKTFT: 'Rang ',
      RANKTFTMAX: 'Rang  maximal atteint',
      RANKVLR: 'Rang ',
      RANKVLRMAX: 'Rang  maximal atteint',
      RANKR6S: 'Rang ',
      RANKR6SMAX: 'Rang  maximal atteint',
      RANKRCK: 'Rang ',
      RANKRCKMAX: 'Rang  maximal atteint',
      RANKLOL: 'Rang ',
      RANKLOLMAX: 'Rang  maximal atteint',
      DMGTOT: 'Total de dégâts',
      LEGFAV: 'Légende favorite',
      HEADSHOTPER: 'Pourcentage de tirs dans la tête',
      NBMVP: 'Nombre de fois MVP',
      LOOSE: 'Nombre de défaites',
      BOMBDEF: 'Bombes désamorcées',
      BOMBPLANT: 'Bombes posées',
      HOSTRESC: 'Otages secourus',
      SHOTACC: 'Précision des tirs',
      TOP4: 'Nombre de top 4',
      TOP4PER: 'Pourcentage de top 4',
      NBGAME: 'Nombre de parties',
      CHAMPFAV: 'Champions favoris',
      HEADSHOT: 'Nombre de tirs dans la tête',
      SCORROUNDMOY: 'Score moyen par round',
      KILLROUNDMOY: 'Nombre de frag moyen par round',
      FIRTBLOOD: 'Nombre de premier sang',
      MOSTKILLMATCH:
        'Nombre de fois où vous avez eu le meilleur nombre de frag dans le match',
      AGFAV: 'Agents favoris',
      KILLMEL: 'Nombre de frag de mélée',
      KILLBLIND: 'Nombre de frags sans vision',
      BUTOT: 'Total de but',
      CFTOT: 'Total de coup-franc',
      PENTOT: 'Total de pénalty',
      MATCHTOT: 'Total de match',
      NBSAIS: 'Nombre de saisons disputées',
      NBGRAD: 'Nombre de titres remportés',
      BUTPER: 'Pourcentage de but',
      SHOT: 'Nombre de tirs',
      ROLEPRIM: 'Rôle principal',
      ROLESEC: 'Rôle secondaire',
      CREEPMIN: 'Creep par minute',
      VISCORE: 'Score de vision',
    },
    selectOptStats: {
      Ligue_ouverte_Division_1: 'Ligue_ouverte_Division_1',
      Ligue_ouverte_Division_2: 'Ligue_ouverte_Division_2',
      Ligue_ouverte_Division_3: 'Ligue_ouverte_Division_3',
      Ligue_ouverte_Division_4: 'Ligue_ouverte_Division_4',
      Ligue_rivalite_Division_5: 'Ligue_rivalité_Division_5',
      Ligue_rivalite_Division_6: 'Ligue_rivalité_Division_6',
      Ligue_rivalite_Division_7: 'Ligue_rivalité_Division_7',
      Ligue_Champion_Division_8: 'Ligue_Champion_Division_8',
      Ligue_Champion_Division_9: 'Ligue_Champion_Division_9',
      Ligue_Champion_Division_10: 'Ligue_Champion_Division_10',
      jungle: 'Jungle',
      Middle: 'Middle',
      bottom: 'Bottom',
      top: 'Top',
      support: 'Support',
    },
  },
  login: {
    title: 'Vous devez vous connecter pour avoir accès à cette page',
    btnLoginLabel: 'Se connecter',
    btnReturnLabel: 'Retour',
  },
  credentials: {
    passwordSuccess: 'Mot de passe modifié avec succès',
    passwordPlaceholder: 'Nouveau mot de passe',
  },
  register: {
    form: {
      usernameLabel: "Nom d'utilisateur",
      emailLabel: 'Email',
      passwordLabel: 'Mot de passe',
      submitButton: 'Modifier',
      cancelButton: 'Annuler',
      registeredNotice: 'Vous êtes maintenant inscrit sur Unpy',
    },
  },
  topbarMenuProfil: {
    links: {
      annonces: 'Annonces',
      structures: 'Structures',
      details: 'Détails',
      performances: 'Performances',
      members: 'Membres',
      network: 'réseaux',
    },
  },
  topbar: {
    menuConnected: {
      label: {
        parameters: 'Mon compte',
        myProfil: 'Mon profil',
        relations: 'Relations',
        myStructures: 'Mes structures',
        myEvents: 'Mes événements',
        activities: 'Activités',
        logout: 'Se déconnecter',
      },
    },
    links: {
      home: 'HOME',
      mercato: 'MERCATO',
      mercatoPlayer: 'joueurs',
      mercatoStructure: 'structures',
      event: 'EVENT',
      marketplace: 'MARKETPLACE',
      up: 'ABONNEMENT',
    },
    anonyme: {
      login: 'Connexion / Inscription',
      register: 'Inscription',
    },
    create: {
      structure: {
        label: 'Création de structure',
      },
      event: {
        label: "Création d'event",
      },
    },
  },
  parameters: {
    menus: {
      profil: {
        user: {
          title: 'Utilisateur',
          form: {
            submit: 'Sauvegarder',
            errors: {
              videoProfilUrl:
                'Veuillez rentrer une URL valide ( https://[...].[.fr,.com...] )',
            },
            playerIdLabel: 'ID Player',
            playerIdPlaceholder: 'Choisir un identifiant player',
            descriptionLabel: 'Description',
            descriptionPlaceholder: 'Décrivez vous',
            birthdateLabel: 'Date de naissance',
            birthdatePlaceholder: 'Date de naissance',
            videoProfilLabel: 'Vidéo',
            videoProfilPlaceholder: 'Lien de votre vidéo promotionnelle',
            socialLinkPlaceholder: 'Lien de votre {{network}}',
            socialLinkError:
              'Une erreur est survenue lors de la modification du lien {{network}}.',
            pingLabel: 'Ping',
            countryLabel: 'Pays',
            countryPlaceholder: 'Renseignez votre pays',
            talkLangsLabel: 'Langues utilisées',
            talkLangsPlaceholder: 'Choisissez une ou plusieurs langues.',
            neonLabel: 'Neon',
            neonPlaceholder: 'Sélectionnez un type de néon',
            profilPhotoLabel: 'Photo de profil',
            profilPhotoSizeError: 'Fichier trop volumineux. (2Mo max)',
            profilPhotoPlaceholder: 'Choisissez un fichier',
            bannerLabel: 'Bannière',
            bannerPlaceholder: 'Choisissez un fichier',
            backgroundImageLabel: "Image d'arrière plan",
            gamingExperienceLabel: 'Expérience',
            gamingExperiencePlaceholder: 'Sélectionnez votre expérience gaming',
            platformLabel: 'Plateformes',
            platformPlaceholder: 'Sélectionnez une ou plusieurs platformes',
            gamesLabel: 'Jeux',
            gamesPlaceholder: 'Sélectionner un ou plusieurs jeux',
            statSuccess: '{{stat}} modifié avec succès',
            listGameSelectedTitle: 'Jeux joués',
            statistiquesTitle: 'Statistiques',
          },
        },
        structure: {
          pingFieldBlocLabel: 'Paramètres de ping ',
          pingFieldBlocSubLabel: "J'autorise les autres joueurs à : ",
          title: 'Structure',
          form: {
            submit: 'Sauvegarder',
            errors: {
              videoProfilUrl:
                'Veuillez rentrer une URL valide ( https://[...].[.fr,.com...] )',
            },
            nameLabel: 'Nom de la structure',
            namePlaceholder: 'Renseignez un nom de structure',
            descriptionLabel: 'Description',
            descriptionPlaceholder: 'Décrivez vous',
            videoProfilLabel: 'Vidéo',
            videoProfilPlaceholder: 'Lien de votre vidéo promotionnelle',
            socialLinkPlaceholder: 'Lien de votre {{network}}',
            socialLinkError:
              'Une erreur est survenue lors de la modification du lien {{network}}.',
            pingLabel: 'Ping',
            countryLabel: 'Pays',
            countryPlaceholder: 'Renseignez votre pays',
            talkLangsLabel: 'Langues utilisées',
            talkLangsPlaceholder: 'Choisissez une ou plusieurs langues.',
            neonLabel: 'Neon',
            neonPlaceholder: 'Sélectionnez un type de néon',
            profilPhotoLabel: 'Photo de profil',
            profilPhotoPlaceholder: 'Choisissez un fichier',
            bannerLabel: 'Bannière',
            bannerPlaceholder: 'Choisissez un fichier',
            backgroundImageLabel: "Image d'arrière plan",
            gamingExperienceLabel: 'Expérience',
            gamingExperiencePlaceholder: 'Sélectionnez votre expérience gaming',
            platformLabel: 'Plateformes',
            platformPlaceholder: 'Sélectionnez une ou plusieurs platformes',
            gamesLabel: 'Jeux',
            gamesPlaceholder: 'Sélectionnez un ou plusieurs jeux',
            gamesSuccess: 'Jeux modifiés avec succès',
            statSuccess: '{{stat}} modifié avec succès',
            listGameSelectedTitle: 'Sélectionnez pour saisir les statistiques associées',
            statistiquesTitle: 'Statistiques',
          },
        },
        appearence: {
          title: 'Apparence',
        },
      },
    },
  },
  date: {
    ...translationDateFR,
  },
  helpers: {
    modal: {
      rolesInfos: {
        title: 'Liste des rôles et permissions',
      },
    },
    events: {
      type: {
        ligue: {
          title: 'Ligue',
          content:
            'Les event de type ligue fonctionnent en ronde robin. Chaque participant doit disputer un match contre tous les autres participants. Le vainqueur est celui ayant obtenu le plus de points une fois tous les matchs disputés.',
        },
        ligueBR: {
          title: 'Ligue BR (Battle royal)',
          content:
            'Un nombre de matchs est généré en fonction des paramètres saisis, chaque match contient tous les participants. Le vainqueur est celui ayant obtenu le plus de points une fois tous les matchs disputés et chaque score saisi.',
        },
        tournament: {
          title: 'Tournois',
          content:
            'Tournois à élimination directe classique (4,8,16,32 etc ...). Le vainqueur du tournois est décidé en finale.',
        },
        tournamentDoubleElem: {
          title: 'Tournois à double élimination',
          content:
            'Les event de type ligue fonctionnent en ronde robin. Chaque participant doit disputer un match contre tous les autres participants. Le vainqueur est celui ayant obtenu le plus de points une fois tous les matchs disputés',
        },
      },
      pointsSystem: {
        edit: {
          defeat: 'Défaite: -1',
          null: 'Nul: +1',
          victory: 'Victoire: +3',
        },
        brief: 'Unpy laisse le choix entre deux systèmes de points :',
        [SystemPointsEnum.DEFAULT]:
          "Le système de points par défaut implique de laisser Unpy appliquer les scores de : Défaite = -1, Nul = 0, Victoire = 3. L'administrateur devra choisir entre ces 3 options lors de la saisie de scores.",
        [SystemPointsEnum.PERSO]:
          "Le système de points personnalisé permet à l'administrateur d'utiliser son propre système de points et de saisir directement un nombre de points lors de la saisie du score.",
      },
      freeRegister: {
        freeKey: 'Oui',
        notFreeKey: 'Non',
        free: "Les inscriptions ne nécessitent pas de validation par un administrateur de l'event",
        notFree: "Un administrateur doit valider l'inscription de chaque participant",
      },
    },
  },
  invoice: {
    list: {
      noContent: 'Aucune transaction',
      title: 'Liste des factures & transactions',
    },
    datatable: {
      id: 'Identifiant',
      date: 'datetime-local',
      total: 'Montant',
      status: 'Statut',
      download: 'Fichier .pdf',
      downloadTitle: 'Télécharger au format .pdf',
    },
  },
  modals: {
    pendingStructureInvitation: {
      title: 'Invitations structure en attente',
      noContent: 'Aucune invitation en attente',
    },
    pendingFriendInvitation: {
      title: 'Invitations amis en attente',
      noContent: 'Aucune invitation en attente',
    },
    pendingTeamsInvitation: {
      title: 'Invitation à rejoindre une équipe pour un événement',
      noContent: 'Aucune invitation en attente',
    },
    pendingEventInvitation: {
      title: 'Invitation à créer une équipe pour un événement',
      noContent: 'Aucune invitation en attente',
    },
    pendingEventParticipationsInvitation: {
      title: 'Demande de participation à un évenement',
      noContent: 'Aucune invitation en attente',
    },
    annonce: {
      createTitle: 'Créer une annonce',
      editTitle: 'Modifier une annonce',
    },
    waitingForActions: {
      title:
        'Veuillez suivre les instructions sur la page de notre partenaire de paiement Stripe.',
      text: "Vous pouvez fermer cette modal et continuer le processus sur la fenêtre de paiement Stripe, votre abonnement Unpy s'activera une fois le paiement validé",
      closeBtn: 'Fermer',
    },
    deleteFriend: {
      title: 'Supprimer {{ name }} de mes amis',
      success: 'Suppression effectuée avec succès',
    },
    addFriend: {
      title: 'Ajouter {{ name }} en ami',
      success: "Demande d'ajout en ami envoyée avec succès",
    },
    sendMessage: {
      title: 'Envoyer un message',
      success: 'Message envoyé avec succès',
    },
    sendMessageToStrucure: {
      title: 'Contacter la structure {{ name }}',
      success: 'Message envoyé avec succès',
    },
    joinStructure: {
      title: 'Rejoindre la structure {{ name }}',
      success: "Demande d'adhésion envoyée avec succès",
    },
    inviteStructure: {
      title: 'Inviter {{ name }} dans une structure',
      success: "Demande d'ajout à la structure envoyée avec succès",
    },
    inviteEvent: {
      title: 'Inviter {{ name }} à un événement que vous organisez',
      success: 'Invitation envoyée avec succès',
    },
  },
};
