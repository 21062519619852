import { PlayerStatusEnum } from 'domain/event/PlayerStatusEnum';
import {
  RestInvitationLink,
  toInvitationLink,
} from 'secondary/invitationLink/RestInvitationLink';
import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { PlayerRoleEnum } from '../../domain/event/PlayerRoleEnum';
import { PlayerTeam } from '../../domain/event/PlayerTeam';
import { toEnum } from '../../helper/enum.helper';
import { RestTeam, toTeam } from '../team/RestTeam';

export interface RestPlayerTeam {
  team: RestTeam;
  player: RestUserProfilView;
  invitationLink: RestInvitationLink;
  role: string;
  accepted: boolean;
}

export function toPlayerTeam(restPlayerTeam: RestPlayerTeam): PlayerTeam {
  return new PlayerTeam(
    restPlayerTeam.team ? toTeam(restPlayerTeam.team) : null,
    restPlayerTeam.player != null ? toUserProfilView(restPlayerTeam.player) : null,
    toEnum(PlayerRoleEnum, restPlayerTeam.role),
    toEnum(
      PlayerStatusEnum,
      restPlayerTeam.accepted === null
        ? 'PENDING'
        : restPlayerTeam.accepted
        ? 'ACCEPTED'
        : 'DECLINED',
    ),
    restPlayerTeam.invitationLink
      ? toInvitationLink(restPlayerTeam.invitationLink)
      : null,
  );
}
