import { AxiosInstance } from 'axios';
import { IInvitationRepository } from 'domain/invitation/IInvitation.repository';
import { Invitation, InvitationFilters } from 'domain/invitation/Invitation';
import { Pageable } from 'domain/pageable/Pageable';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toInvitation } from 'secondary/invitation/RestInvitation';
import { toPageable } from 'secondary/RestPageable';

export class InvitationRepository
  extends AxiosProviderRepository
  implements IInvitationRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  createInvitation(
    type: string,
    receiverId: number,
    recourceId: string,
  ): Promise<Invitation> {
    return this.axios
      .post('/unpy/api/invitation', {
        type,
        receiverId,
        resourceId: recourceId,
      })
      .then((response) => toInvitation(response.data));
  }

  createInvitationFromLink(type: string, link: number): Promise<Invitation> {
    return this.axios
      .post('/unpy/api/invitation/link/consumed', {
        type,
        linkId: link,
      })
      .then((response) => toInvitation(response.data));
  }

  cancelInvitation(id: number): Promise<void> {
    return this.axios.delete(`/unpy/api/invitation/cancel/${id}`);
  }

  responseInvitation(invitationId: number, response: boolean): Promise<Invitation> {
    return this.axios
      .post(`/unpy/api/invitation/response`, { invitationId, response })
      .then((resp) => toInvitation(resp.data));
  }

  search(
    filters: InvitationFilters,
    page: number,
    nbPerPage: number,
  ): Promise<Pageable<Invitation>> {
    return this.axios
      .post('/unpy/api/invitation/search', {
        ...filters,
        page,
        nbPerPage,
      })
      .then((response) => {
        return toPageable(response, toInvitation);
      });
  }
}
