import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ComformityStateWrapper } from 'primary/Parameters/user/portefeuille/ComformityStateWrapper';
import { useUserParameters } from 'primary/Parameters/user/useUserParameters.hook';
import React from 'react';

export const Prizes = () => {
  const t = useTranslate();
  const { user, onSubmitted } = useUserParameters();

  return (
    <PageLayout>
      <Title
        title={t('user.parameters.prizes.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <ComformityStateWrapper>
        <span>Content conformity ok</span>
      </ComformityStateWrapper>
    </PageLayout>
  );
};
