import { BalanceChargeStatus } from 'domain/balance/BalanceChargeStatus';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { Team } from 'domain/team/Team';
import { TeamStatusEnum } from 'domain/team/TeamStatusEnum';
import { toEnum } from 'helper/enum.helper';
import {
  RestClassementParticipant,
  toClassementParticipant,
} from 'secondary/classement/RestClassementParticipant';
import {
  RestMatchParticipant,
  toMatchParticipant,
} from 'secondary/event/match/RestMatchParticipant';
import { RestEventView, toUnpyEventView } from 'secondary/event/RestEventView';
import { RestStructureView, toStructureView } from 'secondary/structure/RestStructure';
import { RestTeam, toTeam } from 'secondary/team/RestTeam';

export interface RestEventParticipant {
  id: number;
  team: RestTeam;
  classementParticipant: RestClassementParticipant;
  event: RestEventView;
  structure: RestStructureView;
  matchsParticipants: RestMatchParticipant[];
  status: TeamStatusEnum;
  balanceChargeStatus?: BalanceChargeStatus;
}

export function toEventParticipant(
  restEventParticipant: RestEventParticipant,
): EventParticipant {
  return new EventParticipant(
    restEventParticipant.id,
    restEventParticipant.team
      ? toTeam(restEventParticipant.team)
      : new Team(0, 'N/A', [], TeamStatusEnum.VALID, undefined, undefined),
    toUnpyEventView(restEventParticipant.event),
    toEnum(EventParticipantStatusEnum, restEventParticipant.status),
    restEventParticipant.structure
      ? toStructureView(restEventParticipant.structure)
      : undefined,
    restEventParticipant.matchsParticipants != null
      ? restEventParticipant.matchsParticipants.map((p) => toMatchParticipant(p))
      : undefined,
    restEventParticipant.classementParticipant != null
      ? toClassementParticipant(restEventParticipant.classementParticipant)
      : undefined,
    restEventParticipant.balanceChargeStatus
      ? toEnum(BalanceChargeStatus, restEventParticipant.balanceChargeStatus)
      : undefined,
  );
}
