import './FilterTalkLang.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import React from 'react';

import { GamingProfilEnum } from '../../../domain/enums/GamingProfil.enum';
import { useTranslate } from '../../hooks/useTranslate';

type FilterTalkLangProps = {
  onSelectExperience: (values?: string) => void;
  selectedExperience?: string;
};

export const FilterExperience = ({
  onSelectExperience,
  selectedExperience,
}: FilterTalkLangProps) => {
  const t = useTranslate();

  return (
    <div>
      <InputSelectTag
        placeholder={t('mercato.filters.placeholders.experiences')}
        options={insertEmptyOpt(
          Object.values(GamingProfilEnum)?.map((exp: string) => ({
            label: t(`enum.gamingExperience.${exp as GamingProfilEnum}`),
            value: exp,
          })) || [],
        )}
        onChange={(event: ChangeHandlerTypeMeta) => {
          //@ts-ignore
          onSelectExperience(event.target.value as string[]);
        }}
        value={selectedExperience}
        menuPortalTarget={document.body}
      />
    </div>
  );
};
