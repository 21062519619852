import { RoutesList } from './Routes.config';

type ParametersRoutesEventKey =
  | 'replaceDefault'
  | 'apparence'
  | 'rules'
  | 'parameterize'
  | 'participants'
  | 'scoreboard'
  | 'timeline';

// @ts-ignore
export const parametersRoutesEvent: RoutesList<ParametersRoutesEventKey> = {
  replaceDefault: {
    to: `general/apparence`,
    path: 'general/apparence',
  },
  apparence: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/general/apparence`,
    path: '/parameters/event/:idEvent/general/apparence',
  },
  timeline: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/general/timeline`,
    path: '/parameters/event/:idEvent/general/timeline',
  },
  rules: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/general/rules`,
    path: '/parameters/event/:idEvent/general/rules',
  },
  parameterize: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/handling/parameterize`,
    path: '/parameters/event/:idEvent/handling/parameterize',
  },
  participants: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/handling/participants`,
    path: '/parameters/event/:idEvent/handling/participants',
  },
  classement: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/handling/classement`,
    path: '/parameters/event/:idEvent/handling/classement',
  },
  scoreboard: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/handling/scoreboard`,
    path: '/parameters/event/:idEvent/handling/scoreboard',
  },
  unpyAdmin: {
    to: (idEvent: string) => `/parameters/event/${idEvent}/superadmin`,
    path: '/parameters/event/:idEvent/superadmin',
  },
};
