import {
  add,
  differenceInDays,
  differenceInMonths,
  format,
  getDate,
  getDay,
  getMonth,
  isSameWeek,
  isValid,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { Translate } from 'domain/translation/Translation.repository';

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

/**
 * @deprecated
 */
export const dateFormatOptional = (dateToFormat?: string): string | undefined => {
  if (dateToFormat && isValid(new Date(dateToFormat))) {
    return formatDate(new Date(dateToFormat), 'readable(long year with -)');
  } else {
    return undefined;
  }
};

export type Format =
  | 'back'
  | 'inputDatetime'
  | 'readable text day'
  | 'readable text month'
  | 'readable(short year)'
  | 'readable all text'
  | 'readable(long year)'
  | 'readable(long year with -)'
  | 'readable(with hour)'
  | 'hour'
  | 'hour:minute'
  | 'day number'
  | 'back(datetime)';

const formatWithTrad = [
  'readable short day of week',
  'day with translated month',
  'readable all text',
] as const;
export type FormatWithTrad = typeof formatWithTrad[number];

function isFormatWithTrad(
  maybeFormatWithTrad: unknown,
): maybeFormatWithTrad is FormatWithTrad {
  return (
    typeof maybeFormatWithTrad === 'string' &&
    formatWithTrad.includes(maybeFormatWithTrad as FormatWithTrad)
  );
}

const formatToFormatDate: Record<Format, string> = {
  back: 'yyyy-MM-dd',
  inputDatetime: "yyyy-MM-dd'T'HH:mm",
  'readable text day': 'EEEE',
  'day number': 'dd',
  'readable all text': 'EEEE dd MMMM yyyy',
  'readable text month': 'MMMM',
  'readable(short year)': 'dd/MM/yy',
  'readable(long year)': 'dd/MM/yyyy',
  'readable(long year with -)': 'dd-MM-yyyy',
  'readable(with hour)': 'dd/MM/yyyy HH:mm',
  'hour:minute': 'HH:mm',
  'back(datetime)': "yyyy-MM-dd'T'HH:mm:ss.SSS",
  hour: "HH'h'mm",
};

const formatToFormatDateTranslate: Record<
  FormatWithTrad,
  (t: Translate, date: Date) => string
> = {
  'readable short day of week': (translate, date) =>
    `${translate(`date.shortDayOfWeek.${getDay(date)}`)} ${(
      '0' + `${getDate(date)}`
    ).slice(-2)} ${translate(`date.month.${getMonth(date) as Month}`)}`,
  'day with translated month': (t, date) =>
    `${date.getDate().toString().padStart(2, '0')} ${t(
      `date.month.${getMonth(date) as Month}`,
    )}`,
  'readable all text': (t, date) =>
    `${t(`date.dayFirstCapitalize.${getDay(date)}`)} ${date
      .getDate()
      .toString()
      .padStart(2, '0')} ${t(
      `date.monthComplete.${getMonth(date) as Month}`,
    )} ${date.getFullYear()}`,
};

export const getNewLocalDateFromBack = (date: string) => {
  return new Date(date);
};

export function formatDate(
  date: Date | undefined,
  formatter: Format | 'back(datetime) UTC',
): string;
export function formatDate(
  date: Date | undefined,
  formatter: FormatWithTrad,
  t: Translate,
): string;
export function formatDate(
  date: Date | undefined,
  formatter: Format | FormatWithTrad | 'back(datetime) UTC',
  t?: Translate,
): string {
  if (!date || !isValid(date)) return '';
  if (isFormatWithTrad(formatter)) {
    const translate = t as Translate;
    return formatToFormatDateTranslate[formatter](translate, date);
  }
  if (formatter === 'back(datetime) UTC') return date.toISOString();
  return format(date, formatToFormatDate[formatter], { locale: fr });
}

export const differenceBetweenDatesInMonthsAndDays = (
  dateStartStr: Date | string | undefined,
  dateEndStr: Date | string | undefined,
): [number, number] => {
  if (!dateStartStr || !dateEndStr) return [0, 0];
  const dateStart = new Date(dateStartStr);
  const dateEnd = new Date(dateEndStr);
  if (!isValid(dateStart) || !isValid(dateEnd)) return [0, 0];
  const diffInMonths = differenceInMonths(dateEnd, dateStart);
  const diffInDays = differenceInDays(dateEnd, add(dateStart, { months: diffInMonths }));
  return [diffInMonths, diffInDays];
};

// get day of week number starting from 0
export const getDayOfWeekAsNumber = (date: Date): DayOfWeek => {
  const day = getDay(date);
  return day === 0 ? 6 : ((day - 1) as DayOfWeek);
};

// add one day to base week on Monday and not on Sunday
export const getFirstDayOfWeek = (date: Date): number => {
  const newDate = new Date(date);
  return newDate.getDate() - newDate.getDay() + 1;
};

export const isDateInCurrentWeek: (
  dateToTest: Date,
  oneDateOftheWeek: Date,
) => boolean = (dateToTest, oneDateOftheWeek: Date) => {
  if (dateToTest < oneDateOftheWeek)
    return isSameWeek(oneDateOftheWeek, dateToTest, { weekStartsOn: 1 });
  return isSameWeek(dateToTest, oneDateOftheWeek, { weekStartsOn: 1 });
};

export const getTodaysDate = () => formatDate(new Date(), 'back');

export const stringToDateOrUndefined = (str?: string): Date | undefined =>
  str ? new Date(str) : undefined;

export const dateInTenYearsFromNow = () => {
  const tenYearsLater = new Date();
  tenYearsLater.setFullYear(new Date().getFullYear() + 10);
  return tenYearsLater;
};
