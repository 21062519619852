import { Payout } from 'domain/payout/Payout';
import { PayoutStatusEnum } from 'domain/payout/PayoutStatus.enum';
import { PayoutTypeEnum } from 'domain/payout/PayoutType.enum';
import { RestUserView, toUserView } from 'secondary/user/RestUser';

export interface RestPayout {
  id: number;
  status: PayoutStatusEnum;
  stripeId: string;
  type: PayoutTypeEnum;
  amount: number;
  arrivalDate?: string;
  restUser: RestUserView;
}

export function toPayout(restPayout: RestPayout) {
  return new Payout(
    restPayout.id,
    restPayout.status,
    restPayout.stripeId,
    restPayout.type,
    restPayout.amount,
    toUserView(restPayout.restUser),
    restPayout.arrivalDate ? new Date(restPayout.arrivalDate) : undefined,
  );
}
