import './EventFilters.scss';

import { InputSwitchTag } from 'primary/Components/Input/InputSwitchTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterDisplayFinishedProps = {
  onSelectDisplayFinished: (value?: string) => void;
  selectedDisplayFinished?: string;
};

export const FilterDisplayFinished = ({
  onSelectDisplayFinished,
  selectedDisplayFinished,
}: FilterDisplayFinishedProps) => {
  const t = useTranslate();
  return (
    <InputSwitchTag
      value={selectedDisplayFinished}
      label={t('event.filters.displayFinished')}
      placeholder={t('event.filters.displayFinished')}
      onChange={(event: ChangeHandlerTypeMeta) => {
        // @ts-ignore
        onSelectDisplayFinished(event?.target?.value.toString());
      }}
    />
  );
};
