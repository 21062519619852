import '../../Utils/Forms/UnpyForm.scss';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import {
  BRACKET_TEAM_NUMBER_OPT,
  DOUBLE_BRACKET_TEAM_NUMBER_OPT,
} from 'domain/event/eventOptByGames';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import {
  canEditField,
  getInitialValueRules,
  getIsReadOnly,
} from 'domain/event/UnpyEvent.func';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import React, { FC, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { Translate } from '../../../domain/translation/Translation.repository';
import { InputWrapper } from '../../Components/Input/InputWrapper';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventRulesFormData = {
  typeTeam: string;
  rules: string;
  systemPoints: string;
  typeEvent: string;
  maxTeam: number;
  bracketTeamNumber: string;
  prizeText?: string;
  cashprize?: number;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    typeTeam: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    rules: yup
      .string()
      .nullable()
      .max(
        ValidationStringLengthEnum.XL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.XL }),
      ),
    typeEvent: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    maxTeam: yup.string().nullable(),
    bracketTeamNumber: yup.string().nullable(),
    systemPoints: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    prizeText: yup.string().nullable(),
    cashprize: yup.number().nullable(),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    idEvent: number,
    t: Translate,
  ) =>
  (data: EventRulesFormData) => {
    eventRepostory.updateEventRules(data, idEvent).then((event) => {
      sendEventToastMessage(t('event.parameters.rules.updateSuccess'), 'success');
      onSubmitted(event.id);
    });
  };

interface EventCreateFormProps {
  onCreated: (id: number) => void;
  event: UnpyEvent;
}

export const EventRulesForm: FC<EventCreateFormProps> = ({ onCreated, event }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const initialValues = getInitialValueRules(event);
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<EventRulesFormData>({
    resolver,
    defaultValues: initialValues,
  });
  const { eventRepository } = useContextDependency();

  const t = useTranslate();
  const onSubmit = useOnSubmit(eventRepository, onCreated, event.id, t);

  const [typeTeam] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeTeam(),
    undefined,
  );

  const [typeEvent] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeEvent(),
    undefined,
  );

  const { field: bracketTeamNumberField } = useController({
    control: control,
    name: 'bracketTeamNumber',
    defaultValue: initialValues.bracketTeamNumber?.toString(),
  });
  const { field: typeEventField } = useController({
    control: control,
    name: 'typeEvent',
    defaultValue: initialValues.typeEvent,
  });
  const { field: systemPointField } = useController({
    control: control,
    name: 'systemPoints',
    defaultValue: initialValues.systemPoints,
  });

  const { field: rulesControl } = useController({ control: control, name: 'rules' });
  const { field: typeTeamField } = useController({ control: control, name: 'typeTeam' });

  const readOnly = useMemo(
    () => getIsReadOnly(event, 'rules') || isSubmitting,
    [event, isSubmitting],
  );

  return (
    <Box className={'eventRulesForm unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'modal-create-event__field'}
              label={t('event.form.labels.typeTeam')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.typeTeam'),
                ...typeTeamField,
                options: typeTeam
                  ? typeTeam.map((typeTeam) => ({
                      label: t(`enum.typeTeamCode.${typeTeam.code}`),
                      value: typeTeam.code,
                    }))
                  : [],
                disabled: readOnly || !canEditField('typeTeam', event),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.typeEvent')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.typeEvent'),
                ...typeEventField,
                options: typeEvent
                  ? typeEvent.map((typeEvent) => ({
                      label: t(`enum.typeEventCode.${typeEvent.code}`),
                      value: typeEvent.code,
                    }))
                  : [],
                disabled: readOnly || !canEditField('typeTeam', event),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT && (
              <InputWrapper
                className={'unpy-form__field'}
                label={t('event.form.labels.bracketTeamNumber')}
                errors={errors}
                Input={InputSelectMui}
                isSubmitting={false}
                required
                inputProps={{
                  ...bracketTeamNumberField,
                  options: BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                    label: t(`event.form.labels.bracketTeamNumberOpt`, { count: number }),
                    value: number.toString(),
                  })),
                  disabled: readOnly || !canEditField('maxTeam', event),
                }}
              />
            )}
            {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM && (
              <InputWrapper
                className={'unpy-form__field'}
                label={t('event.form.labels.bracketTeamNumber')}
                errors={errors}
                Input={InputSelectMui}
                isSubmitting={false}
                required
                inputProps={{
                  ...bracketTeamNumberField,
                  options: DOUBLE_BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                    label: t(`event.form.labels.bracketTeamNumberOpt`, { count: number }),
                    value: number.toString(),
                  })),
                  disabled: readOnly || !canEditField('maxTeam', event),
                }}
              />
            )}
            {(event.typeEvent.code === TypeEventCodeEnum.LIGUE ||
              event.typeEvent.code === TypeEventCodeEnum.LIG_BR) && (
              <InputWrapper
                errors={errors}
                Input={TextField}
                label={t('event.form.labels.maxTeam')}
                className={'-input'}
                inputProps={{
                  type: 'number',
                  ...register('maxTeam'),
                  disabled: readOnly || !canEditField('maxTeam', event),
                  variant: 'filled',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={'-prizeField'}>
              <InputWrapper
                label={t(`event.form.labels.cashprize`)}
                errors={{}}
                className={'unpy-form__field'}
                isSubmitting={isSubmitting}
                Input={TextField}
                noFullWidth
                inputProps={{
                  ...register(`cashprize`),
                  type: 'number',
                  //@ts-ignore
                  disabled: readOnly || !canEditField('prizeText', event),
                }}
              />
              <InputWrapper
                errors={errors}
                Input={TextField}
                label={t('event.form.labels.prizeText')}
                className={'-input'}
                inputProps={{
                  ...register('prizeText'),
                  disabled: readOnly || !canEditField('prizeText', event),
                  variant: 'filled',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.systemPoints')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                ...systemPointField,
                options: Object.values(SystemPointsEnum).map((systemPoint) => ({
                  label: t(`enum.systemPoints.${systemPoint}`),
                  value: systemPoint,
                  disabled: readOnly || !canEditField('pointSystem', event),
                })),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'modal-create-event__field'}
              label={t('event.form.labels.rules')}
              errors={errors}
              Input={InputTextArea}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.rules'),
                ...rulesControl,
                disabled: readOnly || !canEditField('rules', event),
                minRows: 3,
              }}
            />
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons -one'}>
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            loading={isSubmitting}
            disabled={readOnly}
          >
            {t('event.form.editBtn')}
          </LoadingButton>
        </div>
      </form>
    </Box>
  );
};
