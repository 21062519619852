import './RightPanelTeamCreating.scss';

import classNames from 'classnames';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { PlayersNumberByTypeTeams, TypeTeam } from 'domain/event/TypeTeam';
import _ from 'lodash';
import {
  EventRegisterFormData,
  PlayerItemFormData,
} from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { EventRegisterForm } from 'primary/events/forms/EventRegisterForm';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { setPlayerProperty } from 'primary/teams/store/TeamCreatingSlice';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import * as yup from 'yup';

//@ts-ignore
const testTooManyChief = (value, context) => {
  const players = context.from[1]?.value;
  if (!value) return false;
  if (!players) return true;
  if (value !== PlayerRoleEnum.CHIEF) return true;
  if (
    Object.values(players).filter(
      //@ts-ignore
      (value1: PlayerItemFormData) => value1?.role === PlayerRoleEnum.CHIEF.toString(),
    ).length >= 2
  ) {
    return false;
  }
  return true;
};
const validationSchema = (typeTeam: TypeTeam) => (t: UseTranslateReturn) =>
  yup.object({
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    structure: yup.string().when('participationAsStructure', {
      is: (val?: boolean) => !!val,
      then: yup
        .string()
        .max(
          ValidationStringLengthEnum.SMALL,
          t('general.forms.errors.tooLong', {
            maxChar: ValidationStringLengthEnum.SMALL,
          }),
        )
        .required(t('general.forms.errors.required')),
      otherwise: yup.string().nullable(),
    }),
    players: yup.object({
      ...PlayersNumberByTypeTeams[typeTeam.code].reduce((acc, value, currentIndex) => {
        return {
          ...acc,
          [`player-${currentIndex}`]: yup.object({
            idPlayer: yup.string().when(`withLink`, {
              is: (val?: boolean) => !val || val?.toString() === 'false',
              then: yup.string().required(t('general.forms.errors.required')),
              otherwise: yup.string().nullable(),
            }),
            role: yup
              .mixed()
              .test('role', t('team.tooManyChief'), testTooManyChief)
              .required(t('general.forms.errors.required')),
          }),
        };
      }, {}),
    }),
  });

type loadingState = [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>];

export const RightPanelContentTeamCreating = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);
  const resolver = useYupValidationResolver(
    validationSchema(teamCreating?.typeTeam as TypeTeam),
  );
  const form = useForm<EventRegisterFormData>({
    resolver,
    defaultValues: teamCreating?.data,
  });

  const { setValue, watch } = form;
  const players = watch('players');
  const isEqual = _.isEqual(players, teamCreating?.data.players);
  useEffect(() => {
    if (!isEqual) {
      setValue('players', {
        ...players,
        ...teamCreating?.data?.players,
      });
    }
  }, [teamCreating?.data?.players, isEqual]);

  // useEffect(() => {
  //   if (structure != teamCreating?.data.structure) {
  //     console.log('NOT IS EQUAL FOR UPDATE STORE');
  //     dispatch(setStructure(structure));
  //   }
  // }, [structure]);

  if (!teamCreating) return null;
  return (
    <div className={classNames('rightPanelTeamCreating', '--scrollBarContent')}>
      <div className={'rightPanelTeamCreating__header'}>
        <span className={'-title'}>
          {t('event.register.rightPanelTitle', { teamName: teamCreating.data.name })}
          <br />
          <small>{t(`event.register.addPlayerFromCard`)}</small>
        </span>
      </div>
      <div className={'rightPanelTeamCreating__players'}>
        <EventRegisterForm
          form={form}
          typeTeam={teamCreating.typeTeam}
          onChangeField={(name, property, value) => {
            console.log('CHANGE FIELD', {
              name,
              property,
              value,
            });
            dispatch(setPlayerProperty({ property, value, name }));
          }}
          eventId={teamCreating.event.id}
        />
      </div>
    </div>
  );
};
