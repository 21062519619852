import './NetworkForm.scss';

import { Button, Container, Grid, Switch, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import { getInitialValuesStructureProfilNetwork } from 'domain/profil/StructureProfil.func';
import { IStructureProfilRepository } from 'domain/structure/IStructureProfil.repository';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { SocialNetworkFormsWrapper } from 'primary/Components/NetworkForms/SocialNetworkFormsWrapper';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { useStructureParameters } from 'primary/Parameters/user/useUserStructureParameters.hook';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { themeParameters } from '../../parameters.helper';
export type PrivacyFormData = {
  code: string;
  profilId: number;
  isChecked: boolean;
};

export type StructureProfilNetworkFormFormData = {
  video?: string;
  ping?: boolean;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  discord?: string;
  twitch?: string;
  privacies: {
    [key: string]: {
      profilId: number;
      code: string;
      isChecked: boolean;
    };
  };
};
export const useOnSubmit =
  (
    structureProfilRepository: IStructureProfilRepository,
    onSubmitted: () => void,
    idProfilStructure: number,
  ) =>
  (data: StructureProfilNetworkFormFormData) => {
    structureProfilRepository
      .updateProfilNetwork(data, idProfilStructure)
      .then(onSubmitted);
  };

const validationSchema = (t: UseTranslateReturn) => ({
  video: yup
    .string()
    .nullable()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .url(t('parameters.menus.profil.user.form.errors.videoProfilUrl')),
  twitter: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  facebook: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  youtube: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  instagram: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  discord: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
  twitch: yup
    .string()
    .max(
      ValidationStringLengthEnum.LONG,
      t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
    )
    .nullable(),
});

export const StructureProfilNetworkForm = () => {
  const { structure, onSubmitted, structureProfilRepository } = useStructureParameters();

  const t = useTranslate();

  // @ts-ignore
  const shape = (structure?.structureProfil?.privacies as ProfilPrivacy[])?.reduce(
    (acc, privacy) => {
      return {
        ...acc,
        [privacy.code]: yup.object().shape({
          code: yup
            .string()
            .max(
              ValidationStringLengthEnum.DEFAULT,
              t('general.forms.errors.tooLong', {
                maxChar: ValidationStringLengthEnum.DEFAULT,
              }),
            )
            .required(t('general.forms.errors.required')),
          isChecked: yup.bool().required(t('general.forms.errors.required')),
        }),
      };
    },
    {},
  );

  const initialValues = useMemo(
    () => getInitialValuesStructureProfilNetwork(structure?.structureProfil),
    [structure?.structureProfil],
  );

  const resolver = useYupValidationResolver(
    yup.object().shape({ ...validationSchema(t), privacies: yup.object().shape(shape) }),
  );

  const form = useForm<StructureProfilNetworkFormFormData>({
    resolver,
    defaultValues: initialValues,
  });

  const onSubmit = useMemo(
    () =>
      useOnSubmit(
        structureProfilRepository,
        onSubmitted,
        structure?.structureProfil?.id as number,
      ),
    [structureProfilRepository, onSubmitted],
  );
  if (!structure?.structureProfil) return null;

  return (
    <div className={'StructureProfilNetworkForm'}>
      <form name={'network-profil'} onSubmit={form.handleSubmit(onSubmit)}>
        <PageLayout>
          <Title
            title={t('user.parameters.network.title')}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
          <ThemeProvider theme={themeParameters}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <InputWrapper
                    label={t('parameters.menus.profil.user.form.videoProfilLabel')}
                    errors={form.formState.errors}
                    inputClassName={'input-user-details '}
                    labelClassName={'label-user-details '}
                    isSubmitting={form.formState.isSubmitting}
                    Input={TextField}
                    inputProps={{
                      placeholder: t(
                        'parameters.menus.profil.user.form.videoProfilPlaceholder',
                      ),
                      disabled: form.formState.isSubmitting,
                      ...form.register('video', { required: true }),
                      variant: 'filled',
                    }}
                  />
                </Grid>
                <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <SocialNetworkFormsWrapper
                      form={form}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3>
                  {t('parameters.menus.profil.structure.pingFieldBlocLabel')}
                  <br />
                  <small>
                    {t('parameters.menus.profil.structure.pingFieldBlocSubLabel')}
                  </small>
                </h3>
                {structure?.structureProfil?.privacies?.map((privacy) => (
                  <Grid item xs={6} key={privacy.code}>
                    <InputWrapper
                      //@ts-ignore
                      label={t(`privacy.${privacy.code}.labelForm`)}
                      errors={form.formState.errors}
                      className={'unpy-form__field'}
                      isSubmitting={form.formState.isSubmitting}
                      Input={Switch}
                      noFullWidth
                      inputProps={{
                        ...form.register(`privacies.${privacy.code}.isChecked`),
                        //@ts-ignore
                        defaultChecked: initialValues.privacies[privacy.code]?.isChecked,
                        onChange: (e) => {
                          form.setValue(
                            `privacies.${privacy.code}.isChecked`,
                            e.target.checked,
                          );
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button color={'primary'} variant={'contained'} type={'submit'}>
                  {t('parameters.menus.profil.user.form.submit')}
                </Button>
              </div>
            </Grid>
          </ThemeProvider>
        </PageLayout>
      </form>
    </div>
  );
};
