export const insertEmptyOpt = (param: { label: string; value: string }[]) => {
  return [
    ...param,
    {
      label: '',
      value: '',
    },
  ];
};

export const insertEmptyOptWithPlaceholder = (
  param: { label: string; value: string }[],
  placeholder: string,
) => {
  return [
    ...param,
    {
      label: '',
      value: undefined,
    },
    {
      label: placeholder,
      value: '',
    },
  ];
};
