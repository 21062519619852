import { routesConfig } from 'primary/Configs/Routes.config';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';

export const useMustConnected = () => {
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAccountLoaded && !user) {
      navigate(routesConfig.home.to());
    }
  }, [isAccountLoaded]);

  return {
    user,
    isAccountLoaded,
  };
};
