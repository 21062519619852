import { Divider } from '@mui/material';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import React, { useState } from 'react';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useNavigate } from 'react-router-dom';

import { NotificationResourceTypeEnum } from '../../../domain/notification/NotificationResourceTypeEnum';
import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import { IStructureMemberRepository } from '../../../domain/structureMember/StructureMember.repository';
import {
  TranslationOption,
  TranslationType,
} from '../../../domain/translation/Translation.repository';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { routesConfig } from '../../Configs/Routes.config';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';

interface NotificationInvitationStructureContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
  isResponse?: boolean;
}

export const getOnClickResponseStructureInvitation = (
  structureMemberRepository: IStructureMemberRepository,
  userInvitedId: number,
  structureId: number,
  t: (key: TranslationType, option?: TranslationOption) => string,
  response: boolean,
  reFetchMessage: () => void,
  setLoading: (loading: boolean) => void,
) => {
  return () => {
    setLoading(true);
    structureMemberRepository
      .responseMemberInvitation(response, userInvitedId, structureId)
      .then(() => {
        reFetchMessage();
        sendEventToastMessage(
          t('notification.content.invitationStructure.response.refused'),
          'success',
        );
      })
      .finally(() => {
        reFetchMessage();
        setLoading(false);
      });
  };
};

export const getOnClickSeeStructure = (
  navigate: NavigateFunction,
  idStructure: string,
) => {
  return () => {
    navigate(routesConfig.otherStructureProfil.to(idStructure));
  };
};

export const NotificationInvitationStructureContent = ({
  notification,
  reFetchNotification,
  isResponse,
}: NotificationInvitationStructureContentProps) => {
  const t = useTranslate();
  const { structureMemberRepository, authRepository } = useContextDependency();
  const [loading, setLoading] = useState(false);
  const [member, , fetching] = useRetrieveFromDomain(
    () =>
      structureMemberRepository.getMember(
        parseInt(notification.resources[NotificationResourceTypeEnum.STRUCTURE_ID]),
        notification.userTo.id,
      ),
    undefined,
    !!notification.resources[NotificationResourceTypeEnum.STRUCTURE_ID],
  );

  const navigate = useNavigate();

  return (
    <div>
      <Divider className={'-topMarged'} />
      {!fetching && !member && (
        <span>{t('notification.content.invitationStructure.alreadyRefused')}</span>
      )}
      {!fetching && member?.state === StructureMemberState.ACCEPTED && (
        <span>{t('notification.content.invitationStructure.alreadyAccepted')}</span>
      )}
      {!fetching && member?.state !== StructureMemberState.ACCEPTED && (
        <ActionButtonsForContent
          loading={loading}
          onClickExitTo={getOnClickSeeStructure(
            navigate,
            notification.resources[NotificationResourceTypeEnum.STRUCTURE_ID],
          )}
          onClickCancel={getOnClickResponseStructureInvitation(
            structureMemberRepository,
            authRepository?.currentUser?.id as number,
            parseInt(notification.resources[NotificationResourceTypeEnum.STRUCTURE_ID]),
            t,
            false,
            reFetchNotification,
            setLoading,
          )}
          onClickValid={getOnClickResponseStructureInvitation(
            structureMemberRepository,
            authRepository?.currentUser?.id as number,
            parseInt(notification.resources[NotificationResourceTypeEnum.STRUCTURE_ID]),
            t,
            true,
            reFetchNotification,
            setLoading,
          )}
        />
      )}
    </div>
  );
};
