import '../ParametersPage.scss';

import classNames from 'classnames';
import { getNeonBorderClassName } from 'domain/profil/Profil.func';
import { WrapperOtherProfilPhotoOverview } from 'primary/Components/User/WrapperOtherProfilPhotoOverview';
import { routesConfig } from 'primary/Configs/Routes.config';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from 'store';

import { LoadingScreen } from '../../../Components/LoadingScreen/LoadingScreen';
import { MenusParameters } from '../../../Components/MenuParameters/MenusParameters';
import { menusParametersUser } from '../../../Components/MenuParameters/MenusParametersUser.config';
import { useTranslate } from '../../../hooks/useTranslate';

export const UserParametersPage: FC = () => {
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);
  const t = useTranslate();
  const neonBorderClassName = getNeonBorderClassName(user?.userProfil);
  const navigate = useNavigate();
  const menu = useMemo(() => menusParametersUser(user), [user]);
  console.log('(user)', user);
  return (
    <div className={classNames('page-parameters', '-user')}>
      {!isAccountLoaded && (
        <LoadingScreen message={t('user.parameters.getUserMsgLoading')} loading={!user} />
      )}
      <div className={'-parametersProfil'}></div>
      {user && (
        <>
          <MenusParameters
            confs={menu}
            header={
              <div
                className={'-paramHeader'}
                onClick={() =>
                  navigate(
                    routesConfig.mercatoUserProfilDetails.userProfilDetails.to(
                      user.userProfil.id,
                    ),
                  )
                }
              >
                <WrapperOtherProfilPhotoOverview
                  size={'small'}
                  displayName={user.userProfil.idPlayer}
                  photo={user.userProfil.publicPhotoUrl}
                  className={classNames('photo', '-photo', neonBorderClassName)}
                  idUser={user.id as number}
                />
                <span>{t('general.menuPlayerParamTitle')}</span>
                <span className={'-accent'}>{user.userProfil.idPlayer}</span>
              </div>
            }
          />
          <div className={'parameters__content'}>
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};
