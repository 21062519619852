import './EventListPage.scss';

import { Button } from '@mui/material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { UnpyTabs } from 'primary/Components/Tabs/UnpyTabs';
import { EVENT_OPEN_CREATE_UNPY_EVENT } from 'primary/Components/User/LoggedInUserAvatarMenu';
import { EventsSlider } from 'primary/events/components/EventsSlider';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import React, { FC, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LayoutPageListFitlered } from '../Components/Layout/Pages/LayoutPageListFiltered';
import { Section } from '../Components/Section/Section';
import { useTranslate } from '../hooks/useTranslate';
import { EventList } from './list/EventList';

export const SliderEventsCashprize = () => {
  const { eventRepository } = useContextDependency();
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<UnpyEventView, Partial<UnpyEventFilters>>({
    keyStoreFilters: 'event-home-open',
    staticFilters: {
      status: EventStatusEnum.PUBLISH,
      endSubscribeDate: formatDate(new Date(), 'back(datetime) UTC'),
      placeAvailable: 'true',
    },
    filters: {},
    callApi: (filters, page, nbPerPage) => eventRepository.searchHome('CASHPRIZE', page),
  });

  return (
    <div
      style={{
        width: '100%',
        margin: '16px 32px',
      }}
    >
      <div className={'sliderEventTitle'}>
        <h2
          style={{
            margin: '8px 0',
          }}
        >
          Remporte un cashprize !
          <br />
          <span style={{ fontSize: '80%' }}>
            Participe à ces tournois pour avoir une chance de remporter un prix.
          </span>
        </h2>
      </div>
      <EventsSlider
        type={'CASHPRIZE'}
        noContentMessage={'Aucun évenement avec cashprize'}
        events={fetchedResource?.content}
      />
    </div>
  );
};

export const SliderEventsFreeRegister = () => {
  const { eventRepository } = useContextDependency();
  const { fetchedResource } = useFetchWithFilterPage<
    UnpyEventView,
    Partial<UnpyEventFilters>
  >({
    keyStoreFilters: 'event-home-open',
    staticFilters: {
      status: EventStatusEnum.PUBLISH,
      placeAvailable: 'true',
    },
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      eventRepository.searchHome('FREE_REGISTER', page, undefined),
  });

  return (
    <div
      style={{
        width: '100%',
        margin: '16px 32px',
      }}
    >
      <div className={'sliderEventTitle'}>
        <h2>
          Inscriptions gratuites ! <br />
          <span style={{ fontSize: '80%' }}>
            Aucun frais d'inscription sur ces événements.
          </span>
        </h2>
      </div>
      <EventsSlider
        type={'FREE_REGISTER'}
        noContentMessage={'Aucun événement avec cashprize'}
        events={fetchedResource?.content}
      />
    </div>
  );
};

export const EventListPage: FC = () => {
  const t = useTranslate();
  const { selectedGamesCode, handleSelection } = useSelectionGameListPage('event');
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const gameToFilter = searchParams?.get('game');
  const refGameSetted = useRef(false);
  const refPortal = useRef<HTMLDivElement>();
  const { authRepository } = useContextDependency();
  useEffect(() => {
    if (gameToFilter && !selectedGamesCode.find((game) => game === gameToFilter)) {
      console.log('HANDLE SELECTION');
      handleSelection(gameToFilter, 'ADD');
      refGameSetted.current = true;
      setUrlSearchParams({});
    }
  }, [gameToFilter, selectedGamesCode]);

  const ref = useRef<HTMLDivElement | null>(null);
  const goToRef = () =>
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  return (
    <div className={'event-list-page'}>
      <div className={'-diagonal-effect'}></div>
      <h1 className={'-absoluteTitle'}>{t('event.eventsListPage.titleEvents')}</h1>
      <div className={'explicationBlock'}>
        <div className={'explicationBlock__title'}></div>
        <div className={'explicationBlock__explanation'}>
          <UnpyTabs
            center
            tabsConf={[
              {
                label: "Organisateur d'événements",
                component: (
                  <div className={'explanation-content'}>
                    <h2>Organisez vos propres événements !</h2>
                    <p>
                      Gérez entièrement vos événements, de la publication à la
                      distribution du cashprize.
                    </p>
                    <ul>
                      <li>Gestion des inscriptions et des équipes</li>
                      <li>Génération des matchs et placement aléatoires</li>
                      <li>Gestion des scores avec classement automatique</li>
                      <li>
                        Appliquez des frais d'inscription si vous le souhaitez et
                        encaissez après avoir complété le formulaire de conformité
                      </li>
                    </ul>
                    <div className={'containerButton'}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          if (authRepository?.currentUser) {
                            const event = new CustomEvent(EVENT_OPEN_CREATE_UNPY_EVENT);
                            window.document.dispatchEvent(event);
                          } else {
                            authRepository.login(
                              window.location.origin +
                                window.location.pathname +
                                '?createEvent=true',
                            );
                          }
                        }}
                      >
                        Créer un événement
                      </Button>
                    </div>
                  </div>
                ),
              },
              {
                label: 'Joueurs',
                component: (
                  <div className={'explanation-content'}>
                    <h2>Participez à des événements sur vos jeux favoris</h2>
                    <p>
                      Faites grandir votre réseau, progressez, et gagnez des prix en
                      participant à des événements gratuits ou payant, avec ou sans
                      cashprize.
                    </p>
                    <ul>
                      <li>
                        Gérez vos propres équipes (Gestion des joueurs, gestion des rôles)
                      </li>
                      <li>
                        Gagnez des cashprize et retirez les aprés avoir remplis le
                        formulaire de conformité.
                      </li>
                    </ul>
                    <div className={'containerButton'}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => goToRef()}
                      >
                        Trouver un événement
                      </Button>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <Section className={'event-list-page__section'}>
        <LayoutPageListFitlered
          listContent={
            <div className={'event-list-page__listEvents'} ref={ref}>
              <EventList
                key={'finished'}
                title={t('event.eventsListPage.titleEventListFinished')}
                gamesSelected={selectedGamesCode}
                typeSearch={'PUBLIC'}
              />
            </div>
          }
        />
      </Section>
    </div>
  );
};
