import { Divider } from '@mui/material';
import { IEventParticipantRepository } from 'domain/event/participants/EventParticipant.repository';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import React from 'react';

import { NotificationResourceTypeEnum } from '../../../domain/notification/NotificationResourceTypeEnum';
import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import {
  TranslationOption,
  TranslationType,
} from '../../../domain/translation/Translation.repository';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';

interface NotificationParticipationEventContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
}

export const getOnClickResponseEventParticipation = (
  eventParticipantRepository: IEventParticipantRepository,
  t: (key: TranslationType, option?: TranslationOption) => string,
  response: boolean,
  reFetchNotification: () => void,
  teamId: number,
  eventId: number,
  setLoading: (loading: boolean) => void,
) => {
  return () => {
    setLoading(true);
    eventParticipantRepository
      .responseParticipationEvent(response, teamId, eventId)
      .then(() => {
        reFetchNotification();
        setLoading(false);
      });
  };
};

export const NotificationParticipationEventContent = ({
  notification,
  reFetchNotification,
}: NotificationParticipationEventContentProps) => {
  const t = useTranslate();
  const { eventParticipantRepository } = useContextDependency();
  const [loading, setLoading] = React.useState(false);
  const [eventParticipant, , fetching] = useRetrieveFromDomain(
    () =>
      eventParticipantRepository.getEventParticipantById(
        parseInt(
          notification.resources[NotificationResourceTypeEnum.EVENT_PARTICIPANT_ID],
        ),
      ),
    undefined,
  );
  if (!eventParticipant) return null;

  return (
    <div>
      <Divider className={'-topMarged'} />
      {eventParticipant.status === EventParticipantStatusEnum.REJECTED && (
        <span>{t('notification.content.joinEvent.alreadyRefused')}</span>
      )}
      {eventParticipant.status === EventParticipantStatusEnum.REGISTERED && (
        <span>{t('notification.content.joinEvent.alreadyAccepted')}</span>
      )}
      {eventParticipant &&
        eventParticipant.status === EventParticipantStatusEnum?.WAIT_VALIDATE && (
          <ActionButtonsForContent
            loading={loading}
            onClickCancel={getOnClickResponseEventParticipation(
              eventParticipantRepository,
              t,
              false,
              reFetchNotification,
              eventParticipant.id,
              parseInt(notification.resources[NotificationResourceTypeEnum.EVENT_ID]),
              setLoading,
            )}
            onClickValid={getOnClickResponseEventParticipation(
              eventParticipantRepository,
              t,
              true,
              reFetchNotification,
              eventParticipant.id,
              parseInt(notification.resources[NotificationResourceTypeEnum.EVENT_ID]),
              setLoading,
            )}
          />
        )}
      {!fetching && !eventParticipant && (
        <span>{t('notification.content.joinEvent.alreadyAccepted')}</span>
      )}
    </div>
  );
};
