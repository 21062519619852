import './MyActivitiesPage.scss';

import { AddCircle } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import { UnpyMatch, UnpyMatchFilters } from 'domain/event/match/UnpyMatch';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { ButtonInvitationsEvent } from 'primary/activities/Buttons/ButtonInvitationsEvent';
import { ButtonInvitationsEventParticipations } from 'primary/activities/Buttons/ButtonInvitationsEventParticipation';
import { ButtonInvitationsFriend } from 'primary/activities/Buttons/ButtonInvitationsFriend';
import { ButtonInvitationsStructures } from 'primary/activities/Buttons/ButtonInvitationsStructures';
import { ButtonInvitationsTeams } from 'primary/activities/Buttons/ButtonInvitationsTeams';
import { MyEvents } from 'primary/activities/MyEvents';
import { MyMatchs } from 'primary/activities/MyMatchs';
import { MyScores } from 'primary/activities/MyScores';
import { EventCardSkelleton } from 'primary/Components/Event/EventCard';
import { ActivitiesEventParticipantCard } from 'primary/Components/Event/EventParticipantCard';
import { ActivitiesMatchLastScore } from 'primary/Components/Event/MatchLastScoreCard';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import React, { FC } from 'react';

import { PageLayout } from '../../Components/PageLayout/PageLayout';

const ComponentDetailsByType = {
  event: MyEvents,
  matchs: MyMatchs,
  score: MyScores,
};

export const MyActivities: FC = () => {
  const { authRepository } = useContextDependency();

  if (!authRepository.currentUser) return null;

  return (
    <div className={'myActivitiesPage'}>
      <PageLayout>
        <div className={'-title'}>
          <Title
            title={'Mes ativités'}
            level={1}
            compensatePadding
            threeQuarter
            bordered
          />
        </div>
        <div className={'-content'}>
          <div className={'-feedsContainer'}>
            <div className={'-feed'}>
              <Title
                title={'Prochains matchs à jouer'}
                level={3}
                compensatePadding
                accent
              />
              <FeedNextMatchsToPlay />
            </div>
            <Divider className={'-divider'} />
            <div className={'-feed'}>
              <Title title={'Derniers résultats'} level={3} compensatePadding accent />
              <FeedLastScoreEdited />
            </div>
          </div>
          <div className={'-socialsContainer'}>
            <ButtonInvitationsStructures />
            <ButtonInvitationsTeams />
            <ButtonInvitationsFriend />
            <ButtonInvitationsEventParticipations />
            <ButtonInvitationsEvent />
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export type FeedsProps = {
  setTypeOfDetails: (type: 'event' | 'matchs' | 'score') => void;
};
// export const FeetActivitiesHandlingEvent = ({ setTypeOfDetails }: FeedsProps) => {
//   const { eventRepository } = useContextDependency();
//
//   const {
//     fetchedResource,
//     goToPage,
//     loading,
//     activeFilters,
//     changeNbPerPage,
//     setFilters,
//   } = useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
//     keyStoreFilters: 'event',
//     filters: {},
//     callApi: (filters) => eventRepository.searchOwn(filters, 0, 5),
//   });
//   const events = fetchedResource?.content;
//   const fetching = loading;
//   return (
//     <>
//       <div className={'-feedsRow -feedHandlingEvent --scrollBarContent'}>
//         {!events &&
//           fetching &&
//           Array(5)
//             .fill(0)
//             .map((val, index) => (
//               <EventCardSkelleton className={'-feedHandlingEvent__item'} key={index} />
//             ))}
//         {!events?.length && !fetching && (
//           <span className={'-noContent'}>Aucun Événement géré en cours</span>
//         )}
//         {events &&
//           events.length &&
//           events.map((event) => (
//             <EventCard
//               key={'announcement' + event.id}
//               // @ts-ignore
//               event={event}
//               className={'-feedsRow--item'}
//             />
//           ))}
//       </div>
//       <div className={'-seeMoreContainer'}>
//         <Button
//           color={'primary'}
//           style={{
//             justifySelf: 'flex-end',
//             display: 'flex',
//             flexDirection: 'column',
//           }}
//           variant={'text'}
//           onClick={() => useFetchReturn.nextPage()}
//           disabled={!((useFetchReturn.fetchedResource?.totalElementsCount ?? 0) > 5)}
//         >
//           <AddCircle style={{ marginRight: '8px' }} />
//           Voir plus
//         </Button>
//       </div>
//     </>
//   );
// };

export const FeedNextMatchsToPlay = () => {
  const { authRepository, eventParticipantRepository } = useContextDependency();
  const staticFilters: EventParticipantFilters = {
    orderByNextMatch: true,
    hasScoreNull: true,
    idProfil: authRepository.currentUser?.userProfil.id,
  };
  const useFetchReturn = useFetchWithFilterPage<
    EventParticipant,
    EventParticipantFilters
  >({
    keyStoreFilters: 'next-match-feed',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => eventParticipantRepository.search(filters, 0, 5),
  });

  return (
    <div className={'-feedRowContainer'}>
      <div className={'-feedsRow -feedHandlingEvent --scrollBarContent'}>
        {!useFetchReturn.fetchedResource?.content?.length && useFetchReturn.loading && (
          <LoadingScreen loading />
        )}
        {!useFetchReturn.fetchedResource?.content?.length && !useFetchReturn.loading && (
          <span className={'-noContent'}>Aucun matchs à venir</span>
        )}
        {useFetchReturn.fetchedResource?.content.map((eventParticipant) => (
          <>
            <ActivitiesEventParticipantCard
              key={eventParticipant.id}
              // @ts-ignore
              eventParticipant={eventParticipant}
              className={'-feedsRow--item'}
            />
          </>
        ))}
        <div className={'-seeMoreContainer'}>
          <Button
            color={'primary'}
            style={{
              justifySelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
            }}
            variant={'text'}
            onClick={() => useFetchReturn.nextPage()}
            disabled={!((useFetchReturn.fetchedResource?.totalElementsCount ?? 0) > 5)}
          >
            <AddCircle style={{ marginRight: '8px', color: 'white' }} />
            Voir plus
          </Button>
        </div>
      </div>
    </div>
  );
};

export const FeedLastScoreEdited = () => {
  const { matchsRepository, authRepository } = useContextDependency();
  const staticFilters: UnpyMatchFilters = {
    lastModifiedBeforeTodayForParticipant: authRepository.currentUser?.userProfil.id,
  };
  const useFetchReturn = useFetchWithFilterPage<UnpyMatch, UnpyMatchFilters>({
    keyStoreFilters: 'last-result-feed',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => matchsRepository.search(filters, 0, 5),
  });

  return (
    <div className={'-feedRowContainer'}>
      <div className={'-feedsRow -feedHandlingEvent --scrollBarContent'}>
        {!useFetchReturn.fetchedResource?.content?.length &&
          useFetchReturn.loading &&
          Array(5)
            .fill(0)
            .map((val, index) => (
              <EventCardSkelleton className={'-feedHandlingEvent__item'} key={index} />
            ))}
        {!useFetchReturn.fetchedResource?.content?.length && !useFetchReturn.loading && (
          <span className={'-noContent'}>Aucun matchs à venir</span>
        )}
        {useFetchReturn.fetchedResource?.content.map((match) => (
          <ActivitiesMatchLastScore
            key={match.id}
            // @ts-ignore
            match={match}
            className={'-feedsRow--item'}
          />
        ))}
        <div className={'-seeMoreContainer'}>
          <Button
            color={'primary'}
            style={{
              justifySelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
            }}
            variant={'text'}
            onClick={() => useFetchReturn.nextPage()}
            disabled={!((useFetchReturn.fetchedResource?.content?.length ?? 0) > 5)}
          >
            <AddCircle style={{ marginRight: '8px', color: 'white' }} />
            Voir plus
          </Button>
        </div>
      </div>
    </div>
  );
};
