import './ClassementParticipantViewDatatable.scss';

import { Hidden, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  ClassementFilters,
  ClassementParticipant,
} from 'domain/classement/ClassementParticipant';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { PodiumView } from 'primary/events/components/PodiumView';
import {
  COLUMNS_CLASSEMENT_LIGUE,
  COLUMNS_CLASSEMENT_LIGUE_BR,
} from 'primary/events/datatable/ClassementParticipantColumnsConf';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import React from 'react';

import { useTranslate } from '../../hooks/useTranslate';

interface ClassementParticipantDatatableParametersProps {
  event: UnpyEvent | UnpyEventView;
}

const formatParticipantToRowData = (
  classementParticipant: ClassementParticipant[],
  currentPage: number,
  pageSize: number,
) => {
  return classementParticipant.map((part, index) => ({
    id: part.id,
    position: `# ${currentPage * pageSize + (index + 1)}`,
    name: part?.participant?.team.name,
    score: part.score,
    vnd: part,
    results: part,
    top1: part.top1 ?? '-',
    top3: part.top3 ?? '-',
    topHalf: part.topHalf ?? '-',
    action: part,
  }));
};

export const ClassementParticipantDatataleParameters = ({
  event,
}: ClassementParticipantDatatableParametersProps) => {
  const { classementRepository } = useContextDependency();
  const t = useTranslate();
  const staticFilters = {
    eventId: event?.id,
  };
  const useFetchReturn = useFetchWithFilterPage<ClassementParticipant, ClassementFilters>(
    {
      keyStoreFilters: 'event-classement-param-list',
      staticFilters: staticFilters,
      filters: {},
      callApi: (filters, page, nbPerPage) =>
        classementRepository.search(filters, page, nbPerPage),
    },
  );
  const columns =
    event.typeEvent.code === TypeEventCodeEnum.LIG_BR
      ? COLUMNS_CLASSEMENT_LIGUE_BR(t)
      : COLUMNS_CLASSEMENT_LIGUE(t);

  return (
    <div className={'classementParticipantsParameters'}>
      <Hidden smDown>
        <div className={'-podiumContainer'}>
          <PodiumView event={event} />
        </div>
      </Hidden>
      <div className={'-datatableContainer'}>
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableEval
          disableVirtualization
          pagination
          paginationMode={'server'}
          rowCount={useFetchReturn.fetchedResource?.totalElementsCount}
          paginationModel={{
            pageSize: useFetchReturn.fetchedResource?.pageSize || 0,
            page: useFetchReturn.fetchedResource?.currentPage || 0,
          }}
          onPaginationModelChange={({ pageSize, page }) => {
            if (pageSize !== useFetchReturn.fetchedResource?.pageSize) {
              useFetchReturn.changeNbPerPage(pageSize);
            }
            useFetchReturn.goToPage(page);
          }}
          loading={useFetchReturn.loading}
          className={'-datatableClassement'}
          autoHeight
          sx={{ color: 'white' }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t('event.teams.datatable.noContentLabel')}
              </Stack>
            ),
          }}
          rows={
            useFetchReturn?.fetchedResource?.content
              ? formatParticipantToRowData(
                  useFetchReturn.fetchedResource?.content,
                  useFetchReturn.fetchedResource.currentPage,
                  useFetchReturn.fetchedResource.pageSize,
                )
              : []
          }
          columns={columns}
        />
      </div>
    </div>
  );
};
