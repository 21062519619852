import '../../Utils/Forms/UnpyForm.scss';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Grid, Stack, Switch, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { canEditField, getInitialValueParameterize } from 'domain/event/UnpyEvent.func';
import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';
import { formatDate } from 'helper/date.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { EventPublishFormData } from 'primary/events/forms/EventPublishForm';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useFetch } from 'primary/hooks/useFetch';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import { SellerAccountEnabledWarn } from 'primary/Parameters/user/portefeuille/SellerAccountEnabledWarn';
import React, { FC } from 'react';
import { useController, useForm, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { GAME_ACTIVE_LIST } from '../../../domain/game/GameActiveList';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
  Option,
} from '../../Components/Input/InputWrapper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventParameterizeFormData = {
  startDateTime: string;
  endSubscribeDate: string;
  freeRegister: boolean;
  singleStructureTeam: boolean;
  platforms?: string[];
  game: string;
  registerPrice?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    startDateTime: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required(t('general.forms.errors.required')),
    endSubscribeDate: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required(t('general.forms.errors.required')),
    freeRegister: yup.boolean().nullable(),
    singleStructureTeam: yup.boolean().nullable(),
    platforms: yup.array().nullable(),
    game: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    t: UseTranslateReturn,
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    idEvent: number,
  ) =>
  (data: EventParameterizeFormData) => {
    eventRepostory.updateEventParameterize(data, idEvent).then((event) => {
      sendEventToastMessage(t('general.edit.success'), 'success');
      onSubmitted(event.id);
    });
  };

interface EventCreateFormProps {
  onCreated: (id: number) => void;
  event: UnpyEvent;
}

const _changeHandlerPlatforms =
  (setValue: UseFormSetValue<EventPublishFormData>) => (event: ChangeHandlerTypeMeta) => {
    // @ts-ignore
    setValue('platforms', event.target.value as Option<string, object>[]);
  };

export const EventParameterizeForm: FC<EventCreateFormProps> = ({ onCreated, event }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const t = useTranslate();
  const initialValues = getInitialValueParameterize(t, event);
  const {
    handleSubmit,
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<EventParameterizeFormData>({
    resolver,
    //@ts-ignore
    defaultValues: initialValues,
    mode: 'all',
  });
  const { eventRepository, authRepository } = useContextDependency();

  const onSubmit = useOnSubmit(t, eventRepository, onCreated, event.id);

  const { platformRepository } = useContextDependency();

  const [platforms] = useFetch(() => platformRepository.getAll(), undefined);

  const { field: gameField } = useController({ control: control, name: 'game' });

  const { field: platformsField } = useController({
    control: control,
    name: 'platforms',
  });

  const [
    isOpenModalConfirmEditStartDate,
    ,
    openModalConfirmEditStartDate,
    closeModalConfirmEditStartDate,
  ] = useBooleanToggle();
  const [
    isOpenModalAdvertissementRegisterPrice,
    ,
    openModalAdvertissementRegisterPrice,
    closeModalAdvertissementRegisterPrice,
  ] = useBooleanToggle();
  const newStartDateTime = watch('startDateTime');
  const registerPrice = watch('registerPrice');
  const ref = React.createRef<HTMLButtonElement>();

  const isStartDateTimeDited =
    newStartDateTime !== initialValues.startDateTime &&
    event.status !== EventStatusEnum.DRAFT;

  const eventPriceCreating = initialValues.registerPrice
    ? registerPrice?.toString() !== initialValues.registerPrice?.toString()
    : false;

  const submitBtn = () => {
    if (isStartDateTimeDited) {
      openModalConfirmEditStartDate();
    } else if (eventPriceCreating) {
      openModalAdvertissementRegisterPrice();
    } else {
      ref?.current?.click();
    }
  };

  return (
    <Box className={'unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.game')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.game'),
                ...gameField,
                options: Object.values(GAME_ACTIVE_LIST).map((game) => ({
                  label: t(`games.libelle.${game}`),
                  value: game,
                })),
                disabled: !canEditField('game', event),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('parameters.menus.profil.user.form.platformLabel')}
              errors={errors}
              inputClassName={'input-user-details '}
              labelClassName={'label-user-details '}
              Input={InputSelectMui}
              isSubmitting={isSubmitting}
              //@ts-ignore
              inputProps={{
                placeholder: t('parameters.menus.profil.user.form.platformPlaceholder'),
                ...platformsField,
                options: platforms?.map((platform) => ({
                  label: platform.libelle,
                  value: platform.code.toString(),
                })),
                // @ts-ignore
                onChange: _changeHandlerPlatforms(setValue),
                multiple: true,
                disabled: !canEditField('platforms', event),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.endSubscribeDate')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.endSubscribeDate'),
                variant: 'filled',
                ...register('endSubscribeDate', { required: true }),
                disabled: !canEditField('endSubscribeDate', event),
                type: 'datetime-local',
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.startDateTime')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              notice={
                !canEditField('startDateTime', event)
                  ? t('event.form.notice.startDateDisabled')
                  : undefined
              }
              inputProps={{
                placeholder: t('event.form.placeholders.startDateTime'),
                ...register('startDateTime', { required: true }),
                variant: 'filled',
                type: 'datetime-local',
                disabled: !canEditField('startDateTime', event),
              }}
              required
            />
            {event.delayed && (
              <Alert severity="warning" className={'-alertDelayed'}>
                {t('event.form.notice.delayed', {
                  date: formatDate(event.delayed, 'readable(with hour)'),
                })}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.freeRegister')}
              errors={errors}
              className={'unpy-form__field'}
              isSubmitting={isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                ...register('freeRegister'),
                defaultChecked: event.freeRegister,
                disabled: !canEditField('freeRegister', event),
                readOnly: !canEditField('freeRegister', event),
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.singleStructure')}
              errors={errors}
              className={'unpy-form__field'}
              isSubmitting={isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                disabled: !canEditField('singleStructureTeam', event),
                ...register('singleStructureTeam'),
                defaultChecked: event.singleStructureTeam,
              }}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.registerPrice')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              noFullWidth
              inputProps={{
                ...register('registerPrice'),
                type: 'number',
                min: 1,
                disabled:
                  authRepository.currentUser?.sellerAccountComformity !==
                    SellerAccountConformityEnum.COMFORM ||
                  !canEditField('registerPrice', event),
                readOnly:
                  authRepository.currentUser?.sellerAccountComformity !==
                    SellerAccountConformityEnum.COMFORM ||
                  !canEditField('registerPrice', event),
              }}
              required
            />
            <SellerAccountEnabledWarn />
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons -one'}>
          <button hidden type={'submit'} ref={ref} />
          <LoadingButton
            className={'btn'}
            variant={'contained'}
            color={'primary'}
            onClick={submitBtn}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('event.form.editBtn')}
          </LoadingButton>
        </div>
      </form>
      <ModalConfirm
        handleClose={closeModalConfirmEditStartDate}
        title={t('event.modal.confirmEditStartDate.title')}
        action={() => ref?.current?.click()}
        isOpen={isOpenModalConfirmEditStartDate}
        description={
          <Alert
            severity="warning"
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
          >
            {t('event.modal.confirmEditStartDate.notice', {
              date: formatDate(
                new Date(getValues('startDateTime')),
                'readable(with hour)',
              ),
            })}
          </Alert>
        }
      />

      <ModalConfirm
        handleClose={closeModalConfirmEditStartDate}
        title={t('event.modal.confirmRegisterPrice.title')}
        action={() => ref?.current?.click()}
        isOpen={isOpenModalConfirmEditStartDate}
        description={
          <Alert
            severity="warning"
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
          >
            {t('event.modal.confirmRegisterPrice.notice')}
          </Alert>
        }
      />
    </Box>
  );
};

type ActionAlertsProps = {
  message: string;
  actionMessage: string;
  action: () => void;
};

export default function ActionAlerts({
  message,
  action,
  actionMessage,
}: ActionAlertsProps) {
  return (
    <Stack
      sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
      spacing={2}
    >
      <Alert
        severity="warning"
        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
        action={
          <Button color="inherit" size="small" onClick={action}>
            {actionMessage}
          </Button>
        }
      >
        {message}
      </Alert>
    </Stack>
  );
}
