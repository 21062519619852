import './ActionButton.scss';

import { GroupAdd } from '@mui/icons-material';
import { Button } from '@mui/material';
import { setInviteStructureModalOpen } from 'domain/modal/store/ModalSlice';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { isPrivacyRestricted } from 'domain/profil/Profil.func';
import { UserProfil } from 'domain/profil/UserProfil';
import { selectConnectedUserBlocked } from 'domain/relation/store/RelationSlice';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ActionButtonInviteStructureProps {
  profil: UserProfil;
  withIcon?: boolean;
}

export const stopPropagationBeforeAction = (action?: () => void) => (e: any) => {
  e.stopPropagation();
  action?.();
};

export const ActionButtonInviteStructure = ({
  profil,
  withIcon,
}: ActionButtonInviteStructureProps) => {
  const { authRepository } = useContextDependency();
  const t = useTranslate();
  const blocked = useSelector(selectConnectedUserBlocked);
  const alreadyBlocked = blocked?.find((b) => b.invited.id === profil.id);
  const isRestricted =
    !!alreadyBlocked || isPrivacyRestricted(profil, PRIVACY_CODE.INVITE_STRUCTURE);
  const dispatch = useDispatch();
  const openModal = () =>
    dispatch(
      setInviteStructureModalOpen({
        props: { name: profil.idPlayer, userId: profil.userId },
      }),
    );

  return (
    <Button
      color={'inherit'}
      variant={'text'}
      className={'-actionButton'}
      onClick={stopPropagationBeforeAction(openModal)}
      disabled={isRestricted || !authRepository.currentUser}
    >
      {withIcon && <GroupAdd />}
      {t('profil.userProfilAction.inviteStructure')}
    </Button>
  );
};
