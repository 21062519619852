import { AspectRatio, SportsEsports } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { ModalPendingTeamsInvitation } from 'primary/activities/Modals/ModalPendingTeamsInvitation';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ButtonInvitationsTeams = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { eventParticipantRepository } = useContextDependency();
  const [invitationList, , loading, retry] = useRetrieveFromDomain(
    () => eventParticipantRepository.getAllPendingInvitationsForUser(),
    undefined,
  );
  const afterSubmit = () => {
    retry();
    if (invitationList?.length && invitationList.length - 1 === 0) {
      setIsOpen(false);
    }
  };

  const { state } = useLocation();
  const modalAlreadyOpenFromState = useRef(false);
  useEffect(() => {
    if (!modalAlreadyOpenFromState.current && !isOpen && state?.openInviteTeamPending) {
      setIsOpen(true);
      modalAlreadyOpenFromState.current = true;
    }
  }, [modalAlreadyOpenFromState.current, isOpen]);

  return (
    <div className={'-socialItems'}>
      <div className={'-iconWithNumber'}>
        <SportsEsports className={'-socialLogo'} />
        {!invitationList && <CircularProgress size={15} color={'info'} />}
        {invitationList && <span>{invitationList?.length ?? 0}</span>}
      </div>
      <div className={'-titleWithBtn'}>
        <Title title={'Invitations équipe'} level={3} compensatePadding />
        <IconButton
          disabled={invitationList && invitationList.length <= 0}
          onClick={() => setIsOpen(true)}
        >
          <AspectRatio fontSize={'small'} />
        </IconButton>
      </div>
      {!invitationList && <CircularProgress size={15} color={'info'} />}
      {invitationList && (
        <ModalPendingTeamsInvitation
          isOpen={isOpen}
          invitationList={invitationList}
          afterSubmit={afterSubmit}
          handleClose={() => setIsOpen(false)}
          loading={loading}
        />
      )}
    </div>
  );
};
