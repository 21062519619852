import 'primary/activities/Modals/PendingEventInvitation.scss';

import { Cancel } from '@mui/icons-material';
import { Box, Divider, IconButton, Pagination } from '@mui/material';
import { Invitation } from 'domain/invitation/Invitation';
import { Pageable } from 'domain/pageable/Pageable';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ModalPendingEventInvitationProps = {
  isOpen?: boolean;
  handleClose: () => void;
  goToPage: (page: number) => void;
  fetchedResource: Pageable<Invitation>;
  afterSubmit: () => void;
  loading?: boolean;
};

export const ModalPendingEventInvitation = ({
  isOpen,
  handleClose,
  afterSubmit,
  fetchedResource,
  goToPage,
  loading,
}: ModalPendingEventInvitationProps) => {
  const t = useTranslate();
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={!!isOpen}
      content={
        <Box className={'modal-pending-event-invitation'}>
          <div className={'-header'}>
            <h2>{t('modals.pendingEventInvitation.title')}</h2>
            <IconButton onClick={() => handleClose()}>
              <Cancel />
            </IconButton>
          </div>
          <ListPendingEventInvitation
            invitationList={fetchedResource.content}
            afterSubmit={afterSubmit}
            loadingFromButton={loading}
          />
          <Pagination
            key={fetchedResource?.currentPage}
            count={fetchedResource?.pagesCount || 1}
            page={fetchedResource?.currentPage ? fetchedResource?.currentPage + 1 : 1}
            size={'medium'}
            onChange={(e, value) => goToPage(value - 1)}
          />
        </Box>
      }
    />
  );
};

type ListPendingEventInvitationProps = {
  invitationList: Invitation[];
  afterSubmit: () => void;
  loadingFromButton?: boolean;
};

const ListPendingEventInvitation = ({
  invitationList,
  afterSubmit,
  loadingFromButton,
}: ListPendingEventInvitationProps) => {
  const { eventRepository, invitationRepository } = useContextDependency();
  const navigate = useNavigate();
  const [loadingCancel, setLoadingCancel] = useState(false);
  const handleCancelInvitation = (id: number) => {
    setLoadingCancel(true);
    invitationRepository
      .responseInvitation(id, false)
      .then(() => {
        afterSubmit();
      })
      .finally(() => {
        setLoadingCancel(false);
      });
  };
  const t = useTranslate();
  return (
    <div className={'-listPendingEventInvitation'}>
      {invitationList?.map((invitation) => {
        const [event, , , retry] = useRetrieveFromDomain(
          () => eventRepository.getEventById(invitation.idResource as number),
          undefined,
          !!invitation?.idResource,
        );
        if (!event) return null;
        return (
          <div key={invitation?.id} className={'-pendingEventInvitation'}>
            <div className={'-infos'}>
              <TagPlayer player={invitation?.sender?.userProfil} embed />
              <div className={'-teamInfos'}>
                <KeyValue
                  className={'-margedRight'}
                  accent
                  keyValue={'Event :'}
                  value={event?.name}
                />
                <KeyValue
                  alignStart
                  keyValue={t('event.eventViewPage.header.textInfos.typeEvent')}
                  value={t(`enum.typeEventCode.${event?.typeEvent?.code}`)}
                  className={'-keyValue'}
                  accent
                />
              </div>
            </div>
            <Divider />
            <div className={'-footerActions'}>
              <ActionButtonsForContent
                loading={loadingCancel}
                onClickCancel={() => handleCancelInvitation(invitation.id)}
                onClickValid={() =>
                  navigate(
                    routesConfig.eventViewDetails.eventViewPlayersRegisterOpen.to(
                      invitation?.idResource,
                    ),
                  )
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
