import './EventFilters.scss';

import { FilterAltOff } from '@mui/icons-material';
import { Button, Hidden } from '@mui/material';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { selectFiltersByDomain } from 'domain/filters/FiltersSlice';
import { FilterDisplayFinished } from 'primary/Components/Filters/Events/FilterDisplayFinished';
import { FilterPlaceAvailable } from 'primary/Components/Filters/Events/FilterPlaceAvailable';
import { FilterTypeEvent } from 'primary/Components/Filters/Events/FilterTypeEvent';
import { FilterTypeTeam } from 'primary/Components/Filters/Events/FilterTypeTeam';
import { ModalFitlers } from 'primary/Components/Filters/ModalFilters';
import { TypeSearchEvent } from 'primary/events/list/EventList';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { FilterPlatform } from '../FilterPlatform';

interface EventsFiltersProps {
  setFilters: (filters: UnpyEventFilters) => void;
  typeSearch: TypeSearchEvent;
  resetFilters?: () => void;
}

export const EventsFilters = ({
  setFilters,
  typeSearch,
  resetFilters,
}: EventsFiltersProps) => {
  return (
    <>
      <Hidden smUp>
        <EventsFiltersMobile
          setFilters={setFilters}
          typeSearch={typeSearch}
          resetFilters={resetFilters}
        />
      </Hidden>

      <Hidden smDown>
        <EventsFiltersComp
          setFilters={setFilters}
          typeSearch={typeSearch}
          resetFilters={resetFilters}
        />
      </Hidden>
    </>
  );
};

const EventsFiltersMobile = ({
  setFilters,
  typeSearch,
  resetFilters,
}: EventsFiltersProps) => {
  const t = useTranslate();
  return (
    <ModalFitlers
      // @ts-ignore
      resetFilters={resetFilters}
      filtersComp={
        <EventsFiltersComp
          setFilters={setFilters}
          typeSearch={typeSearch}
          resetFilters={resetFilters}
        />
      }
    />
  );
};

const EventsFiltersComp = ({
  setFilters,
  typeSearch,
  resetFilters,
}: EventsFiltersProps) => {
  const t = useTranslate();
  const [key, setKey] = useState(Math.random());
  // @ts-ignore
  const activeFiltersStore: UnpyEventFilters = useSelector((state: RootState) =>
    selectFiltersByDomain(state, 'event'),
  );
  const onChangePlatform = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      platforms: value ? [value] : undefined,
    });
  };

  const onChangeTypeEvent = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      typeEvent: value,
    });
  };

  const onChangeTypeTeam = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      typeTeam: value,
    });
  };

  const onChangePlaceAvailable = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      placeAvailable: value,
    });
  };

  const onChangeDisplayFinished = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      displayFinished: value,
    });
  };

  useEffect(() => {
    setKey(Math.random());
  }, [activeFiltersStore]);

  return (
    <div className={'eventFilters'}>
      <div className={'-filtersInputs'}>
        <div>
          <FilterPlatform
            selectedPlatform={
              activeFiltersStore.platforms ? activeFiltersStore.platforms[0] : undefined
            }
            onSelectPlatform={onChangePlatform}
          />
        </div>
        <div>
          <FilterPlaceAvailable
            onSelectPlaceAvailable={onChangePlaceAvailable}
            selectedPlaceAvailable={activeFiltersStore.placeAvailable}
          />
        </div>
        <div>
          <FilterDisplayFinished
            onSelectDisplayFinished={onChangeDisplayFinished}
            selectedDisplayFinished={activeFiltersStore.displayFinished}
          />
        </div>
        <div>
          <FilterTypeEvent
            onSelectTypeEvent={onChangeTypeEvent}
            selectedTypeEvent={activeFiltersStore.typeEvent}
          />
        </div>
        <div>
          <FilterTypeTeam
            onSelectTypeTeam={onChangeTypeTeam}
            selectedTypeTeam={activeFiltersStore.typeTeam}
          />
        </div>
      </div>
      <Hidden smDown>
        <Button
          onClick={() =>
            setFilters?.({
              platforms: undefined,
              status: undefined,
              typeEvent: undefined,
              typeTeam: undefined,
              startDateTime: undefined,
            })
          }
          color={'primary'}
        >
          <FilterAltOff width={20} />
        </Button>
      </Hidden>
    </div>
  );
};
