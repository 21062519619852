import { Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Payout, PayoutFiters } from 'domain/payout/Payout';
import { PayoutTypeEnum } from 'domain/payout/PayoutType.enum';
import { Translate } from 'domain/translation/Translation.repository';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

const formatInvoiceToRowData = (payouts: Payout[], t: Translate) => {
  return payouts.map((payout) => ({
    id: payout.id,
    amount: `${payout.amount} €`,
    status: t(`enum.payoutStatus.${payout.status}`),
  }));
};

type PayoutsListProps = {
  type: PayoutTypeEnum;
};

export const PayoutsList = ({ type }: PayoutsListProps) => {
  const t = useTranslate();
  const { payoutRepository } = useContextDependency();
  const {
    fetchedResource: payoutPage,
    goToPage,
    loading,
    activeFilters,
    changeNbPerPage,
    setFilters,
    retry: retryPayoutSearch,
  } = useFetchWithFilterPage<Payout, PayoutFiters>({
    keyStoreFilters: 'event',
    staticFilters: { type: type },
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      payoutRepository.search(filters, page, nbPerPage),
  });
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('payout.datatable.id'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'amount',
      headerName: t('payout.datatable.amount'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('payout.datatable.status'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  return (
    <div className={'payoutsList'}>
      <Title threeQuarter bordered title={t('payout.datatable.title')} level={2} />
      <div className={'payouts__list'}>
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableEval
          disableVirtualization
          pagination
          paginationModel={{
            page: payoutPage?.currentPage || 0,
            pageSize: payoutPage?.pageSize || 10,
          }}
          onPaginationModelChange={(params) => goToPage(params.page)}
          className={'-parameters'}
          autoHeight
          hideFooter
          sx={{ color: 'white' }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t('payout.datatable.noContent')}
              </Stack>
            ),
          }}
          rows={payoutPage ? formatInvoiceToRowData(payoutPage.content, t) : []}
          columns={columns}
        />
      </div>
    </div>
  );
};
