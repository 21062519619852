import { InvitationTypeEnum } from 'domain/invitation/InvitationTypeEnum';
import { UserView } from 'domain/user/UserView';

export interface InvitationFilters {
  asSender?: boolean;
  asReceiver?: boolean;
  type?: string;
  state?: string;
}

export class Invitation {
  constructor(
    public readonly id: number,
    public readonly type: InvitationTypeEnum,
    public readonly state: string,
    public readonly sender: UserView,
    public readonly receiver: UserView,
    public readonly idResource?: number,
  ) {}
}
