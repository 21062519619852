import '../../Utils/Forms/UnpyForm.scss';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, TextField } from '@mui/material';
import { addDays, isBefore, setHours } from 'date-fns';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { formatDate } from 'helper/date.helper';
import { InputRadios } from 'primary/Components/Input/InputRadios';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { HelperEventType } from 'primary/helpers/HelperEventType';
import React, { FC, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { TypeEventCodeEnum } from '../../../domain/event/TypeEventCodeEnum';
import { GAME_ACTIVE_LIST } from '../../../domain/game/GameActiveList';
import { InputWrapper } from '../../Components/Input/InputWrapper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventFormData = {
  startDateTime: string;
  endSubscribeDate: string;
  name: string;
  game: string;
  typeEvent: string;
};

// .when(['startDateTime'], (startDateTime) => {
//   return yup
//     .string()
//     .required(t('general.forms.errors.required'))
//     .test(
//       'minEndSubscribe',
//       "La date de fin d'inscription doit être inférieur à la date de début du tournois",
//       (value) => {
//         if (!startDateTime || !value) return true;
//         else return new Date(startDateTime).getTime() > new Date(value).getTime();
//       },
//     );
// })
const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    startDateTime: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .test(
        'testMinStartDate',
        t('event.form.errors.minStartDateTime'),
        (value, context) =>
          value
            ? !isBefore(new Date(value), new Date(context.parent.endSubscribeDate))
            : false,
      )
      .test(
        'testMinStartDateToday',
        t('event.form.errors.startDateAfterToday'),
        (value) => (value ? !isBefore(new Date(value), setHours(new Date(), 0)) : false),
      )
      .required(t('general.forms.errors.required')),
    endSubscribeDate: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .test(
        'testMinEndSubscribeDate',
        t('event.form.errors.minEndSubscribeDate'),
        (value, context) =>
          value
            ? isBefore(new Date(value), new Date(context.parent.startDateTime))
            : false,
      )
      .test(
        'testMinEndsubscribeDateToday',
        t('event.form.errors.endSubscribeDateAfterToday'),
        (value) => (value ? !isBefore(new Date(value), setHours(new Date(), 0)) : false),
      )
      .required(t('general.forms.errors.required')),
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    game: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    typeEvent: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    handleClose: () => void,
    setIsLoading: (isLoading: boolean) => void,
  ) =>
  (data: EventFormData) => {
    setIsLoading(true);
    eventRepostory
      .create(data)
      .then((event) => onSubmitted(event.id))
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

interface EventCreateFormProps {
  handleClose: () => void;
  onCreated: (id: number) => void;
}

export const EventCreateForm: FC<EventCreateFormProps> = ({ handleClose, onCreated }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    control,
    setError,
    trigger,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<EventFormData>({
    resolver,
    mode: 'onChange',
    defaultValues: {
      startDateTime: formatDate(addDays(new Date(), 4), 'inputDatetime'),
      endSubscribeDate: formatDate(addDays(new Date(), 2), 'inputDatetime'),
    },
  });
  const { eventRepository } = useContextDependency();
  const [isLoading, setIsLoading] = React.useState(false);
  const t = useTranslate();
  const onSubmit = useOnSubmit(eventRepository, onCreated, handleClose, setIsLoading);

  const { field: gameField } = useController({ control: control, name: 'game' });
  const { field: typeEventField } = useController({
    control: control,
    name: 'typeEvent',
  });

  const startDateTime = watch('startDateTime');
  const endSubscribeDate = watch('endSubscribeDate');

  useEffect(() => {
    if (startDateTime) {
      trigger('endSubscribeDate');
    }
  }, [startDateTime]);

  useEffect(() => {
    if (endSubscribeDate) {
      trigger('startDateTime');
    }
  }, [endSubscribeDate]);

  return (
    <Box className={'unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.name')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.name'),
                variant: 'filled',
                disabled: isSubmitting,
                ...register('name', { required: true }),
              }}
              required
            />
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.startDateTime')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.startDateTime'),
                variant: 'filled',
                disabled: isSubmitting,
                ...register('startDateTime', { required: true }),
                type: 'datetime-local',
              }}
              required
            />
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.endSubscribeDate')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.endSubscribeDate'),
                variant: 'filled',
                disabled: isSubmitting,
                ...register('endSubscribeDate', { required: true }),
                type: 'datetime-local',
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.game')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.game'),
                ...gameField,
                options: Object.values(GAME_ACTIVE_LIST).map((game) => ({
                  label: t(`games.libelle.${game}`),
                  value: game,
                })),
              }}
            />
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.typeEvent')}
              errors={errors}
              Input={InputRadios}
              isSubmitting={false}
              required
              helper={<HelperEventType />}
              inputProps={{
                placeholder: t('event.form.placeholders.typeEvent'),
                ...typeEventField,
                options: Object.values(TypeEventCodeEnum).map((typeEvent) => ({
                  label: t(`enum.typeEventCode.${typeEvent}`),
                  value: typeEvent,
                })),
                orientation: 'vertical',
                fullWidth: true,
              }}
            />
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons'}>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={() => handleClose()}
            className={'cancel'}
          >
            <Cancel />
            {t('event.form.cancelBtn')}
          </Button>
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            loading={isLoading}
          >
            {t('event.form.submitBtn')}
          </LoadingButton>
        </div>
      </form>
    </Box>
  );
};
