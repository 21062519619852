import './EventParticipantViewDatatable.scss';

import { Button, Chip, Hidden, Pagination, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import classNames from 'classnames';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { insertIf } from 'helper/array.helper';
import { EventParticipantCardListItem } from 'primary/Components/Event/EventParticipantCardListItem';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { NoContentBloc } from 'primary/Components/NoContentBloc/NoContentBloc';
import { UseFetchWithFilterPageReturn } from 'primary/hooks/useFetchWithFilterPage';
import React from 'react';

import { PlayerTeam } from '../../../domain/event/PlayerTeam';
import { Team } from '../../../domain/team/Team';
import { Translate } from '../../../domain/translation/Translation.repository';
import { TagPlayer } from '../../Components/TagPlayer/TagPlayer';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';

interface EventParticipantDatatableParametersProps {
  asView?: boolean;
  reFetch: () => void;
  eventId: number;
  disabled?: boolean;
  readOnly?: boolean;
  useFetchReturn: UseFetchWithFilterPageReturn<EventParticipant, EventParticipantFilters>;
}

export const getParticipantStatusObject = (
  eventParticipant: EventParticipant,
  t: Translate,
) => {
  return {
    label: t(`enum.eventParticipantStatus.${eventParticipant.status}`),
    state: [
      EventParticipantStatusEnum.WAIT_VALID,
      EventParticipantStatusEnum.WAIT_VALIDATE,
      EventParticipantStatusEnum.PAYMENT_REQUIRED,
    ].includes(eventParticipant.status)
      ? 'warning'
      : EventParticipantStatusEnum.REGISTERED === eventParticipant.status
      ? 'success'
      : 'error',
  };
};

const formatTeamsToRowData = (eventParticipants: EventParticipant[], t: Translate) => {
  return eventParticipants.map((eventParticipant) => ({
    id: eventParticipant.id,
    name: eventParticipant.team.name,
    players: eventParticipant.team.players,
    statut: getParticipantStatusObject(eventParticipant, t),
    action: eventParticipant,
  }));
};

export const EventParticipantDatatable = (
  props: EventParticipantDatatableParametersProps,
) => {
  if (props.useFetchReturn.loading) return <LoadingScreen loading />;
  if (props.asView) {
    return <EventParticipantCardList {...props} asView />;
  }
  return (
    <>
      <Hidden smDown>
        <EventParticipantDatatableDesktop {...props} />
      </Hidden>
      <Hidden smUp>
        <EventParticipantCardList {...props} />
      </Hidden>
    </>
  );
};

export const useEventParticipantsList = (reFetch: () => void, eventId: number) => {
  const { eventParticipantRepository } = useContextDependency();

  const onClickDelete = (teamId: number) => () =>
    eventParticipantRepository
      .deleteEventParticipantForEvent(teamId, eventId)
      .then(() => reFetch());

  const onClickAccept = (teamId: number) => () =>
    eventParticipantRepository
      .responseParticipationEvent(true, teamId, eventId)
      .then(() => reFetch());

  const onClickDecline = (teamId: number) => () =>
    eventParticipantRepository.responseParticipationEvent(false, teamId, eventId);

  return { onClickDelete, onClickAccept, onClickDecline };
};

export const EventParticipantCardList = ({
  reFetch,
  eventId,
  disabled,
  useFetchReturn,
  readOnly,
  asView,
}: EventParticipantDatatableParametersProps) => {
  const { onClickDelete, onClickAccept, onClickDecline } = useEventParticipantsList(
    reFetch,
    eventId,
  );
  const { fetchedResource, goToPage } = useFetchReturn;
  const t = useTranslate();
  return (
    <div className={classNames('eventParticipantCardList', { '-asView': asView })}>
      <div className={'-pagination'}>
        {fetchedResource?.content?.length !== 0 && (
          <Pagination
            key={fetchedResource?.currentPage}
            count={fetchedResource?.pagesCount || 1}
            page={fetchedResource?.currentPage ? fetchedResource?.currentPage + 1 : 1}
            size={'medium'}
            onChange={(e, value) => goToPage(value - 1)}
          />
        )}
      </div>
      <div className={'-cardList'}>
        {fetchedResource?.content?.length !== 0 &&
          fetchedResource?.content?.map((participant) => (
            <EventParticipantCardListItem
              key={participant.id}
              asView={asView}
              participant={participant}
              // @ts-ignore
              actions={
                !readOnly && {
                  onClickDelete,
                  onClickAccept,
                  onClickDecline,
                  disabled: !!readOnly,
                }
              }
            />
          ))}
        {!fetchedResource?.content?.length && (
          <NoContentBloc text={t('general.noContentList.eventParticipants')} />
        )}
      </div>
    </div>
  );
};

export const EventParticipantDatatableDesktop = ({
  reFetch,
  eventId,
  disabled,
  useFetchReturn,
  readOnly,
}: EventParticipantDatatableParametersProps) => {
  const t = useTranslate();
  const { onClickDelete, onClickAccept, onClickDecline } = useEventParticipantsList(
    reFetch,
    eventId,
  );
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('event.teams.datatable.labelName'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'players',
      headerName: t('event.teams.datatable.labelPlayers'),
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      headerClassName: '-header',
      renderCell: (value: GridRenderCellParams<Team>) => {
        if (!value.value) return;
        return (
          <div className={'-playersLink'}>
            {value.value.map((player: PlayerTeam) => (
              <TagPlayer key={player?.player?.idPlayer} player={player.player} />
            ))}
          </div>
        );
      },
    },
    {
      field: 'statut',
      headerName: t('event.teams.datatable.labelStatut'),
      flex: 2,
      sortable: false,
      headerClassName: '-header',
      headerAlign: 'center',
      renderCell: (
        value: GridRenderCellParams<{
          label: string;
          state: 'success' | 'error' | 'warning';
        }>,
      ) => (
        <Chip
          label={value.value?.label}
          variant="outlined"
          color={value.value ? value.value.state : 'warning'}
        />
      ),
    },
    //@ts-ignore
    ...insertIf(!readOnly, {
      field: 'action',
      headerName: t('event.teams.datatable.labelActions'),
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
      flex: 2,
      renderCell: (value: GridRenderCellParams<Team>) => {
        if (!value.value) return;
        return (
          <div className={'-actions'}>
            {value.value.status === EventParticipantStatusEnum.WAIT_VALIDATE && (
              <>
                <Button
                  variant={'contained'}
                  color={'success'}
                  onClick={onClickAccept(value.value.id)}
                  disabled={disabled}
                >
                  {t('event.teams.datatable.acceptAction')}
                </Button>
                <Button
                  variant={'contained'}
                  color={'error'}
                  onClick={onClickDecline(value.value.id)}
                  disabled={disabled}
                >
                  {t('event.teams.datatable.declineAction')}
                </Button>
              </>
            )}
            {value.value.status === EventParticipantStatusEnum.REGISTERED && (
              <Button
                variant={'contained'}
                color={'error'}
                onClick={onClickDelete(value.value.id)}
                disabled={disabled}
              >
                {t('event.teams.datatable.deleteAction')}
              </Button>
            )}
            {value.value.status === EventParticipantStatusEnum.REJECTED && (
              <span>{t('event.teams.datatable.noAction')}</span>
            )}
          </div>
        );
      },
    }),
  ];

  return (
    <div className={'eventParticipantsParameters'}>
      <DataGrid
        disableColumnReorder
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableEval
        disableVirtualization
        disableRowSelectionOnClick
        pagination
        getRowHeight={() => 'auto'}
        paginationMode={'server'}
        rowCount={useFetchReturn.fetchedResource?.totalElementsCount}
        paginationModel={{
          pageSize: useFetchReturn.fetchedResource?.pageSize || 0,
          page: useFetchReturn.fetchedResource?.currentPage || 0,
        }}
        onPaginationModelChange={({ pageSize, page }) => {
          if (pageSize !== useFetchReturn.fetchedResource?.pageSize) {
            useFetchReturn.changeNbPerPage(pageSize);
          }
          useFetchReturn.goToPage(page);
        }}
        loading={useFetchReturn.loading}
        className={'-parameters'}
        autoHeight
        sx={{ color: 'white' }}
        slots={{
          // @ts-ignore
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {t('event.teams.datatable.noContentLabel')}
            </Stack>
          ),
        }}
        rows={
          useFetchReturn.fetchedResource?.content
            ? formatTeamsToRowData(useFetchReturn.fetchedResource?.content, t)
            : []
        }
        columns={columns}
      />
    </div>
  );
};
