import { Button, Divider } from '@mui/material';
import { canRegister } from 'domain/event/UnpyEvent.func';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { routesConfig } from 'primary/Configs/Routes.config';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationResourceTypeEnum } from '../../../domain/notification/NotificationResourceTypeEnum';
import { UnpyNotification } from '../../../domain/notification/UnpyNotification';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate } from '../../hooks/useTranslate';

interface NotificationInvitationTeamEventContentProps {
  notification: UnpyNotification;
  reFetchNotification: () => void;
}

export const NotificationInvitationParticipationEventContent = ({
  notification,
  reFetchNotification,
}: NotificationInvitationTeamEventContentProps) => {
  const t = useTranslate();
  const { eventParticipantRepository, eventRepository, authRepository } =
    useContextDependency();
  const navigte = useNavigate();
  const [eventParticipant, , fetching] = useRetrieveFromDomain(
    () =>
      eventParticipantRepository.getParticipantOfEventByProfil(
        authRepository?.currentUser?.userProfil.id,
        parseInt(notification.resources[NotificationResourceTypeEnum.EVENT_ID]),
      ),
    undefined,
  );
  const [event, , fetchingEvent] = useRetrieveFromDomain(
    () =>
      eventRepository.getEventById(
        parseInt(notification.resources[NotificationResourceTypeEnum.EVENT_ID]),
      ),
    undefined,
  );

  const isCanRegister = canRegister(event);

  return (
    <div>
      <Divider className={'-topMarged'} />
      {(fetching || fetchingEvent) && <LoadingScreen loading />}
      {!fetching && !!eventParticipant && (
        <span>{t('notification.content.inviteEvent.teamAlreadyCreated')}</span>
      )}
      {!fetchingEvent && !fetching && !eventParticipant && !isCanRegister && (
        <span>{t('notification.content.inviteEvent.perrempted')}</span>
      )}
      {!fetching && !eventParticipant && isCanRegister && event && (
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            navigte(
              routesConfig.eventViewDetails.eventViewPlayersRegisterOpen.to(event.id),
            );
          }}
        >
          {t('notification.content.inviteEvent.createTeam')}
        </Button>
      )}
    </div>
  );
};
