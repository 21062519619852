export const translationEN = {
  team: {
    modal: {
      edit: {
        title: 'Edit team {{teamName}}',
        playerStatus: {
          ACCEPTED: 'Accepted',
          NULL: 'On hold',
          DECLINE: 'On hold',
        },
      },
    },
  },
  privacy: {
    ADD_FRIEND: {
      labelForm: 'Add me as a friend',
    },
    JOIN_STRUCTURE: {
      labelForm: 'Ask to join my structure',
    },
    SEND_MESSAGE: {
      labelForm: 'Send me a message',
    },
    INVITE_STRUCTURE: {
      labelForm: 'Invite me to a structure',
    },
  },
  breadcrumb: {
    routes: {
      home: 'Welcome',
      payment: {
        confirm: 'Payment confirmation',
      },
      up: 'Unpy Up',
      mercato: {
        general: 'Mercato',
        player: 'Players',
        structure: 'structure',
      },
      marketplace: 'Marketplace',
      notifications: {
        general: 'Notifications',
      },
      messages: {
        general: 'Messaging',
      },
      myStructures: {
        general: 'My structures',
      },
      myEvents: {
        general: 'My events',
      },
      events: {
        general: 'Events',
        view: 'Details',
      },
      relations: {
        general: 'Relationships',
      },
      profils: {
        player: {
          view: 'Map',
        },
      },
      none: '',
    },
  },
  filtersValues: {
    none: 'No filter active',
    WAITING: 'On hold',
    CONFIRMED: 'Confirmed',
    DELAYED: 'Delayed',
    DECLINE: 'Canceled',
    DEFAULT: 'System Default',
    PERSO: 'Customized system',
    PUBLISH: 'Published',
    DRAFT: 'Draft',
    CANCELED: 'Canceled',
    FINISH: 'Event ended',
    ON_GOING: 'Started',
    SUBSCRIBE_OPEN: 'Registrations open',
    SUBSCRIBE_CLOSE: 'Registrations closed',
    SIMPELEM: 'Simple elimination',
    DOUBLELEM: 'Double elimination',
    POULSIMPLELEM: 'Chicken',
    POULDOUBLELEM: 'Chicken',
    POULBO: 'Chicken',
    LIG: 'League',
    LIGBO: 'League',
    SUISSE: 'Swiss',
    ROBIN: 'robin',
    ROBIN2: 'Two-Trick Robin',
    SOL: '1 Player',
    DUO: '2 Players',
    TRI: '3 Players',
    QUA: '4 Players',
    FIV: '5 Players',
    SQU: 'X Players (Squad)',
    BO1: 'Best Of 1 (BO1)',
    BO3: 'Best Of 3 (BO3)',
    BO5: 'Best Of 5 (BO5)',
    LIGUE: 'League',
    TOURNAMENT: 'Tournaments',
    JOIN_STRUCTURE: 'Request to join this structure',
    INVITE_PLAYER: 'Invite to a structure',
    SEND_MESSAGE: 'Send a notification',
    ADD_FRIEND: 'Ask as friends',
    EN: 'English',
    FR: 'French',
    ESP: 'Spanish',
    PRO: 'Pro / Competition',
    CASU: 'Casual',
    SEMIPRO: 'Semi-pro',
    XBO: 'XBOX',
    PC: 'PC',
    PLA: 'PlayStation',
  },
  apiErrors: {
    unknown: 'An error has occurred, please contact support.',
    unauthorize: 'You are not authorized to perform this action.',
    notAllowed: 'You do not have the required rights to perform this action.',
  },
  product: {
    buy: 'Buy',
    ABO_UP_1: {
      libelle: 'unpy UP subscription 1 month',
      description: '',
    },
    ABO_UP_6: {
      libelle: 'unpy UP subscription 6 months',
      description: '',
    },
    ABO_UP_12: {
      libelle: 'unpy UP subscription 12 months',
      description: '',
    },
    ABO_UP_PREMIUM_1: {
      libelle: 'Unpy UP premium subscription 1 month',
      description: '',
    },
    ABO_UP_PREMIUM_6: {
      libelle: 'unpy UP premium subscription 6 months',
      description: '',
    },
    ABO_UP_PREMIUM_12: {
      libelle: 'unpy UP premium subscription 12 months',
      description: '',
    },
    ABO_UP_STRUCTURE: {
      libelle: 'Structural benefits subscription',
      description: '',
    },
    ABO_UP_EVENT: {
      libelle: 'Subscription event benefits',
      description: '',
    },
    PROD_BOOST: {
      libelle: '24h visibility boost',
      description: '',
    },
  },
  subscription: {
    subscribe: 'Subscribe',
  },
  up: {
    pageTitle: 'Unpy UP',
    abonnement: {
      tabLabel: 'Subscriptions',
    },
    products: {
      productLabel: 'Products',
    },
  },
  general: {
    form: {
      validateChoice: 'Valider',
    },
    lang: {
      btnTitle: 'Langue :',
      langsValue: {
        en: 'Anglais',
        fr: 'Français',
      },
    },
    edit: {
      success: 'Change completed successfully',
      btnLabel: 'To modify',
    },
  },
  comingSoon: 'Coming soon',
  layoutPageCommonList: {
    goBack: 'Back',
    filters: 'Active filter(s):',
  },
  rightBar: {
    seeMore: 'Show more',
    noConnected: {
      info: 'You must be logged in',
    },
    activities: {
      title: 'Activities',
      noContent: 'No activities',
      loading: 'Loading activities',
    },
  },
  modal: {
    confirmModal: {
      defaultAcceptLabel: 'Yes',
      defaultDeclineLabel: 'No',
      defaultTitle: 'Are you sure ?',
    },
  },
  relation: {
    block: {
      title: 'Êtes-vous sûr de vouloir bloquer {{name}} ?',
      notice: "Vous pourrez le débloquer a tout moment dans l'onglet Relations",
      confirmText: 'Bloquer',
      cancelText: 'Annuler',
    },
    page: {
      list: {
        title: 'Relationships',
      },
    },
  },
  message: {
    input: {
      placeholder: 'Type your message',
    },
    list: {
      noMessages: 'No messages',
    },
    page: {
      returnBtn: 'Back',
      title: 'Messaging',
      chooseConversationToSeeMessage: 'Choose a conversation',
    },
    conversation: {
      noContent: 'You have no ongoing conversations.',
      title: 'Discussions',
      item: {
        noMessage: 'No messages',
        deleteSuccess: 'Conversation successfully deleted',
      },
    },
    item: {
      owner: 'YOU',
    },
  },
  classement: {
    tournament: {
      modalShowBracketTitle: 'Tournament bracket {{eventName}}',
      nextMathsTitle: 'Upcoming matches',
      showBracket: 'See the bracket',
    },
    parameters: {
      regenerate: 're-generate.',
      regenerateNotice: 'Attention ! ',
      editClassementBtn: 'Apply changes',
      noContent: 'You have not yet generated your ranking',
      loading: 'Rank recovery',
      generateClassement: 'Generate ranking',
      titleTotalPreview: 'General classification overview',
      listSession: 'List of match sessions',
      titleEditScoreBoard: 'Change session ranking',
      generateClassementLigueModalTitle: 'Generate a league ranking',
      generateClassementModalSubmitBtn: 'Generate',
      startEventModalSubmitBtn: 'Generate',
      generateClassementModalCancelBtn: 'Cancel',
      generateClassementModalTitleFieldLabel: 'Ranking title',
      generateClassemetMatchPerSessionFieldLabel: 'Number of matches per session',
      generateClassementNbMatchSessionOptLabel: '{{nbMatch}} match | {{count}} session',
      generateClassementNbMatchSessionOptLabel_plural:
        '{{nbMatch}} match | {{count}} sessions',
      deleteSessionBtn: 'Delete session',
      deleteSessionConfirmTitle: 'Are you sure you want to delete this match session?',
      deleteSessionConfirmDescription:
        'All matches and their scores will be deleted from the event rankings',
      datatableConfirmEditting: {
        teamNameLabel: 'Team',
        placesLabel: 'Place',
        pointsLabel: 'Points',
        noContentLabel: 'No modification',
      },
      modal: {
        titleListModif: 'List of changes',
        cancelBtn: 'Back',
        confirmBtn: 'Confirm',
        noModif: 'No changes made to the ranking',
      },
    },
    result: {
      optDefault: {
        WIN: 'Victory (3)',
        NULL: 'null (0)',
        LOOSE: 'defeat (-1)',
      },
    },
  },
  countDown: {
    day: '{{count}} day',
    day_plural: '{{count}} days',
    hour: '{{count}} hour',
    hour_plural: '{{count}} hours',
    minute: '{{count}} minute',
    minute_plural: '{{count}} minutes',
    second: '{{count}} second',
    second_plural: '{{count}} seconds',
  },
  countDownShort: {
    day: '{{count}} J',
    hour: '{{count}} H',
    minute: '{{count}} m',
    second: '{{count}} s',
  },
  event: {
    countDownLine: 'Start in {{day}} J {{hour}} H {{minute}} m {{second}} s',
    nextStepBtnLabel: {
      PUBLISH: 'Publish',
      SUBSCRIBE_CLOSE: 'Close registrations',
      ON_GOING: 'Start the event',
      FINISH: 'To end',
      confirmEvent: 'Confirm start date',
      canceledEvent: 'The event is canceled',
      finishEvent: 'The event is over',
    },
    nextInterStepBtnLabel: {
      countdown: 'You can start the event in ',
      countdownForConfirm: 'Request for validation of the start date in ',
      waitConfirm: 'Start date awaiting confirmation',
    },
    nextStepHelper: {
      PUBLISH:
        'Publishing your event requires filling in certain mandatory fields and allows your event to be visible to other players in the Events section.',
      SUBSCRIBE_CLOSE: 'Players will no longer be able to register for your event. ',
      ON_GOING: 'Start your event. ',
      FINISH: 'Finish your event. ',
    },
    nextStepConfirm: {
      DRAFT: {
        confirmedStartSubscribeDate: 'Please confirm registration start date',
      },
    },
    reminds: {
      eventObligations:
        'Event publishing involves management responsibilities that the organizer must fulfill. ',
    },
    filters: {
      status: 'Status',
      game: 'Game',
      games: 'Games',
      startDateTime: 'Start date',
      name: 'Name',
      typeEvent: 'Event type',
      typeTeam: 'Team type',
      platform: 'Platform',
    },
    card: {
      skeleton: {
        addOwn: 'Add your event',
      },
    },
    bracket: {
      matchView: {
        customMatch: {
          undoTitle: 'Cancel result',
        },
      },
      modalView: {
        getMatchsMsgLoading: 'Recovery of matches',
      },
    },
    timeline: {
      next: 'Take the step {{nextStatus}}',
      goToOnGoing: 'Start the tournament.',
    },
    modal: {
      confirmEvent: {
        title: 'Confirmation of event start',
        infos: 'You can extend this date only once.',
        alertDelayed:
          'Pushing back the start date categorizes the tournament as postponed. ',
        alertCanceled:
          'Canceling too many events may result in your account being suspended.',
        confirmBtn: 'Confirm date',
        delayedBtn: 'Delay the event',
        canceledBtn: 'Cancel Event',
      },
      startEvent: {
        title: 'Start the event',
      },
      modalClassementView: {
        fetching: 'Rank recovery',
        titleModal: 'Event ranking {{eventName}}',
        errorStatus: 'The status of this event does not allow you to view a ranking.',
      },
      register: 'Register for the event',
      registerTeam: 'Create your team to register',
      nameLabel: 'Name of your team',
      namePlaceholder: 'Enter the name of your team',
      validationRegisterNotice:
        'A request to participate will be sent to the event admins once all players on the team have accepted the invitation.',
      freeRegisterNotice:
        'Your team will automatically be added to the event once all players on the team have accepted the invitation',
    },
    eventViewPage: {
      networks: {
        title: 'Social networks',
      },
      moreInfos: {
        title: 'Further information',
        noContent: 'No additional information provided',
      },
      socials: {
        title: 'Social networks',
      },
      admins: {
        title: 'Administrators',
      },
      podiumView: {
        title: 'Podium',
        seeAllClassement: 'See the full ranking',
        noPodium: 'No rankings currently',
      },
      confirmStatusText: {
        WAITING: 'Confirmation pending',
        CONFIRMED: 'Start confirmed',
        DECLINE: 'Canceled',
        DELAYED: 'Postponed: {{date}}',
      },
      status: 'Status:',
      noStructureNotice: 'You must be part of an organization to participate in an event',
      mercatoStructureBtn: 'Search for a structure',
      createStructureBtn: 'Create a structure',
      registerBtn: 'Participate',
      alreadyRegistered: 'You are already participating in this event',
      parametersBtn: 'Configure',
      nbRegistered: '{{count}} / {{maxTeam}} registrations',
      infos: {
        rules: {
          title: 'Rules',
          noRules: 'No rules specified',
        },
        details: 'Details',
        game: 'Games :',
        freeRegister: 'Free registration:',
        teams: 'Teams',
        parameters: 'Settings',
        mode: 'Fashion :',
        teamType: 'Type of teams:',
        pointSystem: 'Point system:',
        maxTeam: 'Max number of teams:',
        yes: 'Yes',
        no: 'No',
      },
    },
    eventsListPage: {
      titleCaroussel: 'Select games',
      titleEventList: 'Find the event that suits you',
    },
    eventsPage: {
      selectGamePageTitle: 'Select a game',
      tournamentFamous: 'Popular tournaments',
      tournamentFuture: 'Upcoming tournaments',
      goBack: 'Back',
    },
    myEvents: {
      tabs: {
        created: 'Created',
        registered: 'Registered',
      },
      createEvent: 'Create my event',
      pageTitle: 'Event',
    },
    list: {
      noContent: {
        noContentMsg: 'No events',
        btnSearchEvent: 'Search for an event',
      },
    },
    teams: {
      datatable: {
        statusRefused: 'Denied',
        statusWaiting: 'On hold',
        statusAccepted: 'Accepted',
        deleteAction: 'DELETE',
        noAction: 'No action possible',
        declineAction: 'Refuse',
        acceptAction: 'Accept',
        labelName: 'Name',
        position: 'Name',
        labelPlayers: 'Players',
        labelStatut: 'Status',
        labelScore: 'Score',
        labelActions: 'Actions',
        noContentLabel: 'No teams',
      },
    },
    parameters: {
      timeline: {
        title: 'Timeline',
      },
      getEventMsgLoading: 'Recovery of the event',
      title: 'Event parameters',
      classements: {
        canceledWithoutBoard:
          'The event was canceled without any ranking being generated',
        wrongStatus: 'You must start the event to access the leaderboard',
        infoStatus: 'Your event has not reached its start date set for {{date}}. ',
        title: 'Rankings',
        noStatus: 'Registrations must be closed to add a ranking',
      },
      participants: {
        title: 'Teams',
      },
      appearence: {
        title: 'Appearance',
      },
      unpyAdmin: {
        title: 'Administration via Unpy member',
      },
      parameterize: {
        title: 'Setting',
      },
      rules: {
        title: 'Rules',
        updateSuccess: 'Event updates for your successes',
      },
    },
    form: {
      unpyAdmin: {
        launchGarabageEvent: 'Throw',
      },
      cancelBtn: 'Cancel',
      submitBtn: 'Create',
      editBtn: 'To modify',
      alert: {
        action: 'To modify',
        startDateTimeAfterLimit:
          'The start date entered is greater than D-2, you will no longer be able to edit it',
        endSubscribeDateAfterLimit:
          'The registration end date entered is greater than D-2, you will no longer be able to edit it',
      },
      notice: {
        endSubscribeDateDisabled:
          'You can only edit this field 48 before the date entered',
        startDateDisabled: 'You can only edit this field 48 before the date entered',
      },
      labels: {
        name: 'Name',
        systemPoints: 'Points system',
        startDateTime: 'Start date and time',
        endSubscribeDate: 'Registration end date',
        singleStructure: 'Only one participating team per structure',
        game: 'Affected games',
        typeEvent: 'Event type',
        typeTeam: 'Team type',
        maxTeam: 'Maximum number of teams',
        bracketTeamNumber: 'Number of participants',
        bracketTeamNumberOpt: '{{count}} participants',
        typeBracket: 'Bracket type',
        rules: 'Rules',
        typeRonde: 'Round type',
        freeRegister: 'Free registration (No validation by an admin)',
        description: 'Further information',
        image: 'Picture',
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        discord: 'Discord',
        twitch: 'Twitch',
        youtube: 'Youtube',
      },
      placeholders: {
        players: 'Select a player',
        name: 'Name of your event',
        startDateTime: 'Start date and time',
        game: 'Select a game',
        endSubscribeDate: 'Registration end date',
        singleStructure: 'Only one participating team per structure',
        rules: 'Specify the tournament rules',
        typeEvent: 'Select an event type',
        typeTeam: 'Select a team type',
        typeBracket: 'Select a bracket type',
        typeRonde: 'Select a tour type',
        description: 'Description',
        image: 'Picture',
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        discord: 'Discord',
        twitch: 'Twitch',
        youtube: 'Youtube',
      },
    },
  },
  otherProfil: {
    pingDisabled: 'This user to disable ping',
    titlePage: 'Profile of {{name}}',
  },
  user: {
    myProfil: {
      pageTitle: 'My profile',
      noGames: 'No games entered',
    },
    parameters: {
      admins: {
        event: {
          fixture: {
            createBtn: 'Generate the event',
            creating: 'Creation in progress, this operation may take several minutes. ',
          },
        },
      },
      getUserMsgLoading: 'Retrieving user settings',
      title: 'User settings',
      personalData: {
        title: 'Personal data',
      },
      transaction: {
        title: 'Transactions',
      },
      wallet: {
        title: 'Wallet',
      },
      connexion: {
        title: 'Connections / Registration',
      },
      eventsAdmin: {
        title: 'Event administration',
        subTitleFormFixture: 'Event generation form. ',
      },
      credentials: {
        title: 'Identifiers',
      },
      appearence: {
        title: 'Appearance',
        titleForm: 'Change Appearance',
        titlePreview: 'Preview',
      },
      network: {
        title: 'Networks',
      },
      statGame: {
        title: 'Games',
      },
      userProfilDetails: {
        title: 'User details',
      },
    },
  },
  structure: {
    parameters: {
      getStructureMsgLoading: 'Retrieving structure parameters',
      title: 'Structure parameters',
      appearence: {
        title: 'Appearance',
        titleForm: 'Change Appearance',
        titlePreview: 'Preview',
      },
      network: {
        title: 'Networks',
      },
      statGame: {
        title: 'Games',
      },
      membersStructure: {
        title: 'Members',
      },
      structureProfilDetails: {
        title: 'Structural details',
      },
    },
    inviteResponseWaiting: 'Waiting for a response',
    modalRole: {
      title: 'Change the role of {{name}}',
    },
    roles: {
      PRES: 'President',
      VICE_PRES: 'Vice President',
      STAFF: 'Staff',
      MANAGER: 'Manager',
      PLAYER: 'Player',
      RECRUE: 'Recruit',
    },
    form: {
      name: 'Name of the stucture',
      namePlaceholder: 'Enter the name of your structure',
      formSuccess: 'Structure created successfully',
      submitBtn: 'Create',
    },
    myStructure: 'My structure',
    createStructure: 'Create a structure',
  },
  notificationList: {
    noContent: 'No notifications',
    item: {
      invitationTeamEvent: {
        event: 'Event concerned',
      },
      objectByType: {
        INVITATION_STRUCTURE: '{{user}} Invites you to join its structure',
        JOIN_STRUCTURE: '{{user}} Would like to join your structure',
        INVITATION_TEAM_EVENT: 'You have been invited to join a team!',
        PARTICIPATION_EVENT: 'Request to participate in an event',
        INVITATION_FRIEND: 'Request to add to friends list',
        EVENT_SUBSCRIPTION_CLOSE: 'Registration for the event is closed',
        EVENT_START: 'The event has started!',
        EVENT_DELAYED: 'The event has been delayed.',
        EVENT_FINISHED: 'The event is over!',
        EVENT_CANCELED: 'The event has been canceled.',
        EVENT_CANCEL_DELAYED:
          'The event was canceled because the administrator did not start the event on time.',
        INVITATION_FRIEND_RESPONSE: 'A user responded to your friend list request',
        INVITATION_STRUCTURE_RESPONSE:
          'A user responded to your request to add to a structure',
        REVERT_EVENT_PLACE: 'A match result has been modified',
        MAJ_DECLINE_EVENT: 'The event was canceled before the start date was confirmed',
        NEW_MESSAGE: 'You have a new message',
      },
      header: {
        userFrom: 'Of :',
        receivedDate: 'Received on:',
      },
    },
  },
  myStructuresPage: {
    pageTitle: 'My structures',
    noContentMsg: 'You are not part of any structure',
    noContent: {
      createStructure: 'Create a structure',
      btnSearchStructure: 'Find a structure',
    },
  },
  notification: {
    tabs: {
      all: 'All',
      event: 'Event',
      mercato: 'Mercato',
      social: 'Social',
      divers: 'Miscellaneous',
    },
    pageTitle: 'My notifications',
    object: 'Object :',
    contentMsg: 'Message :',
    noContent: 'No notifications',
    content: {
      event: {
        goToEvent: 'See the event',
      },
      invitationStructure: {
        alreadyRefused: 'You have already declined this invitation',
        alreadyAccepted: 'You have already accepted this invitation',
        refuseBtn: 'Refuse',
        acceptBtn: 'Accept',
        seeStructureBtn: 'See the structure',
        title: '{{ pseudo }} invited you to join its structure {{ structure }}',
        response: {
          refused: 'Invitation refused',
          accepted: 'Invitation accepted',
          sended: 'Reply sent',
        },
      },
      invitationFriend: {
        alreadyRefused: 'You have already declined this invitation',
        alreadyAccepted: 'You have already accepted this invitation',
        refuseBtn: 'Refuse',
        acceptBtn: 'Accept',
        seeProfilBtn: 'View profile',
        title: '{{ pseudo }} would like to add you to his friends',
        response: {
          refused: 'Accepted',
          accepted: 'Refused',
          sended: 'Response sent',
        },
      },
      joinStructure: {
        alreadyRefused: 'You have already declined this invitation',
        alreadyAccepted: 'You have already accepted this invitation',
        refuseBtn: 'Refuse',
        acceptBtn: 'Accept',
        title: '{{ pseudo }} would like to join your organization',
        response: {
          refused: 'Request refused',
          accepted: 'Request accepted',
        },
      },
    },
  },
  enum: {
    eventConfirmationState: {
      WAITING: 'On hold',
      CONFIRMED: 'Confirmed',
      DELAYED: 'Delayed',
      DECLINE: 'Canceled',
    },
    systemPoints: {
      DEFAULT: 'System Default',
      PERSO: 'Customized system',
    },
    eventStatus: {
      PUBLISH: 'Published',
      DRAFT: 'Draft',
      CANCELED: 'Canceled',
      FINISH: 'Finished',
      ON_GOING: 'Started',
    },
    eventSubStatus: {
      SUBSCRIBE_OPEN: {
        title: 'Registrations open',
        details: 'scheduled end on {{datetime}}',
      },
      SUBSCRIBE_CLOSE: {
        title: 'Registrations closed',
        details: 'start of the event scheduled for {{datetime}}',
      },
      FINISH: {
        title: 'Event ended',
        details: '',
      },
    },
    typeBracketCode: {
      SIMPELEM: 'Simple elimination',
      DOUBLELEM: 'Double elimination',
      POULSIMPLELEM: 'Chicken',
      POULDOUBLELEM: 'Chicken',
      POULBO: 'Chicken',
      LIG: 'League',
      LIGBO: 'League',
    },
    typeRondeCode: {
      SUISSE: 'Swiss',
      ROBIN: 'robin',
      ROBIN2: 'Two-Trick Robin',
    },
    typeTeamCode: {
      SOL: '1 Player',
      DUO: '2 Players',
      TRI: '3 Players',
      QUA: '4 Players',
      FIV: '5 Players',
      SQU: 'X Players (Squad)',
    },
    typeBoCode: {
      BO1: 'Best Of 1 (BO1)',
      BO3: 'Best Of 3 (BO3)',
      BO5: 'Best Of 5 (BO5)',
    },
    typeEventCode: {
      LIGUE: 'League',
      TOURNAMENT: 'Tournaments',
    },
    typePing: {
      JOIN_STRUCTURE: 'Request to join this structure',
      INVITE_PLAYER: 'Invite to a structure',
      SEND_MESSAGE: 'Send a notification',
      ADD_FRIEND: 'Ask as friends',
    },
    lang: {
      EN: 'English',
      FR: 'French',
      ESP: 'Spanish',
    },
    gamingExperience: {
      PRO: 'Pro / Competition',
      CASU: 'Casual',
      SEMIPRO: 'Semi-pro',
    },
    platforms: {
      XBO: 'XBOX',
      PC: 'PC',
      PLA: 'PlayStation',
    },
  },
  ping: {
    modalPing: {
      title: 'Ping',
    },
    form: {
      typePingLabel: 'Ping type',
      typePingPlaceholder: 'Ping type',
      messagePingLabel: 'Add a notification',
      messagePlaceHolder: 'Type your notification',
      structureChoiceLabel: 'Choose a structure',
      structureChoicePlaceholder: 'Select a structure',
      submitBtn: 'Send',
      structure: {
        successJoin: 'Membership application sent successfully',
      },
      player: {
        successInvite: 'Invitation sent sent successfully',
      },
    },
  },
  games: {
    libelle: {
      APX: 'Apex legends',
      WRZ: 'Warzone',
      LOL: 'League of Legends',
      PBG: 'PUBG',
      R6S: 'Rainbow Six: Siege',
      FFA: 'Fifa',
      VLR: 'Valorant',
      TFT: 'Teamfight Tactics',
      CSG: 'CS:GO',
      RCK: 'rocket league',
      FRT: 'Fortnite',
    },
    statistiques: {
      WINNUM: 'Number of victories',
      WINPER: 'Win percentage',
      KILL: 'Number of kills',
      KD: 'Kills/deaths ratio',
      KILLMMATCH: 'Average kills per game',
      TOP5: 'Number of top 5',
      DMGMOY: 'Average damage',
      NBRKO: 'Number of KOs',
      POINTARENA: 'Arena Points',
      DIV: 'Division',
      DIVMAX: 'Maximum division reached',
      KDA: 'Kills/deaths/assists ratio',
      TOP10PER: 'Percentage of top 10',
      DEATH: 'Number of deaths',
      ASSIST: 'Number of assists',
      RANKPBG: 'Rank ',
      RANKPBGMAX: 'Maximum rank reached',
      RANKAPX: 'Rank ',
      RANKAPXMAX: 'Maximum rank reached',
      RANKCSGO: 'Rank ',
      RANKCSGOMAX: 'Maximum rank reached',
      RANKTFT: 'Rank ',
      RANKTFTMAX: 'Maximum rank reached',
      RANKVLR: 'Rank ',
      RANKVLRMAX: 'Maximum rank reached',
      RANKR6S: 'Rank ',
      RANKR6SMAX: 'Maximum rank reached',
      RANKRCK: 'Rank ',
      RANKRCKMAX: 'Maximum rank reached',
      RANKLOL: 'Rank ',
      RANKLOLMAX: 'Maximum rank reached',
      DMGTOT: 'Total damage',
      LEGFAV: 'Favorite legend',
      HEADSHOTPER: 'Headshot percentage',
      NBMVP: 'Number of MVP times',
      LOOSE: 'Number of defeats',
      BOMBDEF: 'Bombs defused',
      BOMBPLANT: 'Bombs planted',
      HOSTRESC: 'Hostages rescued',
      SHOTACC: 'Shot accuracy',
      TOP4: 'Number of top 4',
      TOP4PER: 'Top 4 percentage',
      NBGAME: 'Number of games',
      CHAMPFAV: 'Champion favorites',
      HEADSHOT: 'Number of headshots',
      SCORROUNDMOY: 'Average score per round',
      KILLROUNDMOY: 'Average number of kills per round',
      FIRTBLOOD: 'Number of first blood',
      MOSTKILLMATCH: 'Number of times best number of frags in the match',
      AGFAV: 'Favorite agent',
      KILLMEL: 'Number of melee kills',
      KILLBLIND: 'Number of kills without vision',
      BUTOT: 'Total goal',
      CFTOT: 'Total free kicks',
      PENTOT: 'Penalty total',
      MATCHTOT: 'Match total',
      NBSAIS: 'Number of seasons played',
      NBGRAD: 'Number of titles won',
      BUTPER: 'Goal percentage',
      SHOT: 'Number of shots',
      ROLEPRIM: 'Main role',
      ROLESEC: 'Secondary role',
      CREEPMIN: 'Creep per minute',
      VISCORE: 'Vision score',
    },
    selectOptStats: {
      Ligue_ouverte_Division_1: 'Open_League_Division_1',
      Ligue_ouverte_Division_2: 'Open_League_Division_2',
      Ligue_ouverte_Division_3: 'Open_League_Division_3',
      Ligue_ouverte_Division_4: 'Open_League_Division_4',
      Ligue_rivalite_Division_5: 'League_rivalry_Division_5',
      Ligue_rivalite_Division_6: 'League_rivalry_Division_6',
      Ligue_rivalite_Division_7: 'League_rivalry_Division_7',
      Ligue_Champion_Division_8: 'League_Champion_Division_8',
      Ligue_Champion_Division_9: 'League_Champion_Division_9',
      Ligue_Champion_Division_10: 'League_Champion_Division_10',
      jungle: 'Jungle',
      Middle: 'Middle',
      bottom: 'Bottom',
      top: 'top',
      support: 'Support',
    },
  },
  login: {
    title: 'Connection',
  },
  credentials: {
    passwordSuccess: 'Password changed successfully',
    passwordPlaceholder: 'New Password',
  },
  register: {
    form: {
      usernameLabel: 'username',
      emailLabel: 'E-mail',
      passwordLabel: 'Password',
      submitButton: 'To modify',
      cancelButton: 'Cancel',
      registeredNotice: 'You are now registered on Unpy',
    },
  },
  footer: {
    sections: {
      mercato: 'Mercato',
      mercatoStructure: 'Structure',
      mercatoPlayer: 'Player',
      events: 'Events',
      marketplace: 'Marketplace',
      userMenu: {
        title: 'User',
        loggedIn: {
          parametres: '',
        },
        loggedOut: {
          toConnect: 'To log in',
        },
      },
    },
  },
  home: {
    infos: {
      buttonTitle: 'Improve your experience with UP',
      mercato: {
        title: 'Mercato',
        description:
          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ',
      },
      events: {
        title: 'Events',
        description:
          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ',
      },
      marketplace: {
        title: 'Marketplace',
        description:
          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ',
      },
    },
    up: {
      titleStats: 'Unpy in numbers',
      titleSocial: "Don't miss any Unpy news",
    },
    titleWelcome: 'Welcome to unpy',
    titleFirstPlatform: 'First e-sports platform',
    actuality: {
      title: 'UNPY NEWS',
      eventAnnouncement: {
        title: 'Upcoming events',
        getEventOverviewMsgLoading: 'Recovery of events',
      },
      mercatoAnnouncement: {
        title: 'Transfer window trend',
      },
      marketPlaceAnnouncements: {
        title: 'Popular marketplace ad',
      },
    },
    gamingActuality: {
      title: 'Gaming News',
    },
  },
  myProfil: {
    noMember: 'No members',
    age: 'Age',
    aboutMembers: 'Description / members',
    titlePalmares: 'Unpy awards',
    palmaresLoading: 'Loading the list',
    titleDescription: 'Description',
    titlePlatforms: 'Platforms',
    titleLangs: 'LANGUAGES',
    gamingExperience: 'Player Profile',
    interest: 'Interests',
    interestNoContent: 'No interest indicated',
    noGames: 'No games entered',
    noStructure: 'No structure',
    noTalkLank: 'No language specified',
    noPlatforms: 'No platform specified',
    noNetworkValue: '{{network}} not specified',
    titlePage: 'Profile',
    ping: {
      enabled: 'Ping enabled',
      disabled: 'Ping disabled',
    },
    parametersBtnLabel: 'Settings',
    structureActuality: 'Structure news',
    infoBtnLabel: 'Details',
    titleInfos: 'Information',
    retourInfos: 'Back',
    noneStats: 'No statistics provided for this game.',
    noneGameStatSelected: 'Please select a game to display the associated statistics.',
  },
  mercato: {
    card: {
      skeleton: {
        addOwn: 'Add your structure',
      },
    },
    titlePlayer: 'Players',
    titleStructure: 'Structures',
    titleSearchPlayer: 'Find your player',
    titleSearchStructure: 'Find your structure',
    titleCaroussel: 'Select games',
    titleLeftPanel: 'Filters',
    titlePanelFilterBtn: 'FILTERS',
    filters: {
      player: {
        age: 'Age',
        langs: 'LANGUAGES',
        platforms: 'Platforms',
        experiences: 'Experience',
        valueLabel: {
          age: {
            AGE_LESS_18: '- 18 years old',
            AGE_MORE_18: '18 years old',
            AGE_MORE_25: '25 years',
          },
        },
      },
    },
  },
  topbarMenuProfil: {
    links: {
      details: 'details',
      performances: 'performance',
      network: 'networks',
    },
  },
  profil: {
    details: {
      description: {
        noContent: 'No description provided',
      },
    },
  },
  topbar: {
    links: {
      home: 'HOME',
      mercato: 'MERCATO',
      event: 'EVENT',
      marketplace: 'MARKETPLACE',
    },
    anonyme: {
      login: 'Connection',
      register: 'Registration',
    },
    create: {
      structure: {
        label: 'Creation of structure',
      },
      event: {
        label: 'Event creation',
      },
    },
  },
  parameters: {
    menus: {
      profil: {
        user: {
          title: 'User',
          form: {
            submit: 'To safeguard',
            errors: {
              videoProfilUrl: 'Please enter a valid URL (https://[...].[.fr,.com...])',
            },
            playerIdLabel: 'Player ID',
            playerIdPlaceholder: 'Choose a player ID',
            descriptionLabel: 'Description',
            descriptionPlaceholder: 'Describe yourself',
            birthdateLabel: 'Date of birth',
            birthdatePlaceholder: 'Date of birth',
            videoProfilLabel: 'Video',
            videoProfilPlaceholder: 'Link to your promotional video',
            socialLinkPlaceholder: 'Link of your {{network}}',
            socialLinkError: 'An error occurred while editing the link {{network}}.',
            pingLabel: 'Ping',
            countryLabel: 'Country',
            countryPlaceholder: 'Enter your country',
            talkLangsLabel: 'Languages ​​used',
            talkLangsPlaceholder: 'Choose one or more languages.',
            neonLabel: 'Neon',
            neonPlaceholder: 'Select a neon type',
            profilPhotoLabel: 'Profile picture',
            profilPhotoPlaceholder: 'Choose a file',
            bannerLabel: 'Banner',
            bannerPlaceholder: 'Choose a file',
            backgroundImageLabel: 'Background image',
            gamingExperienceLabel: 'Experience',
            gamingExperiencePlaceholder: 'Select your gaming experience',
            platformLabel: 'Platforms',
            platformPlaceholder: 'Select one or more platforms',
            gamesLabel: 'Games',
            gamesPlaceholder: 'Select one or more games',
            statSuccess: '{{stat}} successfully modified',
            listGameSelectedTitle: 'Select to enter associated statistics',
            statistiquesTitle: 'Statistics',
          },
        },
        structure: {
          title: 'Structure',
          form: {
            submit: 'To safeguard',
            errors: {
              videoProfilUrl: 'Please enter a valid URL (https://[...].[.fr,.com...])',
            },
            nameLabel: 'Name of the stucture',
            namePlaceholder: 'Enter a structure name',
            descriptionLabel: 'Description',
            descriptionPlaceholder: 'Describe yourself',
            videoProfilLabel: 'Video',
            videoProfilPlaceholder: 'Link to your promotional video',
            socialLinkPlaceholder: 'Link of your {{network}}',
            socialLinkError: 'An error occurred while editing the link {{network}}.',
            pingLabel: 'Ping',
            countryLabel: 'Country',
            countryPlaceholder: 'Enter your country',
            talkLangsLabel: 'Languages ​​used',
            talkLangsPlaceholder: 'Choose one or more languages.',
            neonLabel: 'Neon',
            neonPlaceholder: 'Select a neon type',
            profilPhotoLabel: 'Profile picture',
            profilPhotoPlaceholder: 'Choose a file',
            bannerLabel: 'Banner',
            bannerPlaceholder: 'Choose a file',
            backgroundImageLabel: 'Background image',
            gamingExperienceLabel: 'Experience',
            gamingExperiencePlaceholder: 'Select your gaming experience',
            platformLabel: 'Platforms',
            platformPlaceholder: 'Select one or more platforms',
            gamesLabel: 'Games',
            gamesPlaceholder: 'Select one or more games',
            gamesSuccess: 'Games successfully modified',
            statSuccess: '{{stat}} successfully modified',
            listGameSelectedTitle: 'Select to enter associated statistics',
            statistiquesTitle: 'Statistics',
          },
        },
        appearence: {
          title: 'Appearance',
        },
      },
    },
  },
  date: {
    shortDayOfWeek: {
      '0': 'SUN',
      '1': 'MON',
      '2': 'MAR',
      '3': 'SEA',
      '4': 'GAME',
      '5': 'FRI',
      '6': 'SAT',
    },
    today: 'Today',
    dayFirstCapitalize: {
      '0': 'Sunday',
      '1': 'Monday',
      '2': 'Tuesday',
      '3': 'Wednesday',
      '4': 'THURSDAY',
      '5': 'Friday',
      '6': 'SATURDAY',
    },
    month: {
      '0': 'JAN',
      '1': 'FEB',
      '2': 'MAR',
      '3': 'APR',
      '4': 'MAY',
      '5': 'JUNE',
      '6': 'JUL',
      '7': 'AOU',
      '8': 'SEP',
      '9': 'OCT',
      '10': 'NOV',
      '11': 'DEC',
    },
    monthComplete: {
      '0': 'January',
      '1': 'FEBRUARY',
      '2': 'March',
      '3': 'April',
      '4': 'May',
      '5': 'June',
      '6': 'July',
      '7': 'August',
      '8': 'September',
      '9': 'October',
      '10': 'November',
      '11': 'December',
    },
  },
  previewEditProfil: {
    mercatoView: 'Transfer window view',
    miniatureView: 'Thumbnail view',
  },
  helpers: {
    events: {
      pointsSystem: {
        edit: {
          defeat: 'Defeat: -1',
          null: 'Draw: 1',
          victory: 'Victory: 3',
        },
        brief: 'Unpy lets you choose between two point systems:',
        DEFAULT:
          'The default points system involves letting Unpy apply the scores of: Defeat = -1, Draw = 0, Victory = 3. The administrator will have to choose between these 3 options when entering scores.',
        PERSO:
          'The personalized points system involves letting the administrator use his own points system and therefore letting him directly enter a number of points when entering a score.',
      },
      freeRegister: {
        freeKey: 'Yes',
        notFreeKey: 'No',
        free: 'Registrations do not require validation by an event administrator',
        notFree: 'An administrator must validate the registration of each participant',
      },
    },
  },
};
