import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';
import { User } from 'domain/user/User';
import { UserView } from 'domain/user/UserView';
import {
  RestUnpySubscription,
  toUnpySubscription,
} from 'secondary/payment/RestUnpySubscription';
import { RestUserProfilView, toUserProfilView } from 'secondary/user/RestUserProfilView';

import { RolesEnum } from '../../domain/security/RolesEnum';
import { toEnum } from '../../helper/enum.helper';
import {
  RestStructureMember,
  toStructureMember,
} from '../structureMember/RestStructureMember';
import { RestUserProfil, toUserProfil } from '../userProfil/RestUserProfil';

export interface RestUser {
  id: number;
  authorities: string[];
  preferred_username: string;
  email: string;
  username: string;
  userProfil: RestUserProfil;
  structuresAsMember?: RestStructureMember[];
  subscription?: RestUnpySubscription;
  stripeSellerId?: string;
  sellerAccountComformity?: string;
}

export interface RestUserView {
  id: number;
  authorities: string[];
  preferred_username: string;
  email: string;
  username: string;
  userProfil: RestUserProfilView;
  subscription?: RestUnpySubscription;
  stripeSellerId?: string;
  sellerAccountComformity?: string;
}

export function toAccount(restUser: RestUser): User {
  const {
    id,
    authorities,
    email,
    username,
    userProfil,
    structuresAsMember,
    subscription,
    stripeSellerId,
    sellerAccountComformity,
  } = restUser;
  return new User(
    id,
    authorities
      .map((restAuthority) =>
        Object.values(RolesEnum).find((r) => r.toString() === restAuthority)
          ? toEnum(RolesEnum, restAuthority)
          : null,
      )
      .filter((role): role is RolesEnum => role !== null),
    username,
    email,
    toUserProfil(userProfil),
    structuresAsMember?.map(toStructureMember),
    subscription ? toUnpySubscription(subscription) : undefined,
    stripeSellerId,
    sellerAccountComformity
      ? toEnum(SellerAccountConformityEnum, sellerAccountComformity)
      : undefined,
  );
}

export function toUser(restUser: RestUser): User {
  const {
    id,
    email,
    username,
    userProfil,
    structuresAsMember,
    subscription,
    stripeSellerId,
  } = restUser;
  return new User(
    id,
    [],
    username,
    email,
    toUserProfil(userProfil),
    structuresAsMember?.map(toStructureMember),
    subscription ? toUnpySubscription(subscription) : undefined,
    stripeSellerId,
  );
}
export function toUserView(restUser: RestUserView): UserView {
  const { id, email, username, userProfil, subscription, stripeSellerId } = restUser;
  return new UserView(
    id,
    [],
    username,
    email,
    toUserProfilView(userProfil),
    subscription ? toUnpySubscription(subscription) : undefined,
    stripeSellerId,
  );
}
