import { setTeamModalClose } from 'domain/modal/store/ModalSlice';
import ModalTeamEvent from 'primary/events/modals/ModalTeamEvent';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export const ModalTeamEventWrapper = () => {
  const modalTeamState = useSelector((state: RootState) => state.modal.team);
  const dispatch = useDispatch();
  const closeModal = () => dispatch(setTeamModalClose());
  const { isOpen, props } = modalTeamState;
  return (
    <ModalContainer
      handleClose={closeModal}
      isOpen={isOpen}
      content={
        <>
          {props?.event && (
            <ModalTeamEvent
              event={props.event}
              teamRegistered={props.teamRegistered}
              afterChanges={() => {
                props?.afterChanges?.();
              }}
              handleClose={closeModal}
            />
          )}
        </>
      }
    />
  );
};
