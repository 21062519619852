import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalInviteEventProps } from 'primary/Components/Profils/User/ActionButton/Modals/ModalInviteEvent';
import { ModalInviteStructureProps } from 'primary/Components/Profils/User/ActionButton/Modals/ModalInviteStructure';
import { ModalTeamEventProps } from 'primary/events/modals/ModalTeamEvent';

export type MODAL_TYPE = 'team' | 'link';

type InitialModalSliceState = {
  team: {
    isOpen: boolean;
    props?: ModalTeamEventProps;
  };
  inviteStructure: {
    isOpen: boolean;
    props?: ModalInviteStructureProps;
  };
  inviteEvent: {
    isOpen: boolean;
    props?: ModalInviteEventProps;
  };
};

export const modalSlice = createSlice({
  name: 'user',
  initialState: {
    team: {
      isOpen: false,
      props: {},
    },
    inviteStructure: {
      isOpen: false,
      props: {},
    },
    inviteEvent: {
      isOpen: false,
      props: {},
    },
  } as InitialModalSliceState,
  reducers: {
    setTeamModalOpen: (state, action: PayloadAction<{ props: ModalTeamEventProps }>) => {
      state.team = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setTeamModalClose: (state) => {
      state.team = {
        isOpen: false,
        props: {} as ModalTeamEventProps,
      };
    },
    setInviteStructureModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteStructureProps }>,
    ) => {
      state.inviteStructure = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setInviteStructureModalClose: (state) => {
      state.inviteStructure = {
        isOpen: false,
        props: {} as ModalInviteStructureProps,
      };
    },
    setInviteEventModalOpen: (
      state,
      action: PayloadAction<{ props: ModalInviteEventProps }>,
    ) => {
      state.inviteEvent = {
        isOpen: true,
        props: action.payload.props,
      };
    },
    setInviteEventModalClose: (state) => {
      state.inviteEvent = {
        isOpen: false,
        props: {} as ModalInviteEventProps,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTeamModalOpen,
  setTeamModalClose,
  setInviteStructureModalOpen,
  setInviteStructureModalClose,
  setInviteEventModalOpen,
  setInviteEventModalClose,
} = modalSlice.actions;

export default modalSlice.reducer;
