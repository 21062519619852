import { ClassementParticipant } from 'domain/classement/ClassementParticipant';
import { CashprizeStatusEnum } from 'domain/event/CashprizeStatus.enum';
import { EventConfirmationStateEnum } from 'domain/event/EventConfirmationStateEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import { PayoutStatusEnum } from 'domain/payout/PayoutStatus.enum';
import { Platform } from 'domain/platform/Platform';

import { Game } from '../game/Game';
import { UserProfil } from '../profil/UserProfil';
import { EventStatusEnum } from './EventStatusEnum';
import { TypeBo } from './TypeBo';
import { TypeBracket } from './TypeBracket';
import { TypeEvent } from './TypeEvent';
import { TypeRonde } from './TypeRonde';
import { TypeTeam } from './TypeTeam';

export const SCORE_DEFAULT = {
  WIN: 3,
  NULL: 1,
  DEFEAT: -1,
};

export interface UnpyEventFilters {
  status?: EventStatusEnum;
  game?: string;
  games?: string[];
  startDateTime?: string;
  name?: string;
  typeEvent?: string;
  typeTeam?: string;
  endSubscribeDate?: string;
  platforms?: string[];
  statusIn?: string[];
  statusNot?: string;
  placeAvailable?: string;
  displayFinished?: string;
  statusNotIn?: string;
}

export class UnpyEvent {
  constructor(
    public readonly id: number,
    public readonly startDateTime: Date,

    public readonly endSubscribeDate: Date,
    public readonly status: EventStatusEnum,
    public readonly name: string,
    public readonly freeRegister: boolean,
    public readonly singleStructureTeam: boolean,
    public readonly creationDate: Date,
    public readonly creator: UserProfil,
    public readonly game: Game,
    public readonly typeEvent: TypeEvent,
    public readonly typeTeam?: TypeTeam,
    public readonly rules?: string,
    public readonly platforms?: Platform[],
    public readonly description?: string,
    public readonly maxTeam?: number,
    public readonly confirmationState?: EventConfirmationStateEnum,
    public readonly delayed?: Date,
    public readonly bracketTeamNumber?: number,
    public readonly twitter?: string,
    public readonly facebook?: string,
    public readonly youtube?: string,
    public readonly instagram?: string,
    public readonly discord?: string,
    public readonly twitch?: string,
    public readonly image?: string,
    public readonly typeBracket?: TypeBracket,
    public readonly typeRonde?: TypeRonde,
    public readonly typeBo?: TypeBo,
    public readonly admins?: UserProfil[],
    public readonly classement?: ClassementParticipant,
    public readonly pointSystem?: SystemPointsEnum,
    public readonly publicImageUrl?: string,
    public readonly defaultImage?: boolean,
    public readonly prizeText?: string,
    public readonly totalParticipants?: number,
    public readonly registerPrice?: number,
    public readonly cashprizeStatus?: CashprizeStatusEnum,
    public readonly registerCashoutStatus?: PayoutStatusEnum,
    public readonly cashprize?: number,
    public readonly endDateTime?: Date,
  ) {}
}
