import './Home.scss';

import { Divider, Hidden } from '@mui/material';
import { HomeFollowSection } from 'primary/Home/Sections/HomeFollowSection';
import { HomeMercatoSection } from 'primary/Home/Sections/HomeMercatoSection';
import { HomeUpSection } from 'primary/Home/Sections/HomeUpSection';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC } from 'react';

import { HomeSlidersSection } from './Sections/HomeSlidersSection';

export type HomeSectionProps = {
  className?: string;
};

export const Home: FC = () => {
  const t = useTranslate();
  return (
    <div className={'home'}>
      <HomeSlidersSection key={'home-section-1'} className={'home__section-1'} />
      <Hidden smUp>
        <Divider
          orientation={'vertical'}
          className={'-dividerHomeEventMercato -vertical'}
        />
      </Hidden>
      <Hidden smDown>
        <Divider
          orientation={'horizontal'}
          className={'-dividerHomeEventMercato -horizontal'}
        />
      </Hidden>
      <HomeMercatoSection key={'home-section-2'} className={'home__section-2'} />
      <HomeUpSection key={'home-section-4'} className={'home__section-4'} />
      <HomeFollowSection key={'home-section-5'} className={'home__section-5'} />
    </div>
  );
};
