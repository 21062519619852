import './StructurePreviewCardProfil.scss';

import { InfoRounded, Settings } from '@mui/icons-material';
import classNames from 'classnames';
import { GameProfil } from 'domain/gameProfil/GameProfil';
import { getNeonBorderClassName, getNeonVariant } from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { RolesStructureEnum } from 'domain/security/RolesStructureEnum';
import { GameSelectionCaroussel } from 'primary/Components/GameSelectionCaroussel/GameSelectionCaroussel';
import { Neon } from 'primary/Components/Neon/Neon';
import { NoGamesIllustration } from 'primary/Components/NoGames/NoGamesIllustration';
import { PlatformsRow } from 'primary/Components/PlatformsRow/PlatformsRow';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { ActionButtonJoinStructure } from 'primary/Components/Profils/User/ActionButton/ActionButtonJoinStructure';
import { ActionButtonSendMessage } from 'primary/Components/Profils/User/ActionButton/ActionButtonSendMessage';
import { OtherStructureProfilBannerOverview } from 'primary/Components/User/OtherStructureProfilBannerOverview';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { TalkLangsRow } from 'primary/TalkLangsRow/TalkLangsRow';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface StructurePreviewCardProfilProps {
  profil: StructureProfil;
  userName: string;
  className?: string;
  inPage?: boolean;
  draftBanner?: File;
  noAction?: boolean;
}

interface ToggleGameStatsProps {
  profil: StructureProfil;
  inPage?: boolean;
}
const ToggleGameStats = ({ profil }: ToggleGameStatsProps) => {
  return (
    <div className={'unpyStatsWithGames'}>
      {/*<Button*/}
      {/*  variant={'outlined'}*/}
      {/*  color={'primary'}*/}
      {/*  onClick={toggleGames}*/}
      {/*  className={'-toggleBtnGameStat'}*/}
      {/*>*/}
      {/*  <ChangeCircle />*/}
      {/*  {!isOpenGames*/}
      {/*    ? t('profil.previewCard.displayGames')*/}
      {/*    : t('profil.previewCard.displayStats')}*/}
      {/*</Button>*/}
      {/*<Collapse in={isOpenGames}>*/}
      <div className={'-games'}>
        <GameSelectionCaroussel
          gamesToShow={profil.games.map((value: GameProfil) => value.game.code)}
          selectedValues={[]}
          rows={1}
          slidePerRow={1}
          slidesToScroll={1}
          embeded
          infinite
          withoutDots
          center
        />
      </div>
      {/*</Collapse>*/}
      {/*<Collapse in={!isOpenGames}>*/}
      {/*  <div className={'-stats'}>*/}
      {/*    <StatsUnpyProfilView profil={profil} />*/}
      {/*  </div>*/}
      {/*</Collapse>*/}
    </div>
  );
};

export const StructurePreviewCardProfil = ({
  profil,
  className,
  inPage,
  draftBanner,
  noAction,
}: StructurePreviewCardProfilProps) => {
  const neonBorderClassName = getNeonBorderClassName(profil);
  const { authRepository } = useContextDependency();
  const navigation = useNavigate();

  const handleClickParam = () => {
    navigation(routesConfig.parametersStructure.to(profil.structure?.id));
  };

  const handleClick = () => {
    navigation(routesConfig.otherStructureProfil.to(profil.id));
  };

  const t = useTranslate();

  const conf: PopperButtonsConf[] = useMemo(() => {
    if (
      profil.structure?.members.find(
        (member) =>
          member.user.userProfil.idProfil === authRepository?.currentUser?.userProfil.id,
      )?.role.code === RolesStructureEnum.PRES
    ) {
      return [
        {
          label: t('profil.userProfilAction.infosMore'),
          action: handleClick,
          icon: <InfoRounded />,
        },
        {
          label: t('profil.userProfilAction.parameters'),
          action: handleClickParam,
          icon: <Settings />,
        },
      ];
    }
    return [
      {
        label: t('profil.userProfilAction.infosMore'),
        action: handleClick,
        icon: <InfoRounded />,
      },
      {
        button: <ActionButtonJoinStructure profil={profil} withIcon />,
      },
      {
        button: <ActionButtonSendMessage profil={profil} withIcon />,
      },
    ];
  }, [profil]);

  return (
    <div className={'-paddingWrapperStructure'}>
      {!noAction && (
        <div className={'rightBarMenu'}>
          <PopperButton
            conf={conf}
            popperName={'user-profil-preview'}
            placement={'auto'}
          />
        </div>
      )}
      <div
        className={classNames(
          'structurePreviewCardProfil',
          { '-hoverable': !noAction },
          { '-pointerable': !noAction },
          className,
          neonBorderClassName,
        )}
        onTouchStart={undefined}
        onTouchEnd={undefined}
        style={{
          backgroundImage: `url("${profil.backgroundImage?.publicUrlSm}")`,
          backgroundSize: 'cover',
        }}
      >
        {noAction && (
          <div className={classNames('background__transp')}>
            <div className={'structurePreviewCardProfil__header'}>
              <div className={classNames('structurePreviewCardProfil__header-banner')}>
                <OtherStructureProfilBannerOverview
                  idStructureProfil={profil.id}
                  className={classNames(className, neonBorderClassName)}
                  banner={
                    draftBanner
                      ? URL.createObjectURL(draftBanner)
                      : profil.bannerImagePublicUrl
                  }
                />
              </div>
              <div className={classNames('structurePreviewCardProfil__header-badges')}>
                <div className={'-langs'}>
                  <TalkLangsRow
                    embedded
                    langs={profil.talkLangs?.map((lang) => lang.code)}
                  />
                </div>
                <div className={'-platforms'}>
                  <PlatformsRow
                    variant={'contained'}
                    platforms={profil.platforms?.map((platform) => platform.code)}
                  />
                </div>
              </div>
            </div>
            <div className={'structurePreviewCardProfil__content'}>
              <div className={'-name'}>
                <h3>{profil.name || profil.structure?.name}</h3>
              </div>
              <Neon
                className={'neon'}
                width={'50%'}
                variant={getNeonVariant(profil?.neon)}
              />
              <div className={'structurePreviewCardProfil__content-row -third'}>
                {profil?.games?.length > 0 && (
                  <ToggleGameStats profil={profil} inPage={inPage} />
                )}
                {profil?.games?.length === 0 && <NoGamesIllustration />}
              </div>
            </div>
          </div>
        )}
        {!noAction && (
          <Link
            to={routesConfig.otherStructureProfil.to(profil.id)}
            className={'-linkWrapper'}
          >
            <div className={classNames('background__transp')}>
              <div className={'structurePreviewCardProfil__header'}>
                <div className={classNames('structurePreviewCardProfil__header-banner')}>
                  <OtherStructureProfilBannerOverview
                    idStructureProfil={profil.id}
                    className={classNames(className, neonBorderClassName)}
                    banner={
                      draftBanner
                        ? URL.createObjectURL(draftBanner)
                        : profil.bannerImagePublicUrl
                    }
                  />
                </div>
                <div className={classNames('structurePreviewCardProfil__header-badges')}>
                  <div className={'-langs'}>
                    <TalkLangsRow
                      embedded
                      langs={profil.talkLangs?.map((lang) => lang.code)}
                    />
                  </div>
                  <div className={'-platforms'}>
                    <PlatformsRow
                      variant={'contained'}
                      platforms={profil.platforms?.map((platform) => platform.code)}
                    />
                  </div>
                </div>
              </div>
              <div className={'structurePreviewCardProfil__content'}>
                <div className={'-name'}>
                  <h3>{profil.name || profil.structure?.name}</h3>
                </div>
                <Neon
                  className={'neon'}
                  width={'50%'}
                  variant={getNeonVariant(profil?.neon)}
                />
                <div className={'structurePreviewCardProfil__content-row -third'}>
                  {profil?.games?.length > 0 && (
                    <ToggleGameStats profil={profil} inPage={inPage} />
                  )}
                  {profil?.games?.length === 0 && <NoGamesIllustration />}
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
