export enum NotificationTypeEnum {
  INVITATION_FRIEND = 'INVITATION_FRIEND',
  INVITATION_FRIEND_RESPONSE = 'INVITATION_FRIEND_RESPONSE',
  INVITATION_STRUCTURE = 'INVITATION_STRUCTURE',
  ABONNEMENT_UPDATE = 'ABONNEMENT_UPDATE ',
  JOIN_STRUCTURE = 'JOIN_STRUCTURE',
  JOIN_STRUCTURE_FROM_INVITATION_LINK = 'JOIN_STRUCTURE_FROM_INVITATION_LINK',
  INVITATION_STRUCTURE_RESPONSE = 'INVITATION_STRUCTURE_RESPONSE',
  INVITATION_TEAM_EVENT = 'INVITATION_TEAM_EVENT',
  PARTICIPATION_EVENT = 'PARTICIPATION_EVENT',
  INVITATION_PARTICIPATION_EVENT = 'INVITATION_PARTICIPATION_EVENT',
  INVITATION_TEAM_PARTICIPATION_EVENT = 'INVITATION_TEAM_PARTICIPATION_EVENT',
  INVITATION_RESPONSE_ACCEPT = 'INVITATION_RESPONSE_ACCEPT',
  INVITATION_RESPONSE_DECLINE = 'INVITATION_RESPONSE_DECLINE',
  REVERT_EVENT_PLACE = 'REVERT_EVENT_PLACE',
  MAJ_DECLINE_EVENT = 'MAJ_DECLINE_EVENT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  EVENT_CANCELED = 'EVENT_CANCELED',
  EVENT_START = 'EVENT_START',
  EVENT_DELAYED = 'EVENT_DELAYED',
  EVENT_FINISHED = 'EVENT_FINISHED',
  EVENT_CANCEL_DELAYED = 'EVENT_CANCEL_DELAYED',
  EVENT_SUBSCRIPTION_CLOSE = 'EVENT_SUBSCRIPTION_CLOSE',
  PARTICIPATION_EVENT_ACCEPTED = 'PARTICIPATION_EVENT_ACCEPTED',
  PARTICIPATION_PAYMENT_REQUIRED = 'PARTICIPATION_PAYMENT_REQUIRED',
  PARTICIPATION_PAYMENT_RECEIVED = 'PARTICIPATION_PAYMENT_RECEIVED',
}
