import { Environment } from 'domain/environment/Environment';
import { EnvironmentRepository } from 'domain/environment/Environment.repository';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { SystemEnvironmentRepository } from 'secondary/environment/SystemEnvironment.repository';

const environmentRepository: EnvironmentRepository = new SystemEnvironmentRepository();
const environment: Environment = environmentRepository.get();

export const handleCopyLink = (link: InvitationLink) => () => {
  if (!link) return;
  const linkUrl = generateLink(link.uuid);
  navigator.clipboard.writeText(linkUrl).then(() => {
    sendEventToastMessage('Copié avec succès', 'success');
  });
};

export const generateLink = (link: string): string => {
  if (!link) return '';
  return `${environment.forntUrl}${routesConfig.homeWithInvitationLink.to(link)}`;
};
