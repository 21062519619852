import { Button, Hidden } from '@mui/material';
import classNames from 'classnames';
import { getNeonBorderClassName } from 'domain/profil/Profil.func';
import {
  selectConnectedUserBlocked,
  selectConnectedUserFriend,
} from 'domain/relation/store/RelationSlice';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { ActionButtonAddFriend } from 'primary/Components/Profils/User/ActionButton/ActionButtonAddFriend';
import { ActionButtonBlock } from 'primary/Components/Profils/User/ActionButton/ActionButtonBlock';
import { ActionButtonInviteStructure } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { ActionButtonSendMessage } from 'primary/Components/Profils/User/ActionButton/ActionButtonSendMessage';
import { LinkSocialNetwork } from 'primary/Components/SocialNetworks/LinkSocialNetwork';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserProfil } from '../../../../../domain/profil/UserProfil';
import { useTranslate } from '../../../../hooks/useTranslate';
import { socialNetworkList } from '../../../../network/network';
import { TeamCreatingAction } from '../TeamCreatingActions';

type ProfilNetworkPingProps = {
  profil: UserProfil;
};

export const useProfilPingBtn = (profil: UserProfil) => {
  const friends = useSelector(selectConnectedUserFriend);
  const block = useSelector(selectConnectedUserBlocked);
  const { authRepository } = useContextDependency();
  const navigate = useNavigate();
  const neonBorderClassName = getNeonBorderClassName(profil);
  const handleGoParam = () => {
    navigate(routesConfig.parametersUser.to());
  };
  const isDisabled = !authRepository?.currentUser?.userProfil.id;
  const isOwn = authRepository?.currentUser?.userProfil.id === profil.id;
  const conf: PopperButtonsConf[] = [
    {
      button: <ActionButtonAddFriend profil={profil} withIcon />,
    },
    {
      button: <ActionButtonSendMessage profil={profil} withIcon />,
    },
    {
      button: <ActionButtonInviteStructure profil={profil} withIcon />,
    },
    {
      button: <ActionButtonBlock profil={profil} withIcon />,
    },
  ];

  return {
    handleGoParam,
    isOwn,
    conf,
    neonBorderClassName,
    isDisabled,
  };
};

export const ProfilNetworkPing = ({ profil }: ProfilNetworkPingProps) => {
  const t = useTranslate();

  const { isDisabled, handleGoParam, isOwn, conf, neonBorderClassName } =
    useProfilPingBtn(profil);

  return (
    <div className={'userProfilNetworkPing'}>
      <div className={'-networks'}>
        {socialNetworkList.map((network) => (
          <LinkSocialNetwork
            key={network.name}
            network={network}
            value={profil[network.value]}
            big
            variant={'outlined'}
          />
        ))}
      </div>
      <Hidden smDown>
        <div className={'-ping'}>
          {isOwn && (
            <Button
              disabled={isDisabled}
              className={classNames('-pingBtn', neonBorderClassName)}
              onClick={handleGoParam}
            >
              <span>{t('profil.details.parameters')}</span>
            </Button>
          )}
          {!isOwn && (
            <div>
              <PopperButton
                disabled={isDisabled}
                conf={conf}
                popperName={'user-profil-preview'}
                placement={'right-start'}
                button={(togglePopper, ref) => (
                  <Button
                    ref={ref}
                    disabled={isDisabled}
                    className={classNames('-pingBtn', neonBorderClassName)}
                    onClick={togglePopper}
                  >
                    <span>{t('profil.details.pingBtn')}</span>
                    {isDisabled && (
                      <span className={'-notice'}>
                        {t('profil.details.notConnected')}
                      </span>
                    )}
                  </Button>
                )}
              />
              <TeamCreatingAction profil={profil} expanded />
            </div>
          )}
        </div>
      </Hidden>
    </div>
  );
};
