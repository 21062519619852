import './MembersStructure.scss';

import { Grid, ThemeProvider } from '@mui/material';
import { Structure } from 'domain/structure/Structure';
import {
  StructureMember,
  StructureMemberFilters,
} from 'domain/structureMember/StructureMember';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { LayoutList } from 'primary/Components/Layout/Lists/LayoutList';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import { PreviewCardProfilWithRole } from 'primary/Parameters/structure/Admin/PreviewCardProfilWithRole';
import { StructureMemberSwitchView } from 'primary/Structure/StructureMemberSwitchView';
import React from 'react';

import { themeParameters } from '../../parameters.helper';
import { useStructureParameters } from '../../user/useUserStructureParameters.hook';

type MembersStructureCompProps = {
  readonly?: boolean;
  onlyAccepted?: boolean;
  structure: Structure;
  withoutTitle?: boolean;
  onClickEditMember?: (member: StructureMember) => void;
};

export const MembersStructure = () => {
  const { structure, refrehStructure } = useStructureParameters();
  if (!structure) return null;
  return (
    <StructureMemberSwitchView
      structure={structure}
      readonly={false}
      onEditted={refrehStructure}
      withInfosRole
    />
  );
};

export const MembersStructureComp = ({
  readonly,
  structure,
  withoutTitle,
  onClickEditMember,
  onlyAccepted,
}: MembersStructureCompProps) => {
  const { structureMemberRepository, roleStructureRepository } = useContextDependency();

  const defaultFilters: StructureMemberFilters = {
    idStructure: structure.id.toString(),
    state: onlyAccepted ? StructureMemberState.ACCEPTED : undefined,
  };

  // @ts-ignore
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    changeNbPerPage,
    setFilters,
    retry,
  } = useFetchWithFilterPage<StructureMember, StructureMemberFilters>({
    keyStoreFilters: 'event',
    staticFilters: defaultFilters,
    filters: {},
    callApi: (filters, page, nbPerPage) =>
      structureMemberRepository.search(filters, page, nbPerPage),
  });

  const t = useTranslate();

  if (!structure) return null;

  return (
    <div className={'membersStructure'}>
      <ThemeProvider theme={themeParameters}>
        <div className={'membersStructure__list'}>
          <LayoutList
            opaq
            opaqBody
            className={'-layoutMembersStructure'}
            tablePagination
            resetFilters={() => setFilters({})}
            keyDomainFilter={'structureMember'}
            paginationProps={{
              goToPage: goToPage,
              currentPage: fetchedResource?.currentPage,
              numberOfItem: fetchedResource?.totalElementsCount,
              setRowPerPage: changeNbPerPage,
              numberOfItemPerPage: fetchedResource?.pageSize,
              pagesCount: fetchedResource?.pagesCount || 0,
            }}
            pagination
            listComponent={
              <ListElements
                loading={loading}
                empty={fetchedResource?.content.length === 0}
              >
                <Grid container>
                  {!loading &&
                    fetchedResource?.totalElementsCount !== 0 &&
                    fetchedResource?.content.map((member) => (
                      <PreviewCardProfilWithRole
                        readonly={readonly}
                        key={member.user.id}
                        className={'-item'}
                        profil={member.user.userProfil}
                        structure={structure}
                        userId={member.user.id}
                        onSubmitted={(member) => retry()}
                        role={member.role}
                        isTemp={member?.state !== StructureMemberState.ACCEPTED}
                        onClickEditMember={() =>
                          onClickEditMember && onClickEditMember(member)
                        }
                      />
                    ))}
                </Grid>
              </ListElements>
            }
            // @ts-ignore
            filtersTag={activeFilters?.filters}
            noTags
            onDeleteFilter={(filter) => {
              setFilters({ ...activeFilters?.filters, [filter.key]: undefined });
            }}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};
