import './PendingFriendsInvitation.scss';

import { Cancel } from '@mui/icons-material';
import { Box, Divider, IconButton } from '@mui/material';
import classNames from 'classnames';
import { Relation } from 'domain/relation/Relation';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ActionButtonsForContent } from 'primary/MessageList/contents/ActionButtonsForContent';
import {
  getOnClickResponseAddFriend,
  getOnClickSeeProfilFromAddFriend,
} from 'primary/MessageList/contents/NotificationInvitationFriendContent';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ModalPendingFriendInvitationProps = {
  isOpen?: boolean;
  loading?: boolean;
  handleClose: () => void;
  invitationList: Relation[];
  afterSubmit: () => void;
};

export const ModalPendingFriendInvitation = ({
  isOpen,
  handleClose,
  afterSubmit,
  invitationList,
  loading,
}: ModalPendingFriendInvitationProps) => {
  const t = useTranslate();
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={!!isOpen}
      content={
        <Box className={'modal-pending-friend-invitation'}>
          <div className={'-header'}>
            <h2>{t('modals.pendingFriendInvitation.title')}</h2>
            <IconButton onClick={() => handleClose()}>
              <Cancel />
            </IconButton>
          </div>
          <ListPendingFriendInvitation
            invitationList={invitationList}
            afterSubmit={afterSubmit}
            loadingFromButton={loading}
          />
        </Box>
      }
    />
  );
};

type ListPendingFriendInvitationProps = {
  invitationList: Relation[];
  afterSubmit: () => void;
  loadingFromButton?: boolean;
};

const ListPendingFriendInvitation = ({
  invitationList,
  afterSubmit,
  loadingFromButton,
}: ListPendingFriendInvitationProps) => {
  const [loading, setLoading] = useState(false);
  const { relationRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const navigate = useNavigate();
  return (
    <div className={'-listPendingFriendInvitation'}>
      {invitationList?.map((invitation) => {
        return (
          <div key={invitation.owner?.id} className={'-pendingFriendInvitation'}>
            <TagPlayer embed player={invitation.owner} />
            <Divider />
            <div className={'-infos'}>
              <div className={'-friendInfos'}>
                <KeyValue
                  className={'-margedRight'}
                  accent
                  keyValue={'Profil :'}
                  value={invitation.owner?.idPlayer}
                />
              </div>
              <div className={classNames('-playedGames', '--scrollBarContent')}>
                <KeyValue
                  className={'-wrappedWord'}
                  accent
                  keyValue={t('myProfil.gamesPlayed')}
                  value={
                    <>
                      {invitation.owner?.games
                        ?.map((gameProfil) => t(`games.libelle.${gameProfil.game.code}`))
                        .join(', ')}
                    </>
                  }
                />
              </div>
            </div>
            <Divider />
            <div className={'-footerActions'}>
              <ActionButtonsForContent
                loading={loadingFromButton || loading}
                onClickExitTo={getOnClickSeeProfilFromAddFriend(
                  navigate,
                  invitation.owner.id as number,
                )}
                onClickCancel={getOnClickResponseAddFriend(
                  relationRepository,
                  invitation.id as number,
                  t,
                  false,
                  afterSubmit,
                  setLoading,
                )}
                onClickValid={getOnClickResponseAddFriend(
                  relationRepository,
                  invitation.id as number,
                  t,
                  true,
                  afterSubmit,
                  setLoading,
                )}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
