import { Button, Divider } from '@mui/material';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { EventCard } from 'primary/Components/Event/EventCard';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
interface ConsumeEventTeamInvitationLinkProps {
  link: InvitationLink;
  handleClose: () => void;
  nextStep: (data?: any) => void;
  data: { member: EventParticipant };
}
export const ConsumeEventTeamInvitationLink = ({
  link,
  handleClose,
  nextStep,
}: ConsumeEventTeamInvitationLinkProps) => {
  const {
    authRepository,
    invitationRepository,
    eventRepository,
    eventParticipantRepository,
  } = useContextDependency();
  const t = useTranslate();
  const navigate = useNavigate();
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const [eventParticipant, , fetching] = useRetrieveFromDomain(
    () => eventParticipantRepository.getEventParticipantByTeamPlayerId(link.idRessource),
    undefined,
    !!link.idRessource,
  );
  const alreadyInTeam = eventParticipant?.team.players.find(
    (player) => player?.player?.id === authRepository.currentUser?.userProfil?.id,
  );

  const alreadyCreating = useRef(false);

  useEffect(() => {
    if (alreadyCreating.current) return;
    if (authRepository.currentUser && !fetching && !alreadyInTeam) {
      alreadyCreating.current = true;
      eventParticipantRepository.updateParticipantFromLink(link.id).then((member) => {
        handleClose();
        navigate(routesConfig.activities.to(), {
          state: {
            openInviteTeamPending: true,
          },
        });
      });
    } else if (!!authRepository?.currentUser && !!alreadyInTeam) {
      alreadyCreating.current = true;
      handleClose();
      navigate(
        routesConfig.eventViewDetails.eventViewPlayersTeamOpen.to(
          link.idRessource,
          eventParticipant?.id,
        ),
      );
    }
  }, [authRepository.currentUser, eventParticipant, alreadyInTeam, fetching]);

  const handleSubscribe = () => {
    authRepository.register(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  const handleLogin = () => {
    authRepository.login(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  return (
    <div className={'consumeEventTeamInvitationLink'}>
      <h4>
        {
          //@ts-ignore
          t('invitationLink.consumedModal.eventTeam.title', {
            teamName: eventParticipant?.team.name,
            eventName: eventParticipant?.event.name,
          })
        }
      </h4>
      {fetching && !eventParticipant && <LoadingScreen loading />}
      {!fetching && eventParticipant && (
        <div className={'-infosEvent'}>
          <div className={'-cardProfil'}>
            <EventCard event={eventParticipant.event} />
          </div>
          <div className={'-teamInfos'}>
            <div className={'-title'}>
              <h4>Team {eventParticipant?.team?.name}</h4>
            </div>
            <div className={'-players'}>
              {eventParticipant?.team.players.map((player) => (
                <TagPlayer key={player?.player?.idPlayer} player={player.player} embed />
              ))}
            </div>
          </div>
        </div>
      )}
      <Divider />
      {!isAccountLoaded && <LoadingScreen loading message={'Récupération du compte'} />}
      {isAccountLoaded && !authRepository?.currentUser && (
        <div className={'-actions'}>
          <span>Vous devez être connecté pour continuer</span>
          <div className={'-btnContainer'}>
            <Button
              onClick={handleLogin}
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              {t('invitationLink.consumeModal.steps.account.loginBtn')}
            </Button>
            <Button
              onClick={handleSubscribe}
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              {t('invitationLink.consumeModal.steps.account.registerBtn')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
