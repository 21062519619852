import './ModalConsumeLinkInvitation.scss';

import { Cancel } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { InvitationLinkTypeEnum } from 'domain/invitationLink/InvitationLinkTypeEnum';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ConsumeEventInvitationLink } from 'primary/InvitationLink/Modal/Steps/ConsumeEventInvitationLink';
import { ConsumeEventTeamInvitationLink } from 'primary/InvitationLink/Modal/Steps/ConsumeEventTeamInvitationLink';
import { ConsumeStructureInvitationLink } from 'primary/InvitationLink/Modal/Steps/ConsumeStructureInvitationLink';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'store';

export const ComponentByType = {
  [InvitationLinkTypeEnum.EVENT]: ConsumeEventInvitationLink,
  [InvitationLinkTypeEnum.STRUCTURE]: ConsumeStructureInvitationLink,
  [InvitationLinkTypeEnum.TEAM_EVENT]: ConsumeEventTeamInvitationLink,
};

export const ModalConsumeLinkInvitation = () => {
  const [searchParams] = useSearchParams();
  const [link, setLink] = useState<string | undefined>();
  const { invitationLinkRepository, authRepository } = useContextDependency();
  const [forceClose, setForceClose] = useState(false);
  const t = useTranslate();

  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const [linkFetched, , fetching] = useRetrieveFromDomain(
    () => invitationLinkRepository.getByUuid(link as string),
    undefined,
    !!link,
  );
  useEffect(() => {
    const uuid = searchParams?.get('invitationLink');
    if (uuid && !link) {
      setLink(uuid);
    }
  }, [searchParams]);
  //@ts-ignore
  const Component = ComponentByType[linkFetched?.typeInvitationLink];

  console.log('linkFetched', linkFetched);

  return (
    <ModalContainer
      handleClose={() => setForceClose(true)}
      isOpen={!!link && !forceClose}
      content={
        <Box className={'modalConsumeLinkInvitation'}>
          {!linkFetched && (
            <LoadingScreen loading message={"Récupération du lien d'invitation"} />
          )}
          <div className={'modalConsumeLinkInvitation__header'}>
            <h2>
              {linkFetched &&
                t(
                  `invitationLink.consumeModal.${linkFetched?.typeInvitationLink}.titleModal`,
                )}
            </h2>
            <IconButton onClick={() => setForceClose(true)} className={'-close'}>
              <Cancel />
            </IconButton>
          </div>
          <div className={'modalConsumeLinkInvitation__content'}>
            {linkFetched && Component && (
              <Component link={linkFetched} handleClose={() => setForceClose(true)} />
            )}
          </div>
          {!fetching && !linkFetched && (
            <span>{t('invitationLink.consumeModal.linkNotFound')}</span>
          )}
        </Box>
      }
    />
  );
};
