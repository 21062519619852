import './Mercato.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { LayoutPageListFitlered } from 'primary/Components/Layout/Pages/LayoutPageListFiltered';
import { UnpyTabs } from 'primary/Components/Tabs/UnpyTabs';
import { EVENT_OPEN_CREATE_STRUCTURE } from 'primary/Components/User/LoggedInUserAvatarMenu';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { useSelectionGameListPage } from 'primary/Mercato/Mercato.helper';
import { StructureProfilList } from 'primary/ProfilList/StructureProfilList';
import { UserProfilList } from 'primary/ProfilList/UserProfilList';
import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';

import { Section } from '../Components/Section/Section';

export const MercatoPage: FC = () => {
  return (
    <Grid className={classNames('mercato')}>
      <Section className={'mercato__content'}>
        <div className={'-backgroundList'}></div>
        <Outlet />
      </Section>
    </Grid>
  );
};

export const MercatoPageRefonte: FC = () => {
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const { selectedGamesCode, handleSelection } =
    useSelectionGameListPage('mercatoPlayer');
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const refToSearch = useRef<HTMLDivElement | null>(null);
  const goToRef = () =>
    refToSearch?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const tabsConf = [
    {
      label: t(`mercatoTabs.players`),
      component: (
        <UserProfilList
          gamesSelected={selectedGamesCode}
          filters={
            teamCreating
              ? {
                  games: selectedGamesCode,
                  idStructure: teamCreating?.data?.structure,
                }
              : undefined
          }
        />
      ),
      to: routesConfig.mercatoSubPath.mercatoPlayer.to,
    },
    {
      label: t(`mercatoTabs.structures`),
      component: (
        <StructureProfilList
          keyDomainFilter={'mercatoStructure'}
          gamesSelected={selectedGamesCode}
        />
      ),
      to: routesConfig.mercatoSubPath.mercatoStructure.to,
    },
  ];

  return (
    <Grid className={classNames('mercato')}>
      <div className={'-diagonal-effect'}></div>
      <div className={'-backgroundFixed'}></div>
      <h1 className={'-absoluteTitle'}>
        {t('mercato.absoluteTitle')} <br />
        <small>STRUCTURES & JOUEURS</small>
      </h1>
      <div className={'explicationBlock'}>
        <div className={'explicationBlock__title'}></div>
        <div className={'explicationBlock__explanation'}>
          <UnpyTabs
            center
            tabsConf={[
              {
                label: 'Joueurs',
                component: (
                  <div className={'explanation-content'}>
                    <h2>Trouvez vos futures mates !</h2>
                    <p>
                      Utilisez le mercato pour agrandir votre réseau de joueurs et de
                      coéquipiers portentiels pour vous inscrire aux événements
                    </p>
                    <ul>
                      <li>
                        Demandez d'autres joueurs en amis pour les retrouver plus vite
                      </li>
                      <li>Discutez directement avec les joueurs qui le permettent !</li>
                      <li>
                        Découvrez le profil e-sport d'autres joueurs incluant leurs
                        performances, une vidéo promotionelle et d'autres détails utiles
                      </li>
                    </ul>
                    <div className={'containerButton'}>
                      {isAccountLoaded && !authRepository?.currentUser && (
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          onClick={() => {
                            authRepository.register(
                              window.location.origin + window.location.pathname,
                            );
                          }}
                        >
                          Créer mon profil
                        </Button>
                      )}
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          navigate(routesConfig.mercatoSubPath.mercatoPlayer.to());
                          goToRef();
                        }}
                      >
                        Rechercher des joueurs
                      </Button>
                    </div>
                  </div>
                ),
              },
              {
                label: 'Structures',
                component: (
                  <div className={'explanation-content'}>
                    <h2>Structures e-sport amateurs et professionnels</h2>
                    <p>
                      Créez ou rejoignez une structure e-sports niveau amateur ou
                      professionnel.
                    </p>
                    <ul>
                      <li>
                        Vous pouvez participer aux événements au nom d'une structure
                      </li>
                      <li>
                        Créez ou rejoignez une organisation composée de manageurs,
                        joueurs, et autres rôles clés pour progresser
                      </li>
                    </ul>
                    <div className={'containerButton'}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          if (authRepository?.currentUser) {
                            const event = new CustomEvent(EVENT_OPEN_CREATE_STRUCTURE);
                            window.document.dispatchEvent(event);
                          } else {
                            authRepository.login(
                              window.location.origin +
                                window.location.pathname +
                                '?createStructure=true',
                            );
                          }
                        }}
                      >
                        Créer une structure
                      </Button>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          navigate(routesConfig.mercatoSubPath.mercatoStructure.to());
                          goToRef();
                        }}
                      >
                        Rechercher des structures
                      </Button>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <Section className={'mercato__content'}>
        <LayoutPageListFitlered
          listContent={
            <div className={'-content'} ref={refToSearch}>
              <UnpyTabs background={'mercato'} tabsConf={tabsConf} fonced center />
            </div>
          }
        />
      </Section>
    </Grid>
  );
};
