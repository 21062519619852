import './Helpers.scss';

import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { HelperPopper } from 'primary/helpers/HelperPopper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

export const FreeRegisterHelper = () => {
  const t = useTranslate();
  return (
    <HelperPopper color={'white'}>
      <div className={'helpers -helperTypeEvent'}>
        <KeyValue
          accent
          alignStart
          keyValue={t('helpers.events.freeRegister.freeKey')}
          value={t('helpers.events.freeRegister.free')}
        />
        <KeyValue
          accent
          alignStart
          keyValue={t(`helpers.events.freeRegister.notFreeKey`)}
          value={t('helpers.events.freeRegister.notFree')}
        />
      </div>
    </HelperPopper>
  );
};
