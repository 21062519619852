import { Edit, ExitToApp, Remove } from '@mui/icons-material';
import { Button, Hidden, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import classNames from 'classnames';
import {
  StructureMember,
  StructureMemberFilters,
} from 'domain/structureMember/StructureMember';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { Translate } from 'domain/translation/Translation.repository';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { UseFetchWithFilterPageReturn } from 'primary/hooks/useFetchWithFilterPage';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TeamsViewDatatableParametersProps {
  members?: StructureMember[];
  className?: string;
  classNameDatatable?: string;
  readonly?: boolean;
  onClickEditMember?: (member: StructureMember) => void;
  onClickDeleteMember?: (member: StructureMember) => void;
  canDelete?: boolean;
  useFetchReturn: UseFetchWithFilterPageReturn<StructureMember, StructureMemberFilters>;
}

const formatMemberToRowData = (members: StructureMember[], t: Translate) => {
  return members.map((member) => ({
    id: member.user.userProfil.id,
    idPlayer: member.user.userProfil.idPlayer,
    role: t(`structure.roles.${member.role.code}`),
    meta: member,
    status:
      member.state === StructureMemberState.ACCEPTED
        ? t('structure.members.datatable.statusAccepted')
        : t(`structure.members.datatable.statusPending.${member.state}`),
    actions: member,
  }));
};

export const StructureMembersDatatable = ({
  members,
  className,
  classNameDatatable,
  readonly,
  onClickEditMember,
  onClickDeleteMember,
  useFetchReturn,
}: TeamsViewDatatableParametersProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { authRepository } = useContextDependency();
  const columns: GridColDef[] = [
    {
      field: 'idPlayer',
      headerName: t('structure.members.datatable.idPlayerLabel'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'role',
      headerName: t('structure.members.datatable.roleLabel'),
      flex: 2,
      headerAlign: 'center',
      headerClassName: '-header',
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('structure.members.datatable.statusMemberLabel'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: t('structure.members.datatable.actionLabel'),
      flex: 2,
      headerAlign: 'center',
      sortable: false,
      renderCell: (value: GridRenderCellParams<StructureMember>) => {
        if (!value.value) return;
        if (value.value.state === StructureMemberState.ACCEPTED) {
          return (
            <>
              <Button
                color={'primary'}
                variant={'contained'}
                size={'small'}
                onClick={() => {
                  navigate(
                    routesConfig.userProfil.to(value?.value?.user?.userProfil?.id),
                  );
                }}
              >
                <ExitToApp />
                <Hidden smDown>{t('structure.members.datatable.seeProfil')}</Hidden>
              </Button>
              {!readonly && (
                <Button
                  size={'small'}
                  color={'secondary'}
                  variant={'contained'}
                  onClick={() => {
                    onClickEditMember &&
                      onClickEditMember(value.value as StructureMember);
                  }}
                >
                  <Edit />
                  {t('structure.members.datatable.editRole')}
                </Button>
              )}
              {!readonly &&
                value?.value?.user?.id != authRepository.currentUser?.id &&
                members?.find((m) => m.user.id === authRepository.currentUser?.id)?.role
                  .code === 'PRES' && (
                  <Button
                    size={'small'}
                    color={'secondary'}
                    variant={'contained'}
                    onClick={() => {
                      onClickDeleteMember &&
                        onClickDeleteMember(value.value as StructureMember);
                    }}
                  >
                    <Remove />
                    {t('structure.members.datatable.deleteMember')}
                  </Button>
                )}
            </>
          );
        } else if (
          [
            StructureMemberState.WAITING_JOIN_ACCEPTED,
            StructureMemberState.WAITINT_LINK_ACCEPTED,
          ].includes(value.value.state)
        ) {
          return (
            <>
              <Button color={'primary'} variant={'contained'} size={'small'}>
                <Hidden smDown>{t('structure.members.datatable.accept')}</Hidden>
              </Button>
              <Button color={'primary'} variant={'outlined'} size={'small'}>
                <Hidden smDown>{t('structure.members.datatable.decline')}</Hidden>
              </Button>
            </>
          );
        } else {
          return (
            <>
              <span>{t('structure.members.datatable.inviteWaitingResponse')}</span>;
              <Button
                size={'small'}
                color={'secondary'}
                variant={'contained'}
                onClick={() => {
                  onClickDeleteMember &&
                    onClickDeleteMember(value.value as StructureMember);
                }}
              >
                <Remove />
                {t('structure.members.datatable.cancelInvitation')}
              </Button>
            </>
          );
        }
      },
    },
  ];

  return (
    <div className={classNames('structureMembersDatatable', className)}>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableEval
        disableVirtualization
        classes={{
          root: classNames('structureMembersDatatable__datatable', classNameDatatable),
        }}
        pagination
        paginationMode={'server'}
        rowCount={useFetchReturn.fetchedResource?.totalElementsCount}
        paginationModel={{
          pageSize: useFetchReturn.fetchedResource?.pageSize || 0,
          page: useFetchReturn.fetchedResource?.currentPage || 0,
        }}
        onPaginationModelChange={({ pageSize, page }) => {
          if (pageSize !== useFetchReturn.fetchedResource?.pageSize) {
            useFetchReturn.changeNbPerPage(pageSize);
          }
          useFetchReturn.goToPage(page);
        }}
        loading={useFetchReturn.loading}
        className={'-parameters'}
        getRowId={(row) => row.idPlayer}
        autoHeight
        sx={{ color: 'white' }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {t('structure.members.datatable.noContent')}
            </Stack>
          ),
        }}
        rows={
          useFetchReturn.fetchedResource
            ? formatMemberToRowData(useFetchReturn.fetchedResource.content, t)
            : []
        }
        columns={columns}
      />
    </div>
  );
};
