import { EventBalance } from 'domain/balance/EventBalance';
import {
  RestEventParticipant,
  toEventParticipant,
} from 'secondary/event/participants/RestEventParticipant';

export interface RestEventBalance {
  waitingParticipantForCashout: RestEventParticipant[];
  readyParticipantForCashout: RestEventParticipant[];
}

export function toEventBalance(restEventBalance: RestEventBalance) {
  return new EventBalance(
    restEventBalance.waitingParticipantForCashout.map(toEventParticipant),
    restEventBalance.readyParticipantForCashout.map(toEventParticipant),
  );
}
