import '../MercatoFilters.scss';

import { FilterAltOff } from '@mui/icons-material';
import { Button, Hidden } from '@mui/material';
import { selectFiltersByDomain } from 'domain/filters/FiltersSlice';
import { UserProfilFilters } from 'domain/profil/UserProfil';
import { FilterCountry } from 'primary/Components/Filters/FilterCountry';
import { ModalFitlers } from 'primary/Components/Filters/ModalFilters';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { FilterExperience } from '../FilterExperiences';
import { FilterPlatform } from '../FilterPlatform';
import { FilterTalkLang } from '../FilterTalkLang';
import { AGE_CRIT, FilterAge } from './FilterAge';

interface MercatoPlayerFiltersProps {
  setFilters: (filters: UserProfilFilters) => void;
  activeFilters: UserProfilFilters;
}

export const MercatoPlayerFilters = ({
  activeFilters,
  setFilters,
}: MercatoPlayerFiltersProps) => {
  return (
    <>
      <Hidden smUp>
        <MercatoPlayerFiltersMobile
          setFilters={setFilters}
          activeFilters={activeFilters}
        />
      </Hidden>

      <Hidden smDown>
        <MercatoPlayerFiltersComp setFilters={setFilters} activeFilters={activeFilters} />
      </Hidden>
    </>
  );
};

const MercatoPlayerFiltersMobile = ({
  activeFilters,
  setFilters,
}: MercatoPlayerFiltersProps) => {
  const t = useTranslate();
  return (
    <ModalFitlers
      title={t('mercato.filters.titlePlayer')}
      resetFilters={() => setFilters?.({})}
      filtersComp={
        <MercatoPlayerFiltersComp setFilters={setFilters} activeFilters={activeFilters} />
      }
    />
  );
};

export const MercatoPlayerFiltersComp = ({
  activeFilters,
  setFilters,
}: MercatoPlayerFiltersProps) => {
  const t = useTranslate();

  // @ts-ignore
  const activeFiltersStore: UserProfilFilters = useSelector((state: RootState) =>
    selectFiltersByDomain(state, 'mercatoPlayer'),
  );
  const onChangeLangs = (values: string[]) => {
    setFilters({
      ...activeFiltersStore,
      langs: values,
    });
  };

  const onChangeAge = (filterType: AGE_CRIT, value?: number) => {
    if (filterType === 'MAX_AGE') {
      setFilters({
        ...activeFiltersStore,
        ['maxAge']: value,
        ['minAge']: undefined,
      });
    }
    if (filterType === 'MIN_AGE') {
      setFilters({
        ...activeFiltersStore,
        ['maxAge']: undefined,
        ['minAge']: value,
      });
    }
  };

  const onChangeExperience = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      experiences: value ? [value] : undefined,
    });
  };

  const onChangePlatform = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      platforms: value ? [value] : undefined,
    });
  };

  const onChangeCountry = (value?: string) => {
    setFilters({
      ...activeFiltersStore,
      country: value,
    });
  };

  return (
    <div className={'mercatoFilters'}>
      <div className={'-filtersInputs --scrollBarContent'}>
        <FilterTalkLang
          selectedLangs={activeFiltersStore?.langs ? activeFiltersStore.langs : []}
          onSelectLang={onChangeLangs}
        />
        <FilterAge
          selectedAge={
            activeFiltersStore.minAge
              ? {
                  value: activeFiltersStore.minAge,
                  type: 'MIN_AGE',
                }
              : {
                  value: activeFiltersStore.maxAge,
                  type: 'MAX_AGE',
                }
          }
          onSelectAge={onChangeAge}
        />
        <div>
          <FilterPlatform
            selectedPlatform={
              activeFiltersStore.platforms ? activeFiltersStore.platforms[0] : undefined
            }
            onSelectPlatform={onChangePlatform}
          />
        </div>
        <div>
          <FilterExperience
            selectedExperience={
              activeFiltersStore.experiences
                ? activeFiltersStore.experiences[0]
                : undefined
            }
            onSelectExperience={onChangeExperience}
          />
        </div>
        <div>
          <FilterCountry
            selectedCountry={
              activeFiltersStore.country ? activeFiltersStore.country : undefined
            }
            onSelectCountry={onChangeCountry}
          />
        </div>
      </div>
      <Hidden smDown>
        <Button onClick={() => setFilters?.({})} color={'primary'}>
          <FilterAltOff width={20} />
        </Button>
      </Hidden>
    </div>
  );
};
