import { Environment } from 'domain/environment/Environment';
import { EnvironmentRepository } from 'domain/environment/Environment.repository';

export class SystemEnvironmentRepository implements EnvironmentRepository {
  get(): Environment {
    return new Environment(
      import.meta.env.VITE_API_WEBSERVICE_URL ?? 'https://preprod-api.unpy.fr/',
      import.meta.env.VITE_KEYCLOAK_URL ?? 'https://auth.preprod.unpy.fr',
      import.meta.env.VITE_KEYCLOAK_REALM ?? 'unpy',
      import.meta.env.VITE_KEYCLOAK_CLIENT_ID ?? 'unpy-front',
      import.meta.env.VITE_SOCKET_URL ?? 'ws://localhost:5000/ws-unpy',
      import.meta.env.VITE_STRIPE_KEY ??
        'pk_test_51MgCtWHdWfj7XXHBUSIE9DOhyidOaFxSuKfmjHUPTh058iLkyzD3ysgdcA8EmCR4MSw16O02nTZbsqpgxeW9muPa00CmEE6vtT',
      import.meta.env.VITE_FRONT_URL ?? 'http://localhost:3000',
      import.meta.env.VITE_SENTRY_AUTH_TOKEN ??
        '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',
    );
  }
}
