import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Annonce } from 'domain/annonce/Annonce';
import { AnnonceTypeEnum } from 'domain/annonce/AnnonceTypeEnum';
import { ModalCreateEditAnnonce } from 'primary/annonce/Modal/ModalCreateEditAnnonce';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

interface ActionButtonCreateEditAnnonceProps {
  withIcon?: boolean;
  eventId?: string;
  structureId?: string;
  annonceToEdit?: Annonce;
  type: AnnonceTypeEnum;
  onSubmitted: (annonce: Annonce) => void;
}

export const ActionButtonCreateEditAnnonce = ({
  withIcon,
  eventId,
  structureId,
  annonceToEdit,
  type,
  onSubmitted,
}: ActionButtonCreateEditAnnonceProps) => {
  const [isOpen, toggleOpen, openModal, closeModal] = useBooleanToggle();
  const t = useTranslate();
  return (
    <>
      {(eventId || structureId) && (
        <ModalCreateEditAnnonce
          isOpen={isOpen}
          handleClose={closeModal}
          eventId={eventId}
          structureId={structureId}
          annonceToEdit={annonceToEdit}
          type={type}
          onSubmitted={onSubmitted}
        />
      )}
      <Button
        color={'primary'}
        variant={'contained'}
        className={'-actionButtonAddAnnonce'}
        onClick={openModal}
      >
        {withIcon && <Add />}
        {t('annonce.addAnnonce')}
      </Button>
    </>
  );
};
