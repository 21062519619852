import './EventFilters.scss';

import { InputSwitchTag } from 'primary/Components/Input/InputSwitchTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterPlaceAvailableProps = {
  onSelectPlaceAvailable: (value?: string) => void;
  selectedPlaceAvailable?: string;
};

export const FilterPlaceAvailable = ({
  onSelectPlaceAvailable,
  selectedPlaceAvailable,
}: FilterPlaceAvailableProps) => {
  const t = useTranslate();
  return (
    <InputSwitchTag
      value={selectedPlaceAvailable}
      label={t('event.filters.placeAvailable')}
      placeholder={t('event.filters.placeAvailable')}
      onChange={(event: ChangeHandlerTypeMeta) => {
        // @ts-ignore
        onSelectPlaceAvailable(event?.target?.value?.toString());
      }}
    />
  );
};
