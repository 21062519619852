import './CustomMatch.scss';

import { Edit } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import classNames from 'classnames';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { MatchComponentProps } from 'primary/Components/bracket/CustomBracket/types';
import { IconPodiumByIndex } from 'primary/events/components/PodiumView';
import { ellipsis } from 'primary/helpers/String.helper';
import React from 'react';

import { useTranslate } from '../../hooks/useTranslate';

type CustomMatchProps = MatchComponentProps & {
  className?: string;
  isEditable?: boolean;
  fullWidth?: boolean;
  guetteredTop?: boolean;
  noBorder?: boolean;
  noRadius?: boolean;
  guetteredBottom?: boolean;
  openEditScore?: () => void;
  typeEvent: TypeEventCodeEnum;
};

export const CustomMatch = ({
  guetteredTop,
  noBorder,
  noRadius,
  guetteredBottom,
  bottomHovered,
  fullWidth,
  bottomParty,
  bottomText,
  bottomWon,
  match,
  onMatchClick,
  onMouseEnter,
  onMouseLeave,
  onPartyClick,
  topHovered,
  topParty,
  topText,
  topWon,
  isEditable,
  openEditScore,
  typeEvent,
  className,
}: CustomMatchProps) => {
  const t = useTranslate();

  const isParticipant1Win =
    topParty?.score && bottomParty?.score && topParty?.score > bottomParty?.score;
  const isParticipant2Win =
    bottomParty?.score && topParty?.score && bottomParty?.score > topParty?.score;

  return (
    <div
      className={classNames('custom-match', className, {
        '-isEditable': isEditable,
        '-fullWidth': fullWidth,
        '-guettered--top': guetteredTop,
        '-guettered--bottom': guetteredBottom,
        '-noBorder': noBorder,
        '-noRadius': noRadius,
      })}
    >
      {match.startTime && <div className={'-header'}>{match.startTime}</div>}
      <div className={'custom-match__match'}>
        <div className={'-playersContainer'}>
          <div
            className={classNames('custom-match__match-player', {
              '--winner': topWon,
              '--looser': bottomWon || topParty?.isForfait,
              '--hovered': topHovered,
            })}
            onMouseEnter={() => {
              onMouseEnter(topParty.id);
            }}
            onMouseLeave={onMouseLeave}
            onClick={() => onPartyClick?.(topParty, topWon)}
          >
            <div className={'nameContainer'}>
              <span className={'-name'}>{ellipsis(20, topParty?.name)}</span>
            </div>
            <div className={'scoreContainer'}>
              <span className={'-score'}>
                {typeEvent === 'LIGUE'
                  ? topParty?.score
                  : topParty?.score
                  ? t(
                      `classement.abreviationResult.${
                        isParticipant1Win ? 'WIN' : 'LOOSE'
                      }`,
                    )
                  : ''}
              </span>
            </div>
            <div className={'resultContainer'}>
              <span className={'-result'}>
                {(topParty.score || 0) > (bottomParty.score || 0) && IconPodiumByIndex[0]}
              </span>
            </div>
          </div>
          <Divider
            className={classNames('-divider', { hovering: topHovered || bottomHovered })}
          />
          <div
            className={classNames('custom-match__match-player', {
              '--winner': bottomWon,
              '--looser': topWon || bottomParty.isForfait,
              '--hovered': bottomHovered,
            })}
            onMouseEnter={() => onMouseEnter(bottomParty.id)}
            onMouseLeave={onMouseLeave}
            onClick={() => onPartyClick?.(bottomParty, bottomWon)}
          >
            <div className={'nameContainer'}>
              <span className={'-name'}>{ellipsis(20, bottomParty?.name)}</span>
            </div>
            <div className={'scoreContainer'}>
              <span className={'-score'}>
                {typeEvent === 'LIGUE'
                  ? bottomParty?.score
                  : bottomParty?.score
                  ? t(
                      `classement.abreviationResult.${
                        isParticipant2Win ? 'WIN' : 'LOOSE'
                      }`,
                    )
                  : ''}
              </span>
            </div>
            <div className={'resultContainer'}>
              <span className={'-result'}>
                {(bottomParty.score || 0) > (topParty.score || 0) && IconPodiumByIndex[0]}
              </span>
            </div>
          </div>
        </div>
        {isEditable && openEditScore && (
          <div className={'-editBtn'}>
            <Button
              color={'primary'}
              variant={'text'}
              onClick={openEditScore}
              disabled={!isEditable}
            >
              <Edit />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
