import { Neon, NeonClassNameByCode } from 'domain/neon/Neon';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { Profil } from 'domain/profil/Profil';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { UserProfil } from 'domain/profil/UserProfil';
import { UserProfilView } from 'domain/user/UserProfilView';

export const getNeonBorderClassName = (profil?: Profil | UserProfilView | null) => {
  return `borderNeons borderNeons__${
    profil?.neon?.code ? NeonClassNameByCode[profil.neon.code] : 'default'
  }`;
};

export const getNeonBorderClassNameByNeon = (neon: Neon) => {
  return `borderNeons borderNeons__${
    neon?.code ? NeonClassNameByCode[neon.code] : 'default'
  }`;
};

export const getNeonVariant = (neon?: Neon) => {
  if (neon?.code) return NeonClassNameByCode[neon.code];
  else return undefined;
};

export const isUserProfil = (
  profil: UserProfil | StructureProfil,
): profil is UserProfil => {
  return Object.hasOwn(profil, 'idPlayer');
};

export const isStructureProfil = (
  profil: StructureProfil | UserProfil,
): profil is StructureProfil => {
  return Object.hasOwn(profil, 'structure');
};

export const getName = (profil: Profil) => {
  if (isStructureProfil(profil)) {
    return profil.structure?.name;
  } else if (isUserProfil(profil)) {
    return profil.idPlayer;
  }
  return '';
};

export const isPrivacyRestricted = (profil: Profil, privacy: PRIVACY_CODE): boolean => {
  return !profil.privacies?.find((p) => p.code === privacy)?.isChecked;
};
