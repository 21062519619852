import { PlayerStatusEnum } from 'domain/event/PlayerStatusEnum';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { UserProfilView } from 'domain/user/UserProfilView';

import { Team } from '../team/Team';
import { PlayerRoleEnum } from './PlayerRoleEnum';

export type PlayerStatus = 'ACCEPTED' | 'DECLINED' | 'PENDING';

export class PlayerTeam {
  constructor(
    public readonly team: Team | null,
    public readonly player: UserProfilView | null,
    public readonly role: PlayerRoleEnum,
    public readonly status: PlayerStatusEnum,
    public readonly invitationLink: InvitationLink | null,
  ) {}
}
