import './LayoutList.scss';

import { FilterAlt } from '@mui/icons-material';
import { Chip, Hidden, Pagination, TablePagination } from '@mui/material';
import classNames from 'classnames';
import { isValid, parse } from 'date-fns';
import {
  KeyDomainFilters,
  selectIsPanelOpenByDomain,
  setPanelOpenByDomain,
} from 'domain/filters/FiltersSlice';
import { TranslationType } from 'domain/translation/Translation.repository';
import { formatDate } from 'helper/date.helper';
import { isArray } from 'lodash';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import {
  ANIMATION_BUMP_LEFT_PANEL,
  LeftPanel,
  useLocalStorageState,
} from '../../LeftPanel/LeftPanel';

type FetchedResourcesPaginatedProps = {
  currentPage?: number;
  pagesCount?: number;
  goToPage: (page: number) => void;
  numberOfItem?: number;
  numberOfItemPerPage?: number;
  setRowPerPage: (value: number) => void;
};

type LeftPanelProps = {
  title: string;
  component: ReactNode;
  initialOpen?: boolean;
};

type Filters<T> = {
  [key in Partial<keyof T>]: string | number | string[] | undefined;
};

export type LayoutListProps<T extends Filters<T>> = {
  transparent?: boolean;
  withoutBorder?: boolean;
  withoutBottomMarged?: boolean;
  searchTextComponent?: ReactNode;
  gamesComponent?: ReactNode;
  pagination?: boolean;
  activeFilters?: string;
  paginationProps: FetchedResourcesPaginatedProps;
  listComponent: ReactNode;
  leftPanelProps?: LeftPanelProps;
  filtersTag?: T;
  keyFiltersWithoutTag?: (keyof T)[];
  onDeleteFilter?: (filter: TagFilter<T>) => void;
  topPagination?: boolean;
  noTags?: boolean;
  listAlignStart?: boolean;
  resetFilters?: () => void;
  keyDomainFilter: KeyDomainFilters;
  title?: string | ReactNode;
  tablePagination?: boolean;
  className?: string;
  opaq?: boolean;
  opaqBody?: boolean;
  filtersBarComponent?: ReactNode;
  secondaryColor?: 'mercato' | 'event';
};

type TagFilter<T> = { key: Partial<keyof T>; value: string | undefined };

export function LayoutList<T extends Filters<T>>({
  pagination,
  searchTextComponent,
  gamesComponent,
  paginationProps,
  listComponent,
  leftPanelProps,
  filtersTag,
  onDeleteFilter,
  topPagination,
  noTags,
  listAlignStart,
  keyFiltersWithoutTag,
  resetFilters,
  title,
  keyDomainFilter,
  tablePagination,
  className,
  transparent,
  withoutBorder,
  withoutBottomMarged,
  opaq,
  opaqBody,
  filtersBarComponent,
  secondaryColor,
}: LayoutListProps<T>) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useLocalStorageState('unpy-leftpanel');
  const isPanelOpen = useSelector((state: RootState) =>
    selectIsPanelOpenByDomain(state, keyDomainFilter),
  );
  const { countryRepository } = useContextDependency();
  const [countries] = useFetch(() => countryRepository.getAll(), undefined);
  const toggleIsOpen = () => {
    if (isPanelOpen) {
      document.dispatchEvent(new CustomEvent(ANIMATION_BUMP_LEFT_PANEL));
    } else {
      // @ts-ignore
      setIsOpen(true);
      dispatch(setPanelOpenByDomain({ key: keyDomainFilter, isOpen: true }));
    }
  };

  const tagParsed: TagFilter<T>[] | undefined = useMemo(
    () =>
      filtersTag &&
      Object.keys(filtersTag)
        .filter((val) => !keyFiltersWithoutTag?.includes(val as keyof T))
        .map((val) => {
          const valParsed = val as keyof T;
          const dateParsed = parse(
            filtersTag[valParsed] as string,
            'dd/MM/yyyy:hh:mm',
            new Date(),
          );
          if (
            !filtersTag[valParsed] ||
            (isArray(filtersTag[valParsed]) &&
              (filtersTag[valParsed] as string[]).length === 0)
          ) {
            return {
              key: valParsed,
              value: undefined,
            };
          } else if (isArray(filtersTag[valParsed])) {
            return {
              key: valParsed,
              value: (filtersTag[valParsed] as string[])
                .map((value) => t(`filtersValues.${value}` as TranslationType))
                .join(', '),
            };
          } else if (isValid(dateParsed)) {
            return {
              key: valParsed,
              value: formatDate(dateParsed, 'readable(with hour)'),
            };
          } else if (val === 'minAge') {
            return {
              key: valParsed,
              value: t(`filtersValues.minAge` as TranslationType, {
                age: filtersTag[valParsed]?.toString(),
              }),
            };
          } else if (val === 'maxAge') {
            return {
              key: valParsed,
              value: t(`filtersValues.maxAge` as TranslationType, {
                age: filtersTag[valParsed]?.toString(),
              }),
            };
          } else if (val === 'country') {
            const country = countries?.find((c) => c.code === filtersTag[valParsed]);
            return {
              key: valParsed,
              value: country?.libelle,
            };
          } else {
            return {
              key: valParsed,
              value: t(
                `filtersValues.${filtersTag[valParsed]?.toString()}` as TranslationType,
              ),
            };
          }
        }),
    [filtersTag],
  );

  return (
    <div
      className={classNames(
        'layoutList',
        className,
        { '-opaq': opaq },
        { '-opaqBody': opaqBody },
        { '-withoutBorder': withoutBorder },
        { '-withoutBottomMarged': withoutBottomMarged },
        { '-transparent': transparent },
        { [`-secondaryColor--${secondaryColor}`]: !!secondaryColor },
      )}
    >
      {(!!title ||
        !noTags ||
        !!searchTextComponent ||
        (!!pagination && topPagination)) && (
        <div className={'layoutList__header'}>
          {title && typeof title === 'string' && (
            <div className={'-title'}>
              <h1>{title}</h1>
            </div>
          )}
          {title && typeof title !== 'string' && title}
          {gamesComponent && (
            <div className={'-filtersGamesContainer'}>{gamesComponent}</div>
          )}
          <div className={'-filtersWithSearchContainer'}>
            {searchTextComponent && (
              <div className={'-searchTextContainer'}>{searchTextComponent}</div>
            )}
            {filtersBarComponent && (
              <div className={'-filtersBarContainer --scrollBarContent'}>
                {filtersBarComponent}
              </div>
            )}
          </div>
          {!noTags && (tagParsed?.length || 0) > 0 && (
            <div className={'-tagFiltersContainer'}>
              <div className={'--filters'}>
                <span>{t('layoutPageCommonList.filters')}</span>
                {tagParsed?.map(
                  (tag) =>
                    tag &&
                    !!tag.value && (
                      <Chip
                        variant="outlined"
                        color={'info'}
                        className={'-filter'}
                        label={tag.value}
                        onDelete={() => {
                          console.log('DELETE FILTER', tag, onDeleteFilter);
                          onDeleteFilter && onDeleteFilter(tag);
                        }}
                      />
                    ),
                )}
              </div>
            </div>
          )}

          {pagination && topPagination && !tablePagination && (
            <Pagination
              key={paginationProps?.currentPage}
              count={paginationProps?.pagesCount || 1}
              page={paginationProps?.currentPage ? paginationProps?.currentPage + 1 : 1}
              size={'medium'}
              onChange={(e, value) => paginationProps.goToPage(value - 1)}
            />
          )}
          {pagination && topPagination && tablePagination && (
            <>
              <Hidden smUp>
                <Pagination
                  key={paginationProps?.currentPage}
                  count={paginationProps?.pagesCount || 1}
                  page={
                    paginationProps?.currentPage ? paginationProps?.currentPage + 1 : 1
                  }
                  size={'medium'}
                  onChange={(e, value) => paginationProps.goToPage(value - 1)}
                />
              </Hidden>
              <Hidden smDown>
                <TablePagination
                  labelDisplayedRows={({ from, to, count }) =>
                    t('pagination.table.fromToCount', { from, to, count })
                  }
                  labelRowsPerPage={t('pagination.table.rowsPerPage')}
                  key={paginationProps?.currentPage}
                  count={-1}
                  page={paginationProps?.currentPage ? paginationProps?.currentPage : 0}
                  rowsPerPage={paginationProps?.numberOfItemPerPage || 0}
                  size={'medium'}
                  onPageChange={(e, value) => paginationProps.goToPage(value)}
                  onRowsPerPageChange={(e) =>
                    paginationProps.setRowPerPage(parseInt(e.target.value))
                  }
                />
              </Hidden>
            </>
          )}
        </div>
      )}
      <div
        className={classNames('layoutList__content', {
          '-listAlignStart': listAlignStart,
        })}
      >
        {listComponent}
      </div>
      <div className={'layoutList__footer'}>
        {pagination && !tablePagination && (
          <Pagination
            key={paginationProps?.currentPage}
            count={paginationProps?.pagesCount || 1}
            page={paginationProps?.currentPage ? paginationProps?.currentPage + 1 : 1}
            size={'medium'}
            onChange={(e, value) => paginationProps.goToPage(value - 1)}
          />
        )}
        {pagination && tablePagination && (
          <>
            <Hidden smUp>
              <Pagination
                key={paginationProps?.currentPage}
                count={paginationProps?.pagesCount || 1}
                page={paginationProps?.currentPage ? paginationProps?.currentPage + 1 : 1}
                size={'medium'}
                onChange={(e, value) => paginationProps.goToPage(value - 1)}
              />
            </Hidden>
            <Hidden smDown>
              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  t('pagination.table.fromToCount', { from, to, count })
                }
                labelRowsPerPage={t('pagination.table.rowsPerPage')}
                key={paginationProps?.currentPage}
                count={paginationProps?.numberOfItem || -1}
                page={paginationProps?.currentPage ? paginationProps?.currentPage : 0}
                rowsPerPage={paginationProps?.numberOfItemPerPage || 0}
                onRowsPerPageChange={(e) =>
                  paginationProps.setRowPerPage(parseInt(e.target.value))
                }
                size={'medium'}
                onPageChange={(e, value) => paginationProps.goToPage(value)}
              />
            </Hidden>
          </>
        )}
      </div>
      {leftPanelProps && (
        <LeftPanel
          initOpen={leftPanelProps.initialOpen}
          className={'layoutList__leftPanel'}
          IconOpen={FilterAlt}
          keyDomain={keyDomainFilter}
        >
          {leftPanelProps.component}
        </LeftPanel>
      )}
    </div>
  );
}
