import './ListGameSelectable.scss';

import classNames from 'classnames';
import React from 'react';

import { GAME_ACTIVE_LIST, GAME_IMAGES } from '../../../domain/game/GameActiveList';
import { ImageSelectable } from '../ImageSelectable/ImageSelectable';

interface ListGameSelectableProps {
  games?: GAME_ACTIVE_LIST[];
  action: (gameCode: GAME_ACTIVE_LIST) => void;
  gamesSelected?: GAME_ACTIVE_LIST[];
  size?: 'small' | 'medium' | 'large';
  flex?: boolean;
  inline?: boolean;
}

export const ListGameSelectable = ({
  games,
  action,
  gamesSelected,
  size,
  flex,
  inline,
}: ListGameSelectableProps) => {
  const stylDisplayed = {
    backgroundColor: '#2f2f2f87',
    border: '1px solid grey',
    borderRadius: '10px',
  };

  const [hidden, setHidden] = React.useState(true);
  return (
    <div
      className={classNames('listGameSelectable', {
        [`--${size}`]: !!size,
        [`--flex`]: !!flex,
        [`--inline`]: !!inline,
      })}
    >
      <div
        className={'--scrollBarContent'}
        style={{
          height: 'auto',
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          border: '1px solid transparent',
          transition:
            'max-height 0.5s, background-color 1s, border 0.5s, border-radius 0.5s',
          position: 'relative',
          textAlign: 'start',
          backgroundColor: 'transparent',
          zIndex: 999,
          top: '0px',
          left: '0px',
          paddingBottom: 4,
          ...(hidden ? {} : stylDisplayed),
        }}
      >
        {games?.map((gameCode) => (
          <ImageSelectable
            key={gameCode}
            onClick={() => action(gameCode)}
            alt={gameCode}
            imageUrl={GAME_IMAGES[gameCode]}
            isSelected={
              !!gamesSelected?.find((value) => value.toString() === gameCode.toString())
            }
          />
        ))}
      </div>
    </div>
  );
};

/*
import './ListGameSelectable.scss';

import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { ClickAwayListener, IconButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import { GAME_ACTIVE_LIST, GAME_IMAGES } from '../../../domain/game/GameActiveList';
import { ImageSelectable } from '../ImageSelectable/ImageSelectable';

interface ListGameSelectableProps {
  games?: GAME_ACTIVE_LIST[];
  action: (gameCode: GAME_ACTIVE_LIST) => void;
  gamesSelected?: GAME_ACTIVE_LIST[];
  size?: 'small' | 'medium' | 'large';
  flex?: boolean;
}

export const ListGameSelectable = ({
  games,
  action,
  gamesSelected,
  size,
  flex,
}: ListGameSelectableProps) => {
  const stylDisplayed = {
    backgroundColor: '#2f2f2f87',
    border: '1px solid grey',
    borderRadius: '10px',
  };

  const [hidden, setHidden] = React.useState(true);
  return (
    <div
      className={classNames('listGameSelectable --scrollBarContent', {
        [`--${size}`]: !!size,
        [`--flex`]: !!flex,
      })}
    >
      <ClickAwayListener onClickAway={() => setHidden(true)}>
        <div
          style={{
            maxHeight: hidden ? '80px' : '1500px',
            height: 'auto',
            width: '100%',
            overflow: 'hidden',
            border: '1px solid transparent',
            transition:
              'max-height 0.5s, background-color 1s, border 0.5s, border-radius 0.5s',
            position: 'absolute',
            backgroundColor: 'transparent',
            zIndex: 999,
            top: '0px',
            left: '0px',
            ...(hidden ? {} : stylDisplayed),
          }}
        >
          <IconButton
            style={{
              width: '10px',
              height: '10px',
              position: 'absolute',
              bottom: '5px',
              right: '5px',
            }}
            className={'-collapseBarButton'}
            onClick={() => setHidden(!hidden)}
          >
            {hidden ? <AddCircleOutline /> : <RemoveCircleOutline />}
          </IconButton>
          {games?.map((gameCode) => (
            <ImageSelectable
              key={gameCode}
              onClick={() => action(gameCode)}
              alt={gameCode}
              imageUrl={GAME_IMAGES[gameCode]}
              isSelected={
                !!gamesSelected?.find((value) => value.toString() === gameCode.toString())
              }
            />
          ))}
        </div>
      </ClickAwayListener>
    </div>
  );
};

 */
