import { Invitation } from 'domain/invitation/Invitation';
import { InvitationTypeEnum } from 'domain/invitation/InvitationTypeEnum';
import { toEnum } from 'helper/enum.helper';
import { RestUserView, toUserView } from 'secondary/user/RestUser';

export interface RestInvitation {
  id: number;
  type: string;
  state: string;
  sender: RestUserView;
  receiver: RestUserView;
  idResource?: number;
}

export const toInvitation = (invitation: RestInvitation): Invitation => {
  return new Invitation(
    invitation.id,
    toEnum(InvitationTypeEnum, invitation.type),
    invitation.state,
    toUserView(invitation.sender),
    toUserView(invitation.receiver),
    invitation.idResource,
  );
};
