import { Cancel } from '@mui/icons-material';
import { Button } from '@mui/material';
import { InvitationLink } from 'domain/invitationLink/InvitationLink';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { StructureMember } from 'domain/structureMember/StructureMember';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { PreviewCardProfil } from 'primary/Components/Profils/PreviewCardProfil';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
interface ConsumeStructureInvitationLinkProps {
  link: InvitationLink;
  handleClose: () => void;
  nextStep: (data?: any) => void;
  data: { member: StructureMember };
}
export const ConsumeStructureInvitationLink = ({
  link,
  handleClose,
  nextStep,
}: ConsumeStructureInvitationLinkProps) => {
  const { structureRepository, authRepository, structureMemberRepository } =
    useContextDependency();
  const [structure, , fetchingStructure] = useRetrieveFromDomain(
    () => structureRepository.getStructureById(link.idRessource),
    undefined,
  );
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);

  const [structureMember, , fetchingMember] = useRetrieveFromDomain(
    () =>
      structureMemberRepository.getMember(
        link.idRessource,
        authRepository?.currentUser?.id as number,
      ),
    undefined,
    !!authRepository.currentUser && !!structure?.id,
  );

  const t = useTranslate();
  const navigate = useNavigate();
  const alreadyCreating = useRef(false);

  useEffect(() => {
    if (alreadyCreating.current) return;
    if (authRepository.currentUser && !fetchingMember && !structureMember) {
      alreadyCreating.current = true;
      structureMemberRepository.createMemberFromLink(link.uuid).then((member) => {
        handleClose();
        navigate(routesConfig.activities.to(), {
          state: {
            openInviteStructurePending: true,
          },
        });
      });
    } else if (authRepository.currentUser && structureMember) {
      alreadyCreating.current = true;
      handleClose();
      navigate(routesConfig.structureProfilMembers.to(structureMember.structure?.id));
    }
  }, [authRepository.currentUser, fetchingMember, structureMember]);

  const handleSubscribe = () => {
    authRepository.register(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  const handleLogin = () => {
    authRepository.login(
      `${window.location.origin}/parameters/user/profil/apparence?invitationLink=${link.uuid}`,
    );
  };

  if (!structure?.structureProfil || !isAccountLoaded) return <LoadingScreen loading />;
  const alreadyMember = !!structureMember;
  if (alreadyMember) {
    return (
      <div className={'-alreadyMember'}>
        <Cancel className={'-icon'} color={'warning'} />
        <span>{t('invitationLink.consumedModal.structure.alreadyMember')}</span>
      </div>
    );
  }
  return (
    <div className={'consumeStructureInvitationLink'}>
      <h4>
        {t('invitationLink.consumeModal.structure.title', {
          structureName: structure.name,
        })}
      </h4>
      {fetchingStructure && !structure && <LoadingScreen loading />}
      {!fetchingStructure && structure && (
        <div className={'-infosStructure'}>
          <div className={'-cardProfil'}>
            <PreviewCardProfil
              className={'-itemLayoutList'}
              key={structure.id}
              profil={structure.structureProfil as StructureProfil}
              noAction
            />
          </div>
        </div>
      )}
      {!isAccountLoaded && <LoadingScreen loading message={'Récupération du compte'} />}
      {isAccountLoaded && !authRepository?.currentUser && (
        <div className={'-actions'}>
          <span>Vous devez être connecté pour continuer</span>
          <Button
            onClick={handleLogin}
            variant={'contained'}
            color={'primary'}
            type={'submit'}
          >
            {t('invitationLink.consumeModal.steps.account.loginBtn')}
          </Button>
          <Button
            onClick={handleSubscribe}
            variant={'contained'}
            color={'primary'}
            type={'submit'}
          >
            {t('invitationLink.consumeModal.steps.account.registerBtn')}
          </Button>
        </div>
      )}
    </div>
  );
};
