import { Cancel, Check } from '@mui/icons-material';
import { Chip, FormControl } from '@mui/material';
import { isArray } from 'lodash';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import React, { forwardRef, Ref, useRef } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { InputProps, Option, ValueType } from './InputWrapper';

type InputSwitchTagMuiProps<T extends ValueType = string, M = object> = {
  required?: boolean;
  options?: Option<string, M>[];
  isSearchable?: boolean;
  onInputChange?: (newValue: string) => void;
  filterOption?: (opt: FilterOptionOption<Option<string, M>>, input: string) => boolean;
  menuPortalTarget?: HTMLElement | null;
  multiple?: boolean;
  isOptionDisabled?: (option: Option<string, M>) => boolean;
  isLoading?: boolean;
  onLoadingMessage?: string;
  noContentMessage?: string;
  variant?: 'standard' | 'outlined' | 'filled' | 'tag';
} & InputProps<T>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  onClick: (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  },
};

export const InputSwitchTag = forwardRef(function InputSwitchTagForwarded(
  {
    value,
    onChange,
    options,
    multiple,
    isLoading,
    onLoadingMessage,
    noContentMessage,
    variant,
    disabled,
    placeholder,
  }: InputSwitchTagMuiProps,
  ref: Ref<HTMLElement>,
) {
  const valueDefault = isLoading || options?.length === 0 ? 'search-disabled' : '';
  const anchorElTagSelect = useRef<HTMLDivElement>(null);
  const isDisplayPlaceholder =
    ((isArray(value) && !value?.length) || !value) && !!placeholder;

  const [isOpenTagSelect, , openModalTagSelect, closeModalTagSelect] = useBooleanToggle();
  return (
    <FormControl sx={{ width: '100%' }}>
      <Chip
        label={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: value === 'true' ? '#ff7800' : 'white',
            }}
          >
            {placeholder}
            {value === 'true' ? (
              <Check width={10} fontSize={'inherit'} />
            ) : (
              <Cancel fontSize={'inherit'} width={10} />
            )}
          </div>
        }
        ref={anchorElTagSelect}
        onClick={() =>
          onChange?.({ target: { value: value === 'true' ? 'false' : 'true' } })
        }
      />
    </FormControl>
  );
});
