import './EventFilters.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type FilterTypeTeamProps = {
  onSelectTypeTeam: (value?: string) => void;
  selectedTypeTeam?: string;
};

export const FilterTypeTeam = ({
  onSelectTypeTeam,
  selectedTypeTeam,
}: FilterTypeTeamProps) => {
  const { eventRepository } = useContextDependency();
  const [typeTeam] = useFetch(() => eventRepository.getAllTypeTeam(), undefined);
  if (!typeTeam) return null;
  const t = useTranslate();
  return (
    <InputSelectTag
      placeholder={t('event.filters.typeTeam')}
      options={insertEmptyOpt(
        typeTeam.map((typeTeam) => ({
          value: typeTeam.code,
          label: t(`enum.typeTeamCode.${typeTeam.code}`),
        })),
      )}
      onChange={(event: ChangeHandlerTypeMeta) => {
        //ts-ignore
        onSelectTypeTeam(event.target.value as string);
      }}
      value={selectedTypeTeam}
      menuPortalTarget={document.body}
    />
  );
};
