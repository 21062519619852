import { Box } from '@mui/material';
import { setInviteStructureModalClose } from 'domain/modal/store/ModalSlice';
import { InviteStructureForm } from 'primary/Components/Profils/User/ActionButton/Forms/InviteStructureForm';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export interface ModalInviteStructureProps {
  userId?: number;
  name: string;
}
export const ModalInviteStructure = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const stateModal = useSelector((state: RootState) => state.modal.inviteStructure);
  const handleClose = () => dispatch(setInviteStructureModalClose());
  const { userId, name } = stateModal.props as ModalInviteStructureProps;
  if (!userId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={stateModal.isOpen}
      content={
        <Box className={'modal-edit-role'}>
          <h2>{t('modals.inviteStructure.title', { name: name })}</h2>
          <InviteStructureForm
            onSubmitted={() => handleClose()}
            userId={userId?.toString()}
            onCancel={() => handleClose()}
          />
        </Box>
      }
    />
  );
};
