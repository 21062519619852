import { IInvitationRepository } from 'domain/invitation/IInvitation.repository';
import { InvitationTypeEnum } from 'domain/invitation/InvitationTypeEnum';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { EventSelector } from 'primary/Components/Ping/EventSelector';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface InviteEventFormProps {
  userId: string;
  onSubmitted: () => void;
  onCancel: () => void;
}

export type InviteEventFormData = {
  userId: string;
  event: string;
  message?: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    userId: yup.string().required(),
    event: yup.string().required(t('general.forms.errors.required')),
  });

export const onSubmitInviteEvent =
  (
    invitationRepository: IInvitationRepository,
    onSubmitted: () => void,
    userId: number,
    t: UseTranslateReturn,
  ) =>
  async (data: InviteEventFormData) => {
    return invitationRepository
      .createInvitation(InvitationTypeEnum.EVENT, userId, data.event)
      .then(() => {
        onSubmitted();
        sendEventToastMessage(t('modals.inviteEvent.success'), 'success');
      });
  };

export const InviteEventForm = ({
  userId,
  onSubmitted,
  onCancel,
}: InviteEventFormProps) => {
  const { invitationRepository, authRepository } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<InviteEventFormData>({
    resolver,
    defaultValues: {
      userId: userId,
    },
  });
  const { field: messageField } = useController({ control: control, name: 'message' });
  const t = useTranslate();
  const onSubmit = onSubmitInviteEvent(
    invitationRepository,
    () => onSubmitted(),
    parseInt(userId),
    t,
  );
  const structureControl = useController({
    control: control,
    name: 'event',
  });
  if (!userId || !authRepository.currentUser) return null;

  return (
    <form className={'-inviteEventForm'} onSubmit={handleSubmit(onSubmit)}>
      <EventSelector
        userId={authRepository.currentUser.id}
        errors={errors}
        control={structureControl}
      />
      <CoupleButtonValidCancel isTypeSubmit={true} onClickCancel={onCancel} />
    </form>
  );
};
