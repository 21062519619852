import { ConnectedAccountSessionManagement } from 'domain/sellerAccount/ConnectedAccountSessionManagement';

export interface RestConnectedAccountSessionManagement {
  clientSecret: string;
}

export function toConnectedAccountSessionManagemnent(
  rest: RestConnectedAccountSessionManagement,
): ConnectedAccountSessionManagement {
  return new ConnectedAccountSessionManagement(rest.clientSecret);
}
