import { AspectRatio, SportsEsports } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { Invitation, InvitationFilters } from 'domain/invitation/Invitation';
import { ModalPendingEventInvitation } from 'primary/activities/Modals/ModalPendingEventInvitation';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ButtonInvitationsEvent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { invitationRepository } = useContextDependency();
  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    fetch,
    nextPage,
    retry,
    allContents,
  } = useFetchWithFilterPage<Invitation, InvitationFilters>({
    filters: {},
    staticFilters: { asReceiver: true, state: 'PENDING' },
    callApi: (filters, page) => invitationRepository.search(filters, page, 30),
    keyStoreFilters: 'invitation-event',
  });

  const { state } = useLocation();
  const modalAlreadyOpenFromState = useRef(false);
  useEffect(() => {
    if (!modalAlreadyOpenFromState.current && !isOpen && state?.openInviteEventPending) {
      setIsOpen(true);
      modalAlreadyOpenFromState.current = true;
    }
  }, [modalAlreadyOpenFromState.current, isOpen]);
  const afterSubmit = () => {
    fetch(true);
    if (fetchedResource?.content?.length && fetchedResource?.content?.length - 1 === 0) {
      setIsOpen(false);
    }
  };
  return (
    <div className={'-socialItems'}>
      <div className={'-iconWithNumber'}>
        <SportsEsports className={'-socialLogo'} />
        {!fetchedResource?.content && <CircularProgress size={15} color={'info'} />}
        {fetchedResource?.content && <span>{fetchedResource?.content?.length ?? 0}</span>}
      </div>
      <div className={'-titleWithBtn'}>
        <Title title={'Invitations événement'} level={3} compensatePadding />
        <IconButton
          disabled={fetchedResource?.content && fetchedResource?.content?.length <= 0}
          onClick={() => setIsOpen(true)}
        >
          <AspectRatio fontSize={'small'} />
        </IconButton>
      </div>
      {!fetchedResource?.content && <CircularProgress size={15} color={'info'} />}
      {fetchedResource?.content && (
        <ModalPendingEventInvitation
          isOpen={isOpen}
          goToPage={goToPage}
          fetchedResource={fetchedResource ?? []}
          afterSubmit={afterSubmit}
          handleClose={() => setIsOpen(false)}
          loading={loading}
        />
      )}
    </div>
  );
};
