import './OtherProfilPhotoOverview.scss';

import classNames from 'classnames';
import {
  ATTR_BY_SIZE,
  PhotoSize,
} from 'primary/Components/User/WrapperOtherProfilPhotoOverview';
import React, { FC } from 'react';

interface OtherUserProfilPhotoOverviewProps {
  className?: string;
  photo?: string;
  idUserProfl: number;
  displayName?: string;
  size: PhotoSize;
}

export const OtherUserProfilPhotoOverview: FC<OtherUserProfilPhotoOverviewProps> = ({
  className,
  photo,
  idUserProfl,
  displayName,
  size,
}) => {
  const isDraft = photo?.includes('blob');

  return (
    <div
      className={classNames('otherProfilPhotoOverview', className, '-asNeon')}
      title={displayName}
    >
      {photo && <img alt={`profil ${displayName}`} src={photo} {...ATTR_BY_SIZE[size]} />}
    </div>
  );
};
