import './TeamCreateStepPlayers.scss';

import { GroupAdd, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { Auth } from 'domain/auth/Auth.repository';
import { IEventRepository } from 'domain/event/Event.repository';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { PlayersNumberByTypeTeams, TypeTeam } from 'domain/event/TypeTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { CreateTeamPlayerFields } from 'primary/Components/Event/CreateTeamPlayerFields';
import { routesConfig } from 'primary/Configs/Routes.config';
import { confStepCreateTeam } from 'primary/events/modals/ModalRegisterEvent';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { setTeamCreating } from 'primary/teams/store/TeamCreatingSlice';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

export type FormStepPlayersData = {
  players: {
    [key: string]: PlayerItemFormData;
  };
};

export type PlayerItemFormData = {
  idPlayer?: string;
  role?: PlayerRoleEnum;
  withLink?: boolean;
};

export type EventRegisterPlayerFormData = {
  [key: string]: {
    idPlayer?: string;
    role?: PlayerRoleEnum;
    withLink?: boolean;
  };
};

export type EventRegisterFormData = {
  name: string;
  structure: string;
  players: EventRegisterPlayerFormData;
  idEvent: string;
  participationAsStructure?: boolean;
};

//@ts-ignore
const testTooManyChief = (value: PlayerRoleEnum, context: any) => {
  const players = context.from[1]?.value;
  if (!value) return false;
  if (!players) return true;
  if (value !== PlayerRoleEnum.CHIEF) return true;
  if (
    Object.values(players).filter(
      //@ts-ignore
      (value1: PlayerItemFormData) => value1?.role === PlayerRoleEnum.CHIEF.toString(),
    ).length >= 2
  ) {
    return false;
  }
  return true;
};

const validationSchema = (typeTeam: TypeTeam) => (t: UseTranslateReturn) =>
  yup.object({
    players: yup.object({
      ...PlayersNumberByTypeTeams[typeTeam.code].reduce((acc, value, currentIndex) => {
        return {
          ...acc,
          [`player-${currentIndex}`]: yup.object({
            idPlayer: yup.string().when(`withLink`, {
              is: (val?: boolean) => !val || val?.toString() === 'false',
              then: yup.string().required(t('general.forms.errors.required')),
              otherwise: yup.string().nullable(),
            }),
            role: yup
              .mixed()
              .test('role', t('team.tooManyChief'), testTooManyChief)
              .required(t('general.forms.errors.required')),
          }),
        };
      }, {}),
    }),
  });

const useOnSubmit =
  (
    eventRepository: IEventRepository,
    dataEventRegister: EventRegisterFormData,
    setLoading: (teamCreatingLoading: boolean) => void,
    onSubmitted: (participant: EventParticipant) => void,
    idEvent: number,
  ) =>
  (data: FormStepPlayersData) => {
    setLoading(true);
    eventRepository
      .createTeam({ ...dataEventRegister, players: data.players }, idEvent)
      .then((participant: EventParticipant) => {
        onSubmitted(participant);
      })
      .finally(() => {
        setLoading(false);
      });
  };

type FormStepPlayersProps = {
  nextStep: (data?: FormStepPlayersData) => void;
  previousStep: (data: FormStepPlayersData) => void;
  initialValues: FormStepPlayersData;
  data: EventRegisterFormData;
  typeTeam: TypeTeam;
  game: GAME_ACTIVE_LIST;
  inRightPanel?: boolean;
  event: UnpyEvent & UnpyEventView;
  setTeamCreated?: (eventParticipant?: EventParticipant) => void;
  onSubmitted?: (eventParticipant?: EventParticipant) => void;
};

export const getnitialValuesPlayersFromTypeTeam = (
  typeTeam: TypeTeam,
  authRepository: Auth,
) => {
  return PlayersNumberByTypeTeams[typeTeam.code].reduce((acc, value, currentIndex) => {
    return {
      ...acc,
      [`player-${currentIndex}`]: {
        idPlayer:
          currentIndex === 0
            ? authRepository.currentUser?.userProfil?.idPlayer.toString()
            : undefined,
        role:
          currentIndex === 0
            ? PlayerRoleEnum.CHIEF.toString()
            : PlayerRoleEnum.TITULAR.toString(),
      },
    };
  }, {});
};

export const FormStepPlayers = ({
  nextStep,
  previousStep,
  initialValues,
  typeTeam,
  data,
  game,
  inRightPanel,
  event,
  setTeamCreated,
  onSubmitted,
}: FormStepPlayersProps) => {
  const { authRepository, eventRepository } = useContextDependency();
  const resolver = useYupValidationResolver(validationSchema(typeTeam));
  const form = useForm<FormStepPlayersData>({
    resolver,
    defaultValues: {
      ...initialValues,
      players: {
        ...initialValues.players,
        ...getnitialValuesPlayersFromTypeTeam(typeTeam, authRepository),
      },
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    register,
    getValues,
    watch,
    setError,
    formState: { isSubmitting, errors },
  } = form;

  const t = useTranslate();
  const dispatch = useDispatch();
  const players = watch('players');
  const [teamCreatingLoading, setTeamCreatingLoading] = useState(false);
  const onSubmit = useOnSubmit(
    eventRepository,
    data,
    setTeamCreatingLoading,
    (participant: EventParticipant) => {
      dispatch(setTeamCreating(undefined));
      if (setTeamCreated) {
        setTeamCreated?.(participant);
      }
      onSubmitted?.(participant);
      nextStep(undefined);
    },
    event.id,
  );
  const navigate = useNavigate();

  const onClickGoMercato = () => {
    dispatch(
      setTeamCreating({
        steps: confStepCreateTeam(t).steps,
        activeStep: 'step-players',
        data: { ...data, players: { ...data.players, ...players } },
        typeTeam: typeTeam,
        game: game,
        event: event,
      }),
    );
    navigate(routesConfig.mercatoSubPath.mercatoPlayer.to());
  };
  console.log('ERR', errors);
  console.log('VAM', getValues());
  return (
    <div
      className={classNames('teamCreateStepPlayers', { '-inRightPanel': inRightPanel })}
    >
      <h4>{t('event.register.choiceAddPlayer')}</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'-playersField'}>
          {PlayersNumberByTypeTeams[typeTeam.code].map((value, index) => (
            <CreateTeamPlayerFields
              key={index}
              //@ts-ignore
              form={form}
              index={index}
              structureId={data.structure}
              disabledPlayer={index === 0}
              eventId={event.id}
              creatorIdPlayer={event.creator.idPlayer}
            />
          ))}
        </div>
        <h4>{t('event.register.choiceMercato')}</h4>
        <div className={'mercatoBtnContainer'}>
          <Button variant={'contained'} color={'primary'} onClick={onClickGoMercato}>
            {t('event.register.goMercato')}
            <GroupAdd />
          </Button>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => previousStep(getValues())}
              className={'cancel'}
            >
              <NavigateBefore />
              {t('formStepper.previousLabel')}
            </Button>
            <LoadingButton
              variant={'contained'}
              color={'primary'}
              type={'submit'}
              loading={teamCreatingLoading}
            >
              <NavigateNext />
              {t('formStepper.nextLabel')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
