import './EventTimelineInfos.scss';

import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { TimelineEventComp } from 'primary/Components/Event/TimelineEvent';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type EventTimelineInfosProps = {
  event?: UnpyEvent | UnpyEventView;
};

export const EventTimelineInfos = ({ event }: EventTimelineInfosProps) => {
  const [selectedIdentifierInfos, setSelectedIdentifierInfos] = React.useState<
    EventStatusEnum | undefined
  >(event?.status);
  const t = useTranslate();
  return (
    <div className={'eventTimelineInfos'}>
      <TimelineEventComp
        orientation={'horizontal'}
        readonly={true}
        event={event}
        selectedIdentifierInfos={selectedIdentifierInfos}
        onSelectStepInfo={(stepCode: EventStatusEnum) =>
          setSelectedIdentifierInfos(stepCode)
        }
      />
      <div className={'-infos'}>
        {selectedIdentifierInfos && (
          <p>
            {t(
              `event.parameters.timeline.currentStateExplain.${
                selectedIdentifierInfos ?? event?.status
              }`,
            )}
          </p>
        )}
        {selectedIdentifierInfos === EventStatusEnum.PUBLISH && (
          <>
            <KeyValue
              wrap
              accent
              alignStart
              keyValue={t('enum.eventSubStatus.SUBSCRIBE_OPEN.title')}
              value={t('event.parameters.timeline.currentStateExplain.SUBSCRIBE_OPEN')}
            />
            <KeyValue
              wrap
              accent
              alignStart
              keyValue={t(`enum.eventSubStatus.SUBSCRIBE_CLOSE.title`)}
              value={t('event.parameters.timeline.currentStateExplain.SUBSCRIBE_CLOSE')}
            />
          </>
        )}
      </div>
    </div>
  );
};
