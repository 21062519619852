import './ModalContainer.scss';

import { Cancel } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface ModalContainerProps {
  handleClose: () => void;
  content: ReactNode;
  isOpen: boolean;
  fullScreen?: boolean;
  header?: {
    title: string | ReactNode;
    withButtonClose?: boolean;
  };
}

const ModalContainer = ({
  handleClose,
  content,
  isOpen,
  fullScreen,
  header,
}: ModalContainerProps) => {
  return (
    <Modal
      disableAutoFocus
      className={classNames('-modal', { '-fullScreen': fullScreen })}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-describedby="modal-modal-description"
    >
      <div className={classNames('modal-container', { '-fullScreen': fullScreen })}>
        {header && (
          <div className={'-header'}>
            {header.title}
            {header.withButtonClose && (
              <IconButton onClick={handleClose} className={'-close'}>
                <Cancel />
              </IconButton>
            )}
          </div>
        )}
        <div className={'-container'}>{content}</div>
      </div>
    </Modal>
  );
};

export default ModalContainer;
