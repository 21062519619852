import './ProfilList.scss';

import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { UserProfil, UserProfilFilters } from 'domain/profil/UserProfil';
import { insertIf } from 'helper/array.helper';
import { ListElements } from 'primary/Components/ListElements/ListElements';
import { ListGameSelectable } from 'primary/Components/UserStatsGameForms/ListGameSelectable';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { AutocompleteUserProfilWrapperFetch } from '../Components/AutoCompleteUserProfil/AutocompleteUserProfil';
import { MercatoPlayerFilters } from '../Components/Filters/MercatoPlayer/MercatoPlayerFilters';
import { LayoutList } from '../Components/Layout/Lists/LayoutList';
import { PreviewCardProfil } from '../Components/Profils/PreviewCardProfil';
import { useContextDependency } from '../hooks/useContextDependency';
import { useFetchWithFilterPage } from '../hooks/useFetchWithFilterPage';

interface UserProfilListProps {
  gamesSelected: string[];
  filters?: Partial<UserProfilFilters>;
  title?: string | React.ReactNode;
  secondaryColor?: 'mercato' | 'event';
}

export const UserProfilList = ({
  gamesSelected,
  filters,
  title,
  secondaryColor,
}: UserProfilListProps) => {
  const { userProfilRepository, authRepository } = useContextDependency();
  const teamCreating = useSelector((state: RootState) => state.teamCreating.teamCreating);

  const defaultFilters: UserProfilFilters = {
    games: gamesSelected,
    experiences: undefined,
    platforms: undefined,
    maxAge: undefined,
    minAge: undefined,
    langs: undefined,
    ...filters,
  };

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    setFilters,
    changeNbPerPage,
  } = useFetchWithFilterPage<UserProfil, UserProfilFilters>({
    staticFilters: teamCreating
      ? { notInEventId: teamCreating?.event.id.toString() }
      : {},
    filters: defaultFilters,
    keyStoreFilters: 'mercatoPlayer',
    callApi: (filters, page, nbPerPage) =>
      userProfilRepository.search(filters, page, nbPerPage),
  });

  const playerToDisable = teamCreating
    ? [
        ...(Object.values(teamCreating.data.players)?.map((p) => p.idPlayer as string) ??
          []),
        authRepository.currentUser?.userProfil.idPlayer as string,
        teamCreating.event.creator.idPlayer as string,
      ]
    : undefined;

  useEffect(() => {
    setFilters({
      games: teamCreating?.event.game.code ? [teamCreating?.event.game.code] : [],
      idStructure: teamCreating?.data.structure,
      notInEventId: teamCreating?.event?.id.toString(),
      platforms: teamCreating?.event?.platforms?.map((p) => p.code),
      ...insertIf(!!teamCreating, { notInEventId: teamCreating?.event.id.toString() }),
    });
  }, [
    teamCreating?.data.structure,
    teamCreating?.event.game.code,
    teamCreating?.event.id,
    teamCreating?.event.platforms,
  ]);
  const t = useTranslate();
  return (
    <LayoutList
      secondaryColor={secondaryColor}
      onDeleteFilter={(filter) => {
        //ts-ignore
        const keyGame = Object.keys(GAME_ACTIVE_LIST).find(
          //@ts-ignore
          (g) => t(`filtersValues.${GAME_ACTIVE_LIST[g]}`) === filter.value,
        );
        setFilters({
          ['games']: activeFilters.filters.games?.filter((g) => g !== keyGame),
        });
      }}
      title={title}
      tablePagination
      filtersBarComponent={
        <MercatoPlayerFilters
          setFilters={setFilters}
          activeFilters={activeFilters?.filters}
        />
      }
      resetFilters={() => setFilters({})}
      keyDomainFilter={'mercatoPlayer'}
      paginationProps={{
        goToPage: goToPage,
        currentPage: fetchedResource?.currentPage,
        numberOfItem: fetchedResource?.totalElementsCount,
        setRowPerPage: changeNbPerPage,
        numberOfItemPerPage: fetchedResource?.pageSize,
        pagesCount: fetchedResource?.pagesCount || 0,
      }}
      pagination
      gamesComponent={
        <ListGameSelectable
          inline
          size={'small'}
          flex
          games={Object.values(GAME_ACTIVE_LIST)}
          action={(gamecode) => {
            if (activeFilters?.filters?.games?.includes(gamecode)) {
              setFilters({
                ...activeFilters,
                games: activeFilters?.filters?.games?.filter((g) => g !== gamecode),
              });
            } else {
              setFilters({
                ...activeFilters,
                games: [...(activeFilters?.filters?.games ?? []), gamecode],
              });
            }
          }}
          //@ts-ignore
          gamesSelected={activeFilters?.filters.games ?? []}
        />
      }
      searchTextComponent={
        <AutocompleteUserProfilWrapperFetch
          onChangeSelected={(value) => {
            setFilters({ ...activeFilters?.filters, idPlayer: value?.toString() });
          }}
        />
      }
      listComponent={
        <ListElements loading={loading} empty={fetchedResource?.content.length === 0}>
          <div className={'-containerList'}>
            {fetchedResource?.content.map((profil) => (
              <div key={profil.id} className={'list__elements'}>
                <PreviewCardProfil key={profil.id} profil={profil} inPage />
              </div>
            ))}
          </div>
        </ListElements>
      }
    />
  );
};
