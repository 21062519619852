import './ComformityStateWrapper.scss';

import { KeyboardDoubleArrowRight, WarningSharp } from '@mui/icons-material';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { Link } from 'react-router-dom';
type comformityStateWrapperProps = {
  children: React.ReactNode;
};

export const ComformityStateWrapper = ({ children }: comformityStateWrapperProps) => {
  const { sellerAccountRepository, userProfilRepository } = useContextDependency();
  const [connectedAccount, , fetching, retry] = useRetrieveFromDomain(
    () => sellerAccountRepository.getDetails(),
    undefined,
  );
  const t = useTranslate();
  const requirement = [...(connectedAccount?.requirements?.currentlyDue || [])];

  if (fetching) {
    return <LoadingScreen loading />;
  }

  if (!fetching && (!connectedAccount || requirement?.length > 0)) {
    return <ConformityNoticeWithLink />;
  }

  return <>{children}</>;
};

type ConformityNoticeWithLinkProps = {
  message?: string;
  title?: string;
};
export const ConformityNoticeWithLink = ({
  message,
  title,
}: ConformityNoticeWithLinkProps) => {
  return (
    <div className={'conformityStateWrapper'}>
      <WarningSharp className={'-svgWarn'} />
      <h2>{title || 'Unpy a encore besoins de vérifier quelques informations !'}</h2>
      <span>
        {message ||
          'Vous devez compléter le formulaire de conformité pour accèder a cet écran.'}
      </span>
      <Link className={'-link'} to={routesConfig.parametersDetails.comformity.to()}>
        <span>Onglet conformité</span> <KeyboardDoubleArrowRight />
      </Link>
    </div>
  );
};
