import './Balance.scss';

import { Cancel, Check, ExitToApp, GetApp } from '@mui/icons-material';
import { Button, Chip, IconButton } from '@mui/material';
import { addDays } from 'date-fns';
import { BalanceChargeStatus } from 'domain/balance/BalanceChargeStatus';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { PayoutTypeEnum } from 'domain/payout/PayoutType.enum';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ComformityStateWrapper } from 'primary/Parameters/user/portefeuille/ComformityStateWrapper';
import { useUserParameters } from 'primary/Parameters/user/useUserParameters.hook';
import { PayoutsList } from 'primary/payout/PayoutList';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type ItemBalanceByEvent = {
  [key: string]: EventParticipant[];
};
type ListBalanceParticipantItemByEventProps = {
  itemByEvent: ItemBalanceByEvent;
};

const ListBalanceParticipantItemByEvent = ({
  itemByEvent,
}: ListBalanceParticipantItemByEventProps) => {
  const t = useTranslate();
  const mappedKeyOfEvent = itemByEvent ? Object.keys(itemByEvent) : undefined;
  const navigate = useNavigate();
  if (!mappedKeyOfEvent) return null;
  return (
    <div className={'-balanceParticipantItemByEvent'}>
      <h3>{mappedKeyOfEvent.length} Événements concernés</h3>
      {mappedKeyOfEvent.length > 0 &&
        mappedKeyOfEvent.map((key) => {
          const isFundAvailable = itemByEvent[key].some(
            (p) => p.balanceChargeStatus === BalanceChargeStatus.available,
          );
          const isStatusOk = itemByEvent[key][0].event.status === EventStatusEnum.FINISH;
          const isDateOk = itemByEvent[key][0].event.endDateTime
            ? addDays(itemByEvent[key][0].event.endDateTime as Date, 7) < new Date()
            : false;
          return (
            <div className={'-eventParticipantBalanceItem'} key={key}>
              <div className={'-nameWithTag'}>
                <div className={'-nameWithAmount'}>
                  <span className={'-eventName'}>{itemByEvent[key][0].event.name}</span>
                  <span className={'-eventAmount'}>
                    Coût d'inscription :
                    <span className={'-amount'}>
                      {itemByEvent[key][0].event.registerPrice}
                    </span>
                    €
                  </span>
                  <span className={'-eventAmount'}>
                    Total :
                    <span className={'-amount'}>
                      {(itemByEvent[key][0].event.registerPrice ?? 0) *
                        itemByEvent[key]?.length}
                    </span>
                    €
                  </span>
                  <Button
                    color={'secondary'}
                    style={{ justifySelf: 'flex-end' }}
                    variant={'contained'}
                    onClick={() => {
                      navigate(routesConfig.eventView.to(itemByEvent[key][0].event.id));
                    }}
                  >
                    <ExitToApp />
                    {t('notification.content.event.goToEvent')}
                  </Button>
                </div>
                <div className={'-tags'}>
                  <span>Conditions de retrait :</span>
                  <Chip
                    size={'small'}
                    className={'-tag'}
                    color={isStatusOk ? 'primary' : 'warning'}
                    icon={isStatusOk ? <Check /> : <Cancel />}
                    label="Status terminé"
                  />
                  <Chip
                    size={'small'}
                    className={'-tag'}
                    color={isDateOk ? 'primary' : 'warning'}
                    icon={isDateOk ? <Check /> : <Cancel />}
                    label="Fin de l'Événement > 7 jours"
                  />
                  <Chip
                    size={'small'}
                    className={'-tag'}
                    color={isFundAvailable ? 'primary' : 'warning'}
                    icon={isFundAvailable ? <Check /> : <Cancel />}
                    label="Fonds disponibles"
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const Balance = () => {
  const t = useTranslate();
  const { eventBalanceRepository } = useContextDependency();
  const { user, onSubmitted } = useUserParameters();
  const [balance, , fetching, retry] = useRetrieveFromDomain(
    () => eventBalanceRepository.getBalanceEvent(),
    undefined,
  );

  const handleCashout = () => {
    eventBalanceRepository.cashoutAvailableEventAmount().then(retry);
  };

  // @ts-ignore
  const staledAmountPArticipantByEvent: ItemBalanceByEvent =
    balance?.waitingParticipantForCashout
      ?.filter((p) => p.status === EventParticipantStatusEnum.REGISTERED)
      ?.reduce((acc, p) => {
        // @ts-ignore
        if (!acc[p.event.id]) {
          // @ts-ignore
          acc[p.event.id] = [p];
        } else {
          // @ts-ignore
          acc[p.event.id].push(p);
        }
        return acc;
      }, {});
  // @ts-ignore
  const availableAmountPArticipantByEvent: ItemBalanceByEvent =
    balance?.readyParticipantForCashout
      ?.filter((p) => p.status === EventParticipantStatusEnum.REGISTERED)
      ?.reduce((acc, p) => {
        // @ts-ignore
        if (!acc[p.event.id]) {
          // @ts-ignore
          acc[p.event.id] = [p];
        } else {
          // @ts-ignore
          acc[p.event.id].push(p);
        }
        return acc;
      }, {});

  const amountStaled = staledAmountPArticipantByEvent
    ? Object.keys(staledAmountPArticipantByEvent).reduce((acc, key) => {
        return (
          acc +
          (staledAmountPArticipantByEvent[key][0].event.registerPrice ?? 0) *
            staledAmountPArticipantByEvent[key].length
        );
      }, 0)
    : 0;

  const amountAvailable = availableAmountPArticipantByEvent
    ? Object.keys(availableAmountPArticipantByEvent).reduce((acc, key) => {
        return (
          acc +
          (availableAmountPArticipantByEvent[key][0].event.registerPrice ?? 0) *
            availableAmountPArticipantByEvent[key].length
        );
      }, 0)
    : 0;
  return (
    <PageLayout>
      <Title
        title={t('user.parameters.balance.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <ComformityStateWrapper>
        {fetching && <LoadingScreen loading />}
        {!fetching && (
          <div className={'balancesContainer'}>
            <div className={'-balanceStaled'}>
              <span className={'-amount'}>{amountStaled} €</span>
              <div className={'-infos'}>
                <h2>Montant en attente</h2>
              </div>
              <ListBalanceParticipantItemByEvent
                itemByEvent={staledAmountPArticipantByEvent}
              />
            </div>
            <div className={'-balanceAvailable'}>
              <span className={'-amount'}>{amountAvailable} €</span>
              <div className={'-infos'}>
                <h2>Montant prêt pour le retrait</h2>
                <IconButton
                  title={'Transférer le solde sur mon compte bancaire.'}
                  onClick={handleCashout}
                  disabled={amountAvailable === 0}
                >
                  <GetApp />
                </IconButton>
              </div>
              <ListBalanceParticipantItemByEvent
                itemByEvent={availableAmountPArticipantByEvent}
              />
            </div>
          </div>
        )}
        <PayoutsList type={PayoutTypeEnum.EVENT_REGISTER} />
      </ComformityStateWrapper>
    </PageLayout>
  );
};
