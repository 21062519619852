import '../../Utils/Forms/UnpyForm.scss';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Grid, Stack, TextField } from '@mui/material';
import {
  getInitialValueParameterize,
  isStartDateTimeEditable,
} from 'domain/event/UnpyEvent.func';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { InputWrapper } from '../../Components/Input/InputWrapper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventUnpyAdminFormData = {
  startDateTime: string;
  endSubscribeDate: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    startDateTime: yup.string().nullable(),
    endSubscribeDate: yup.string().nullable(),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    idEvent: number,
  ) =>
  (data: EventUnpyAdminFormData) => {
    eventRepostory
      .updateEventAsAsmin(data, idEvent)
      .then((event) => onSubmitted(event.id));
  };

interface EventCreateFormProps {
  onCreated: (id: number) => void;
  event: UnpyEvent;
}

export const EventUnpyAdminForm: FC<EventCreateFormProps> = ({ onCreated, event }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const t = useTranslate();
  const {
    handleSubmit,
    control,
    register,
    watch,
    setFocus,
    formState: { isSubmitting, errors },
  } = useForm<EventUnpyAdminFormData>({
    resolver,
    // @ts-ignore
    defaultValues: getInitialValueParameterize(t, event),
  });
  const { eventRepository, matchsRepository } = useContextDependency();

  const onSubmit = useOnSubmit(eventRepository, onCreated, event.id);

  const [typeEvent] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeEvent(),
    undefined,
  );

  const launchGarabageClick = () => {
    eventRepository.launchGarabageEventAsAdmin();
  };

  return (
    <Box className={'unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>
              Lancer manuellement le processus de vérification des events a démarrer
            </h3>
            <Button
              className={'btn'}
              variant={'contained'}
              color={'primary'}
              onClick={launchGarabageClick}
            >
              {t('event.form.unpyAdmin.launchGarabageEvent')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.startDateTime')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              notice={
                !isStartDateTimeEditable(event)
                  ? t('event.form.notice.startDateDisabled')
                  : undefined
              }
              inputProps={{
                placeholder: t('event.form.placeholders.startDateTime'),
                disabled: !isStartDateTimeEditable(event) || isSubmitting,
                ...register('startDateTime', { required: true }),
                type: 'datetime-local',
                variant: 'filled',
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.endSubscribeDate')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.endSubscribeDate'),
                disabled: isSubmitting,
                ...register('endSubscribeDate', { required: true }),
                type: 'datetime-local',
                variant: 'filled',
              }}
              required
            />
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons -one'}>
          <LoadingButton
            className={'btn'}
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            loading={isSubmitting}
          >
            {t('event.form.editBtn')}
          </LoadingButton>
        </div>
      </form>
    </Box>
  );
};

type ActionAlertsProps = {
  message: string;
  actionMessage: string;
  action: () => void;
};

export default function ActionAlerts({
  message,
  action,
  actionMessage,
}: ActionAlertsProps) {
  return (
    <Stack
      sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
      spacing={2}
    >
      <Alert
        severity="warning"
        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
        action={
          <Button color="inherit" size="small" onClick={action}>
            {actionMessage}
          </Button>
        }
      >
        {message}
      </Alert>
    </Stack>
  );
}
