import './Balance.scss';

import { Cancel, Check, ExitToApp, GetApp } from '@mui/icons-material';
import { Button, Chip, IconButton } from '@mui/material';
import { CashprizeStatusEnum } from 'domain/event/CashprizeStatus.enum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { PayoutTypeEnum } from 'domain/payout/PayoutType.enum';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import { ComformityStateWrapper } from 'primary/Parameters/user/portefeuille/ComformityStateWrapper';
import { useUserParameters } from 'primary/Parameters/user/useUserParameters.hook';
import { PayoutsList } from 'primary/payout/PayoutList';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type ItemBalanceByEvent = {
  [key: string]: EventParticipant[];
};
type ListBalanceParticipantItemByEventProps = {
  events: UnpyEvent[];
};

const ListBalanceParticipantItemByEvent = ({
  events,
}: ListBalanceParticipantItemByEventProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  if (!events) return null;
  return (
    <div className={'-balanceParticipantItemByEvent'}>
      <h3>{events.length} Événements concernés</h3>
      {events.length > 0 &&
        events.map((event) => {
          const isFundAvailable =
            event.cashprizeStatus === CashprizeStatusEnum.READY_PAYOUT;
          const isSent = event.cashprizeStatus === CashprizeStatusEnum.SENT;
          const isWaiting = event.cashprizeStatus === CashprizeStatusEnum.WAIT;
          return (
            <div className={'-eventParticipantBalanceItem'} key={event.id}>
              <div className={'-nameWithTag'}>
                <div className={'-nameWithAmount'}>
                  <span className={'-eventName'}>{event.name}</span>
                  <span className={'-eventAmount'}>
                    Montant :<span className={'-amount'}>{event.cashprize}</span>€
                  </span>
                  <Button
                    color={'secondary'}
                    style={{ justifySelf: 'flex-end' }}
                    variant={'contained'}
                    onClick={() => {
                      navigate(routesConfig.eventView.to(event.id));
                    }}
                  >
                    <ExitToApp />
                    {t('notification.content.event.goToEvent')}
                  </Button>
                </div>
                <div className={'-tags'}>
                  <span>Conditions de retrait :</span>
                  {isSent && (
                    <Chip
                      size={'small'}
                      className={'-tag'}
                      label="En cours de traitement"
                    />
                  )}
                  {isWaiting && (
                    <Chip
                      size={'small'}
                      className={'-tag'}
                      label="En attente de paiement"
                    />
                  )}
                  <Chip
                    size={'small'}
                    className={'-tag'}
                    color={isFundAvailable ? 'primary' : 'warning'}
                    icon={isFundAvailable ? <Check /> : <Cancel />}
                    label="Fonds disponibles"
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const CashprizeBalance = () => {
  const t = useTranslate();
  const { eventBalanceRepository } = useContextDependency();
  const { user, onSubmitted } = useUserParameters();
  const [balance, , fetching, retry] = useRetrieveFromDomain(
    () => eventBalanceRepository.getBalanceCashprize(),
    undefined,
  );

  const handleCashout = () => {
    eventBalanceRepository.cashoutAvailableEventAmount().then(retry);
  };

  // @ts-ignore
  const staledAmountPArticipantByEvent: UnpyEvent[] =
    balance?.waitingParticipantForCashout.map((p) => p.event);
  // @ts-ignore
  const availableAmountPArticipantByEvent: UnpyEvent[] =
    balance?.readyParticipantForCashout.map((p) => p.event);

  const amountStaled = staledAmountPArticipantByEvent
    ? staledAmountPArticipantByEvent.reduce((acc, key) => {
        return acc + (key.cashprize ?? 0);
      }, 0)
    : 0;

  const amountAvailable = availableAmountPArticipantByEvent
    ? availableAmountPArticipantByEvent.reduce((acc, key) => {
        return acc + (key.cashprize ?? 0);
      }, 0)
    : 0;

  return (
    <PageLayout>
      <Title
        title={t('user.parameters.cashprizeBalance.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <ComformityStateWrapper>
        {fetching && <LoadingScreen loading />}
        {!fetching && (
          <div className={'balancesContainer'}>
            <div className={'-balanceStaled'}>
              <span className={'-amount'}>{amountStaled} €</span>
              <div className={'-infos'}>
                <h2>Montant en attente</h2>
              </div>
              <ListBalanceParticipantItemByEvent
                events={staledAmountPArticipantByEvent}
              />
            </div>
            <div className={'-balanceAvailable'}>
              <span className={'-amount'}>{amountAvailable} €</span>
              <div className={'-infos'}>
                <h2>Montant prêt pour le retrait</h2>
                <IconButton
                  title={'Transférer le solde sur mon compte bancaire.'}
                  onClick={handleCashout}
                  disabled={amountAvailable === 0}
                >
                  <GetApp />
                </IconButton>
              </div>
              <ListBalanceParticipantItemByEvent
                events={availableAmountPArticipantByEvent}
              />
            </div>
          </div>
        )}
        <PayoutsList type={PayoutTypeEnum.CASHPRIZE} />
      </ComformityStateWrapper>
    </PageLayout>
  );
};
