import './PopperButton.scss';

import { MoreVert } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import React, { ReactElement, RefObject, useRef } from 'react';

export type PopperButtonsConf = {
  label?: string;
  icon?: ReactElement;
  onlyIcon?: boolean;
  action?: () => void;
  button?: ReactElement;
};
export type PopperName =
  | 'account-menu'
  | 'structure-item-menu'
  | 'relation-item-menu'
  | 'create-menu'
  | 'notification-menu'
  | 'message-menu'
  | 'user-profil-preview'
  | 'structure-profil-preview';

type PopperButtonProps = {
  conf: PopperButtonsConf[];
  iconPopper?: ReactElement;
  popperName: PopperName;
  placement: PopperPlacementType;
  disabled?: boolean;
  button?: (
    togglePopper: (
      e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
    ) => void,
    ref: RefObject<HTMLButtonElement>,
  ) => ReactElement;
};

export const PopperButton = ({
  conf,
  iconPopper,
  popperName,
  placement,
  button,
}: PopperButtonProps) => {
  const [isOpen, toggle, open, close] = useBooleanToggle();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const handleToggle = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };

  return (
    <div className={'popperButton'}>
      {!!button && button(handleToggle, anchorEl)}
      {!button && (
        <IconButton
          onClick={handleToggle}
          aria-controls={isOpen ? popperName : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          ref={anchorEl}
          style={{ zIndex: 600 }}
        >
          {iconPopper && iconPopper}
          {!iconPopper && <MoreVert />}
        </IconButton>
      )}
      <Popper
        key={popperName}
        open={isOpen}
        anchorEl={anchorEl.current}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener
              onClickAway={(e) => {
                e.preventDefault();
                e.stopPropagation();
                close();
              }}
            >
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'popperButton__menu'}>
                  {conf.map((item) => (
                    <>
                      {!item.button && item.onlyIcon && (
                        <IconButton onClick={item.action}>{item.icon}</IconButton>
                      )}
                      {!item.button && !item.onlyIcon && (
                        <Button
                          color={'inherit'}
                          variant={'text'}
                          className={'linkMenu'}
                          onClick={item.action}
                        >
                          {item.icon && item.icon}
                          {item.label}
                        </Button>
                      )}
                      {item.button && item.button}
                    </>
                  ))}
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </div>
  );
};
