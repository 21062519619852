import './ModalRegisterEvent.scss';

import { Lock, WarningSharp } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { isAuthorizeFromCountLimit } from 'domain/event/UnpyEvent.func';
import { RolesEnum } from 'domain/security/RolesEnum';
import { Team } from 'domain/team/Team';
import { FormStepName } from 'primary/Components/Event/RegisterEventFormStep/FormStepName';
import {
  EventRegisterFormData,
  FormStepPlayers,
  getnitialValuesPlayersFromTypeTeam,
  PlayerItemFormData,
} from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { FormStepValidate } from 'primary/Components/Event/RegisterEventFormStep/FormStepValidate';
import { FormStepper, FormStepperConf } from 'primary/Components/FormStepper/FormStepper';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { UnpyLogoUp } from 'primary/Components/UnpyLogo/UnpyLogoUp';
import { routesConfig } from 'primary/Configs/Routes.config';
import { EventRegisterForm } from 'primary/events/forms/EventRegisterForm';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { PlayersNumberByTypeTeams, TypeTeam } from '../../../domain/event/TypeTeam';
import { Title } from '../../Components/Title/Title';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';

interface ModalRegisterEventProps {
  handleClose: () => void;
  typeTeam: TypeTeam;
  idEvent: number;
  onSubmitted: (eventParticipant?: EventParticipant) => void;
}

//@ts-ignore
const testTooManyChief = (value, context) => {
  const players = context.from[1]?.value;
  if (!value) return false;
  if (!players) return true;
  if (value !== PlayerRoleEnum.CHIEF) return true;
  if (
    Object.values(players).filter(
      //@ts-ignore
      (value1: PlayerItemFormData) => value1?.role === PlayerRoleEnum.CHIEF.toString(),
    ).length >= 2
  ) {
    return false;
  }
  return true;
};
const validationSchema = (typeTeam: TypeTeam) => (t: UseTranslateReturn) =>
  yup.object({
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    structure: yup.string().when('participationAsStructure', {
      is: (val?: boolean) => !!val,
      then: yup
        .string()
        .max(
          ValidationStringLengthEnum.SMALL,
          t('general.forms.errors.tooLong', {
            maxChar: ValidationStringLengthEnum.SMALL,
          }),
        )
        .required(t('general.forms.errors.required')),
      otherwise: yup.string().nullable(),
    }),
    players: yup.object({
      ...PlayersNumberByTypeTeams[typeTeam.code].reduce((acc, value, currentIndex) => {
        return {
          ...acc,
          [`player-${currentIndex}`]: yup.object({
            idPlayer: yup.string().when(`withLink`, {
              is: (val?: boolean) => !val || val?.toString() === 'false',
              then: yup.string().required(t('general.forms.errors.required')),
              otherwise: yup.string().nullable(),
            }),
            role: yup
              .mixed()
              .test('role', t('team.tooManyChief'), testTooManyChief)
              .required(t('general.forms.errors.required')),
          }),
        };
      }, {}),
    }),
  });

export const confStepCreateTeam: (t: UseTranslateReturn) => FormStepperConf = (
  t: UseTranslateReturn,
) => ({
  steps: [
    {
      id: 'step-name',
      title: t('event.register.step.name'),
      component: FormStepName,
    },
    {
      id: 'step-players',
      title: t('event.register.step.players'),
      component: FormStepPlayers,
    },
    {
      id: 'step-finish',
      title: t('event.register.step.finish'),
      component: FormStepValidate,
    },
  ],
});

const ModalRegisterEvent = ({
  handleClose,
  typeTeam,
  idEvent,
  onSubmitted,
}: ModalRegisterEventProps) => {
  const t = useTranslate();
  const { eventRepository, authRepository, eventParticipantRepository } =
    useContextDependency();
  const resolver = useYupValidationResolver(validationSchema(typeTeam as TypeTeam));
  const [teamCreated, setTeamCreated] = React.useState<Team | undefined>(undefined);

  const form = useForm<EventRegisterFormData>({
    resolver,
    defaultValues: {
      name: '',
      structure: '',
      players: {
        ...getnitialValuesPlayersFromTypeTeam(typeTeam, authRepository),
      },
      idEvent: idEvent.toString(),
      participationAsStructure: false,
    },
  });
  console.log('FORM ERR', form.formState.errors);
  const [event] = useRetrieveFromDomain(
    () => eventRepository.getEventById(idEvent),
    undefined,
  );

  const [count, , loading] = useRetrieveFromDomain(
    () => eventParticipantRepository.countEventsParticipationConsume(),
    undefined,
  );
  const isUserUp = authRepository.currentUser?.roles.includes(RolesEnum.USER_UP);
  const isAuthorized = isAuthorizeFromCountLimit(count, isUserUp);
  if (!event) return null;
  return (
    <div className={'modal-register-event'}>
      <Title
        threeQuarter
        bordered
        title={t('event.modal.registerTeam')}
        level={2}
        subTitle={t(
          event.freeRegister
            ? 'event.modal.freeRegisterNotice'
            : 'event.modal.validationRegisterNotice',
        )}
      />
      {event && event.registerPrice && (
        <div className={'-registerPriceNotice'}>
          <>
            <WarningSharp />
            <span>
              Cet Événement à un coût de participation de ${event.registerPrice} € qui
              devra être réglé avant l'envois de la demande de participation
            </span>
          </>
        </div>
      )}
      <div className={'-form'}>
        {loading && <LoadingScreen loading />}
        {!loading && !isAuthorized && (
          <LimiteREgisterEventSubscription handleClose={handleClose} />
        )}
        {!loading &&
          isAuthorized &&
          event &&
          event.typeTeam?.code != TypeTeamCodeEnum.SOL && (
            <FormStepper
              conf={confStepCreateTeam(t)}
              generalProps={{
                event: event,
                typeTeam: typeTeam,
                game: event.game.code,
                teamCreated: teamCreated,
                setTeamCreated: setTeamCreated,
                handleClose: handleClose,
                onSubmitted: onSubmitted,
              }}
            />
          )}
        {!loading &&
          isAuthorized &&
          event &&
          event.typeTeam?.code === TypeTeamCodeEnum.SOL && (
            <EventRegisterForm
              eventId={event.id}
              form={form}
              typeTeam={typeTeam}
              closeModal={handleClose}
              onSubmitted={onSubmitted}
            />
          )}
      </div>
    </div>
  );
};

export default ModalRegisterEvent;

export const LimiteREgisterEventSubscription = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleGotOSubscribe = () => {
    handleClose();
    navigate(routesConfig.up.to());
  };
  return (
    <div className={'-limitParticipateEventSubscription'}>
      <Lock className={'lock-icon'} />
      <span>{t('event.limit.participate')}</span>
      <div className={'notice'}>
        <span>{t('event.limit.participateSubscribeNotice')}</span>
        <UnpyLogoUp />
        <span>{t('event.limit.participateSubscribeNotice2')}</span>
      </div>
      <Button
        variant={'contained'}
        onClick={handleGotOSubscribe}
        color={'primary'}
        className={'-subscribeBtn'}
      >
        {t('home.up.subscribe')}
      </Button>
    </div>
  );
};
