import './Comformity.scss';

import { LoadingButton } from '@mui/lab';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import { SellerAccount } from 'domain/sellerAccount/SellerAccount';
import { User } from 'domain/user/User';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetch } from 'primary/hooks/useFetch';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import { StepperComformity } from 'primary/Parameters/user/portefeuille/StepperComformity';
import { useUserParameters } from 'primary/Parameters/user/useUserParameters.hook';
import React, { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

const SessionAccountManagment = (props: {
  connectedAccount: SellerAccount | null;
  retry: () => void;
}) => {
  const { sellerAccountRepository } = useContextDependency();
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await sellerAccountRepository.getSessionManagement();
      if (response.clientSecret) {
        return Promise.resolve(response.clientSecret);
      } else {
        return Promise.reject('');
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        import.meta.env.VITE_STRIPE_KEY ??
        'pk_test_51MgCtWHdWfj7XXHBUSIE9DOhyidOaFxSuKfmjHUPTh058iLkyzD3ysgdcA8EmCR4MSw16O02nTZbsqpgxeW9muPa00CmEE6vtT',
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: '#FF7800',
          colorBackground: '#202020',
          spacingUnit: '8px',
        },
      },
    });
  });

  return (
    <div className={'-managementContainer '}>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <h2>Mes informations</h2>
        <ConnectAccountManagement />
      </ConnectComponentsProvider>
    </div>
  );
};

const SessionAccountOnboarding = (props: {
  connectedAccount?: SellerAccount | null;
  retry: () => void;
}) => {
  const { sellerAccountRepository } = useContextDependency();
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await sellerAccountRepository.getSessionOnboarding();
      console.log('RESPONSE', response);
      if (response.clientSecret) {
        return Promise.resolve(response.clientSecret);
      } else {
        return Promise.reject('');
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        import.meta.env.VITE_STRIPE_KEY ??
        'pk_test_51MgCtWHdWfj7XXHBUSIE9DOhyidOaFxSuKfmjHUPTh058iLkyzD3ysgdcA8EmCR4MSw16O02nTZbsqpgxeW9muPa00CmEE6vtT',
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: '#FF7800',
          colorBackground: '#202020',
          spacingUnit: '8px',
        },
      },
    });
  });

  return (
    <div className={'-onBoardingContainer '}>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <h2>Activation</h2>
        <ConnectAccountOnboarding onExit={props.retry} />
      </ConnectComponentsProvider>
    </div>
  );
};

export type ActivateComformityFormData = {
  country: number;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    country: yup.string().required(t('general.forms.errors.required')),
  });

type FormActivateConnectedAccountProps = {
  user: User;
  retry: () => void;
  existingAccount?: SellerAccount | null;
};

const useOnSubmit =
  (
    userProfilRepository: IUserProfilRepository,
    setLoading: (loading: boolean) => void,
    onSubmitted: () => void,
  ) =>
  (data: ActivateComformityFormData) => {
    setLoading(true);
    userProfilRepository
      .createSellerAccount(data)
      .then((user) => {
        onSubmitted();
      })
      .finally(() => setLoading(false));
  };

const FormActivateConnectedAccount = ({
  user,
  retry,
  existingAccount,
}: FormActivateConnectedAccountProps) => {
  const { userProfilRepository, countryRepository } = useContextDependency();
  const [countries] = useFetch(() => countryRepository.getAll(), undefined);

  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm<ActivateComformityFormData>({
    resolver,
    defaultValues: {
      country: user.userProfil.country?.id || undefined,
    },
  });
  const { field: countryField } = useController({
    control: control,
    name: 'country',
  });
  const t = useTranslate();

  const [isActionLoading, setIsActionLoading] = useState(false);

  const onSubmit = useOnSubmit(userProfilRepository, setIsActionLoading, retry);

  return (
    <div className={'-enableAccountContainer'}>
      {!existingAccount && <h2>Démarrer l'activation</h2>}
      {existingAccount && <h2>Changer de pays</h2>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          label={t('parameters.menus.profil.user.form.countryLabel')}
          errors={errors}
          inputClassName={'input-user-details '}
          labelClassName={'label-user-details '}
          Input={InputSelectMui}
          isSubmitting={isSubmitting}
          inputProps={{
            placeholder: t('parameters.menus.profil.user.form.countryPlaceholder'),
            ...countryField,
            options: countries?.map((country) => ({
              label: country.libelle,
              value: country.id.toString(),
            })),
            onChange: (e: ChangeHandlerTypeMeta) => {
              setValue('country', e.target.value as number);
              trigger('country');
            },
          }}
        />
        <LoadingButton
          color={'primary'}
          style={{ justifySelf: 'flex-end' }}
          variant={'contained'}
          loading={isActionLoading}
          type={'submit'}
        >
          {!existingAccount ? 'Démarrer' : "Recommencer l'activation"}
        </LoadingButton>
      </form>
    </div>
  );
};

export const Comformity = () => {
  const t = useTranslate();
  const { sellerAccountRepository, userProfilRepository, authRepository } =
    useContextDependency();
  const { user, onSubmitted, retry } = useUserParameters();
  const [connectedAccount, , fetching, retryAccount] = useRetrieveFromDomain(
    () => sellerAccountRepository.getDetails(),
    undefined,
  );

  const requirement = [...(connectedAccount?.requirements?.currentlyDue || [])];

  return (
    <PageLayout>
      <Title
        title={t('user.parameters.comformity.title')}
        level={1}
        compensatePadding
        threeQuarter
        bordered
      />
      <StepperComformity
        retry={retryAccount}
        requirement={requirement}
        requirementFuture={connectedAccount?.requirements?.eventuallyDue || []}
        currentComformityStatus={user?.sellerAccountComformity}
      />
      {user && !fetching && (
        <FormActivateConnectedAccount
          user={user}
          retry={() => {
            retry();
            retryAccount();
          }}
          existingAccount={connectedAccount}
        />
      )}

      {connectedAccount && !fetching && connectedAccount?.detailsSubmitted && (
        <SessionAccountManagment connectedAccount={connectedAccount} retry={retry} />
      )}

      {connectedAccount && !fetching && !connectedAccount?.detailsSubmitted && (
        <SessionAccountOnboarding connectedAccount={connectedAccount} retry={retry} />
      )}
    </PageLayout>
  );
};

type ConformityStateProps = {
  user: User;
};
