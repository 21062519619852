import './FilterTalkLang.scss';

import { insertEmptyOpt } from 'helper/options.helper';
import { InputSelectTag } from 'primary/Components/Input/InputSelectTag';
import { ChangeHandlerTypeMeta } from 'primary/Components/Input/InputWrapper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useFetch } from '../../hooks/useFetch';

type FilterPlatformProps = {
  onSelectPlatform: (value?: string) => void;
  selectedPlatform?: string;
};

export const FilterPlatform = ({
  onSelectPlatform,
  selectedPlatform,
}: FilterPlatformProps) => {
  const t = useTranslate();
  const { platformRepository } = useContextDependency();
  const [platforms] = useFetch(() => platformRepository.getAll(), undefined);
  if (!platforms) return null;

  return (
    <InputSelectTag
      placeholder={t('mercato.filters.placeholders.platforms')}
      options={insertEmptyOpt(
        platforms.map((type) => ({
          // @ts-ignore
          label: t(`enum.platforms.${type.code}`),
          value: type.code,
        })),
      )}
      onChange={(event: ChangeHandlerTypeMeta) => {
        //@ts-ignore
        onSelectPlatform(event.target.value as string[]);
      }}
      value={selectedPlatform}
      menuPortalTarget={document.body}
    />
  );
};
