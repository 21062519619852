import './StepperComformity.scss';

import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material';
import { Collapse, Hidden, IconButton, Stepper } from '@mui/material';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import classNames from 'classnames';
import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

const getStepIndexByState = (state: SellerAccountConformityEnum) => {
  switch (state) {
    case SellerAccountConformityEnum.NO_COMFORMITY:
      return 0;
    case SellerAccountConformityEnum.PENDING_COMFORMITY:
    case SellerAccountConformityEnum.ERROR_COMFORMITY:
      return 1;
    case SellerAccountConformityEnum.COMFORM:
      return 2;
    default:
      return 0;
  }
};

type StepperComformityProps = {
  currentComformityStatus?: SellerAccountConformityEnum;
  retry: () => void;
  requirement: string[];
  requirementFuture: string[];
};
export const StepperComformity = ({
  currentComformityStatus,
  retry,
  requirement,
  requirementFuture,
}: StepperComformityProps) => {
  const { userProfilRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const stepIndex = currentComformityStatus
    ? getStepIndexByState(currentComformityStatus)
    : 0;
  const [isMobileCollapseedTimelinte, setIsMobileCollapseedTimelinte] =
    React.useState(false);
  const steps = [
    {
      codes: [SellerAccountConformityEnum.NO_COMFORMITY],
      label: 'Activation de votre compte vendeur',
      description: (
        <div className={'stepperComformityItemDesc'}>
          <span>Ce niveau de vérification vous permets de :</span>
          <ul style={{ listStyle: 'none' }}>
            <li>
              <Check color={'success'} />
              Créer des Événements avec des frais d'inscription
            </li>
            <li>
              <Check color={'success'} />
              Participer à des Événements avec un cashprize
            </li>
          </ul>
        </div>
      ),
    },
    {
      codes: [SellerAccountConformityEnum.PENDING_COMFORMITY],
      label: 'Informations complémentaires',
      description: (
        <div className={'stepperComformityItemDesc'}>
          {stepIndex === 1
            ? 'Unpy à besoins de quelques informations supplémentaire pour activer les paiements'
            : 'Unpy est succeptible de vous demander des informations complémentaire en fonction des montants encaissé et certaines informations.'}
        </div>
      ),
    },
    {
      codes: [SellerAccountConformityEnum.COMFORM],
      label: 'Vous êtes conforme !',
      needInformations: 'Aucune informations supplémentaire nécessaires',
      description: (
        <div className={'stepperComformityItemDesc'}>
          Ce niveau de vérification vous permets de :
          <ul style={{ listStyle: 'none' }}>
            <li>
              <Check color={stepIndex === 2 ? 'success' : undefined} />
              Retirer les recettes liées aux inscriptions d'Événements sur votre compte
              bancaire
            </li>
            <li>
              <Check color={stepIndex === 2 ? 'success' : undefined} />
              Retirer les prix perçus suite à la participation à des Événements
            </li>
          </ul>
        </div>
      ),
      icon: stepIndex === 2 ? Check : undefined,
    },
  ];

  console.log('stepIndex', stepIndex);
  console.log('currentComformityStatus', currentComformityStatus);
  return (
    <>
      <Hidden smDown>
        <Stepper
          className={'stepperComformity--horizontal'}
          alternativeLabel
          activeStep={stepIndex}
        >
          {steps.map((step, index) => (
            <Step
              key={step.label}
              className={classNames('-stepComformity', {
                '-active': index === stepIndex,
                '-completed': index < stepIndex,
                '-toComplete': index > stepIndex,
              })}
            >
              <StepLabel
                className={'-label'}
                StepIconComponent={step.icon as React.ElementType<StepIconProps>}
              >
                {step.label}
              </StepLabel>
              <div className={'-description'}>{step.description}</div>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <Hidden smUp>
        <div className={'-headerCollapseMobile'}>
          <h3>
            État :
            {t(
              `enum.comformity.${currentComformityStatus as SellerAccountConformityEnum}`,
            )}
          </h3>
          <IconButton
            onClick={() => setIsMobileCollapseedTimelinte(!isMobileCollapseedTimelinte)}
          >
            {isMobileCollapseedTimelinte ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        </div>
        <Collapse in={isMobileCollapseedTimelinte}>
          <Stepper
            className={'stepperComformity--horizontal'}
            alternativeLabel
            activeStep={stepIndex}
            orientation={'vertical'}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                className={classNames('-stepComformity', {
                  '-active': index === stepIndex,
                  '-completed': index < stepIndex,
                  '-toComplete': index > stepIndex,
                })}
              >
                <StepLabel
                  className={'-label'}
                  StepIconComponent={step.icon as React.ElementType<StepIconProps>}
                >
                  {step.label}
                </StepLabel>
                <div className={'-description'}>{step.description}</div>
              </Step>
            ))}
          </Stepper>
        </Collapse>
      </Hidden>
    </>
  );
};
