import { AxiosInstance } from 'axios';
import { ConnectedAccountSessionManagement } from 'domain/sellerAccount/ConnectedAccountSessionManagement';
import { ISellerAccountRepository } from 'domain/sellerAccount/ISellerAccount.repository';
import { SellerAccount } from 'domain/sellerAccount/SellerAccount';
import { AxiosProviderRepository } from 'secondary/AxiosProviderRepository';
import { toConnectedAccountSessionManagemnent } from 'secondary/sellerAccount/RestConnectedAccountSessionManagement';
import { toSellerAccount } from 'secondary/sellerAccount/RestSellerAccount';

export class SellerAccountRepository
  extends AxiosProviderRepository
  implements ISellerAccountRepository
{
  constructor(readonly axios: AxiosInstance) {
    super(axios);
  }

  getDetails(): Promise<SellerAccount | null> {
    return this.axios
      .get('/unpy/api/connected-account')
      .then((resp) => toSellerAccount(resp.data));
  }

  getSessionManagement(): Promise<ConnectedAccountSessionManagement> {
    return this.axios
      .post('/unpy/api/connected-account/management')
      .then((resp) => toConnectedAccountSessionManagemnent(resp.data));
  }

  getSessionOnboarding(): Promise<ConnectedAccountSessionManagement> {
    return this.axios
      .post('/unpy/api/connected-account/onboarding')
      .then((resp) => toConnectedAccountSessionManagemnent(resp.data));
  }
}
