import './ProfilTabs.scss';

import { Grid } from '@mui/material';
import classNames from 'classnames';
import { differenceInYears } from 'date-fns';
import { GamingProfilEnum } from 'domain/enums/GamingProfil.enum';
import { GAME_IMAGES } from 'domain/game/GameActiveList';
import { Profil } from 'domain/profil/Profil';
import {
  getNeonBorderClassName,
  isStructureProfil,
  isUserProfil,
} from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { UserProfil } from 'domain/profil/UserProfil';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { NoGamesIllustration } from 'primary/Components/NoGames/NoGamesIllustration';
import { ProfilContext } from 'primary/Components/Profils/structure/StructureProfilCard';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

type ProfilTabsDetailCompProps = {
  profil: Profil;
};

export const ProfilTabsDetails = () => {
  const context = useOutletContext<ProfilContext | undefined>();
  if (!context) return null;
  return <ProfilTabsDetailComp profil={context.profil} />;
};

function getLinkVideo(video: string) {
  if (video.includes('youtube')) {
    if (video.includes('embed')) return video;
    return video.replace('watch?v=', 'embed/');
  }
  return video;
}

export const ProfilTabsDetailComp = ({ profil }: ProfilTabsDetailCompProps) => {
  const t = useTranslate();
  const neonBorderClassName = getNeonBorderClassName(profil);
  return (
    <Grid container className={'profilTabs'}>
      <Grid container xs={12} className={'-firstRow'}>
        <Grid xs={12} sm={6}>
          <div className={'-blocInfos'}>
            <h3 className={'-title'}>{t('myProfil.titleInformations')}</h3>
            <div className={'-infosText'}>
              <KeyValue
                className={'-keyValue'}
                alignStart
                keyValue={t('myProfil.gamingExperience')}
                value={
                  profil.gamingProfil
                    ? t(
                        `enum.gamingExperience.${
                          profil.gamingProfil as GamingProfilEnum
                        }`,
                      )
                    : t('general.keyValueNoValue')
                }
                accent
              />
              {isUserProfil(profil) && (
                <KeyValue
                  className={'-keyValue'}
                  alignStart
                  keyValue={t('myProfil.age')}
                  accent
                  value={
                    profil.birthdate
                      ? t('myProfil.ageValue', {
                          count: differenceInYears(
                            new Date(),
                            new Date(profil.birthdate),
                          ),
                        })
                      : 'Non renseigné'
                  }
                />
              )}
              {isStructureProfil(profil) && (
                <KeyValue
                  className={'-keyValue'}
                  alignStart
                  keyValue={t('myProfil.membersNumber')}
                  accent
                  value={
                    (profil as StructureProfil).structure?.members
                      ? (profil as StructureProfil).structure?.members?.length.toString()
                      : 'Non renseigné'
                  }
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6}>
          <div className={'-blocInfos'}>
            <h3 className={'-title'}>{t('myProfil.gamesPlayed')}</h3>
            <div className={classNames('-playedGames', '--scrollBarContent')}>
              {profil.games?.map((gameProfil) => (
                <img
                  key={gameProfil.game.code}
                  alt={`game-img-${gameProfil.game.code}`}
                  src={GAME_IMAGES[gameProfil.game.code]}
                  width={'100px'}
                  height={'100px'}
                />
              ))}
              {profil?.games?.length === 0 && <NoGamesIllustration />}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12} className={'-firstRow'}>
        <Grid xs={12} sm={6}>
          <div className={'-blocInfos'}>
            <div className={'-description'}>
              <h3 className={'-title'}>{t('myProfil.titleDescription')}</h3>
              <div
                className={classNames('text-area', neonBorderClassName, '-size__medium', {
                  '-noContent': !profil.description,
                })}
                dangerouslySetInnerHTML={{
                  __html: profil.description || t('profil.details.description.noContent'),
                }}
              ></div>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6}>
          <div className={'-blocPromos'}>
            <h3 className={'-title'}>{t('myProfil.videoPromo')}</h3>
            {profil?.video && (
              <iframe
                title={`${
                  isStructureProfil(profil)
                    ? profil.name
                    : (profil as UserProfil).idPlayer
                } promotion video`}
                width={'100%'}
                height={'100%'}
                src={getLinkVideo(profil.video)}
              ></iframe>
            )}
            {!profil?.video && (
              <div className={'-noContentVideoPromo'}>{t('myProfil.noVideoPromo')}</div>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
