import { RoutesList } from './Routes.config';

type ParametersRoutesUserKey =
  | 'replaceDefault'
  | 'profilUtilisateur'
  | 'profilAppearence'
  | 'profilNetwork'
  | 'interests'
  | 'accountPersonnalData'
  | 'accountWallet'
  | 'accountTransaction'
  | 'account'
  | 'eventsAdmin';
// @ts-ignore
export const parametersRoutesUser: RoutesList<ParametersRoutesUserKey> = {
  replaceDefault: {
    to: 'profil/utilisateur',
    path: 'profil/utilisateur',
  },
  profilUtilisateur: {
    path: '/parameters/user/profil/utilisateur',
    to: () => '/parameters/user/profil/utilisateur',
  },
  profilAppearence: {
    path: '/parameters/user/profil/apparence',
    to: () => '/parameters/user/profil/apparence',
  },
  profilNetwork: {
    path: '/parameters/user/profil/network',
    to: () => '/parameters/user/profil/network',
  },
  interests: {
    path: '/parameters/user/profil/interests',
    to: () => '/parameters/user/profil/interests',
  },
  accountPersonnalData: {
    path: '/parameters/user/account/personnal',
    to: () => '/parameters/user/account/personnal',
  },
  accountTransaction: {
    path: '/parameters/user/account/transactions',
    to: () => '/parameters/user/account/transactions',
  },
  account: {
    path: '/parameters/user/account',
    to: () => '/parameters/user/account',
  },
  eventsAdmin: {
    path: '/parameters/user/admin/events',
    to: () => '/parameters/user/admin/events',
  },
  sells: {
    path: '/parameters/user/wallet/sells',
    to: () => '/parameters/user/wallet/sells',
  },
  prizes: {
    path: '/parameters/user/wallet/prize',
    to: () => '/parameters/user/wallet/prize',
  },
  comformity: {
    path: '/parameters/user/wallet/comform',
    to: () => '/parameters/user/wallet/comform',
  },
};
