import './HelperPopper.scss';

import { InfoOutlined } from '@mui/icons-material';
import {
  ClickAwayListener,
  IconButton,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import classNames from 'classnames';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React, { FC, ReactNode } from 'react';

type HelperPopperProps = {
  children: React.ReactNode;
  color?: 'black' | 'white';
  modal?: {
    title?: string | ReactNode;
  };
  placement?: PopperPlacementType;
};

export const HelperPopper: FC<HelperPopperProps> = ({
  children,
  color,
  modal,
  placement,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <div
      className={classNames('helper', {
        '-black': color === 'black',
        '-white': color === 'white',
      })}
    >
      <IconButton aria-describedby={id} type="button" onClick={handleClick}>
        <InfoOutlined />
      </IconButton>
      {!modal && (
        <Popper id={id} open={open} anchorEl={anchorEl} placement={placement ?? 'bottom'}>
          <ClickAwayListener onClickAway={handleClose}>
            <div className={'-popperContainerHelper --scrollBarContent'}>{children}</div>
          </ClickAwayListener>
        </Popper>
      )}
      {modal && (
        <ClickAwayListener onClickAway={handleClose}>
          <ModalContainer
            header={
              modal.title ? { title: modal.title, withButtonClose: true } : undefined
            }
            fullScreen
            handleClose={handleClose}
            isOpen={!!anchorEl}
            content={children}
          />
        </ClickAwayListener>
      )}
    </div>
  );
};
